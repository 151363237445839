import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { OrganisationType } from "../../../../_App/Types/Entites/OrganisationType";
import styled from "styled-components";
import {
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
} from "react-hook-form";
import { AffectationMenuDataType } from "../../../../_App/Types/Forms/AppMenus/AffectationMenuDataType";
import _ from "lodash";
import { UserRole } from "../../../../_App/Types/Entites/UserRole";

const StyledOrg = styled.div`
  height: 100% !important;

  .clearboth {
    clear: both;
    margin-top: -5px;
  }
  .component-card_1,
  .col-org {
    height: 95%!important;
    margin-bottom:15px;
  }
  .user-ligne {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 30px;
    padding: 5px;
    border-radius: 15px;
    cursor: pointer;
  }
  .card-body {
    padding-top: 15px;
  }
  .user-ligne:hover {
    background: #bdfdc4;
    border-radius: 15px;
  }
  .user-name {
    width: 250px;
  }
  .user-role,
  .user-role > span {
    width: 100px;
    font-weight: 400 !important;
  }
`;
const RoleItemMenu = ({
  role,
  register,
  getValues,
  setValue,
}: {
  role: UserRole;
  register: UseFormRegister<AffectationMenuDataType>;
  getValues: UseFormGetValues<AffectationMenuDataType>;
  setValue: UseFormSetValue<AffectationMenuDataType>;
}) => {


    const [checkRole, setcheckRole] = useState<boolean>(false);

    useEffect(() => {
      console.log('role', role)
    }, [role])
    
    

    /**
     * @desc check or uncheck user 
     * @param role_id string
     * @param user_id string
     */
  const onCheckUser = (role_id: string, user_id: string) => {
    
    const currentValue = getValues(`roles.${role_id}.${user_id}`);

    if (_.isUndefined(currentValue) || currentValue== false) {
      setValue(`roles.${role_id}.${user_id}`, true);
    } else {
      setValue(`roles.${role_id}.${user_id}`, false);
    }
  };

  
    /**
     * @desc check or uncheck user 
     * @param role_id string
     * @param user_id string
     */
    const onCheckRole = (role_id: string) => {
    
        const role = getValues(`roles.${role_id}`);

        _.each(role, (check: boolean, user_id: string) => {
            if(checkRole) {
                setValue(`roles.${role_id}.${user_id}`, false);  
            }else{
                setValue(`roles.${role_id}.${user_id}`, true);  
            }
        })
    
      };

  return (
    <div className=" col-xs-12 col-sm-12 col-md-6 col-lg-3 col-org">
      <StyledOrg>
        <div className={`card component-card_1 relative`}>
          <div className="card-body">
            <div className="user-ligne" onClick={()=> {
                setcheckRole(!checkRole);
                onCheckRole(role._id);
            }}>
              <div className="user-name">
                <div
                  className="text-center c-primary float-left"
                  style={{ fontSize: "21px" }}
                >
                  {role.libelle}
                </div>
              </div>
              
              <div className="user-check">
                <input type="checkbox" checked={checkRole}  
                        onChange={(e : BaseSyntheticEvent)=> {
                            setcheckRole(e.target.value);
                            onCheckRole(role._id);
                        }}/>
              </div>
            </div>
            <div className="clearboth">
              <hr />
            </div>
            {role.users &&
              role.users.map((user: any, indexUs: number) => (
                <div
                  className="user-ligne"
                  key={indexUs}
                  onClick={(e) => onCheckUser(role._id, user._id)}
                >
                  <div className="user-name">
                    {user.nom} {user.prenom}
                  </div>
                 
                  <div className="user-check">
                    <input
                      type="checkbox"
                      {...register(
                        `roles.${role._id}.${user._id}`
                      )}
                    />
                  </div>
                </div>
              ))}
          </div>
        </div>
      </StyledOrg>
    </div>
  );
};

export default RoleItemMenu;
