// i18n.ts
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { selectUser, userLang } from "./_App/Redux/Slices/Auth/authSlice"; // Import setLanguage action
import { useAppSelector } from "./_App/Redux/hooks";
import translationEN from "./locales/en/translation.json";
import translationFR from "./locales/fr/translation.json";
import translationAR from "./locales/ar/translation.json";

const I18nInitializer: React.FC = () => {
  const langue = useAppSelector(userLang);

  i18n.use(initReactI18next).init({
    resources: {
      en: { translation: translationEN },
      fr: { translation: translationFR },
      ar: { translation: translationAR },
    },
    lng: langue, // Use the user's preference or fallback to a default
    fallbackLng: "fr",
    interpolation: {
      escapeValue: false,
    },
  });

  return null; // This component doesn't render anything
};

export default I18nInitializer;
