import React, { useState, useEffect, useRef } from "react";
import { Image, Modal } from "react-bootstrap";
import JsBarcode from "jsbarcode";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { ListProductCaracteristiques, findProductsCaracteristiquesApiBySociete } from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import ReactToPrint from "react-to-print";

export default function Codebarreartice({
  t,
  ListArticle,
  ShowModal,
  setShowModal,
  societeId
}: any) {
  const dispatch = useAppDispatch()
  const componentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if(societeId) dispatch(findProductsCaracteristiquesApiBySociete(societeId))
  }, [societeId]);

  const listcaracteristique = useAppSelector(ListProductCaracteristiques)
  //console.log('listcaracteristique',listcaracteristique)
  const [checkboxState, setCheckboxState] = useState({
    Produit: true,
    code_article: true,
    Color: true,
    Size: true,
    prixttc: true,
    prixht: true,
  });

  const codeabarreimage = (productdetailitem: any) => {
    const code_a_barre = productdetailitem.code_a_barre[0];

    const canvas = document.createElement("canvas");
    JsBarcode(canvas, code_a_barre, {
      format: "CODE128", // Change the format to CODE128
      displayValue: true,
    });

    const imageDataUrl = canvas.toDataURL("image/png");
    return imageDataUrl;
  };

  const reset = () => {
    const defaultstatecheck = {
      Produit: true,
      code_article: true,
      Color: true,
      Size: true,
      prixttc: true,
      prixht: true,
    };
  };

 
  const checkItem = (type: any, status: any) => {
    setCheckboxState((prevData) => ({ ...prevData, [type]: status }));
  };
 /* const downloadTickets = () => {
    const modalContent = document.getElementById("modeltickets");

    if (modalContent) {
      // Temporarily apply print-specific styles
      const printStyles = `
        @media print {
          body * {
            visibility: hidden;
          }
          #modeltickets, #modeltickets * {
            visibility: visible;
            margin-bottom: 15px; 

          }
          #modeltickets {
            position: flex;
            left: 0;
            top: 0;

          }
         
        }
      `;

      const styleElement = document.createElement("style");
      styleElement.type = "text/css";
      styleElement.appendChild(document.createTextNode(printStyles));
      document.head.appendChild(styleElement);
      window.print();

      document.head.removeChild(styleElement);
    } else {
      console.error("Content to print not found");
    }
  };*/
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-90w"
      className="modal-4"
      backdropClassName="modal-backdrop-4"
    >
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">
          {t('Code a barre')}:
          
          <ReactToPrint
        trigger={() => (
          <button
            className="btn btn-sm btn-primary btn-primary-app"
          >
            <i className="fas fa-print fa-lg"></i>
          </button>
        )}
        content={() => componentRef.current}
      />
   
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="d-flex flex-wrap justify-content-center"
          ref={componentRef}
        >
          {ListArticle.length !== 0 ? (
            ListArticle.map((item: any) => (
              <div key={item._id} className="mr-4 mb-4 mt-5  text-center">
                {true ? <Image src={codeabarreimage(item)} fluid /> : null}
                <div className="mt-3">
                  <span
                    style={{
                      display: checkboxState.Produit ? "inline-block" : "none",
                    }}
                  >
                    {" "}
                    {item?.titre.fr} -{" "}
                  </span>
                  <span
                    style={{
                      display: checkboxState.code_article
                        ? "inline-block"
                        : "none",
                    }}
                  >
                    {" "}
                    {item?.code_article}
                  </span>
                  <span>
                   
                  {item?.caracteristique?.map((element :any) =>{
                      <p>{listcaracteristique.find(car => car._id === element.caracteristique_id)?.libelle}</p>
                    
                  })}
                  </span>
                </div>
                <div>
                
                
                </div>
                <div>
                  {
                    <span
                      style={{
                        display: checkboxState.prixttc
                          ? "inline-block"
                          : "none",
                      }}
                    >
                      {t('Prix TTC')}: {item.prix_vente_ttc} -{" "}
                    </span>
                  }
                  {
                    <span
                      style={{
                        display: checkboxState.prixht ? "inline-block" : "none",
                      }}
                    >
                      {t('Prix HT')}: {item.prix_vente_ht}
                    </span>
                  }
                </div>
              </div>
            ))
          ) : (
            <p className="text-center">{t('Aucun détail')}</p>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="modal-footer-padding{">
        <div>
          <span onClick={reset}>
            <i className="fas fa-redo-alt"></i>{" "}
          </span>

          <span className="ml-5">
            <input
              type="checkbox"
              checked={checkboxState.Produit}
              onChange={() => checkItem("Produit", !checkboxState.Produit)}
            />{" "}
            {t('Produit titre')}{" "}
          </span>
          <span className="ml-5">
            <input
              type="checkbox"
              checked={checkboxState.code_article}
              onChange={() =>
                checkItem("code_article", !checkboxState.code_article)
              }
            />{" "}
            {t('Code Article')}{" "}
          </span>

          <span className="ml-5">
            <input
              type="checkbox"
              checked={checkboxState.prixttc}
              onChange={() => checkItem("prixttc", !checkboxState.prixttc)}
            />{" "}
            {t('Prix TTC')}{" "}
          </span>

          <span className="ml-5">
            <input
              type="checkbox"
              checked={checkboxState.prixht}
              onChange={() => checkItem("prixht", !checkboxState.prixht)}
            />{" "}
            {t('Prix HT')}{" "}
          </span>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

/*    <span  className='ml-5' > 
                <input
                  type="checkbox"
                  checked={checkboxState.Color}
                  onChange={() => checkItem('Color', !checkboxState.Color)}
                />
                {' '}
                Couleur  {' '}
              </span>
          
         
              <span  className='ml-5'  >
                <input
                  type="checkbox"
                  checked={checkboxState.Size}
                  onChange={() => checkItem('Size', !checkboxState.Size)}
                />
                {' '}
                Taille  {' '}
              </span>*/
