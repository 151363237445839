import React from "react";
import styled from "styled-components";
import ParametrageMenuList from "../ParametrageMenus/ParametrageMenuList";
import MouvementCaisse from "../../../Pages/Dashboard/Modules/MouvementCaisse";
import CaisseParametrageForm from "./CaisseParametrageForm";

const StyledNavbar = styled.div`
  .menu-vertical {
    background: rgb(224, 240, 239);
    padding-top: 20px;
    padding-bottom: 10px;
    width: 13%;
  }
  .content-vertical {
    width: 87%;
  }
  @media (min-width: 768px) {
    .col-md-2 {
      flex: 0 0 auto;
      width: 16%;
    }
    .col-md-10 {
      flex: 0 0 auto;
      width: 83%;
    }
  }

  .btn-menu {
    border: 0px solid rgba(3, 134, 122, 0.11) !important;
    color: #009688 !important;
    background: rgba(3, 134, 122, 0.11) !important;
    height: 90px;
  }
  .btn-menu:hover {
    border: 1px solid #009688 !important;
    color: #009688 !important;
    background: rgba(3, 134, 122, 0.11) !important;
  }

  .btn-menu.active {
    border: 1px solid #009688 !important;
    color: #009688 !important;
    background: rgba(3, 134, 122, 0.11) !important;
  }
`;

const ParametrageTabs = () => {
  return (
    <StyledNavbar>
      <div className="rounded-vertical-pills-icon">
        <div className="row">
          <div className="col-xs-1 col-sm-1 col-md-2 col-lg-1 menu-vertical">
            <div
              className="nav flex-column nav-pills mb-sm-0 mb-3"
              id="AllParametrage"
              role="tablist"
              aria-orientation="vertical"
            >
              <a
                className="nav-link mb-2 pt-3 mx-auto btn-menu active"
                id="param-profile-tab"
                data-toggle="pill"
                href="#param-profile"
                role="tab"
                aria-controls="param-profile"
                aria-selected="true"
              >
                <i className="far fa-user"></i> Profile
              </a>
              <a
                className="nav-link mb-2  pt-3 mx-auto btn-menu"
                id="rounded-vertical-pills-home-tab"
                data-toggle="pill"
                href="#rounded-vertical-pills-home"
                role="tab"
                aria-controls="rounded-vertical-pills-home"
                aria-selected="false"
              >
                <i className="far fa-list-alt"></i>Menu
              </a>
              <a
                className="nav-link mb-2  pt-3 mx-auto btn-menu"
                id="rounded-vertical-pills-home-tab"
                data-toggle="pill"
                href="#rounded-vertical-pills-mouv"
                role="tab"
                aria-controls="rounded-vertical-pills-home"
                aria-selected="false"
              >
                <i className="far fa-list-alt"></i>Types mouvements
              </a>
              <a
                className="nav-link mb-2  pt-3 mx-auto btn-menu"
                id="rounded-vertical-pills-home-tab"
                data-toggle="pill"
                href="#rounded-vertical-pills-caisse"
                role="tab"
                aria-controls="rounded-vertical-pills-home"
                aria-selected="false"
              >
                <i className="far fa-list-alt"></i>Caisse
              </a>
              <a
                className="nav-link mb-2  pt-3 mx-auto btn-menu "
                id="rounded-vertical-pills-messages-tab"
                data-toggle="pill"
                href="#rounded-vertical-pills-messages"
                role="tab"
                aria-controls="rounded-vertical-pills-messages"
                aria-selected="false"
              >
                <i className="fas fa-print"></i> Tickets
              </a>
            </div>
          </div>

          <div className="col-xs-11 col-sm-11 col-md-10 col-lg-11 content-vertical">
            <div className="tab-content" id="AllParametrageContent">
              <div
                className="tab-pane fade active show"
                id="param-profile"
                role="tabpanel"
                aria-labelledby="rounded-vertical-pills-profile-tab"
              >
                <div className="mt-3">
                  <h2 className="text-center">Parametrage Profile</h2>
                  <h2 className="text-center">Coming soon...</h2>
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="rounded-vertical-pills-home"
                role="tabpanel"
                aria-labelledby="rounded-vertical-pills-home-tab"
              >
                <div className="mt-3">
                  <ParametrageMenuList />
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="rounded-vertical-pills-caisse"
                role="tabpanel"
                aria-labelledby="rounded-vertical-pills-home-tab"
              >
                <div className="mt-3">
                  <CaisseParametrageForm />
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="rounded-vertical-pills-mouv"
                role="tabpanel"
                aria-labelledby="rounded-vertical-pills-home-tab"
              >
                <div className="mt-3">
                  {" "}
                  <MouvementCaisse />
                </div>
              </div>

              <div
                className="tab-pane fade"
                id="rounded-vertical-pills-messages"
                role="tabpanel"
                aria-labelledby="rounded-vertical-pills-messages-tab"
              >
                <div className="mt-3">
                  <h2 className="text-center">Parametrage Tickets</h2>
                  <h2 className="text-center">Coming soon...</h2>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledNavbar>
  );
};

export default ParametrageTabs;
