import React, { useEffect, useMemo, useState } from "react";
import { Modal } from "react-bootstrap";
import { areArraysEqual, calculTTC_Remise } from "../../../_App/Helpers/helpers";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  ListProductCaracteristiques,
  findProductsCaracteristiquesApiBySociete,
} from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
export default function MatriceAchat({
  ShowModal,
  setShowModal,
  matrice,
  quantite,
  index,
  inputs,
  setInputs,
  taxes,
  listProduits,
  societe,
  t
}: any) {
  const [list, setlist] = useState<any[]>([[], [], []]);
  const [dimension, setdimension] = useState<any[]>([]);
  const [checkedlist, setcheckedlist] = useState<any[]>([]);
  const [Qunatiteinput, setQunatiteinput] = useState(0);
  const dispatch = useAppDispatch();
  const listcaracteristique = useAppSelector(ListProductCaracteristiques);
  useEffect(() => {
    if (societe) {
      dispatch(findProductsCaracteristiquesApiBySociete(societe));
    }
  }, [societe]);
  useEffect(() => {
    setcheckedlist([]);
    setlist([[], [], []]);

    if (matrice?._id) {
      setlist((prev: any) => {
        const newListArray = [...prev];
        newListArray[0] = matrice?.list_dimension1;
        return newListArray;
      });
      setlist((prev: any) => {
        const newListArray = [...prev];
        newListArray[1] = matrice?.list_dimension2;
        return newListArray;
      });
      setlist((prev: any) => {
        const newListArray = [...prev];
        newListArray[2] = matrice?.list_dimension3;
        return newListArray;
      });
      setdimension([
        matrice?.dimension1,
        matrice?.dimension2,
        matrice?.dimension3,
      ]);
      setcheckedlist(matrice.checked);
      setInputs((prevInputs: any) => {
        const newInputs = [...prevInputs];
        newInputs[index] = { ...newInputs[index], type: 'commentaire' , show: false}; 
        return newInputs;
      });
    }
  }, [ShowModal]);

  const handleChange = (value: number, item0: any, item1: any, item2: any) => {
    const item = { dimension: [item0, item1, item2], valeur: value };
    const caracteristique = [
      { caracteristique_id: dimension[0], value: item0 },
      { caracteristique_id: dimension[1], value: item1 },
      ...(item.dimension[2]
        ? [{ caracteristique_id: dimension[2], value: item2 }]
        : []),
    ];
    // chercher le produit fils => parent_id === inputs[index].product_id  and p.caracrecterstique =caracteristique
    const filsproduit = listProduits.find(
      (item: any) =>
        item.type === "fils" &&
        item.produitparent === inputs[index].product_id &&
        caracteristiqueEqual(item.caracteristiques, caracteristique)
    );

    function caracteristiqueEqual(arr1: any[], arr2: any[]): boolean {
      if (arr1.length !== arr2.length) {
        return false;
      }

      for (let i = 0; i < arr1.length; i++) {
        if (
          arr1[i].caracteristique_id !== arr2[i].caracteristique_id ||
          arr1[i].value !== arr2[i].value
        ) {
          return false;
        }
      }

      return true;
    }

    //TESTER SI LE PRODUIT FILS EXIST OU NN
    if (filsproduit) {
      const indexfils = inputs.findIndex(
        (item: any) => item.product_id === filsproduit?._id
      );
      if (indexfils !== -1) {
        // update la ligne
        setInputs((prevInputs: any) => {
          const newInputs = [...prevInputs];
          newInputs[indexfils] = { ...newInputs[indexfils], quantite: value ,  total_prix_ttc: parseFloat(
            (filsproduit?.prix_achat_ttc * value).toFixed(3)
          ),
          total_net_ttc: calculTTC_Remise(
            filsproduit?.prix_achat_ttc || 0,
            value,
            inputs[index]?.remise
          ),
          total_prix_ht: parseFloat(
            (filsproduit?.prix_achat_ht * value).toFixed(3)
          ), };

          // Update the details for an existing item
          if (index < newInputs.length) {
            const ledetail = newInputs[index].details;

            // Filter out elements with equal dimensions
            const filteredDetails = ledetail.filter(
              (element: any) =>
                !areArraysEqual(element.dimension, item.dimension)
            );

            // Update the details for an existing item

            newInputs[index] = {
              ...newInputs[index],
              details: [...filteredDetails, item],
              quantite: sumOfValeur
            };
          }

          return newInputs;
        });
      } else {
        // ajouter le nouveau ligne + AJOUT DETAIL POUR LIGNE PARENT
        setInputs((prevInputs: any) => {
          const newInputs = [...prevInputs];
          const detailsArray = newInputs[index]?.details || [];

          // Add the new item to the inputs
          newInputs.push({
            produit: filsproduit,
            product_id: filsproduit?._id,
            tva: taxes.find((element: any) => element._id === filsproduit?.tva)
              ?.taux,
            prix_ttc: filsproduit?.prix_achat_ttc || 0,
            prix_ht: filsproduit?.prix_achat_ht || 0,
            quantite: value,
            caracteristique: item,
            remise:     inputs[index]?.remise,
            depots_id: inputs[index]?.depots_id,
            total_prix_ttc: parseFloat(
              (filsproduit?.prix_achat_ttc * value).toFixed(3)
            ),
            total_net_ttc: calculTTC_Remise(
              filsproduit?.prix_achat_ttc || 0,
              value,
              inputs[index]?.remise
            ),
            total_prix_ht: parseFloat(
              (filsproduit?.prix_achat_ht || 0 * value).toFixed(3)
            ),
            details: [item], 
            type: 'fils',
            show: true
            
          });
          // Update the details for an existing item
          let newprixht = 0 ;
          let newprixttc = 0
          inputs.forEach((element:any) => {
            if(element.produit.produitparent === inputs[index].product_id){
              newprixht = + element.prix_ht ;
              newprixttc = + element.prix_ttc 
            }
            
          });
          if (index < newInputs.length) {
            newInputs[index] = {
              ...newInputs[index],
              details: [...detailsArray, item],
              quantite: sumOfValeur,
              prix_ht:parseFloat((newprixht/sumOfValeur).toFixed(3)) ,
              prix_ttc:parseFloat((newprixttc/sumOfValeur).toFixed(3))
            };
          }

          return newInputs;
        });
      }
    } else {
      ToastWarning("le produit n'a aucun produit fils");
    }
  };


  const sumOfValeur = useMemo(() => {
    return inputs[index]?.details
      ? inputs[index]?.details.reduce((accumulator: any, item: any) => {
          return accumulator + (item.valeur || 0);
        }, 0)
      : 0;
  }, [inputs[index]?.details]);

  const save = ()=>{
    if(sumOfValeur !== quantite && quantite  && inputs[index].type!== "commentaire") {
      ToastWarning('la quantité input différentes de la quantité total  ')
    }else{
      setInputs((prevInputs: any) => {
        const newInputs = [...prevInputs];
        newInputs[index] = { ...newInputs[index], quantite: sumOfValeur }; 
        return newInputs;
      });

      setShowModal(false)
    }
  } 
  return (
    <Modal
      show={ShowModal}
      dialogClassName="modal-70w"
      className="modal-3"
      backdropClassName="modal-backdrop-3"

    >
      <Modal.Header >
        <Modal.Title className="c-primary">  
           {t('matrice')} :  <span>{matrice?.name_matrice} </span> <span className="ml-5"> {t('produit')} {inputs[index]?.produit?.titre?.fr || ''}</span></Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div>
          <div className="row  mx-auto ">
       
            <span  className=" col-3 mx-auto "><strong>{t('Qunatite  Total')}</strong>: {quantite}</span>
            <span className=" col-6 mx-auto">
             <strong> {t('Quantite input')} :</strong>
              {sumOfValeur}
            </span>
          </div>

          <div className=" table-responsive mt-5  ">
            <table
              id="style-2"
              className=" style-2 text-center bg-white "

            >
              <thead style={{ background: "#f1f2f3" }}>
                <tr>
                  <th className="text-center"></th>

                  {list[1]?.map((item1: any, poidsIndex: number) => (
                    <th className="text-center"   style={{
                      cursor: "pointer",
                      width: "100px",
                      height: '40px',
                      padding: "0 0 0 0",
                      border: "1px solid #ccc",
                      backgroundColor: listcaracteristique.find(
                        (item: any) => item._id === dimension[1]
                      )?.color
                        ? item1.valeur
                        : null,
                    }}key={poidsIndex}>
                      { listcaracteristique.find(
                        (item: any) => item._id === dimension[1]
                      )?.color
                        ? item1.libelle
                        : item1}
                      {list[2]?.map((item2: any) => (
                        <th
                          className="border text-center"
                        ><div style={{
                          cursor: "pointer",
                          width: "100px",
                          height: '40px',
                          backgroundColor: listcaracteristique.find(
                            (item: any) => item._id === dimension[2]
                          )?.color
                            ? item2.valeur
                            : null,
                        }}>
                           { listcaracteristique.find(
                        (item: any) => item._id === dimension[2]
                      )?.color
                        ? item2.libelle
                        : item2}

                        </div>
                        </th>
                      ))}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody >
                {list[0]?.map((item0: any, item0Index: number) => (
                  <tr key={item0Index}>
                    <td
                      className="text-center"
                     
                    >
                      <div 
                       style={{
                        cursor: "pointer",
                        width: "100px",
                        height: '40px',
                        padding: "0 0 0 0",
                        border: "1px solid #ccc",
                        backgroundColor: listcaracteristique.find(
                          (item: any) => item._id === dimension[0]
                        )?.color
                          ? item0.valeur
                          : null,
                      }} > { listcaracteristique.find(
                        (item: any) => item._id === dimension[0]
                      )?.color
                        ? item0.libelle
                        : item0}</div>
                    </td>

                    {list[1]?.map((item1: any, item1Index: number) => (
                      <td
                        key={item1Index}
                        style={{
                          cursor: "pointer",
                          width: "50px",
                        }}
                      >
                        {list[2]?.length !== 0 ? (
                          list[2]?.map((item2: any, item2Index: number) => (
                            <td
                              key={item2Index}
                              className="border"
                              style={{
                                height: "50px",
                                width: "100px",
                              }}
                            >
                              <div className="checkbox-container">
                                <input
                                  type="number"
                                  style={{
                                    height: "50px",
                                    width: "100%",
                                  }}
                                  onChange={(e) =>
                                    handleChange(
                                      parseInt(e.target.value),
                                      item0?.valeur||item0,
                                      item1?.valeur||item1,
                                      item2?.valeur||item2,
                                      )
                                  }
                                  value={
                                    inputs[index]?.details?.find((item: any) =>
                                      areArraysEqual(item.dimension, [
                                        item0?.valeur||item0,
                                        item1?.valeur||item1,
                                        item2?.valeur||item2,
                                      ])
                                    )?.valeur
                                  }
                                  className="text-center numberinput"
                                  disabled={
                                    !checkedlist.some((item) =>
                                      areArraysEqual(item, [
                                        item0?.valeur||item0,
                                        item1?.valeur||item1,
                                        item2?.valeur||item2,
                                      ])
                                    )
                                  }
                                />

                                <div className="checkbox-label"></div>
                              </div>
                            </td>
                          ))
                        ) : (
                          <td
                            className="border"
                            style={{
                              cursor: "pointer",
                              width: "100px",
                            }}
                          >
                            <div className="checkbox-container">
                              <input
                                type="number "
                                style={{
                                  height: "50px",
                                  width: "100%",
                                }}
                                onChange={(e) =>
                                  handleChange(
                                    parseInt(e.target.value),
                                    item0?.valeur||item0,
                                    item1?.valeur||item1,
                                    null
                                  )
                                }
                                value={
                                  inputs[index]?.details?.find((item: any) =>
                                    areArraysEqual(item.dimension, [
                                      item0?.valeur||item0,
                                      item1?.valeur||item1,
                                      null,
                                    ])
                                  )?.valeur
                                }
                                className="text-center numberinput"
                                disabled={
                                  !checkedlist.some((item) =>
                                    areArraysEqual(item, [item0?.valeur||item0, item1?.valeur||item1, null])
                                  )
                                }
                              />

                              <div className="checkbox-label"></div>
                            </div>
                          </td>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-primary-app" onClick={save}> {t('Enregistrer')}</button>
      </Modal.Footer>
    </Modal>
  );
}
