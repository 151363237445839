import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListCommission,
  active_parametrageCommissionApi,
  findCommissionApi,
} from "../../_App/Redux/Slices/commission/commissionSlice";
import {
  ListUsers,
  findUserBySocieteIdApi,
} from "../../_App/Redux/Slices/users/userSlice";
import { Form } from "react-bootstrap";
import CommissionForm from "./CommissionForm";
import parametrage_CommissionType from "../../_App/Types/Entites/Commission/parametrage_Commission";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

interface CommissionData {
  [key: string]: any;
}

export default function CommissionList({ t }: any) {
  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const listOrganisation = useAppSelector(SelectedOrganisations);
  const listUser = useAppSelector(ListUsers);
  const listCommission = useAppSelector(ListCommission);
  const [organisationchoix, setorganisationchoix] = useState(true);
  const [keyword, setkeyword] = useState("");
  const [showModalForm, setshowModalForm] = useState(false);
  const [selectedcommission, setselectedcommission] = useState<CommissionData>();

  const [activeIndex, setActiveIndex] = useState(0);
  const [userOrganisation, setUserOrganisation] = useState<CommissionData>({});
  const [commissionOrganisation, setCommissionOrganisation] =
    useState<CommissionData>({});

  useEffect(() => {
    if (societe) {
      dispatch(findCommissionApi(societe));
      dispatch(findUserBySocieteIdApi(societe));
    }
  }, [dispatch, societe]);

  useEffect(() => {
    if (listCommission.length !== 0 && listOrganisation?.length !== 0) {
      const updatedCommissionOrganisation: CommissionData = {};
      listOrganisation?.forEach((element: any) => {
        const filteredList = listCommission.filter((item: any) =>
          item.organisation_id?.includes(element._id)
        );
        updatedCommissionOrganisation[element._id] = filteredList;
      });
      setCommissionOrganisation(updatedCommissionOrganisation);
    }
  }, [listCommission, listOrganisation]);
  useEffect(() => {
    if (listCommission.length !== 0 && listUser?.length !== 0) {
      const updatedCommissionUser: CommissionData = {};
      listUser?.forEach((element: any) => {
        const filteredList = listCommission.filter((item: any) =>
          item.user_id?.includes(element._id)
        );
        updatedCommissionUser[element._id] = filteredList;
      });
      setUserOrganisation(updatedCommissionUser);
    }
  }, [listCommission, listUser]);
  const onselect=(item:parametrage_CommissionType)=>{
    setselectedcommission(item)
    setshowModalForm(true)

  }
  const activationCommission=async(element:any)=>{
    const data={_id:element._id, active:!element.active}
    const response =await dispatch(active_parametrageCommissionApi(data))
    if (response.payload.success) {

      ToastSuccess(t(response.payload.message));

      window.location.reload();
      setorganisationchoix(false);


     } else {
 
       ToastWarning(t(response.payload.message));
     }
  }
  return (
    <div>
      <div className="row">
      <div className="n-chk mt-3 col-6">
          <Form.Check // prettier-ignore
            type="switch"
            id="custom-switch"
            label={t("commission par organisation")}
            checked={organisationchoix}
            onChange={(e) => {
              setorganisationchoix(!organisationchoix);
            }}
          />
        </div>
      </div>
      {organisationchoix? (
  <div>
  <ul className="nav nav-tabs mt-3" id="border-tabs" role="tablist">
    {listOrganisation?.map((item: any, index: number) => (
      <li className="nav-item" key={index}>
        <a
          className={`nav-link c-primary ${
            index === activeIndex ? "active" : ""
          }`}
          onClick={() => setActiveIndex(index)}
          id={`Stock-${item._id}`}
          data-toggle="tab"
          href={`#Stock-${item._id}`}
          role="tab"
          aria-controls={`Stock-${item._id}`}
          aria-selected={index === activeIndex ? "true" : "false"}
        >
          {item.nom}
        </a>
      </li>
    ))}
  </ul>

  <div className="tab-content mb-4" id="border-tabsContent">
    {listOrganisation?.map((item: any, index: number) => (
      <div
        className={`tab-pane fade ${
          index === activeIndex ? "show active" : ""
        }`}
        id={`Stock-${item._id}`}
        role="tabpanel"
        aria-labelledby={`Stock-${item._id}`}
        key={index}
      >
        {commissionOrganisation && commissionOrganisation[item._id] && (
          <table className="table">
            <thead>
              <tr>
                <th>{t("Article")}</th>
                <th>{t("Code Article")}</th>

                <th>{t("Commission")}</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {commissionOrganisation[item._id].map((element: any) => (
                <tr key={element._id}>
                  <td className="lien-bleu-souligne pointer" onClick={()=>onselect(element)}>{element.article_id?.titre?.fr || "undefined"}</td>
                  <td>
                    {element.article_id?.code_article || "undefined"}
                  </td>

                  <td>{element.commission || "code_undefined"}</td>
                  <td onClick={()=>activationCommission(element)} style={{color:element.active? 'green' : 'red'}}><i className="fas fa-power-off"></i></td>

                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    ))}
  </div>
</div>
      ):
      <div>
    <select
  className="form-control mt-3 col-5"
  value={activeIndex}
  onChange={(e) => setActiveIndex(Number(e.target.value))}
>
  {listUser?.map((item: any, index: number) => (
    <option key={index} value={index}>
      {item.nom} {item.prenom}
    </option>
  ))}
</select>


      <div className="tab-content mb-4" id="border-tabsContent">
        {listUser?.map((item: any, index: number) => (
          <div
            className={`tab-pane fade ${
              index === activeIndex ? "show active" : ""
            }`}
            id={`Stock-${item._id}`}
            role="tabpanel"
            aria-labelledby={`Stock-${item._id}`}
            key={index}
          >
            {userOrganisation && userOrganisation[item._id] && (
              <table className="table">
                <thead>
                  <tr>
                    <th>{t("Article")}</th>
                    <th>{t("Code Article")}</th>

                    <th>{t("Commission")}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {userOrganisation[item._id].map((element: any) => (
                    <tr key={element._id}>
                      <td  className="lien-bleu-souligne pointer" onClick={()=>onselect(element)}>{element.article_id?.titre?.fr || "undefined"}</td>
                      <td>
                        {element.article_id?.code_article || "undefined"}
                      </td>

                      <td>{element.commission || "code_undefined"}</td>
                      <td onClick={()=>activationCommission(element)}style={{color:element.active? 'green' : 'red'}}><i className="fas fa-power-off"></i></td>

                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        ))}
      </div>
    </div>
      }
      <CommissionForm
        t={t}
        societe_id={societe}
        ShowModal={showModalForm}
        setShowModal={setshowModalForm}
        commission={selectedcommission}
        />
    
    </div>
  );
}
