import React, { useState } from 'react'
import ApproverTransfert from '../../Components/TransfertArticles/ApproverTransfert'

export default function PageListDemande() {
    const [ShowModal,setShowModal]=useState(true)
  return (
    <div>
      <ApproverTransfert ShowModal={ShowModal} setShowModal={setShowModal}/>
    </div>
  )
}
