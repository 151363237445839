import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import { PaginationType } from "../../../Types/Entites/PaginationType";
import { ProductRaccourciType } from "../../../Types/Entites/ProductRaccourciType";
import ProductRaccourciDataType from "../../../Types/Forms/ProductRaccourciDataType";
import _ from "lodash";

interface UpdateProductRaccourciParams {
  raccourciId: string;
  newProductId: string | null;
}

/**
 * Get list of product raccourcis.
 * @public
 */
export const findProductRaccourciByOrganisationApi = createAsyncThunk(
  "productraccourci/findProductRaccourciByOrganisationApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productraccourci/findProductRaccourciByOrganisationApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create a product raccourci.
 * @public
 */

export const createProductRaccourciApi = createAsyncThunk(
  "productraccourci/createProductRaccourciApi",
  async (data: ProductRaccourciDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "productraccourci/createProductRaccourciApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * update an empty product raccourci.
 * @public
 */

/**
 * Update the product_id of a product raccourci.
 * @public
 */
export const updateProductRaccourciApi = createAsyncThunk(
  "productraccourci/updateProductRaccourciApi",
  async (params: UpdateProductRaccourciParams, { rejectWithValue }) => {
    try {
      const { raccourciId, newProductId } = params;
      const response = await axios.put(
        "productraccourci/updateProductRaccourciApi",
        { raccourciId, newProductId }
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * delete a product raccourci.
 * @public
 */

export const deleteProductRaccourciApi = createAsyncThunk(
  "productraccourci/deleteProductRaccourciApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `productraccourci/deleteProductRaccourciApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get pagination.
 * @public
 */

interface GroupedProducts {
  [libelle: string]: ProductRaccourciType[];
}

const initialState: GroupedProducts = {};

const productRaccourciSlice = createSlice({
  name: "productsRaccourcis",
  initialState,
  reducers: {
    swapPlacesPagesReducer: (state, action) => {},
  },
  extraReducers: (builder) => {
    builder.addCase(
      findProductRaccourciByOrganisationApi.fulfilled,
      (state, action) => {
        state = _.groupBy(action.payload, "libelle");
        return state;
      }
    );
    builder.addCase(createProductRaccourciApi.fulfilled, (state, action) => {
      const groupedByLibelle = _.groupBy(action.payload.data, "libelle");
      return {
        ...state,
        ...groupedByLibelle,
      };
    });
    builder.addCase(updateProductRaccourciApi.fulfilled, (state, action) => {
      const product_raccourci = action.payload.data;
      const index = _.findIndex(state[product_raccourci.libelle], {
        _id: product_raccourci._id,
      });
      state[product_raccourci.libelle].splice(index, 1, product_raccourci);
    });
  },
});
export const { swapPlacesPagesReducer } = productRaccourciSlice.actions;
export const ListRaccourci = (state: RootState) => state.productRaccourci;

export default productRaccourciSlice.reducer;
