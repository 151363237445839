import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import { FournisseurDetailType } from "../../../Types/Entites/FournisseurDetailType";

/**
 * Get list of fournisseur.
 * @public
 */
export const findFourinsseurDetailApi = createAsyncThunk(
    "fournisseurdetail/findFourinsseurDetailApi",
    async (data:any, { rejectWithValue, dispatch }) => {
      try {
        const {fournisseur,product}= data
        

        const response = await axios.get(`fournisseurdetail/findFourinsseurDetailApi/${fournisseur}/${product}`);
        return response.data;
      } catch (error: any) {
        
        console.log(error);
        return rejectWithValue({
          success: false,
          message: error.response.data.message,
        });
      }
    }
  );

  const initialState: FournisseurDetailType = {
      product_id: "",
      fournisseur_id: "",
      listcouleur: [],
      listTaille: []
  };
export const fournisseurdetailsSlice = createSlice({
  name: "fournisseursdetail",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(findFourinsseurDetailApi.fulfilled, (state, action) => {
      return(state= action.payload);
  
    });
   
  },
  
});

export const FournisseursDetail = (state: RootState) => state.fournisseursdetails;

export default fournisseurdetailsSlice.reducer;
