import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Numpad from "../NumPad/NumPad";

const CreditCardPaymentMethod = ({
  total,
  onAmountChange,
  enteredAmountCreditCard,
}: any) => {
  const [givenAmountCreditCard, setGivenAmountCreditCard] = useState<
    number | null
  >(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount = parseFloat(e.target.value);
    setGivenAmountCreditCard(isNaN(amount) ? 0 : amount);
    onAmountChange(isNaN(amount) ? 0 : amount); // Call the callback function
  };
  useEffect(() => {
    setGivenAmountCreditCard(0);
  }, [total]);
  const { t } = useTranslation();

  const handleButtonClick = (value: any) => {
    if (givenAmountCreditCard === null) {
      setGivenAmountCreditCard(value);
      onAmountChange(value);
    } else {
      setGivenAmountCreditCard(
        Number(givenAmountCreditCard?.toString() + value.toString())
      );
      onAmountChange(
        Number(givenAmountCreditCard?.toString() + value.toString())
      );
    }
  };
  const handleBackspaceClick = () => {
    if (givenAmountCreditCard !== null) {
      if (String(givenAmountCreditCard).length > 1) {
        let str = givenAmountCreditCard.toString();
        setGivenAmountCreditCard(parseFloat(str.slice(0, -1)));
        onAmountChange(parseFloat(str.slice(0, -1)));
      } else if (String(givenAmountCreditCard).length === 1) {
        setGivenAmountCreditCard(null);
        onAmountChange(null);
      }
    }
  };

  const hideNumpad = () => {
    setIsInputFocused(false);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget as HTMLElement).closest(".numpad-container")
    ) {
      return;
    }
    setIsInputFocused(false);
  };
  return (
    <div className="navbar-item flex-row search-ul navbar-dropdown">
      <div className="nav-item align-self-center search-animated">
        {/* <i className="fas fa-dollar-sign"></i>{" "} */}
        <form
          className="form-inline search-full form-inline search"
          role="search"
        >
          <div className="search-bar" style={{ marginBottom: "7px" }}>
            {/* <i className="fas fa-id-card"></i> */}
            <input
              type="text"
              className="form-control search-form-control  ml-lg-auto"
              value={
                enteredAmountCreditCard
                  ? enteredAmountCreditCard
                  : givenAmountCreditCard !== null
                  ? givenAmountCreditCard
                  : ""
              }
              placeholder={t("amount")}
              onChange={handleAmountChange}
              onFocus={() => setIsInputFocused(true)}
              onBlur={handleBlur}
              readOnly
            />
          </div>
        </form>
      </div>
      <div className={`numpad-container ${isInputFocused ? "show" : ""}`}>
        <Numpad
          onButtonClick={handleButtonClick}
          onBackspaceClick={handleBackspaceClick}
          onHideNumpad={hideNumpad}
        />
      </div>
    </div>
  );
};

export default CreditCardPaymentMethod;
