import React from "react";
import { useNavigate } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { SelectedRole } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../_App/Redux/hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #fff; /* Fond blanc */
`;

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const ShieldImage = styled.img`
  width: 16em;
  height: 16em;
  animation: ${bounceAnimation} 5s infinite;
  margin-bottom: 20px;
`;

const Title = styled.h1`
  font-size: 1.8em;
  color: #4a90e2;
  animation: fadeIn 3s ease;
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const RedirectButton = styled.button`
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 1em;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.1s ease;

  &:hover {
    background-color: #6a5acd;
  }
`;

const UnauthorizedPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const redirect = useAppSelector(SelectedRole);

  const redirectToHome = () => {
  
    if (redirect) {
      navigate("/" + redirect);
    } 
  };
  
  return (
    <Container>
      <Title>
        {t(
          "Vous ne disposez pas des autorisations nécessaires pour accéder à cette page"
        )}
      </Title>
      <ShieldImage src="https://img.freepik.com/free-vector/locker_53876-25496.jpg?w=740&t=st=1707822657~exp=1707823257~hmac=5e50002eb60c6458495381879da5aa6202ea52122d37d699a16e8fab2cfdbf6a" />

      <RedirectButton className="btn btn-primary-app" onClick={redirectToHome}>
        {t("Retourner à la page d'accueil")}
      </RedirectButton>
    </Container>
  );
};

export default UnauthorizedPage;
