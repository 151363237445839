import React, { useState, useEffect } from "react";
import ProductTaillesForm from "../../../Components/Products/ProductTailles/ProductTaillesForm";
import Button from "react-bootstrap/Button";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListProductTailles,
  findProductTailleApi,
  deleteProductTailleApi,
} from "../../../_App/Redux/Slices/product_taille/product_tailleSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import ProductTaillesTable from "../../../Components/Products/ProductTailles/ProductTaillesTable";
export default function ProductTaillePage() {
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [ShowModalProductTaille, setShowModalProductTaille] = useState(false);
  const [SelectedProductTaille, setSelectedProductTaille] = useState(null);
  const dispatch = useAppDispatch();
  const ListProductTaille = useAppSelector(ListProductTailles);

  useEffect(() => {
  
    dispatch(findProductTailleApi(currentOrganisation));
  }, [dispatch]);

  const openEditForm = (ProductsCaracteristiques: any) => {
    setSelectedProductTaille(ProductsCaracteristiques);
    setShowModalProductTaille(true);
  };
  const deleteitem = (id: any) => {
    dispatch(deleteProductTailleApi(id));
  };
  return (
    <div>
      <Button
        variant="outline-primary"
        onClick={() => setShowModalProductTaille(true)}
      >
        Create Product Taille
      </Button>{" "}
      <ProductTaillesForm
        ShowModal={ShowModalProductTaille}
        setShowModal={setShowModalProductTaille}
        ProductTaille={SelectedProductTaille}
        organisation_id={currentOrganisation}
      />
      <ProductTaillesTable
        ListProductTaille={ListProductTaille}
        onEditClick={openEditForm}
        deleteitem={deleteitem}
      />
    </div>
  );
}
