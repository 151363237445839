import React ,{useState} from  'react'
import { Produit } from '../../../../_App/Types/Entites/Produit';
import { ProduitDetail } from './ProduitDetail';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card'
  interface ProduitItemProps {
        produit: Produit;
      }

     
      
      const ProduitItem: React.FC<ProduitItemProps> = ({ produit }) => {
        const [selectedProduit, setSelectedProduit] = useState<String>('');
        const [modalShow, setModalShow] = useState(false);
      
        const openPopup = (produitId: any) => {
          setSelectedProduit(produitId);
          setModalShow(true);
        };
      
        return (
          <div>
          <div className="card component-card_2" onClick={() => openPopup(produit?._id || null)}>
         {/*   <img src={process.env.REACT_APP_API_BASE_URL + produit.image[0]} className="card-img-top" alt={process.env.REACT_APP_API_BASE_URL + produit.image[0]} />*/}
            <div className="card-body">
              <h5 className="card-title">{produit.titre.fr}</h5>
            </div>
          </div>
          {selectedProduit!== '' ?(
          <ProduitDetail idProduit={selectedProduit} show={modalShow} onHide={() => {setModalShow(false) ; setSelectedProduit('')}} />

          ):null }
        </div>
        );
      };
      

 export default ProduitItem;

