import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useForm, Resolver } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { createOrganisationApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { OrganisationSchema } from "../../_App/Schemas/OrganisationSchema";
import { OrganisationType } from "../../_App/Types/Entites/OrganisationType";
import { addOrganisationToUser } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";

interface OrganisationsFormProps {
  showPopup: boolean;
  onClose: () => void;
}

const OrganisationsForm: React.FC<OrganisationsFormProps> = ({
  showPopup,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.auth.user?._id)!;
  const { t } = useTranslation();

  const formOptions = {
    resolver: yupResolver(
      OrganisationSchema
    ) as unknown as Resolver<OrganisationType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<OrganisationType>(formOptions);
  const { errors } = formState;

  const onSubmit = async (data: OrganisationType) => {
    try {
      data.users_ids = [userId];

      const response = await dispatch(createOrganisationApi(data));

      if (response.payload.success) {
        ToastSuccess(response.payload.message);
        dispatch(addOrganisationToUser(response.payload.data));
        onClose();
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      console.error("Erreur lors de la création de l'organisation:", error);
    }
  };

  const modalStyles = {
    borderRadius: "10px",
  };

  const labelStyles = {
    fontWeight: "bold",
    marginBottom: "5px",
  };

  const inputStyles = {
    width: "100%",
    padding: "8px",
    borderRadius: "5px",
    border: "1px solid #ccc",
    marginBottom: "15px",
  };

  const checkboxStyles = {
    marginRight: "5px",
  };

  return (
    <Modal show={showPopup} onHide={onClose} style={modalStyles}>
      <Modal.Header>
        <Modal.Title> {t("Ajouter une nouvelle organisation")}</Modal.Title>
        <button
          type="button"
          className="close"
          onClick={onClose}
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label style={labelStyles}>  {t("Nom de l'organisation")}</Form.Label>
                  <Form.Control
                    type="text"
                    {...register("nom")}
                    isInvalid={!!errors.nom}
                    style={inputStyles}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nom?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label style={labelStyles}>
                     {t("Description")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    {...register("description")}
                    isInvalid={!!errors.description}
                    style={inputStyles}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.description?.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group>
              <Form.Label
                style={{
                  ...labelStyles,
                  ...checkboxStyles,
                  marginBottom: "10px",
                }}
              >
               
                {t("Mode de paiement")}
              </Form.Label>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="cash"
                    label="Cash"
                    value="cash"
                    {...register("paiementmode")}
                    style={checkboxStyles}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="cheque"
                    label="Chèque"
                    value="cheque"
                    {...register("paiementmode")}
                    style={checkboxStyles}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="carte_credit"
                    label="Carte de crédit"
                    value="carte_credit"
                    {...register("paiementmode")}
                    style={{ ...checkboxStyles, marginBottom: "10px" }}
                  />
                </Col>
                <Col>
                  <Form.Check
                    type="checkbox"
                    id="sodexo"
                    label="Sodexo"
                    value="sodexo"
                    {...register("paiementmode")}
                    style={{ ...checkboxStyles, marginBottom: "10px" }}
                  />
                </Col>
              </Row>
            </Form.Group>

            <Row>
              <Col>
                <div className="form-group">
                  <label htmlFor="currency" style={labelStyles}>
                  {t("Devise")}{" "}
  
                  </label>
                  <select
                    className={`form-control ${
                      errors.currency ? "is-invalid" : ""
                    }`}
                    id="currency"
                    {...register("currency")}
                  >
                    <option value="TND">TND</option>
                  </select>
                  {errors.currency && (
                    <div className="invalid-feedback">
                      {errors.currency.message}
                    </div>
                  )}
                </div>
              </Col>
              <Col>
                <div className="form-group">
                  <label htmlFor="menu" style={labelStyles}>
                  {t("Téléphone")}

                  </label>
                  <input
                    type="text"
                    className={`form-control ${
                      errors.numero ? "is-invalid" : ""
                    }`}
                    id="numero"
                    {...register("numero")}
                  />
                  {errors.numero && (
                    <div className="invalid-feedback">
                      {errors.numero.message}
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button className="btn btn-primary-app" onClick={onClose}>
                                 {t("Annuler")}

          </Button>
          <Button type="submit" className="btn btn-primary-app">
             {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default OrganisationsForm;
