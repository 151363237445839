import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";

import MatriceType from "../../../Types/Entites/MatriceType";
/**
 * create matrice
 * @private
 */

export const createMatriceApi = createAsyncThunk(
  "matrice/createMatriceApi",
  async (data: MatriceType, { rejectWithValue }) => {
    try {
      const response = await axios.post("matrice/createMatriceApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

  
/**
 * Get list of matrice.
 * @public
 */
export const findMatriceBySocieteApi = createAsyncThunk(
  "matrice/findMatriceBySocieteApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`matrice/findMatriceBySocieteApi/${societe}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of matrice.
 * @public
 */
export const findMatriceById = createAsyncThunk(
  "matrice/findMatriceById",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`matrice/findMatriceById/${id}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Update matrice
 * @private
 */
export const updateMatriceApi = createAsyncThunk(
  `matrice/updateMatriceApi`,
  async (data: MatriceType, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        "matrice/updateMatriceApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

  
  const initialState: MatriceType[]=[]
  
  export const matriceSlice = createSlice({
    name: "matrice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
   
      builder.addCase(
        createMatriceApi.fulfilled,
         (state, action) => {
      
          state.splice(0, 0, action.payload.data);
        }
      );
      builder.addCase(findMatriceBySocieteApi.fulfilled, (state, action) => {
      
       return(state = action.payload)   
      });
      builder.addCase(findMatriceById.fulfilled, (state, action) => {
      
        return(state = action.payload)   
       });
      builder.addCase(
        updateMatriceApi.fulfilled,
        (state, action) => {
          const updated_matrice = action.payload.data;
          var index = _.findIndex(state, {
            _id: updated_matrice._id,
          });
          state.splice(index, 1, updated_matrice);
        }
      );
    },
  })

  
  export const Listmatrice = (state: RootState) =>state.matrices;

export default matriceSlice.reducer;
