import React, { useState, useEffect } from "react";
import AchatForm from "../../Components/Achat/AchatForm";
import Button from "react-bootstrap/Button";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  findAchatsBySocieteApi,
  ListAchat,
  ListDetailAchats,
  findDetailAchatsBySocieteApi,
} from "../../_App/Redux/Slices/achat/achatSlice";
import {
  findParametrageApi,
  ListParametrges,
} from "../../_App/Redux/Slices/AppParametrages/parametrage/parametrageSlice";
import AchatTable from "../../Components/Achat/AchatTable";
import {
  SelectedOrganisationId,
  selectedAutorisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Navbar from "../../_Layouts/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

export default function AchatPage() {
  const { t } = useTranslation();

  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  const [ShowModalAchat, setSShowModalAchat] = useState(false);
  const [ShowModalProductDetail, setSShowModalProductDetail] = useState(false);
  const [ShowModalDetailAchat, setShowModalDetailAchat] = useState(false);

  const [achat, setachat] = useState(null);
  const dispatch = useAppDispatch();
  const listAchat = useAppSelector(ListAchat);
  const listDetailAchats = useAppSelector(ListDetailAchats);
  const listParametrges = useAppSelector(ListParametrges);

  useEffect(() => {
    if (societe) {
      dispatch(findAchatsBySocieteApi(societe));
      //  dispatch(findParametrageApi(null));
      dispatch(findDetailAchatsBySocieteApi(societe));
    }
  }, [dispatch, ShowModalDetailAchat]);
  const autorisations = useAppSelector(selectedAutorisations);
const canViewAchats = autorisations?.CAN_VIEW_ACHATS ;

  const navigate = useNavigate();

useEffect(() => {
  if (!canViewAchats) {
    navigate("/unauthorized");
  }
}, [canViewAchats, navigate]);

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-end mb-3">
        <button
          onClick={() => {
            setachat(null);
            setSShowModalAchat(true);
          }}
          className="btn btn-primary-app"
        >
          {t("Ajouter Achat")}
        </button>{" "}
      </div>
      <AchatForm
        
        ShowModal={ShowModalAchat}
        setShowModal={setSShowModalAchat}
        ProductCaracteristique={achat}
        parametrage={listParametrges}
        organisation_id={currentOrganisation}
        societe={societe}
      />
      <AchatTable
        t={t}
        ListAchats={listAchat}
        ListDetailAchats={listDetailAchats}
        organisation_id={currentOrganisation}
        setShowModalDetailAchat={setShowModalDetailAchat}
        ShowModalDetailAchat={ShowModalDetailAchat}
        ShowModalProductDetail={ShowModalProductDetail}
        setSShowModalProductDetail={setSShowModalProductDetail}
        societe={societe}
      />
    </div>
  );
}
