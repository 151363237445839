import React, { useEffect ,useState} from "react";
import { Modal, Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { getAllSessionJournals, SessionJournals } from "../../_App/Redux/Slices/sessionJournal/sessionJournalSlice";
import { JournalCaisseType } from "../../_App/Types/Entites/JournalCaisseType";
import { ListJournaux, getAllJournalCaisses } from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { SelectedOrganisationId, selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";

interface SessionListByJournalIdModalProps {
  showModal: boolean;
  handleClose: () => void;
  journalId: string;
  getCodeJournalById: (journalId: string) => string | null;

}

const SessionListByJournalIdModal: React.FC<SessionListByJournalIdModalProps> = ({
  showModal,
  handleClose,
  journalId,
  getCodeJournalById,

}) => {
  const dispatch = useAppDispatch();
  const sessions = useAppSelector(SessionJournals);
  const idOrganisation = useAppSelector(SelectedOrganisationId);

  const journaux = useAppSelector(ListJournaux);


  
  useEffect(() => {
    dispatch(getAllSessionJournals());
  }, [dispatch]);

  useEffect(() => {
    if (idOrganisation) {
      dispatch(getAllJournalCaisses(idOrganisation));
    }
  }, [idOrganisation]);

  const sessionsForJournal = sessions.filter((session) => session.journal_id === journalId);
  const [journal, setJournal] = useState<JournalCaisseType | null>(null);
  const autorisations = useAppSelector(selectedAutorisations);
  const canUseGardeCaisse = autorisations?.CAN_USE_GARDE_CAISSE ;
  
  return (
    <Modal show={showModal} onHide={handleClose} size="lg" >
      <Modal.Header closeButton>
      <Modal.Title className="c-primary">Sessions pour le journal {getCodeJournalById(journalId) }</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>Fond Initial</th>
              <th>Fond Final</th>
              {canUseGardeCaisse && (    <th>Maintenus en caisse</th>)}
              <th>Cloturé</th>
              <th>Créé le</th>
              <th>Cloturé  le</th>

            </tr>
          </thead>
          <tbody>
            {sessionsForJournal.map((session) => (
              <tr key={session._id}>
                <td>{session.fond_initial}</td>
                <td>{session.fond_final || "-"}</td>
                {canUseGardeCaisse && ( <td>{session.garde_caisse !== null ? session.garde_caisse : "-"}</td>)}
                <td>{session.cloture ? "Oui" : "Non"}</td>
                <td>{session.createdAt && new Date(session.createdAt).toLocaleString()}</td>
                <td>{session.updatedAt && new Date(session.updatedAt).toLocaleString()}</td>

              </tr>
            ))}
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SessionListByJournalIdModal;
