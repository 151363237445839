import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import TaxType from "../../../Types/Entites/TaxType";
/**
 * Get list of tax = tva  by societe_id
 * @public
 */
export const findTaxTvaApi = createAsyncThunk(
    "tax/findTaxTvaApi",
    async (idsociete: any, { rejectWithValue, dispatch }) => {
      try {
        const response = await axios.get(
          `tax/findTaxTvaApi/${idsociete}`
        );
        return response.data;
      } catch (error: any) {
        console.log(error);
        return rejectWithValue({
          success: false,
          message: error.response.data.message,
        });
      }
    }
  );

  
interface ProduitState {
    listTaxTva: TaxType[];
    
  }

  
  const initialState: ProduitState = {
    listTaxTva: [],
   
  };
  
  export const taxSlice = createSlice({
    name: "tax",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
   
      builder.addCase(findTaxTvaApi.fulfilled, (state, action) => {
        state.listTaxTva= action.payload;
      });
  
    },
  })

  
  export const ListTaxTva = (state: RootState) =>state.taxs.listTaxTva;

export default taxSlice.reducer;
