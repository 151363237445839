import OrganisationList from "../../Components/Organisations/OrganisationList";
import { useAppSelector } from "../../_App/Redux/hooks";
import { selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";
import React, { useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Navbar from "../../_Layouts/Navbar/Navbar";






const OrganisationPage = () => {
const autorisations = useAppSelector(selectedAutorisations);
const canViewOrga = autorisations?.CAN_VIEW_ORGANISATION ;



const navigate = useNavigate();

useEffect(() => {
  if (!canViewOrga) {
    navigate("/unauthorized");
  }
}, [canViewOrga, navigate]);
  return (
    <>
    <Navbar  />
    <div>
      <OrganisationList />
    </div>
    </>
  );
};

export default OrganisationPage;
