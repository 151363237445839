import React, { useState ,useEffect} from "react";
import ProductDetailsForm from "../../../Components/Products/ProductDetails/ProductDetailsForm";
import { ListProductDetails , findProductsDetailApi } from "../../../_App/Redux/Slices/product_detail/product_detailSlice";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import ProductDetailsTable from "../../../Components/Products/ProductDetails/ProductDetailsTable";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
export default function ProductDetailsPage() {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const ProductsDetail =useAppSelector(ListProductDetails);
  useEffect(() => {
    
    dispatch(findProductsDetailApi(currentOrganisation));
  

  }, [dispatch]);  
  return (
    <div>
    
      <ProductDetailsTable productList={ProductsDetail}  organisation_id={currentOrganisation}/>
    </div>
  );
}
