import React from "react";
import { useAppSelector } from "../../../_App/Redux/hooks";
import { ActiveMenu } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";

function NumpadButton({ value, onClick }: any) {
  return (
    <button
      onClick={() => onClick(value)}
      style={{
        background: "black",
        color: "white",
        border: "3px solid white",
        width: "80px",
        height: "80px",
        borderRadius: "13px",
      }}
    >
      {value}
    </button>
  );
}

const Numpad = ({ onButtonClick, onBackspaceClick, onHideNumpad }: any) => {
  const Menu = useAppSelector(ActiveMenu);
  const parametrage = useAppSelector(parametrageCaisse);

  return (
    <div
      className="numpad-container"
      style={{
        position: "absolute",
        zIndex: 1,
        bottom: Menu?.menuPosition.position === "down" ? "100px" : 0,
        borderRadius: "15px",
        width: parametrage && parametrage.barcodeOnly ? "600px" : "unset",
        right: parametrage && parametrage.barcodeOnly ? 0 : "unset",

      }}
    >
      <div className="numpad">
        <div>
          <NumpadButton value={1} onClick={onButtonClick} />
          <NumpadButton value={2} onClick={onButtonClick} />
          <NumpadButton value={3} onClick={onButtonClick} />
          <NumpadButton
            value={<i className="fas fa-backspace"></i>}
            onClick={onBackspaceClick}
          />
        </div>
        <div>
          <NumpadButton value={4} onClick={onButtonClick} />
          <NumpadButton value={5} onClick={onButtonClick} />
          <NumpadButton value={6} onClick={onButtonClick} />
        </div>
        <div>
          <NumpadButton value={7} onClick={onButtonClick} />
          <NumpadButton value={8} onClick={onButtonClick} />
          <NumpadButton value={9} onClick={onButtonClick} />
        </div>
        <div>
          <NumpadButton
            value={<i className="fas fa-chevron-down"></i>}
            onClick={onHideNumpad}
          />
          <NumpadButton value={0} onClick={onButtonClick} />
          <NumpadButton value={"."} onClick={onButtonClick} />
        </div>
      </div>
    </div>
  );
};
export default Numpad;
