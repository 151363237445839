import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import AffectationUserOrganisationDataType from "../../../Types/Forms/AffectationUserOrganisationDataType";
import _ from "lodash";
import AffectationUserOrganisationType from "../../../Types/Entites/AffectaionUserOrganisationType";

export const createAffectationApi = createAsyncThunk(
  "affectationUserOrganisation/createAffectationApi",
  async (data: AffectationUserOrganisationDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("affectationUserOrganisation/createAffectationApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const getAllAffectationsApi = createAsyncThunk(
  "affectationUserOrganisation/getAllAffectationsApi",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("affectationUserOrganisation/getAllAffectationsApi");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getAffectationsByUserApi = createAsyncThunk(
  "affectationUserOrganisation/getAffectationsByUserApi",
  async (userId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`affectationUserOrganisation/getAffectationsByUserApi/${userId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getAffectationsByOrganisationApi = createAsyncThunk(
  "affectationUserOrganisation/getAffectationsByOrganisationApi",
  async (organisationId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`affectationUserOrganisation/getAffectationsByOrganisationApi/${organisationId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

type AffectationUserOrganisationState = {
  listAffectations: AffectationUserOrganisationType[];
};

const initialState: AffectationUserOrganisationState = {
  listAffectations: [],
};

export const AffectationUserOrganisationSlice = createSlice({
  name: "affectationUserOrganisation",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAffectationApi.fulfilled, (state, action) => {
      state.listAffectations.splice(0, 0, action.payload.data);
    });

    builder.addCase(getAllAffectationsApi.fulfilled, (state, action) => {
      state.listAffectations = action.payload;
    });

    builder.addCase(getAffectationsByUserApi.fulfilled, (state, action) => {
      state.listAffectations = action.payload;
    });

    builder.addCase(getAffectationsByOrganisationApi.fulfilled, (state, action) => {
      state.listAffectations = action.payload;
    });
  },
});

export const ListAffectations = (state: RootState) => state.affectationUserOrganisation.listAffectations;

export default AffectationUserOrganisationSlice.reducer;
