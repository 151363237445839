import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { updatePasswordApi } from "../../_App/Redux/Slices/Auth/authSlice";

const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;


const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 16px;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 15px;
`;

const Message = styled.p`
  margin-top: 10px;
  margin-bottom: 0;
  color: #555;
  font-size: 14px;
  text-align: center;
`;

const PasswordChangeForm: React.FC<{ token: string }> = ({ token }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<{ password: string; confirmPassword: string }>();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const password = watch("password");
  const confirmPassword = watch("confirmPassword");
  const [message, setMessage] = React.useState<string | React.ReactNode>("");

  const onSubmit: SubmitHandler<{
    password: string;
    confirmPassword: string;
  }> = async (data) => {
    try {
      setIsSubmitting(true);

      if (data.password !== data.confirmPassword) {
        setMessage("Les mots de passe ne correspondent pas");
        return;
      }

      const response = await dispatch(
        updatePasswordApi({
          token: token,
          newPassword: data.password,
        }) as any
      );

      if (response.payload?.action === "PASSWORD_UPDATED") {
        ToastSuccess(
          response.payload.message
        );
        setMessage(
          <i
            className="fas fa-check"
            style={{ color: "#06958d", fontSize: "35px", marginRight: "5px" }}
          ></i>
        );
        setTimeout(() => {
          navigate("/login");
        }, 1500);
      } else if (response.payload?.action === "BAD_TOKEN") {
        ToastWarning(response.payload?.message);
        setMessage(
          <div>
            <i
              className="fas fa-times"
              style={{ color: "#FF0000", fontSize: "25px", marginRight: "5px" }}
            ></i>
            <span><h6>Jeton de réinitialisation invalide ou expiré.</h6></span>
          </div>
        );
      }
    } catch (error) {
      setMessage(
        "Erreur lors de la mise à jour du mot de passe. Veuillez réessayer."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="password"
          placeholder="Nouveau mot de passe"
          {...register("password", {
            required: "Le champ du mot de passe est requis",
          })}
        />
        <span style={{ fontSize: "12px", color: "red", marginTop: "2px" }}>
          {errors.password && errors.password.message}
        </span>

        <Input
          type="password"
          placeholder="Confirmer le mot de passe"
          {...register("confirmPassword", {
            required: "Le champ de confirmation du mot de passe est requis",
            validate: (value) =>
              value === password || "Les mots de passe ne correspondent pas",
          })}
        />
        <span style={{ fontSize: "12px", color: "red", marginTop: "1px" }}>
          {" "}
          {errors.confirmPassword && errors.confirmPassword.message}
        </span>

        <SubmitButton
          type="submit"
          className="btn btn-primary-app"
          disabled={isSubmitting}
        >
          Valider
        </SubmitButton>

        {/* {isSubmitting && <LoadingIcon icon="spinner" spin />} */}
        {message && <Message>{message}</Message>}
      </FormWrapper>
    </Container>
  );
};

export default PasswordChangeForm;
