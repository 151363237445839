import React, { ChangeEvent, useEffect, useState } from "react";
import {
  FieldErrors,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import AppMenuParametrageType from "../../../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import AppMenuItemsType from "../../../../_App/Types/Entites/AppMenus/AppMenuItems";

const MenuFormDetails = ({
  errors,
  register,
  menu,
  setValue,
  watch,
}: {
  errors: FieldErrors<AppMenuParametrageType>;
  register: UseFormRegister<AppMenuParametrageType>;
  menu: AppMenuParametrageType | null; //for modification,
  setValue: UseFormSetValue<AppMenuParametrageType>;
  watch: UseFormWatch<AppMenuParametrageType>;
}) => {
  useEffect(() => {
    if (menu) {
      setValue("menuPosition.bgColor", menu.menuPosition.bgColor);
      setValue("menuPosition.bgColorButtons", menu.menuPosition.bgColorButtons);
      setValue("menuPosition.colorButtons", menu.menuPosition.colorButtons);
      setValue("menuPosition.position", menu.menuPosition.position);
      setValue("libelle", menu.libelle);
      setValue("_id", menu._id);
    }
  }, [menu]);

  // watch color and bgColor
  const background_buttons = watch("menuPosition.bgColorButtons");
  const color_buttons = watch("menuPosition.colorButtons");
  const watchMenuItems: AppMenuItemsType[] | undefined = watch("menuItems");

  //change color and bgColor in all buttons
  const changeBgColor = (bgColor: ChangeEvent<HTMLInputElement>) => {
    if (watchMenuItems) {
      setValue(
        "menuItems",
        watchMenuItems?.map((item: AppMenuItemsType, index) => {
          
          return {
            ...item,
            bgColor: bgColor.target.value,
          };
        })
      );
    }
  };
  // change color
  const changeColor = (Color: ChangeEvent<HTMLInputElement>) => {
    if (watchMenuItems) {
      setValue(
        "menuItems",
        watchMenuItems?.map((item: AppMenuItemsType, index) => {
          
          return {
            ...item,
            color: Color.target.value,
          };
        })
      );
    }
  };

  return (
    <>
      <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2">
        <div className="form-group">
          <input type="hidden" placeholder="Id" id="id" {...register("_id")} />
          <label htmlFor="libelle">Libelle</label>
          <input
            type="text"
            className={`form-control ${errors.libelle ? "is-invalid" : ""}`}
            id="libelle"
            {...register("libelle")}
          />
          <div className="invalid-feedback">
            {errors.libelle &&
              errors.libelle.message &&
              errors.libelle.message.toString()}
          </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-2 col-lg-2">
        <div className="form-group">
          <label htmlFor="menuPosition">Position Menu</label>
          <select
            className={`form-control ${
              errors.menuPosition?.position ? "is-invalid" : ""
            }`}
            id="menuPosition"
            {...register("menuPosition.position")}
          >
            <option value="">Choisir...</option>
            <option value="left">A Gauche</option>
            <option value="right">A Droite</option>
            <option value="down">En Bas</option>
            <option value="up">En Haut</option>
          </select>
          <div className="invalid-feedback">
            {errors.menuPosition?.position &&
              errors.menuPosition?.position.message &&
              errors.menuPosition?.position.message.toString()}
          </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2">
        <div className="form-group">
          <label htmlFor="menuBgColor">Couleur d'arriére plan menu</label>
          <input
            type="color"
            className={`form-control ${
              errors.menuPosition?.bgColor ? "is-invalid" : ""
            }`}
            id="menuBgColor"
            {...register("menuPosition.bgColor")}
          />
          <div className="invalid-feedback">
            {errors.menuPosition?.bgColor &&
              errors.menuPosition?.bgColor.message &&
              errors.menuPosition?.bgColor.message.toString()}
          </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2">
        <div className="form-group">
          <label htmlFor="bgColorButtons">
            Couleur d'arriére plan Bouttons
          </label>
          <input
            type="color"
            className={`form-control ${
              errors.menuPosition?.bgColorButtons ? "is-invalid" : ""
            }`}
            id="bgColorButtons"
            {...register("menuPosition.bgColorButtons")}
            onChange={(e) => changeBgColor(e)}
          />
          <div className="invalid-feedback">
            {errors.menuPosition?.bgColorButtons &&
              errors.menuPosition?.bgColorButtons.message &&
              errors.menuPosition?.bgColorButtons.message.toString()}
          </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2">
        <div className="form-group">
          <label htmlFor="colorButtons">Couleur des Bouttons </label>
          <input
            type="color"
            className={`form-control ${
              errors.menuPosition?.colorButtons ? "is-invalid" : ""
            }`}
            id="colorButtons"
            {...register("menuPosition.colorButtons")}
            onChange={(e) => changeColor(e)}
          />
          <div className="invalid-feedback">
            {errors.menuPosition?.colorButtons &&
              errors.menuPosition?.colorButtons.message &&
              errors.menuPosition?.colorButtons.message.toString()}
          </div>
        </div>
      </div>
      <div className="col-xs-6 col-sm-6 col-md-3 col-lg-2">
        {/* <h5 className="c-primary"
                    style={{height: "100%", "display": "flex", "alignItems": "center"}}>
                        {ActiveButtons ? ActiveButtons.length : '--'} bouttons selectionnée 
                </h5> */}
      </div>
    </>
  );
};

export default MenuFormDetails;
