import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { UserMacAdressDataType } from "../../../Types/Forms/UserMacAdressDataType";

export const getAddressByMacApi = createAsyncThunk(
  "adressMac/getAddressByMacApi",
  async (macAddress: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/adressMac/getAddressByMac/${macAddress}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const createUserAddressMacApi = createAsyncThunk(
  "adressMac/createUserAddressMacApi",
  async (newUserAddressMac: UserMacAdressDataType, { rejectWithValue }) => {
    try {
      console.log("Appel de createUserAddressMacApi");
      const response = await axios.post(
        "/adressMac/createUserAddressMacApi",
        newUserAddressMac
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

const initialState: UserMacAdressDataType[] = [];

const userAddressMacSlice = createSlice({
  name: "userAddressMacs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createUserAddressMacApi.fulfilled, (state, action) => {
        state.push(action.payload.data);
      })
      .addCase(getAddressByMacApi.fulfilled, (state, action) => {
      });
  },
});

export default userAddressMacSlice.reducer;
