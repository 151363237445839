import React, { useEffect, useState } from "react";
import { DndProvider, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import RaccourciProductItem from "./RaccourciProductItem";
import RaccourciVide from "./RaccourciVide";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { updateProductRaccourciApi } from "../../../_App/Redux/Slices/product_raccourci/ProductRaccourciSlice";
import { ActiveMenu } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import CaisseArticlesItem from "../Articles/CaisseArticlesItem";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";
import { findStockByDepot } from "../../../_App/Redux/Slices/article/articleSlice";

interface TabProps {
  id: string;
  articles: any;
  display: any;
  activeTab: any;
  showModal: any;
}
interface DraggedProduct {
  type: string;
  data: {
    _id: string;
  };
}

const Tab: React.FC<TabProps> = ({
  id,
  articles,
  display,
  activeTab,
  showModal,
}) => {
  const dispatch = useAppDispatch();
  const Menu = useAppSelector(ActiveMenu);

  const handleDrop = (draggedItem: DraggedProduct, raccourciId: string) => {
    console.log("Dragged item:", draggedItem);
    const { _id: newProductId } = draggedItem.data;
    dispatch(updateProductRaccourciApi({ raccourciId, newProductId }));
  };

  const [articleFilter, setArticleFilter] = useState("");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted with filter:", articleFilter);
  };
  const filteringArticles = (item: any) => {
    const filterLowerCase = articleFilter.toLowerCase();

    return (
      item.titre.fr.toLowerCase().includes(filterLowerCase) ||
      (item.produit_ref?.toLowerCase().includes(filterLowerCase) ?? false) ||
      (item.caracteristiques?.some((caracteristique: any) =>
        caracteristique?.value.toLowerCase().includes(filterLowerCase)
      ) ??
        false) ||
      (item.code_a_barre?.some((barCode: any) =>
        barCode?.toLowerCase().includes(filterLowerCase)
      ) ??
        false)
    );
  };

  return (
    <div
      className="tab"
      id={id}
      style={{ display: activeTab === id ? "flex" : "none" }}
    >
      <div
        className="row layout-top-spacing"
        style={{
          overflowY: "auto",
          overflowX:'hidden',
          width: "100%",
          maxHeight:
            Menu?.menuPosition.position === "left"
              ? "calc(81vh - 43px)"
              : "calc(81vh - 135px)",
        }}
      >
        {articles && articles[0].libelle ? (
          <>
            {articles &&
              articles.map((item: any) =>
                item && item.product_id ? (
                  <RaccourciProductItem
                    key={item._id}
                    Item={item}
                    col={display.colRight}
                    activeTab={activeTab}
                    showModal={showModal}
                    stocks={null}
                    onDrop={(draggedItem: DraggedProduct) =>
                      handleDrop(draggedItem, item._id)
                    }
                  />
                ) : (
                  <RaccourciVide
                    key={item._id}
                    Item={item}
                    col={display.colRight}
                    onDrop={(draggedItem: DraggedProduct) =>
                      handleDrop(draggedItem, item._id)
                    }
                  />
                )
              )}
          </>
        ) : (
          <>
            <div className="navbar-item flex-row search-ul navbar-dropdown">
              <div className="nav-item align-self-center search-animated">
                <i className="fas fa-search"></i>
                <form
                  className="form-inline search-full form-inline search"
                  role="search"
                  onSubmit={handleSubmit}
                >
                  <div className="search-bar">
                    <input
                      style={{ background: "#f1f2f3", border: "none" }}
                      type="text"
                      className="form-control search-form-control ml-lg-auto"
                      placeholder="Recherche"
                      autoFocus
                      onChange={(e) => setArticleFilter(e.target.value)}
                    />
                  </div>
                </form>
              </div>
            </div>
            {articles &&
              articles.map((item: any) => {
                return (
                  <React.Fragment key={item._id}>
                    {filteringArticles(item) ? (
                      <CaisseArticlesItem
                        key={item._id}
                        Item={item}
                        col={display.colRight}
                        activeTab={activeTab}
                        showModal={showModal}
                      />
                    ) : null}
                  </React.Fragment>
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};
export default Tab;
