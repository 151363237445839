import React, { useState } from 'react'
import ListTransfertCree from '../../Components/TransfertArticles/ListTransfertCree'

export default function PageDemandeTransfert() {
  const [ShowModal,setShowModal]=useState(true)

  return (
    <div>
      <ListTransfertCree  ShowModal={ShowModal} setShowModal={setShowModal}/>
    </div>
  )
}
