import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { layout } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import {
  ListOrders,
  findOrdersByOrganisation,
  getOrderByIdApi,
  selectOrder,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { formatdate } from "../../../_App/Helpers/helpers";
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.min.css";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import { findPaymentsByOrderApi } from "../../../_App/Redux/Slices/payments/PaymentSlice";
import { ReservationToPanier } from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import {  useNavigate} from "react-router-dom";

const OrdersList = () => {
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const parametrage = useAppSelector(parametrageCaisse);
  const navigate = useNavigate()

  const Orders = useAppSelector(ListOrders);
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [selectedDate, setSelectedDate] = useState(getTodayDate());

  const handleSelectOrder = async (order: any) => {
    if (order.status === "reservation") {
      if (parametrage && parametrage.barcodeOnly) {
        dispatch(findPaymentsByOrderApi(order._id));
        dispatch(
          ReservationToPanier({order_id :order._id ,order:order,items:order.items ,client:order.client_id._id})

        );
        navigate("/caisse") ;

      }
    } else {
      dispatch(
        selectOrder({
          order_id: order._id,
        })
      );
      if (parametrage && parametrage.barcodeOnly)
        window.location.href = "/caisse/" + order._id;
    }
  };

  function formatDateAndTime(timestamp: Date) {
    const dateObject = new Date(timestamp);

    const year = dateObject.getFullYear();
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const day = dateObject.getDate().toString().padStart(2, "0");

    const hours = dateObject.getHours().toString().padStart(2, "0");
    const minutes = dateObject.getMinutes().toString().padStart(2, "0");
    const seconds = dateObject.getSeconds().toString().padStart(2, "0");

    const formattedDateAndTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDateAndTime;
  }
  const [stateShow, setStateShow] = useState(false);
  const [show, setShow] = useState("");
  const [status, setStatus] = useState("all");

  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findOrdersByOrganisation(currentOrganisation));
    }

    const datepickerElement = document.getElementById(
      "datepicker"
    ) as HTMLInputElement;

    if (datepickerElement) {
      const flatpickrInstance = flatpickr(datepickerElement, {
        dateFormat: "Y-m-d",
        onChange: function (selectedDates, dateString) {
          setSelectedDate(dateString);
        },
      });
    }
  }, [dispatch, currentOrganisation]);

  const [ordersFilter, setOrdersFilter] = useState("#");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted with filter:", ordersFilter);
  };
  const filteringOrders = (item: any) => {
    if (item) {
      const filterLowerCase = ordersFilter.toLowerCase();
      const searchFilter =
        (ordersFilter &&
          item.order_number.toLowerCase().includes(filterLowerCase)) ||
        (item.client_id &&
          item.client_id.nom.toLowerCase().includes(filterLowerCase));

      return (
        formatdate(item.date).toString() === selectedDate &&
        (status === "all" ||
          item.status.toLowerCase().includes(status.toLowerCase())) &&
        (ordersFilter === "" || searchFilter)
      );
    } else return true;
  };
  const { t } = useTranslation();

  return (
    <>
      <div className="row layout-spacing">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div className="navbar-item flex-row search-ul navbar-dropdown">
              <div className="nav-item align-self-center search-animated">
                <i className="fas fa-search"></i>
                <form
                  className="form-inline search-full form-inline search"
                  role="search"
                  onSubmit={handleSubmit}
                >
                  <div className="search-bar">
                    <input
                      style={{ background: "#f1f2f3", border: "none" }}
                      type="text"
                      className="form-control search-form-control ml-lg-auto"
                      placeholder={t("search")}
                      autoFocus
                      onChange={(e) => setOrdersFilter(e.target.value)}
                    />
                  </div>
                </form>
              </div>
              <input
                type="text"
                className="form-control"
                style={{
                  background: "#f1f2f3",
                  border: "none",
                  width: "auto",
                  height: "auto",
                }}
                id="datepicker"
                value={t("Date") + ": " + selectedDate}
                onChange={(e) => setSelectedDate(e.currentTarget.value)}
              />{" "}
              <div
                className="nav-item dropdown language-dropdown more-dropdown"
                onClick={open}
                style={{ display: "flex", justifyContent: "right" }}
              >
                <div
                  className={"dropdown custom-dropdown-icon " + show}
                  style={{ cursor: "pointer" }}
                >
                  <a
                    className="dropdown-toggle btn"
                    href="#"
                    role="button"
                    id="customDropdown"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded={stateShow}
                  >
                    <span> {t(status)} </span>{" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-chevron-down"
                    >
                      <polyline points="6 9 12 15 18 9" />
                    </svg>
                  </a>
                  <div
                    className={"dropdown-menu dropdown-menu-right " + show}
                    aria-labelledby="customDropdown"
                  >
                    <div
                      className="dropdown-item"
                      data-img-value="flag-de3"
                      data-value="German"
                      onClick={(e) => setStatus("all")}
                    >
                      {t("all")}
                    </div>
                    <div
                      className="dropdown-item"
                      data-img-value="flag-de3"
                      data-value="German"
                      onClick={(e) => setStatus("Payée")}
                    >
                      {t("Payée")}
                    </div>
                    <div
                      className="dropdown-item"
                      data-img-value="flag-fr3"
                      data-value="French"
                      onClick={(e) => setStatus("En attente")}
                    >
                      {t("En attente")}
                    </div>
                    <div
                      className="dropdown-item"
                      data-img-value="flag-ca2"
                      data-value="English"
                      onClick={(e) => setStatus("Nouvelle")}
                    >
                      {t("Nouvelle")}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="widget-content widget-content-area"
              style={{ overflowX: "auto" }}
            >
              <table id="style-2" className="table style-2  table-hover">
                <thead>
                  <tr>
                    <th
                      className="checkbox-column dt-no-sorting"
                      style={{ fontSize: "15px" }}
                    >
                      {t("N° commande")}
                    </th>
                    <th style={{ fontSize: "15px" }}>{t("Date")}</th>
                    <th style={{ fontSize: "15px" }}>{t("Table")}</th>
                    <th style={{ fontSize: "15px" }}>{t("client")}</th>
                    <th style={{ fontSize: "15px" }}>{t("total")}</th>
                    <th className="text-center" style={{ fontSize: "15px" }}>
                      {t("status")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Orders && Orders.length
                    ? Orders.map((item) => {
                        if (filteringOrders(item)) {
                          return (
                            <tr
                              onClick={(e) => handleSelectOrder(item)}
                              key={item._id}
                            >
                              <td
                                className="checkbox-column"
                                style={{ fontSize: "15px" }}
                              >
                                {item.order_number}
                              </td>
                              <td style={{ fontSize: "15px" }}>
                                {formatDateAndTime(item.date)}
                              </td>
                              <td style={{ fontSize: "15px" }}>
                                {item.table_id?.numero
                                  ? item.table_id.numero
                                  : 1}
                              </td>
                              <td style={{ fontSize: "15px" }}>
                                {item.client_id?.nom?.toString()}
                              </td>
                              <td style={{ fontSize: "15px" }}>
                                {item.total.toFixed(3)}
                              </td>
                              <td
                                className="text-center"
                                style={{ fontSize: "15px" }}
                              >
                                <span
                                  className={
                                    item.status === "Nouvelle"
                                      ? "shadow-none badge badge-info"
                                      : item.status === "En attente"
                                      ? "shadow-none badge badge-warning"
                                      : item.status === "Payée"
                                      ? "shadow-none badge badge-success"
                                      : item.status === "Partiellement payée"
                                      ? "shadow-none badge badge-danger"
                                      : "shadow-none badge badge-danger"
                                  }
                                  style={{
                                    width: "100%",
                                    height: "25px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "15px",
                                  }}
                                >
                                  {t(item.status)}
                                </span>
                              </td>
                            </tr>
                          );
                        } else return null;
                      })
                    : null}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrdersList;
