import { string, boolean, object, bool } from "yup";

export const ProductTailleSchema = object().shape({
  libelle: string().required("Libellé obligatoire!!"),
  type_taille: string().required("type_taille obligatoire!!"),
  type_taille_chaussures: string().required(
    "type_taille_chaussure obligatoire!!"
  ),
  _id: string().notRequired(),
  organisation_id: string().notRequired(),
});
