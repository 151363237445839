import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListFournisseurs,
  findFourinsseurBySocieteApi,
} from "../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import { Modal } from "react-bootstrap";

import Matrice from "./Matrice";
import { useTranslation } from "react-i18next";
import {
  Listmatrice,
  findMatriceBySocieteApi,
} from "../../_App/Redux/Slices/matrice/matriceSlice";
import {
  ListProductCaracteristiques,
  findProductsCaracteristiquesApiBySociete,
} from "../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { ReceptionArticlesType } from "../../_App/Types/Entites/ReceptionArticles/ReceptionArticlesType";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReceptionArticlesSchema } from "../../_App/Schemas/ReceptionArticle/ReceptionArticlesSchema";
import {
  ListDepotByOrganisation,
  findDepotsBySocieteApi,
} from "../../_App/Redux/Slices/depot/depotSlice";
import { handleKeyPress } from "../../_App/Helpers/helpers";
import {
  createReceptionApi,
  updateReceptionApi,
} from "../../_App/Redux/Slices/receptionArticles/receptionArticlesSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  ListSousCategories,
  findSousCategorieBySocieteApi,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import ConfirmQuantite from "./ConfirmQuantite";
import MatriceType from "../../_App/Types/Entites/MatriceType";
import { ReceptionLigneArticleType } from "../../_App/Types/Entites/ReceptionArticles/ReceptionLigneArticleType";

export default function ReceptionForm({
  reception,
  ShowModal,
  setShowModal,
}: any) {
  const { t } = useTranslation();

  const {
    register: registerForm1,
    handleSubmit: handleSubmitForm1,
    formState: { errors: errorsForm1 },
    reset: resetForm1,
    getValues: getValue1,
    setValue: setValue1,
  } = useForm<ReceptionArticlesType>();

  const {
    register: registerForm2,
    handleSubmit: handleSubmitForm2,
    formState: { errors: errorsForm2 },
    reset: resetForm2,
    getValues: getValue2,
    setValue: setValue2,
    watch: watch2,
    
  } = useForm<ReceptionLigneArticleType[]>();

  const dispatch = useAppDispatch();
  const societe = useAppSelector(societeId);
  const listFournisseurs = useAppSelector(ListFournisseurs);
  const listSousCategories = useAppSelector(ListSousCategories);
  const listmatrice = useAppSelector(Listmatrice);
  const list_PCaracteristiques = useAppSelector(ListProductCaracteristiques);
  const ListOrganisation = useAppSelector(SelectedOrganisations);
  const listDepotByOrganisation = useAppSelector(ListDepotByOrganisation);

  const [selectedOrganisation, setSelectedOrganisation] = useState<
    string | null
  >(null);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [delete_ids,setdelete_ids]=useState<any[]>([])

  const [listlignes, setlistlignes] = useState<ReceptionLigneArticleType[]>([
    {
      _id:"",
      reception_id: "",
      code_article: "",
      sous_categorie: "",
      prix_vente: 0,
      matrice: null ,
      quantite: 0,
      depot_id: null,
      organisation_id: "",
      ligne_reception: [],
      code_article_fournisseur: "",
      quantite_input: 0,
    },
  ]);
  
  useEffect(() => {
    if (societe) {
      dispatch(findFourinsseurBySocieteApi(societe));
      dispatch(findSousCategorieBySocieteApi(societe));
      dispatch(findMatriceBySocieteApi(societe));
      dispatch(findProductsCaracteristiquesApiBySociete(societe));
      dispatch(findDepotsBySocieteApi(societe));
    }
  }, [dispatch, societe]);

  useEffect(()=>{

    if(reception !==null){
      const copie_reception = {...reception}
      copie_reception.organisation_id = reception.organisation_id._id
      setSelectedOrganisation( copie_reception.organisation_id )
      copie_reception.depot_id = reception.depot_id._id
      const list =copie_reception.lignes.reduce((acc:any, value:any, index:any) => {
        acc[index] = value;
        return acc;
      }, {} as { [key: number]: any });
      setlistlignes(copie_reception.lignes)
      resetForm2(list)
      resetForm1(copie_reception)}
  },[reception])
useEffect(()=>{
  if (selectedOrganisation !== null && listDepotByOrganisation[selectedOrganisation]) {
    const depot = listDepotByOrganisation[selectedOrganisation][0];
    if (depot?._id !== undefined) {
      setValue1('depot_id', depot?._id);
    }
    
  }  },[selectedOrganisation])



  const addlignes = () => {
    setlistlignes((prev) => [
      ...prev,
      {
        _id:"",
        reception_id: "",
        code_article: "",
        sous_categorie: "",
        prix_vente: 0,
        matrice: null,
        quantite: 0,
        depot_id: null,
        organisation_id: "",
        ligne_reception: [],
        code_article_fournisseur: "",
        quantite_input: 0,
      },
    ]);
  };
  const removelignes = (index: any) => {
    const originalObject = watch2();
    let dataArray = Object.keys(originalObject).map(
      (key: any) => originalObject[key]
    );
    const id = dataArray[index]._id
    if(id!==""){
      setdelete_ids((prev) => [...prev,id]);

    }
    const list = dataArray.splice(index, 1);
    // console.log("dataArray",dataArray)

    //console.log("list",list)
    setlistlignes(dataArray);
    const d = dataArray.reduce((obj: any, item, i) => {
      console.log(i, item);
      obj[i] = item;
      return obj;
    }, {});
    resetForm2(d);
  };

  const save = async () => {

    const entet = getValue1();
    if(societe) entet.societe_id = societe
    const originalObject = watch2();

    const lignes = Object.keys(originalObject).map(

      (key: any) => originalObject[key]
    );
    lignes.forEach(element => {
          element.matrice = element.matrice==="" ? null : element.matrice
      });
    let data 
      let res 
    if(entet._id ===""){
       data = { entet: entet, lignes: lignes };

    res=  await dispatch(createReceptionApi(data));

    }else{
      data = { entet: entet, lignes: lignes ,delete_ids : delete_ids};

      res=await dispatch(updateReceptionApi(data));

    }
    if(res.payload.success){
      ToastSuccess("success")
      setShowModal(false)
      window.location.reload()
    }else{
      ToastWarning(res.payload.message)
    }
    
  };

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-90w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">
            {" "}
            <span>{"Reception articles"}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div
            className="row"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <form
              className="row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="col-2">
                <strong>{t("Code Reception")}</strong>
                <input
                  disabled={reception?.confirmer}
                  type="text"
                  className={`form-control numberinput `}
                  {...registerForm1("code_reception")}
                />
              </div>
              <div className="col-2">
                <strong>{t("Fournisseur")}</strong>

                {reception?.confirmer ? (
                  <p className="mt-2">{reception.fournisseur?.libelle}</p>
                ) : (
                  <select
                    className={`form-control numberinput `}
                    {...registerForm1("fournisseur")}
                  >
                    <option value="">{"Select Fournisseur"} </option>
                    {listFournisseurs.map((item: any) => (
                      <option key={item._id} value={item._id}>
                        {item.libelle}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col-2">
                <strong>{t("Organisation")}</strong>
                {reception?.confirmer ? (
                  <p className="mt-2">{reception.organisation_id?.nom}</p>
                ) : (
                  <select
                    className={`form-control mb-2 `}
                    {...registerForm1("organisation_id")}
                    onChange={(e) => setSelectedOrganisation(e.target.value)}
                  >
                    <option value="null">aucun organisation </option>
                    {ListOrganisation?.map((item: any, index: number) => (
                      <option key={index} value={item._id}>
                        {item.nom}
                      </option>
                    ))}
                  </select>
                )}
              </div>
              <div className="col-2">
                <strong>{t("Depots")}</strong>
                {reception?.confirmer ? (
                  <p className="mt-2">{reception.depot_id.libelle}</p>
                ) : (
                  <select
                    className={`form-control mb-2`}
                    {...registerForm1("depot_id")}
                  >
                    {selectedOrganisation &&
                      listDepotByOrganisation[selectedOrganisation]?.map(
                        (item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.libelle}
                          </option>
                        )
                      )}
                  </select>
                )}
              </div>
            </form>
            <form>
              {listlignes.map((item, index) => (
                <div className="row" key={index}>
                  <div className="col-2">
                    <strong>Code Article</strong>
                    <input
                      type="text"
                      className="form-control numberinput"
                      {...registerForm2(`${index}.code_article`)}
                      defaultValue={item.code_article}
                    />
                  </div>
                  <div className="col-2">
                    <strong>Code Article fournisseur</strong>
                    <input
                      type="text"
                      className="form-control numberinput"
                      {...registerForm2(`${index}.code_article_fournisseur`)}
                      defaultValue={item.code_article_fournisseur}
                    />
                  </div>

                  <div className="col-2">
                    <strong>{t("Categorie")}</strong>
                    {reception?.confirmer ? (
                      <p className="mt-2">
                        {reception.sous_categorie?.libelle.fr}
                      </p>
                    ) : (
                      <select
                        className="form-control"
                        {...registerForm2(`${index}.sous_categorie`)}
                        // onScroll={handleCategoryChange}
                      >
                        <option value="">{t("Select categorie")}</option>
                        {listSousCategories.map((item: any) => (
                          <option key={item._id} value={item._id}>
                            {item.libelle.fr}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-2">
                    <strong>{t("Matrice")}</strong>
                    {reception?.confirmer ? (
                      <p className="mt-2">
                        {" "}
                        {
                          listmatrice.find(
                            (item) => item._id === reception.matrice
                          )?.name_matrice
                        }
                      </p>
                    ) : (
                      <select
                        className={`form-control numberinput `}
                        {...registerForm2(`${index}.matrice`)}
                      >
                        <option value="">{"Select matrice"} </option>
                        {listmatrice.map((item: any) => (
                          <option key={item._id} value={item._id}>
                            {item.name_matrice}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                  <div className="col-2">
                    <strong>{t("prix vente")}</strong>
                    <input
                      disabled={reception?.confirmer}
                      type="text"
                      onKeyPress={handleKeyPress}
                      className={`form-control  `}
                      {...registerForm2(`${index}.prix_vente`)}
                    />
                  </div>

                  <div className="col-1">
                    <strong>{t("Quantite Total")}</strong>
                    <input
                      disabled={reception?.confirmer}
                      type="number"
                      className="form-control number_input"
                      {...registerForm2(`${index}.quantite`)}
                    />
                  </div>
                  <div className="col-1">
                    <strong>{t("Quantité  entré")}</strong>

                    {watch2(`${index}.quantite_input`)}
                  </div>
                  <div className=" row col-1">
                    <span className="c-primary float-right" onClick={addlignes}>
                      <i className="fas fa-plus-circle fa-lg"></i>
                    </span>

                    {index > 0 && (
                      <span
                        className="c-primary float-right"
                        onClick={() => removelignes(index)}
                      >
                        <i className="fas fa-minus-circle fa-lg"></i>
                      </span>
                    )}
                  </div>

                  <div className="row">
                    {watch2() ? (
                      <>
                        <Matrice
                          listcaracteristique={list_PCaracteristiques}
                          societe={societe}
                          matrice={listmatrice.find(
                            (item: any) =>
                              item._id === getValue2(`${index}.matrice`)
                          )}
                          getValue={getValue2}
                          setValue={setValue2}
                          reception={reception}
                          t={t}
                          index={index}
                        />
                        {getValue2(`${index}.quantite_input`)!=
                          getValue2(`${index}.quantite`) &&
                          getValue2(`${index}.quantite`) && (
                            <div>
                              <p style={{ color: "red" }}>
                                {t("La Quantité Totale")}:{" "}
                                {watch2(`${index}.quantite`)}{" "}
                                {t("est différente de la Quantité Calculée :")}{" "}
                                {watch2(`${index}.quantite_input`)}
                              </p>
                            </div>
                          )}
                      </>
                    ) : null}
                  </div>
                </div>
              ))}
            </form>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {reception?.cloturer ? null : (
            <>
              <button
                type="button"
                onClick={() => setShowModal(false)}
                className="btn btn-primary-app"
              >
                {t("Annuler")}
              </button>
              <button onClick={save} className="btn btn-primary-app">
                {t("Enregistrer")}
              </button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}
