import { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import AppMenuParametrageType from "../../../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Modal } from "react-bootstrap";
import { AffectationMenuDataType } from "../../../../_App/Types/Forms/AppMenus/AffectationMenuDataType";
import { AffectationMenuSchema } from "../../../../_App/Schemas/AppParametrages/AffectationMenuSchema";
import { AffectationRolesMenuApi } from "../../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import {
  ListRolesWithUsers,
  RolesWithUsersApi,
} from "../../../../_App/Redux/Slices/userRoles/userRoleSlice";
import RoleItemMenu from "./RoleItemMenu";
import { UserRole } from "../../../../_App/Types/Entites/UserRole";
import { ToastSuccess } from "../../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../../Shared/Toasts/ToastWarning";

const RolesListMenu = ({
  ShowModal,
  setShowModal,
  ShowModalTypeAffectation,
  setShowModalTypeAffectation,
  menu,
}: {
  ShowModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  ShowModalTypeAffectation: boolean;
  setShowModalTypeAffectation: Dispatch<SetStateAction<boolean>>;
  menu: AppMenuParametrageType | null;
}) => {
  const dispatch = useAppDispatch();
  const formOptions = { resolver: yupResolver(AffectationMenuSchema) };
  // get functions to build form with useForm() hook
  const {
    register,
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState,
  } = useForm<AffectationMenuDataType>(formOptions);
  const { errors } = formState;

  const roles = useAppSelector(ListRolesWithUsers);

  useEffect(() => {
    dispatch(RolesWithUsersApi());
  }, [dispatch]);

  useEffect(() => {
    if (menu) {
      reset({
        menu_id: menu._id,
        organisations: {},
        roles : {}
      });
    }
  }, [menu]);

  const onSubmit = async (data: AffectationMenuDataType) => {

    const response = await dispatch(AffectationRolesMenuApi(data));

    if(response.payload.success) {
      setShowModal(false);
      setShowModalTypeAffectation(false)
      ToastSuccess(response.payload.message)
    }else{
      ToastWarning(response.payload.message)
    }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="affectation-menu"
      dialogClassName="modal-80w"
      className="modal-2"
      backdropClassName="modal-2"
    >
      <Modal.Header closeButton>
        <Modal.Title id="affectation-menu" className="custom-modal-title">
          <div className="titleWithButton">
            <div>
              {" "}
              List des Utilisateurs : Affecter le menu{" "}
              <span className="c-primary">{menu && menu.libelle}</span> à :{" "}
            </div>
            <div></div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {roles &&
            roles.map((role: UserRole, index: number) => (
              <RoleItemMenu
                role={role}
                key={index}
                register={register}
                getValues={getValues}
                setValue={setValue}
              />
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="reset"
          onClick={(e) => setShowModal(false)}
          className="btn btn-sm btn-secondary"
        >
          {" "}
          <i className="fas fa-arrow-left"></i> Annuler
        </button>
        <button
          type="submit"
          className="btn btn-sm btn-primary"
          onClick={handleSubmit(onSubmit)}
        >
          <i className="fas fa-save"></i> Enregistrer
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default RolesListMenu;
