import { useEffect, useState } from "react";
import { Button, Col, Row, Form } from "react-bootstrap";
import { SelectedOrganisationId, selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListUsers,
  findUsersByOrganisationsListApi,
} from "../../_App/Redux/Slices/users/userSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import UpdateUserForm from "./EditUserForm";
import AddUserForm from "./UserForm";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";

const UserItem = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(ListUsers);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [updateUserModalVisible, setUpdateUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDataType | null>(null);
  const id_organisation = useAppSelector(SelectedOrganisationId);
  const [searchTerm, setSearchTerm] = useState("");
  const {t} =useTranslation();
  const autorisations = useAppSelector(selectedAutorisations);
  const CanUpdateUser = autorisations?.CAN_UPDATE_USER ;
  const CanCreateUser = autorisations?.CAN_CREATE_USER ;

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const openUpdateUserModal = (user: UserDataType) => {
    setSelectedUser(user);
    setUpdateUserModalVisible(true);
  };

  useEffect(() => {
    if (id_organisation) {
      dispatch(findUsersByOrganisationsListApi());
    }
  }, [dispatch, id_organisation]);
  useEffect(() => {
    if (Array.isArray(users) && users.length > 0) {
    }
  }, [users]);



  const filteredUsers = users.filter((user) => {
    const userString = Object.values(user)
      .map((value) => {
        if (Array.isArray(value)) {
          return value.map((org) => org.nom).join(" ");
        }
        return String(value);
      })
      .join(" ")
      .toLowerCase();

    const roleLibelle = user.role_id?.libelle
      ? String(user.role_id.libelle).toLowerCase()
      : "";

    return (
      userString.includes(searchTerm.toLowerCase()) ||
      roleLibelle.includes(searchTerm.toLowerCase())
    );
  });

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mt-3">
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </Form.Group>
        {CanCreateUser && (

        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-add-user">
              {t("Ajouter un nouveau utilisateur")}
            </Tooltip>
          }
        >
          <span
            style={{
              cursor: "pointer",
              marginLeft: "15px",
              marginBottom: "16px",
            }}
            onClick={() => setAddUserModalVisible(true)}
          >
            <i
            
              className="fas fa-plus-circle"
              style={{ color: "#07a96d", fontSize: "32px" }}
            ></i>
          </span>
        </OverlayTrigger>
        )}
      </div>

      <Row xs={1} md={2} lg={4} className="g-4 gx-2">
        {filteredUsers.map((user) => (
          <Col key={user._id} className="mb-4">
            <div className="card component-card_1" style={{ height: "100%" }}>
              <div className="card-body" style={{ height: "100%" }}>
                <img
                src={process.env.REACT_APP_API_PUBLIC_URL + 'users/'+user.image} 

                  className="rounded-circle"
                  style={{
                    width: "80px",
                    height: "80px",
                    position: "absolute",
                    top: "10px",
                    right: "10px",
                  }}
                />

                <h5 className="card-title">
                  {user.nom}
                  <div>{user.prenom}</div>
                </h5>

                <p className="card-text">
                  <i
                    className="fas fa-phone"
                    style={{ color: "green", marginRight: "5px" }}
                  ></i>
                  {user.mobile}
                </p>
                <p className="card-text">
                  <i
                    className="fas fa-user-tag"
                    style={{
                      color: "#0858d9",
                      marginRight: "5px",
                    }}
                  ></i>
                  {user.role_id?.libelle}
                </p>
                <i
                  className="far fa-envelope"
                  style={{ color: "#8b1593", marginRight: "8px" }}
                ></i>
                {user.email}

                <Row className="d-flex align-items-center">
                  <Col md={4}>
                    <Form.Group controlId="formOnlineStatus">
                      <p className="d-flex align-items-center">
                        <i
                          className={`fas fa-circle ${
                            user.online ? "text-success" : "text-danger"
                          }`}
                          style={{ marginRight: "8px", fontSize: "17px" }}
                        ></i>
                        <span>{user.online ? "Connecté" : "Déconnecté"}</span>
                      </p>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formIsActive">
                      <p className="d-flex align-items-center">
                        {user.is_active ? (
                          <i
                            className="fas fa-unlock"
                            style={{
                              color: "green",
                              marginRight: "8px",
                              marginLeft: "10px",

                              fontSize: "17px",
                            }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-ban"
                            style={{
                              color: "red",
                              marginRight: "8px",
                              marginLeft: "8px",
                              fontSize: "17px",
                            }}
                          ></i>
                        )}
                        <span>{user.is_active ? "Actif" : "Inactif"}</span>
                      </p>
                    </Form.Group>
                  </Col>

                  <Col md={4}>
                    <Form.Group controlId="formConge">
                      <p className="d-flex align-items-center">
                        {user.conge ? (
                          <i
                            className="fas fa-umbrella-beach"
                            style={{
                              color: "#ff0000",
                              marginRight: "8px",
                              fontSize: "17px",
                            }}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-business-time"
                            style={{
                              color: "blue",
                              marginRight: "8px",
                              fontSize: " 17px",
                            }}
                          ></i>
                        )}
                        <span>{user.conge ? "En congé" : "En travail"}</span>
                      </p>
                    </Form.Group>
                  </Col>
                </Row>

                <div className="d-flex justify-content-between align-items-center">
                {CanUpdateUser && (

                  <Button
                    variant="link"
                    className="text-primary"
                    onClick={() => openUpdateUserModal(user)}
                  >
                    {" "}
                    
                    {t("Modifier l'utilisateur")}
                    <i
                      className="fas fa-edit"
                      style={{
                        color: "#06958d",
                        marginLeft: "20px",
                        marginTop: "20px",
                      }}
                    ></i>
                  </Button>
                )}
                </div>
              </div>
            </div>
          </Col>
        ))}
      </Row>

      <AddUserForm
        ShowModal={addUserModalVisible}
        setShowModal={setAddUserModalVisible}
      />

      <UpdateUserForm
        ShowModal={updateUserModalVisible}
        setShowModal={setUpdateUserModalVisible}
        userData={selectedUser}
      />
    </div>
  );
};

export default UserItem;
