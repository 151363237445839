import React, { useEffect } from "react";
import { useParams } from "react-router";
export default function CategoriesList({ categoriesListe, onEditClick }: any) {
  return (
    <div>
      <section className="container">
        <div className="row">
          {categoriesListe.map((item: any) => (
            <div className="col-xxxl-2 col-xl-3 col-lg-6 col-12">
              <div className="card component-card_2">
                <img
                  src="assets/assets/img/tshirt.png"
                  className="card-img-top"
                  alt="widget-card-2"
                  onClick={(e) =>
                    (window.location.href =
                      "/categories/souscategories/" + item._id)
                  }
                />
                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={(e) =>
                      (window.location.href =
                        "/categories/souscategories/" + item._id)
                    }
                  >
                    {item.libelle.fr}
                  </h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
}
