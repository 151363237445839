import { string, object, date, number } from "yup";

export const AchatSchema = object({

  reference_document: string().required("reference_document obligatoire!!"),
  fournisseur_id :string().required("fournisseur obligatoire!!"),
  date_document : date().required('date_document obligatoire'),
  date_livraison: date().required('date_document obligatoire'),
  depots_id: string().required("depots obligatoire!!"),
  Type_id:string().required("type de piece est obligatoire!!"),
});
 