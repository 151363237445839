import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { getAllAffectationsApi } from "../../_App/Redux/Slices/affectationUserOrganisation/affectationUserOrganisationSlice";
import AffectationUserOrganisationType from "../../_App/Types/Entites/AffectaionUserOrganisationType";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AffectationList = () => {
  const dispatch = useAppDispatch();
  const affectations = useAppSelector(
    (state) => state.affectationUserOrganisation.listAffectations
  );

  const [searchTerm, setSearchTerm] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getAllAffectationsApi());
  }, [dispatch]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const filteredAffectations = affectations.filter((affectation) => {
    const searchTermLower = searchTerm.toLowerCase();

    const matchSearchTerm = (value: any) =>
      String(value).toLowerCase().includes(searchTermLower);

    return (
      matchSearchTerm(affectation.user_id?.nom) ||
      matchSearchTerm(affectation.user_id?.prenom) ||
      matchSearchTerm(affectation.organisation_id?.nom) ||
      matchSearchTerm(new Date(affectation.date_debut).toLocaleDateString()) ||
      matchSearchTerm(new Date(affectation.date_fin).toLocaleDateString())
    );
  });

  return (
    <div>
      <div className="d-flex justify-content-end align-items-center mt-3">
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </Form.Group>
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col"> {t("Nom")}</th>
              <th scope="col">{t("Prénom")}</th>
              <th scope="col">{t("Organisation")}</th>
              <th scope="col">{t("Date début")}</th>
              <th scope="col">{t("Date fin")}</th>
            </tr>
          </thead>
          <tbody>
            {filteredAffectations.map(
              (affectation: AffectationUserOrganisationType) => (
                <tr key={affectation._id}>
                  <td>
                    {affectation.user_id ? affectation.user_id.nom : "N/A"}
                  </td>
                  <td>
                    {affectation.user_id ? affectation.user_id.prenom : "N/A"}
                  </td>
                  <td>
                    {affectation.organisation_id
                      ? affectation.organisation_id.nom
                      : "N/A"}
                  </td>
                  <td>
                    {new Date(affectation.date_debut).toLocaleDateString()}
                  </td>
                  <td>{new Date(affectation.date_fin).toLocaleDateString()}</td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AffectationList;
