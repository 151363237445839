import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import SoucheType from "../../../Types/Entites/SoucheType";



  
/**
 * Get souche of product.
 * @public
 */
export const findSoucheProduitApi = createAsyncThunk(
  "produit/findSoucheProduitApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`produit/findSoucheProduitApi/${societe}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

  
/**
 * Get souche of paiement.
 * @public
 */
export const findPaiementSoucheApi = createAsyncThunk(
  "paiementachat/findPaiementSoucheApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`paiementachat/findPaiementSoucheApi/${societe}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get souche of paiement.
 * @public
 */
export const findSoucheArticleApi = createAsyncThunk(
  "article/findSoucheArticleApi",
  async (societe: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`article/findSoucheArticleApi/${societe}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface SoucheState {
    Soucheproduit: SoucheType ;
    Souchearticle: SoucheType ;
    Souchepaiement: SoucheType ;

  }
  
const initialState: SoucheState = {
    Soucheproduit: {
        societe_id: '',
        type: '',
        souche: 0
    },
    Souchearticle: {
        societe_id: '',
        type: '',
        souche: 0
    },
    Souchepaiement: {
      societe_id: '',
      type: '',
      souche: 0
  },
  };
  
  export const soucheSlice = createSlice({
    name: "souche",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
   
    
      builder.addCase(findSoucheProduitApi.fulfilled, (state, action) => {
      
         state.Soucheproduit = action.payload
       });
       builder.addCase(findPaiementSoucheApi.fulfilled, (state, action) => {
      
        state.Souchepaiement = action.payload
      });
      builder.addCase(findSoucheArticleApi.fulfilled, (state, action) => {
        state.Souchearticle = action.payload
      });
    },
  })

  
  export const soucheproduct = (state: RootState) =>state.souches.Soucheproduit;
  export const souchepaiement = (state: RootState) =>state.souches.Souchepaiement;
  export const souchearticle = (state: RootState) =>state.souches.Souchearticle;

export default soucheSlice.reducer;
