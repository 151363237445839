import { useEffect, useState } from "react";
import ParametrageMenuForm from "./ParametrageMenuForm";
import { useAppSelector } from "../../../_App/Redux/hooks";
import { ListParametrgesMenus } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import AppMenuParametrageType from "../../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import ParametrageMenuItem from "./ParametrageMenuItem";
import ParametrageMenuItemEmpty from "./ParametrageMenuItemEmpty";
import OrganisationsListMenu from "./AffectationsUsersMenu/OrganisationsListMenu";
import RolesListMenu from "./AffectationsUsersMenu/RolesListMenu";
import SelectTypeAffectation from "./AffectationsUsersMenu/SelectTypeAffectation";
const ParametrageMenuList = () => {
  const [showModalParametrageMenusForm, setShowModalParametrageMenusForm] =
    useState(false);

  const [ShowModalListOrganisations, setShowModalListOrganisations] =
    useState(false);
    const [ShowModalListRoles, setShowModalListRoles] = useState(false);

    const [ShowModalTypeAffectation, setShowModalTypeAffectation] = useState(false);

  const [Menu, setMenu] = useState<AppMenuParametrageType | null>(null);

  const Menus: AppMenuParametrageType[] | [] =
    useAppSelector(ListParametrgesMenus);

  const [RestMenus, setRestMenus] = useState<number>(0);
  useEffect(() => {
    
    if (Menus) {
      const total_menu = Menus.length;
      if (total_menu < 4) {
        setRestMenus(4 - total_menu);
      }
    }
  }, [Menus]);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="float-left"></div>
        <div className="float-right">
          {/* import modal Parametrage Menu Form */}
          <ParametrageMenuForm
            ShowModal={showModalParametrageMenusForm}
            setShowModal={setShowModalParametrageMenusForm}
            menu={Menu}
          />

          <OrganisationsListMenu
            ShowModal={ShowModalListOrganisations}
            setShowModal={setShowModalListOrganisations}
            ShowModalTypeAffectation={ShowModalTypeAffectation}
            setShowModalTypeAffectation={setShowModalTypeAffectation}
            menu={Menu}
          />

        <RolesListMenu
            ShowModal={ShowModalListRoles}
            setShowModal={setShowModalListRoles}
            ShowModalTypeAffectation={ShowModalTypeAffectation}
            setShowModalTypeAffectation={setShowModalTypeAffectation}
            menu={Menu}
          />

          <SelectTypeAffectation 
              ShowModal={ShowModalTypeAffectation}
              setShowModal={setShowModalTypeAffectation}

              ShowModalListOrganisations={ShowModalListOrganisations}
              setShowModalListOrganisations={setShowModalListOrganisations}

              ShowModalListRoles={ShowModalListRoles}
              setShowModalListRoles={setShowModalListRoles}

              menu={Menu} />
        </div>
      </div>
      <div className="col-md-12">
        <div className="row">
          {Menus &&
            Menus.filter(
              (menu: AppMenuParametrageType, index: number) => index < 4
            ).map((menu: AppMenuParametrageType, index: number) => {
              return (
                <div className="col-md-6 col-lg-6 mt-4" key={index}>
                  <ParametrageMenuItem
                    menu={menu}
                    ShowModalMenuForm={showModalParametrageMenusForm}
                    setShowModalMenuForm={setShowModalParametrageMenusForm}
                    ShowModalTypeAffectation={ShowModalTypeAffectation}
                    setShowModalTypeAffectation={setShowModalTypeAffectation}
                    setMenu={setMenu}
                  />
                </div>
              );
            })}
          {RestMenus > 0 &&
            Array.from({ length: RestMenus }).map((element, index) => {
              return (
                <div className="col-md-6 col-lg-6 mt-4" key={5 + index}>
                  <ParametrageMenuItemEmpty
                    ShowModalMenuForm={showModalParametrageMenusForm}
                    setShowModalMenuForm={setShowModalParametrageMenusForm}
                    setMenu={setMenu}
                  />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ParametrageMenuList;
