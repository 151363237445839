import React from "react";
import { Link } from "react-router-dom";
export default function CarteList({
  carteListe,
  onEditClick,
  deleteItem,
}: any) {
  return (
    <div>
      <section className="container">
        <div className="row">
          {carteListe.map((item: any) => (
            <div className="col-xxxl-2 col-xl-3 col-lg-4 col-md-6 col-12">
              <div className="card component-card_2">
                <img
                  src="/assets/assets/img/tshirt.png"
                  className="card-img-top"
                  alt="widget-card-2"
                  onClick={(e) =>
                    (window.location.href = "/cartes/categories/" + item._id)
                  }
                />

                <div className="card-body">
                  <h5
                    className="card-title"
                    onClick={(e) =>
                      (window.location.href = "/cartes/categories/" + item._id)
                    }
                  >
                    {item.libelle.fr}
                  </h5>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      onEditClick(item);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="btn btn-danger"
                    onClick={() => deleteItem(item._id)}
                  >
                    Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        
      </section>
    </div>
  );
}
