import React from 'react'
import { useParams } from "react-router-dom";
import ProduitList from './Produits/ProduitList';
import SousCategorieList from './SousCategories/SousCategorieList';
import CategorieList from './Categories/CategorieList';

export default function AddVente() {
  let { type ,id }  = useParams(); 
  return (
    <div>
      {!id ? (
        type === 'produits' ? (
          <ProduitList id={id} />
        ) : 
        type === 'souscategories' ? (
          <SousCategorieList id={id} />
        ) : 
        type === 'categories' ? (
          <CategorieList />
        ) : null
      ) : (
        type === 'souscategorie' ? (
          <ProduitList id={id} />
        ) : 
        type === 'categorie' ? (
          <SousCategorieList id={id} />
        ) : null
      )}
    </div>
  );
  
}
