import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store';
import AppMenuParametrageType from '../../../../Types/Entites/AppMenus/AppMenuParametrageType';
import { ToastSuccess } from '../../../../../Shared/Toasts/ToastSuccess';
import { ToastWarning } from '../../../../../Shared/Toasts/ToastWarning';
import _ from 'lodash';
import AppMenuItemsType from '../../../../Types/Entites/AppMenus/AppMenuItems';
import { AffectationMenuDataType } from '../../../../Types/Forms/AppMenus/AffectationMenuDataType';



/**
 * find parametrages Menu.
 * @private
 */
export const findListPagesApi = createAsyncThunk('parametragesmenu/findListPagesApi', async (data: null, { rejectWithValue }) => {
    try {
        const response = await axios.get('parametragesmenu/findListPagesApi');
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);


/**
 * find parametrages Menu.
 * @private
 */
export const findParametragesMenusApi = createAsyncThunk('parametragesmenu/findParametragesMenusApi', async (data: null, { rejectWithValue }) => {
    try {
        const response = await axios.get('parametragesmenu/findParametragesMenusApi');
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);

/**
 * add Parametrage new Menu.
 * @private
 */
export const addParametragesMenusApi = createAsyncThunk('parametragesmenu/addParametragesMenusApi',
    async (data: AppMenuParametrageType, { rejectWithValue }) => {
        try {
            const response = await axios.post('parametragesmenu/addParametragesMenusApi', data);
            return {
                data: { ...response.data },
                success: true,
                message: "Menu modifié avec succés!"
            };
        } catch (error: any) {
            
            return rejectWithValue({
                success: false,
                message: error.response.data.message,
                action: error.response.data.action,
                data: error.response.data.data,
            });
        }
    }
);

/**
 * update Parametrage  Menu.
 * @private
 */
export const updateParametragesMenusApi = createAsyncThunk('parametragesmenu/updateParametragesMenusApi', async (data: AppMenuParametrageType, { rejectWithValue }) => {
    try {
        const response = await axios.put('parametragesmenu/updateParametragesMenusApi', data);
        return {
            data: { ...response.data },
            success: true,
            message: "Menu modifié avec succés!"
        };
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);

/**
 * update Parametrage  Menu.
 * @private
 */
export const updatePositionMenusApi = createAsyncThunk('parametragesmenu/updatePositionMenusApi', async ({_id,position}:{_id: string,position: string} , { rejectWithValue }) => {
    try {
        const response = await axios.put('parametragesmenu/updatePositionMenusApi', {_id, position});
        return {
            data: { ...response.data },
            success: true,
            message: "Position Menu modifié avec succés!"
        };
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);


/**
 * Activate Parametrage Menu.
 * @private
 */
export const activateParametrageMenuApi = createAsyncThunk('parametragesmenu/activateParametrageMenuApi', async (menu_id: string, { rejectWithValue }) => {
    try {
        const response = await axios.post('parametragesmenu/activateParametrageMenuApi', { menu_id });
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);

/**
 * Affectation Parametrage Menu par Organisations/users.
 * @private
 */
export const AffectationOrgsMenuApi = createAsyncThunk('parametragesmenu/AffectationOrgsMenuApi', async (data: AffectationMenuDataType, { rejectWithValue }) => {
    try {
        const response = await axios.post('parametragesmenu/AffectationOrgsMenuApi', data);
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);


/**
 * Affectation Parametrage Menu par roles/users.
 * @private
 */
export const AffectationRolesMenuApi = createAsyncThunk('parametragesmenu/AffectationRolesMenuApi', async (data: AffectationMenuDataType, { rejectWithValue }) => {
    try {
        const response = await axios.post('parametragesmenu/AffectationRolesMenuApi', data);
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);

/**
 * delete Parametrage Menu.
 * @private
 */
export const deleteParametrageMenuApi = createAsyncThunk('parametragesmenu/deleteParametrageMenuApi', async (menu_id: string, { rejectWithValue }) => {
    try {
        const response = await axios.delete('parametragesmenu/deleteParametrageMenuApi', { data: { menu_id } });
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);

// Initial state du slice
const initialState: {
    pages: AppMenuItemsType[],
    listItems: AppMenuItemsType[],
    parametragesMenu: AppMenuParametrageType[] | [],
    activeMenu: AppMenuParametrageType | null,
} = {
    pages: [], //default pages from database
    listItems: [], // list of menuItems from parametrage menu and rest of not selected PAGES 
    parametragesMenu: [],
    activeMenu: null
}



export const parametrageMenuSlice = createSlice({
    name: 'appParametragesMenus',
    initialState,
    reducers: {
        swapPlacesItemsReducer: (state, action) => {

            const dragIndex = action.payload.dragIndex;
            const dropIndex = action.payload.dropIndex;

            const dragPage = state.listItems[dragIndex];
            const dropPage = state.listItems[dropIndex];

            state.listItems[dropIndex] = dragPage;
            state.listItems[dragIndex] = dropPage;
        },
        setListItems: (state, action) => {
            state.listItems = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(findParametragesMenusApi.fulfilled, (state, action) => {
            state.parametragesMenu = action.payload
            if(action.payload.length){
                const actif_menu = action.payload.filter((menu: AppMenuParametrageType) => menu.actif == true)
                if (actif_menu.length > 0) {
                    state.activeMenu = actif_menu[0];
                }
            }
            

        });

        builder.addCase(findListPagesApi.fulfilled, (state, action) => {
            state.pages = action.payload
        });

        builder.addCase(addParametragesMenusApi.fulfilled, (state, action) => {
            state.parametragesMenu.splice(0, 0, action.payload.data)
        });

        builder.addCase(updateParametragesMenusApi.fulfilled, (state, action) => {
            const index = _.findIndex(state.parametragesMenu, { _id: action.payload.data._id })
            //replace updated  menu by index
            state.parametragesMenu.splice(index, 1, action.payload.data)
            if(action.payload.data.actif){
                state.activeMenu = action.payload.data;
            }
        });

        builder.addCase(updatePositionMenusApi.fulfilled, (state, action) => {
            const index = _.findIndex(state.parametragesMenu, { _id: action.payload.data._id })
            //replace updated  menu by index
            state.parametragesMenu.splice(index, 1, action.payload.data)
            if(action.payload.data.actif){
                state.activeMenu = action.payload.data;
            }
        });


        builder.addCase(activateParametrageMenuApi.fulfilled, (state, action) => {

            state.activeMenu = action.payload;

            state.parametragesMenu = state.parametragesMenu.map((menu: AppMenuParametrageType) => {
                if (menu._id == action.payload._id) {
                    return { ...action.payload, actif: true };
                } else {
                    return { ...menu, actif: false };
                }
            });
            ToastSuccess(`${action.payload.libelle} Activé avec succés`)
        });

        builder.addCase(deleteParametrageMenuApi.fulfilled, (state, action) => {
            const deletedMenu = action.payload.data;
            state.parametragesMenu = _.remove(state.parametragesMenu,( menu: AppMenuParametrageType)  => menu._id !== deletedMenu._id )
        });

        builder.addCase(AffectationOrgsMenuApi.fulfilled, (state, action) => {
        });
        


    },
});

export const { swapPlacesItemsReducer, setListItems } = parametrageMenuSlice.actions;
export const ListParametrgesMenus = (state: RootState) => state.appParametragesMenus.parametragesMenu;
export const ActiveMenu = (state: RootState) => state.appParametragesMenus.activeMenu;
export const ListPages = (state: RootState) => state.appParametragesMenus.pages;
export const ListItems = (state: RootState) => state.appParametragesMenus.listItems;

export default parametrageMenuSlice.reducer;
