import Modal from 'react-bootstrap/Modal';
import ParametrageTabs from './ParametrageTabs';
const ParametrageModal = ({ showModal, setShowModal }: any) => {

    return (
        <Modal
            show={showModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="app_parametrages"
            // fullscreen={true}
            dialogClassName="modal-100w"

        >
            <Modal.Header closeButton>
                <Modal.Title id="app_parametrages" className="custom-modal-title">
                    <div className="titleWithButton">
                        <div className="c-primary">Paramétrages</div>
                        <div>
                            
                        </div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ padding: "0px 12px" }}>
                <div className="row">
                    <div className="col-md-12" style={{ position: "relative" }}>
                        <ParametrageTabs />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}

export default ParametrageModal
