import React, { useState } from 'react'

export default function InventaireHeader({  t,
    setlistfiltred,
    listInventaire}:any) {
    const [keyword, setkeyword] = useState("");

  
    const handleSearch = () => {
   
    
      const lowercaseKeyword = keyword.toLowerCase();
      const listFiltrer = listInventaire.filter(
        (item:any) =>
          
          (item.code_inventaire.toLowerCase().includes(lowercaseKeyword) ||
            item.item?.depots_id?.libelle.toLowerCase().includes(lowercaseKeyword) ||
            item.item?.depots_id?.toLowerCase().includes(lowercaseKeyword) 
            )
      );
    
      setlistfiltred(listFiltrer.reverse());
      if (keyword === "") {
        setlistfiltred([...listInventaire].reverse());
      }
    };
    
  return (
    <div className="container mt-4 mb-4">
    <div className="row ">
   
      <div className="col-md-4 mt-4">
        {/* Row 1 */}
        <input
          type="text"
          className="w-100 form-control product-search br-30"
          id="input-search"
          placeholder={t('chercher par mot cle')}
          onChange={(e) => setkeyword(e.target.value)}
        />
      </div>
      <div className="col-md-2 mt-4"> 
       <button  className="btn btn-primary-app" onClick={()=> handleSearch()}> {t('chercher')}</button>
      </div>
    </div>
  </div>
  )
}
