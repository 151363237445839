import React, { useState, useEffect } from "react";
import DetailAchatTable from "./DetailAchatTable";
import { formadate } from "../../_App/Helpers/helpers";

import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  annulerAchatApi,
  clotureAchatApi,
  condirmerAchatApi,
} from "../../_App/Redux/Slices/achat/achatSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { AchatType } from "../../_App/Types/Entites/Achat/AchatType";
import {
  findFourinsseurByOrganisationApi,
  findFourinsseurBySocieteApi,
  ListFournisseurs,
} from "../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import HeaderAchatTable from "./AchatTable/HeaderAchatTable";
import {
  ListDepots,
  findDepotsBySocieteApi,
} from "../../_App/Redux/Slices/depot/depotSlice";
import Swal from "sweetalert2";
import PaiementAchat from "./PaiementAchat";
import { FournisseurType } from "../../_App/Types/Entites/FournisseurType";
import {
  ListFournisseursMouvement,
  findFournisseurMouvementApi,
  findFournisseurMouvementBySocieteApi,
} from "../../_App/Redux/Slices/fournisseur/fournisseurmouvement";

import { ListAchatType, findAchatTypeBySocieteApi } from "../../_App/Redux/Slices/achat/achattypeSlice";
import { useNavigate } from "react-router-dom";

export default function AchatTable({
  t,
  ListAchats,
  ListDetailAchats,
  idOrganisation,
  setSShowModalProductDetail,
  ShowModalProductDetail,
  ShowModalDetailAchat,
  setShowModalDetailAchat,
  societe,
}: any) {
  const navigate = useNavigate()
  const [Achat, setAchat] = useState<AchatType>();
  const [listfiltred, setlistfiltred] = useState<any[]>([]);
  const listFournisseurs = useAppSelector(ListFournisseurs);
  const listdepots = useAppSelector(ListDepots);
  const [paiementModal, setShowPaiementModal] = useState(false);
  const [fournisseur, setfournisseur] = useState<FournisseurType>();
  const [listachat_payer, setlistachat_payer] = useState<any[]>([]);
  const [soldetotal, setsoldetotal] = useState(0);
  const [code_paiement, setcode_paiement] = useState("");
  const [totalcredit, settotalcredit] = useState(0);

  const [newlistAchats, setnewlistAchat] = useState<any[]>([]);
  const dispatch = useAppDispatch();
  const listmvtFournisseur = useAppSelector(ListFournisseursMouvement);
  const typeachat = useAppSelector(ListAchatType);


  useEffect(() => {
    if (societe) {
      dispatch(findFourinsseurBySocieteApi(societe));
      dispatch(findDepotsBySocieteApi(societe));
      dispatch(findFournisseurMouvementBySocieteApi(societe));
      dispatch(findAchatTypeBySocieteApi(societe));
    }
  }, [dispatch, societe]);
  useEffect(() => {
    if (!paiementModal) {
      setlistachat_payer([]);
      setcode_paiement("");
      setsoldetotal(0);
      settotalcredit(0);
    }
  }, [paiementModal]);
  useEffect(() => {
    let etat = "";
    const newAchatlist = ListAchats.map((element: any) => {
      const findFournisseur = listFournisseurs.find(
        (fournisseur) => fournisseur._id === element.fournisseur_id
      );
      const finddepots = listdepots.find(
        (depot) => depot._id === element.depots_id
      );
      const findtype = typeachat.find(
        (type) => type._id === element.Type_id
      );
      const type = findtype ? findtype.libelle : null;

      const fournisseur = findFournisseur ? findFournisseur : null;
      const depotachat = finddepots ? finddepots.libelle : null;

      let count_confirmer = 0;

      /*if(ListDetailAchats[element._id] ){
       ListDetailAchats[element._id].map( (item:any)=>{
        if (item.cloture === true ){
          count_confirmer = count_confirmer  +  1 
        }
        
    })
  
    if( ListDetailAchats[element._id].length === count_confirmer  ){
      etat = 'cloturé'
     } else if( count_confirmer  !== 0 && ListDetailAchats[element._id].length > count_confirmer ){
      etat = 'partiellement cloturé'

     }
     else if (count_confirmer  === 0 ){

      etat = 'non cloturer'

     }}*/
      return { ...element, fournisseur, depotachat, etat ,type};
    });
    setnewlistAchat(newAchatlist);
    setlistfiltred(newAchatlist.reverse());
  }, [ListAchats, listFournisseurs, typeachat ,ListDetailAchats]);
  const verifcationMsg = (action: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: t(`vous voulez ${action} ce achat`),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t('Cancel!')

    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const Annuler = async (achat: any) => {
    if (await verifcationMsg("annuler")) {
      const newachat = { ...achat };
      newachat.annuler = true;
      const response = await dispatch(annulerAchatApi(newachat));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };
  const Confirmer = async (id: any) => {
    const verifdetail_achats = ListDetailAchats[id].find(
      (item: any) => item.type === "P"
    );
    if (verifdetail_achats) {
      ToastWarning("l'achat contient des produits parents");
    } else {
      if (await verifcationMsg("confirmer ")) {
        const data = { idachat: id };
        const response = await dispatch(condirmerAchatApi(data));

        if (response.payload.success) {
          ToastSuccess(t(response.payload.message));
          window.location.reload();
        } else {
          ToastWarning(response.payload.message);
        }
      }
    }
  };
  const cloturer = async (achat: any) => {
    if (await verifcationMsg("cloturer")) {
      const listdetailachat = ListDetailAchats[achat._id];
      const data_achat = {
        fournisseur: achat?.fournisseur?.libelle,
        date_livraison: achat.date_livraison,
      };
      //const data
      const data = {
        listdetailachat: listdetailachat,
        data_achat: data_achat,
      };
      const response = await dispatch(clotureAchatApi(data));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };
  /* const Paiement = async (achat: AchatType) => {
    const foundFournisseur = listFournisseurs.find(
      (item: any) => item._id === achat?.fournisseur_id
    );
    console.log("foundFournisseur", foundFournisseur);
    setfournisseur(foundFournisseur);
    setAchat(achat);
    setShowPaiementModal(true);
  };*/
  const addToPaiement = (achat: AchatType) => {
 
      const achatchecked = listachat_payer.find((item) => item === achat._id);

      if (achatchecked) {
        // removed
        const newlistachat_payer = listachat_payer.filter(
          (element: any) => element !== element.achat
        );
        setlistachat_payer(newlistachat_payer);

        const newtotal = soldetotal - (achat.total_net_payer-achat.payer);

        setsoldetotal(newtotal);
      } else {

        const foundFournisseur = listFournisseurs.find(
          (item: any) => item._id === achat?.fournisseur_id
        );
        //setfournisseur(foundFournisseur);
        setlistachat_payer((prev) => [...prev, {achat:achat, fournisseur: foundFournisseur}]);
        const newtotal = soldetotal + (achat.total_net_payer - achat.payer);
        setsoldetotal(newtotal);
      }
    
  };
  const payer = () => {
    setShowPaiementModal(true);
  };
  const repayer = async (achat: AchatType) => {
    const foundFournisseur = listFournisseurs.find(
      (item: any) => item._id === achat?.fournisseur_id
    );

    setfournisseur(foundFournisseur);
    setcode_paiement(achat.code_paiement);

    // find achat de ce code
    const list_achat_codepaiement = ListAchats.filter(
      (item: AchatType) => item.code_paiement === achat.code_paiement
    );

    // Check if list_achat_codepaiement is an array
    if (Array.isArray(list_achat_codepaiement)) {
      const total_MontantTotalachat: number = list_achat_codepaiement.reduce(
        (accumulator: number, currentValue: { total_net_payer: any }) =>
          accumulator + Number(currentValue.total_net_payer),
        0
      );
      list_achat_codepaiement.forEach((element) => {
        setlistachat_payer((prev) => [...prev, element._id]);
      });
      setsoldetotal(total_MontantTotalachat);
      const total_Montantcredits: number = list_achat_codepaiement.reduce(
        (accumulator: number, currentValue: { payer: any }) =>
          accumulator + Number(currentValue.payer),
        0
      );
      settotalcredit(parseFloat(total_Montantcredits.toFixed(3)));
      setShowPaiementModal(true);
    }


    
      
    
  };

  return (
    <div className="row layout-spacing">
      <div className="col-lg-12   bg-white">
        <HeaderAchatTable
          t={t}
          newlistAchats={newlistAchats}
          setlistfiltred={setlistfiltred}
        />
      </div>

      <div className="col-lg-12  mt-5   bg-white">
        <div className="inv--product-table-section ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center" colSpan={2}>
                    {t("Action")}
                  </th>
                  <th className="text-center">{t("etat")}</th>

                  <th>{t('type')}</th>
                 


                  <th> {t("Ref Achat")}</th>
                  <th>{t("Ref Document")}</th>
                  <th>{t("Fournisseur")}</th>
                  <th>{t("Date Document")}</th>
                  <th>{t("Date Livraison")}</th>

                  <th>{t("Total A Payer")}</th>
                  <th>{t("Commentaire")}</th>
                </tr>
              </thead>
              <tbody>
                {listfiltred.length !== 0 &&
                  listfiltred.map((item: any, index: number) => (
                    <tr
                      key={item._id}
                      className={Achat?._id === item._id ? "active" : ""}
                    >
                      <td>
                        {item.code_paiement.length  !==0 ? (
                          item.payer === item.total_net_payer ? (
                            <p>payé</p>
                          ) : (
                            <p
                            
                            >
                              {item.code_paiement[0]}
                              <input
                            type="checkbox"
                            onClick={() => addToPaiement(item)}
                            checked={listachat_payer.find(element => element.achat._id === item._id)}
                          />
                            </p>
                          )
                        ) : (
                          <input
                            type="checkbox"
                            onClick={() => addToPaiement(item)}
                            checked={listachat_payer.find(element => element.achat._id === item._id)}
                          />
                        )}
                      </td>
                      {!item.confirmer ? (
                        item.annuler ? (
                          <>
                            <td
                              className="c-primary pointer"
                              onClick={() => Confirmer(item._id)}
                            >
                              confirmer
                            </td>
                            <td></td>
                          </>
                        ) : (
                          <>
                            <td
                              className="c-primary pointer"
                              onClick={() => Annuler(item)}
                            >
                              Annuler
                            </td>
                            <td
                              className="c-primary pointer"
                              onClick={() => Confirmer(item._id)}
                            >
                              confirmer
                            </td>
                          </>
                        )
                      ) : !item.cloture ? (
                        <>
                          <td
                            className="c-primary pointer"
                            onClick={() => cloturer(item)}
                          >
                            cloturer
                          </td>
                          <td></td>
                        </>
                      ) : (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}

                  
                      <td>
                        {item.cloture ? (
                          <p className="color-red">cloturé</p>
                        ) : item.confirmer ? (
                          <p className="color-red">confirmé</p>
                        ) : item.annuler ? (
                          <p className="color-red">annulé</p>
                        ) : (
                          <p>En cours</p>
                        )}
                      </td>
                      <td>{item.type}</td>


                      <td>{item.refAchat}</td>
                      <td
                        className="lien-bleu-souligne"
                        onClick={() => {
                          setAchat(item);
                          setShowModalDetailAchat(true);
                        }}
                      >
                        {" "}
                        {item.reference_document}
                      </td>
                      <td className="lien-bleu-souligne" onClick={()=>navigate('/fournisseurs')}> {item?.fournisseur?.libelle}</td>
                      <td>{formadate(item.date_document)}</td>
                      <td>{formadate(item.date_livraison)}</td>

                      <td>{item.total_net_payer}</td>

                      <td>{item.commentaire}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="d-flex justify-content-end mb-3">
        <button
          disabled={listachat_payer.length === 0}
          onClick={payer}
          className="btn btn-primary-app mb-3 mt-2"
        >
         {t('payer')} 
        </button>
       
    </div>
       
      </div>
      {ShowModalDetailAchat ? (
        <DetailAchatTable
          t={t}
          idOrganisation={idOrganisation}
          ShowModal={ShowModalDetailAchat}
          setShowModal={setShowModalDetailAchat}
          Achat={Achat}
          listdepots={listdepots}
          ShowModalProductDetail={ShowModalProductDetail}
          setSShowModalProductDetail={setSShowModalProductDetail}
          ListDetailAchats={Achat?._id ? ListDetailAchats[Achat?._id] : null}
        />
      ) : null}
      <PaiementAchat
        t={t}
        ShowModal={paiementModal}
        setShowModal={setShowPaiementModal}
        debittotal={parseFloat(soldetotal.toFixed(3))}
        Fournisseur={fournisseur}
        societe={societe}
        list_achat_selected={listachat_payer}
        organisation={idOrganisation}
        totalcredit={parseFloat(totalcredit.toFixed(3))}
      />
    </div>
  );
}
