import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { ProductTailleSchema } from "../../../_App/Schemas/ProductTailleSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  createProductTarifsApi,updateProductsTarifsApi,ListProductTarif,
} from "../../../_App/Redux/Slices/product_tarif/product_tarifSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ProductTarifSchema } from "../../../_App/Schemas/ProductTarifSchema";
import { ProductTarifType } from "../../../_App/Types/Entites/ProductTarifType";
export default function ProductTarifsForm({
  ShowModal,
  setShowModal,
  ProductTarif,
  Product_id,
  organisation_id
}: any) {
  const dispatch = useAppDispatch();

  // init form hooks

  const formOptions = {
    resolver: yupResolver(
      ProductTarifSchema
    ) as unknown as Resolver<ProductTarifType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ProductTarifType>(formOptions);
  const { errors } = formState;
  useEffect(() => {
    if (ProductTarif) {
      reset({
        _id :ProductTarif._id,
        libelle: ProductTarif.libelle,
        prix_achat_ttc: ProductTarif.prix_achat_ttc,
        prix_achat_ht: ProductTarif.prix_achat_ht,
        prix_vente_ttc: ProductTarif.prix_vente_ttc,
        prix_vente_ht: ProductTarif.prix_vente_ht,
        tva: ProductTarif.tva,
        tax1: ProductTarif.tax1,
        tax2: ProductTarif.tax2,
        tax3: ProductTarif.tax3,
        organisation_id: ProductTarif.organisation_id,
        produit_id:ProductTarif.produit_id
      });
    } else {
      reset({
        libelle: "",
        prix_achat_ttc: 0,
        prix_achat_ht: 0,
        prix_vente_ttc: 0,
        prix_vente_ht: 0,
        tva: 0,
        tax1: 0,
        tax2: 0,
        tax3: 0,
        organisation_id: "",
      });
    }
  }, [ProductTarif]);

  const onSubmit = async (data: any) => {
    
      let response ;
     
      if(data._id){
        response = await dispatch(updateProductsTarifsApi(data));

      }else{
        data.organisation_id = organisation_id;
        data.produit_id=Product_id;
        response = await dispatch(createProductTarifsApi(data));
      }
      


    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        libelle: "",
        prix_achat_ttc: 0,
        prix_achat_ht: 0,
        prix_vente_ttc: 0,
        prix_vente_ht: 0,
        tva: 0,
        tax1: 0,
        tax2: 0,
        tax3: 0,
        organisation_id: "",
      });
      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };
  return <div>
        <Modal show={ShowModal} onHide={() => setShowModal(false)}    dialogClassName="modal-40w">
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">Ajouter Tarif</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
        <div className="row">
            <div className="col-12">
            <div className="form-group">
             <label htmlFor="libelle">libelle</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("libelle")}
                />
                <div>{errors.libelle?.message}</div>
              </div>
             <label htmlFor="prix_achat_ttc">prix_achat_ttc</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("prix_achat_ttc")}
                />
                <div>{errors.prix_achat_ttc?.message}</div>
              </div>
             <label htmlFor="prix_achat_ht">prix_achat_ht</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("prix_achat_ht")}
                />
                <div>{errors.prix_achat_ht?.message}</div>
              </div>
             <label htmlFor="prix_vente_ttc">prix_vente_ttc</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("prix_vente_ttc")}
                />
                <div>{errors.prix_vente_ttc?.message}</div>
              </div>
             
             <label htmlFor="prix_vente_ht">prix_vente_ht</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("prix_vente_ht")}
                />
                <div>{errors.prix_vente_ht?.message}</div>
              </div>
          
             <label htmlFor="tva">tva</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("tva")}
                />
                <div>{errors.tva?.message}</div>
              </div>
             <label htmlFor="tax1">tax1</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("tax1")}
                />
                <div>{errors.tax1?.message}</div>
              </div>
             <label htmlFor="tax2">tax2</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("tax2")}
                />
                <div>{errors.tax2?.message}</div>
              </div>
             <label htmlFor="tax3">tax3</label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("tax3")}
                />
                <div>{errors.tax3?.message}</div>
              </div>
          </div>
          </div> </div>
          
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => setShowModal(false)}
            className="btn btn-grey"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary mt-3">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  </div>;
}
