import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { Panier } from "../../../_App/Redux/Slices/Caisse/PanierSlice";

import Cart from "./cart/Cart";
import Order from "./order/Order";
import PaidOrder from "./order/PaidOrder";
import {
  ListOrders,
  getOrderByIdApi,
  order,
  selectedOrder,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { useParams } from "react-router-dom";
import { ActiveMenu } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { selectedFullTable } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import TableOrders from "./tableOrders/tableOrders";
import { OrderType } from "../../../_App/Types/Entites/Orders/OrderType";
import PartialPaidOrder from "./order/PartialPaidOrder";
import CalculatorModal from "../../../Components/Caisses/Calculator/Calculator";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";

interface PaidProduct {
  itemId: string;
  paid_quantity: number;
}
interface newOrder {
  orderId: string;
  paidProducts: PaidProduct[];
  newPrice: number;
}
interface RightBarPanierProps {
  visible: boolean;
  setVisible: any;
  onArticlesToPayChange: (newArticlesToPay: newOrder | undefined) => void;
  miniOrder: (newOrder: OrderType | undefined) => void;
}
const RightBarPanier: React.FC<RightBarPanierProps> = ({
  visible,
  setVisible,
  onArticlesToPayChange,
  miniOrder,
}) => {
  const dispatch = useAppDispatch();
  const openedOrder = useAppSelector(selectedOrder);
  const retrievedOrder = useAppSelector(order);


  const Menu = useAppSelector(ActiveMenu);
  const table = useAppSelector(selectedFullTable);
  const parametrage = useAppSelector(parametrageCaisse);
  const bgClass =
    parametrage && parametrage.barcodeOnly ? " light-background" : "";

  return (
    <div
      className={
        parametrage && parametrage.barcodeOnly ? "fixed-col-right" : "col-right"
      }
      style={{
        zIndex: "auto",
        overflowY: "hidden",
        position: "fixed",
        left:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "left"
              ? "150px"
              : "0px"
            : Menu?.menuPosition.position === "left"
            ? "unset"
            : "unset",
        right:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "right"
              ? "600px"
              : "610px"
            : Menu?.menuPosition.position === "right"
            ? "145px"
            : 0,
        top:
          parametrage && parametrage.barcodeOnly
            ? Menu?.menuPosition.position === "up"
              ? "130px"
              : "20%"
            : Menu?.menuPosition.position === "up"
            ? "130px"
            : 0,
        bottom: 0,
        width:
          parametrage && parametrage.barcodeOnly
            ? "calc(100% - 745px)"
            : "448px",
        height: Menu?.menuPosition.position === "down" ? "86%" : "unset",
      }}
    >
      <div className={"col-right-content" + bgClass} style={{ padding: "0 0" }}>
        <div
          className="col-right-content-container"
          style={{ height: "100%", display: "flex", flexDirection: "column" }}
        >
          <div
            className="activity-section"
            style={{
              maxHeight: "100%",
              overflowY: "auto",
            }}
          >
            {parametrage && parametrage.barcodeOnly ? (
              retrievedOrder ? (
                retrievedOrder.status === "Payée" ? (
                  <PaidOrder retrievedOrder={retrievedOrder} />
                ) : (
                  <Order
                    setVisible={setVisible}
                    hidePaymentButton={visible}
                    onArticlesToPayChange={onArticlesToPayChange}
                    miniOrder={miniOrder}
                    retrievedOrder={retrievedOrder}
                  />
                )
              ) : table ? (
                <TableOrders
                  setVisible={setVisible}
                  hidePaymentButton={visible}
                  onArticlesToPayChange={onArticlesToPayChange}
                />
              ) : (
                <Cart setVisible={setVisible} hidePaymentButton={visible} />
              )
            ) : openedOrder ? (
              openedOrder.status === "Payée" ? (
                <PaidOrder />
              ) : (
                <Order
                  setVisible={setVisible}
                  hidePaymentButton={visible}
                  onArticlesToPayChange={onArticlesToPayChange}
                  miniOrder={miniOrder}
                  retrievedOrder={null}
                />
              )
            ) : table ? (
              <TableOrders
                setVisible={setVisible}
                hidePaymentButton={visible}
                onArticlesToPayChange={onArticlesToPayChange}
              />
            ) : (
              <Cart setVisible={setVisible} hidePaymentButton={visible} />
            )}

            {/* {openedOrder ? (
              openedOrder.status === "Payée" ? (
                <PaidOrder />
              ) : (
                <Order
                  setVisible={setVisible}
                  hidePaymentButton={visible}
                  onArticlesToPayChange={onArticlesToPayChange}
                  miniOrder={miniOrder}
                />
              )
            ) : table ? (
              <TableOrders
                setVisible={setVisible}
                hidePaymentButton={visible}
                onArticlesToPayChange={onArticlesToPayChange}
              />
            ) : (
              <Cart setVisible={setVisible} hidePaymentButton={visible} />
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightBarPanier;
