
import { object, string, array } from 'yup';

export const AddUserRoleSchema = object({
   
    _id: string().notRequired(),
    libelle: string().required('Libellé obligatoire!!'),
    redirectTo: string().required('Rediriger vers obligatoire!!'),

});
