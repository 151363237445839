import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { Ingredient } from "../../../Types/Entites/Ingredient";
import axios from "axios";

/**
 * Get list of Ingredients.
 * @public
 */
export const findIngredientByProductApi = createAsyncThunk(
  "ingredient/findIngredientByProductApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `ingredient/findIngredientsByProductApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

// Define the initial state using that type
interface IngredientState {
  supplements: Ingredient[];
  ingredients: Ingredient[];
}

const initialState: IngredientState = {
  supplements: [],
  ingredients: [],
};

export const ingredientSlice = createSlice({
  name: "ingredients",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(findIngredientByProductApi.fulfilled, (state, action) => {
      state.supplements = action.payload.filter(
        (ingredient: Ingredient) => ingredient.type === "Supplement"
      );
      state.ingredients = action.payload.filter(
        (ingredient: Ingredient) => ingredient.type === "Ingredient"
      );
    });
  },
});

// Other code such as selectors can use the imported `RootState` type
export const Listingredients = (state: RootState) =>
  state.ingredient.ingredients;
export const Listsupplements = (state: RootState) =>
  state.ingredient.supplements;

export default ingredientSlice.reducer;
