import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { SousCategorieSchema } from "../../_App/Schemas/SousCategorieSchema";
import {
  createSousCategorieApi,
  updateSousCategorieApi,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  findCategorieBySocieteApi,
  ListCategories,
  ListCartegoriesParOrganisation
} from "../../_App/Redux/Slices/categories/categorieSlice";

import { CategorieType } from "../../_App/Types/Entites/CategorieType";
export default function SousCategorieForm({
  ShowModal,
  setShowModal,
  sousCategorie,
  categorie_id,
  currentOrganisation,
  societe,
}: any) {
  const dispatch = useAppDispatch();
  const listCategories = useAppSelector(ListCategories);
  const listCartegoriesParOrganisation  = useAppSelector(ListCartegoriesParOrganisation);
  const [selectedOrganisation, setselectedOrganisation] = useState(true);
  const [list_categories , setlist_categories] = useState<CategorieType[]>([])
  // init form hooks
  const formOptions = { resolver: yupResolver(SousCategorieSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (sousCategorie) {
      console.log(sousCategorie)
      reset({
        libelle: sousCategorie.libelle,
        image: sousCategorie.image,
        categorie_id: sousCategorie.categorie_id
      });
      sousCategorie.organisation_id !== null
        ? setselectedOrganisation(true)
        : setselectedOrganisation(false);
    } else {
      reset({
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
      });
    }
  }, [sousCategorie]);
  useEffect(() => {
    if (societe) {
      dispatch(findCategorieBySocieteApi(societe));
    }
  }, [dispatch, societe]);
  useEffect(() => {
    if (selectedOrganisation) {
      const list = listCartegoriesParOrganisation[currentOrganisation];
      list ? setlist_categories(list) : setlist_categories([]);
    } else {
      setlist_categories(listCategories);
    }
  }, [selectedOrganisation, listCartegoriesParOrganisation, listCategories]);
  
  const onSubmit = async (data: any) => {
    let response;
    if (selectedOrganisation) {
      data.organisation_id = currentOrganisation;
    } else {
      data.organisation_id = null;
    }
    data.societe_id = societe;
    if (sousCategorie && sousCategorie._id) {
      // If the sous categorie has an _id, it means it's an update
      data._id = sousCategorie._id;
      response = await dispatch(updateSousCategorieApi(data));
    } else {
      response = await dispatch(createSousCategorieApi(data));
    }

    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
      });
      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  return (
    <Modal 
    show={ShowModal} onHide={() => setShowModal(false)}
    className="modal-2"
    dialogClassName="modal-40w"
    backdropClassName="modal-backdrop-2">
      <Modal.Header>
        <Modal.Title>
          {sousCategorie && sousCategorie._id ? (
            <span className="modal-title" id="addCategorieLabel">
              Modifier la Catégorie N° : {sousCategorie?._id}
            </span>
          ) : (
            <span className="modal-title c-primary" id="addCategorieLabel">
              Ajouter une sous categorie
            </span>
          )}
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            setShowModal(false);
            reset({
              libelle: {
                fr: "",
                en: "",
                ar: "",
              },
              image: null,
            });
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                {/* <input
                  type="hidden"
                  placeholder="Id"
                  id="id"
                  {...register("id")}
                /> */}
                <label htmlFor="libellefr">
                  {" "}
                  <span style={{ color: "red" }}>*</span>Libelle fr
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.fr ? "is-invalid" : ""
                  }`}
                  id="libellefr"
                  {...register("libelle.fr")}
                />
                 <div className="invalid-feedback">
                    {errors?.libelle?.fr &&
                      errors.libelle.fr.message &&
                      errors.libelle.fr.message.toString()}
                  </div>
                <label htmlFor="libelleen">
                  {" "}
                  <span style={{ color: "red" }}>*</span>Libelle en
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.en ? "is-invalid" : ""
                  }`}
                  id="libelleen"
                  {...register("libelle.en")}
                />
                  <div className="invalid-feedback">
                    {errors?.libelle?.en &&
                      errors.libelle.en.message &&
                      errors.libelle.en.message.toString()}
                  </div>
                <label htmlFor="libellear">
                  {" "}
                  <span style={{ color: "red" }}>*</span>Libelle ar
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.ar ? "is-invalid" : ""
                  }`}
                  id="libellear"
                  {...register("libelle.ar")}
                />
                 <div className="invalid-feedback">
                    {errors?.libelle?.ar &&
                      errors.libelle.ar.message &&
                      errors.libelle.ar.message.toString()}
                  </div>
           
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="image">
                {" "}
                <span style={{ color: "red" }}>*</span>Image
              </label>
              <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="image">
                  <i className="os-icon os-icon-documents-07"></i>
                </label>
                <input
                  type="file"
                  accept="image/*"
                  className={`form-control ${errors.image ? "is-invalid" : ""}`}
                  id="image"
                  placeholder="image"
                  {...register("image")}
                />
              </div>
              <div className="invalid-feedback">
                {errors.image &&
                  errors.image.message &&
                  errors.image.message.toString()}
              </div>
            </div>

            <div className="col-12">
              <label htmlFor="image"> <span style={{ color: "red" }}>*</span>categorie</label>
              <div className="input-group mb-3">
                <label htmlFor="souscategorie"></label>
                <select
                  className={`form-control ${
                    errors.categorie_id ? "is-invalid" : ""
                  }`}
                  id="carte"
                  {...register("categorie_id")}
                >
           
                  {list_categories.map((item: any) => (
                    <option key={item._id} value={item._id}>
                      {" "}
                      {item?.libelle?.fr}
                    </option>
                  ))}
                </select>
                <div className="invalid-feedback">
                    {errors?.categorie_id &&
                      errors.categorie_id.message &&
                      errors.categorie_id.message.toString()}
                  </div>
              </div>
            </div>
            <div className="n-chk">
              <label className="new-control new-radio radio-classic-primary">
                <input
                  type="radio"
                  checked={selectedOrganisation}
                  onChange={(e) => setselectedOrganisation(true)}
                  value="connectedOnly"
                  className="new-control-input"
                  name="custom-radio-2"
                />
                <span className="new-control-indicator" />
                <p className="fs-15">l'organisation connecté seulement</p>
              </label>
            </div>
            <div className="n-chk">
              <label className="new-control new-radio radio-classic-primary">
                <input
                  type="radio"
                  checked={!selectedOrganisation}
                  onChange={() => setselectedOrganisation(false)}
                  value="allOrganizations"
                  className="new-control-input"
                  name="custom-radio-2"
                />
                <span className="new-control-indicator" />
                <p className="fs-15">Tous les organisations de la societe</p>
              </label>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => {
              setShowModal(false);
              reset({
                libelle: {
                  fr: "",
                  en: "",
                  ar: "",
                },
                image: null,
              });
            }}
            className="btn btn-primary-app"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
