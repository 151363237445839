import React, { useEffect, useState } from "react";
import _ from "lodash";
import AddNewRaccourciList from "./AddNewRaccourciList";
import Tab from "./Tab";
import {
  Filter,
  changeFilter,
} from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListArticleBySousCategorie,
  findStockByDepot,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";

const RaccourciProductList = ({
  ProductsList,
  display,
  raccourcisLimit,
  sousCategories,
  currentOrganisation,
  setlist_SousCat,
  setIndex,
  index,
}: any) => {
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useAppDispatch();
  const currentDepot = useAppSelector(DepotDefautOrganisation);

  useEffect(() => {
    if (currentDepot._id) dispatch(findStockByDepot(currentDepot._id));
  }, [dispatch]);

  useEffect(() => {
    if (activeTab == null) {
      // Load products for the first tab when the component mounts
      if (ProductsList && Object.keys(ProductsList).length > 0) {
        const firstTab = Object.keys(ProductsList)[0];
        handleTabChange(firstTab);
      } else {
        const firstTab = sousCategories[0]?._id;
        handleTabChange(firstTab);
      }
    }
  }, [ProductsList]);
  const filter = useAppSelector(Filter);
  const articlesBySousCategorie = useAppSelector(ListArticleBySousCategorie);

  const handleLinkChange = async () => {
    dispatch(
      changeFilter({
        table: filter.table,
        filteredBy: filter.filteredBy,
        rightSideSelectedProduct: "",
      })
    );
  };

  const handleTabChange = (id: string) => {
    setActiveTab(id);
  };

  const openFormModal = () => {
    setShowModal(true);
  };

  return (
    <>
      <AddNewRaccourciList
        ShowModal={showModal}
        setShowModal={setShowModal}
        currentOrganisation={currentOrganisation}
      />
      <div className="row" id="cancel-row" style={{ height: "100%" }}>
        <div className="col-lg-12 layout-spacing layout-top-spacing">
          <div
            className="statbox widget box box-shadow"
            style={{ padding: "0px", height: "100%" }}
          >
            <div className="widget-header">
              <div className="raccourci-tabs">
                {_.map(ProductsList, (array: any, libelle: any) => (
                  <React.Fragment key={libelle}>
                    <input
                      type="radio"
                      name="tabs"
                      id={libelle}
                      checked={activeTab === libelle}
                      onChange={() => handleTabChange(libelle)}
                    />
                    <label
                      htmlFor={libelle}
                      onClick={(e) => handleLinkChange()}
                    >
                      {libelle}
                    </label>
                  </React.Fragment>
                ))}
                {raccourcisLimit < 100 ? (
                  <React.Fragment>
                    <input type="radio" name="tabs" />
                    <label
                      className="add-raccourci-btn"
                      onClick={(e) => openFormModal()}
                      style={{ position: "relative" }}
                    >
                      <i className="fas fa-plus"></i>
                    </label>
                  </React.Fragment>
                ) : null}
                <button
                  onClick={() => setIndex(index + (6 - raccourcisLimit))}
                  className=""
                  style={{
                    border: "none",
                    borderRadius: " 5px",
                    background: "#009688",
                    color: "white",
                    padding: "4px 10px",
                  }}
                >
                  {" "}
                  <i className="fas fa-chevron-left"></i>{" "}
                </button>{" "}
                {sousCategories &&
                  sousCategories.map((item: any, index: number) => (
                    <React.Fragment key={item._id}>
                      <input
                        type="radio"
                        name="tabs"
                        id={item._id}
                        checked={activeTab === item._id}
                        onChange={() => handleTabChange(item._id)}
                      />
                      <label
                        htmlFor={item._id}
                        style={{
                          fontSize: "14px",
                        }}
                        onClick={(e) => handleLinkChange()}
                      >
                        {item.libelle.fr}
                      </label>
                    </React.Fragment>
                  ))}
                <button
                  onClick={() => setIndex(index - (6 - raccourcisLimit))}
                  className=""
                  style={{
                    position: "absolute",
                    right: "8px",
                    border: "none",
                    borderRadius: " 5px",
                    background: "#009688",
                    color: "white",
                    padding: "4px 10px",
                  }}
                >
                  <i className="fas fa-chevron-right"></i>
                </button>
              </div>
              <div className="raccourci-content">
                {sousCategories &&
                  sousCategories.map((item: any) => (
                    <React.Fragment key={item._id}>
                      {/* <button
                        onClick={(e) => console.log(item._id)}
                      >
                        hello
                      </button> */}
                      <Tab
                        id={item._id}
                        articles={articlesBySousCategorie[item._id]}
                        display={display}
                        activeTab={activeTab}
                        showModal={showModal}
                      />
                    </React.Fragment>
                  ))}
                {_.map(ProductsList, (array: any, libelle: any) => (
                  <React.Fragment key={libelle}>
                    <Tab
                      id={libelle}
                      articles={array}
                      display={display}
                      activeTab={activeTab}
                      showModal={showModal}
                    />
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RaccourciProductList;
