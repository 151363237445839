import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ArticleStockGlobale,
  findFreresStocksByArticleIdApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { Modal } from "react-bootstrap";

const StockGlobale = ({
  article,
  setSelectedArticles,
  ShowModal,
  setShowModal,
}: any) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (article) dispatch(findFreresStocksByArticleIdApi(article._id));
  }, [dispatch, article]);
  const stockGlobale = useAppSelector(ArticleStockGlobale);
  return (
    <div>
      <Modal
        dialogClassName="modal-50w"
        show={ShowModal}
        onHide={() => {
          setSelectedArticles(null);
          setShowModal(false);
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <span
              className="modal-title"
              id="addCategorieLabel"
              onClick={() => console.log(article)}
            >
              {" "}
              Stock globale de {article?.titre.fr}
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className="admin-data-content layout-top-spacing">
            <div className="widget-content searchable-container list">
              <div className="searchable-items list">
                <div className="items items-header-section item-content">
                  <div className="user-profile col-5">
                    <h4 className="ml-0">Article</h4>
                  </div>
                  <div className="user-profile col-3">
                    <h4 className="ml-0"> Depot</h4>
                  </div>
                  <div className="user-profile col-1">
                    <h4 className="ml-0">Qte</h4>
                  </div>
                  <div className="user-profile col-3">
                    <h4 className="ml-0">Organisation</h4>
                  </div>
                </div>
                {stockGlobale &&
                  stockGlobale.map((item, index) => (
                    <div className="items item-content">
                      <div className="user-profile col-5 p-0">
                        <img
                        className="m-0"
                          src={
                            item.article_id.image && item.article_id.image[0]
                              ? process.env.REACT_APP_API_PUBLIC_URL +
                                "article/" +
                                item.article_id.image[0]
                              : "/assets/assets/img/no-image.jpg"
                          }
                          alt="avatar"
                        />
                        <div className="user-meta-info">
                          <p className="user-name" data-name="Alan Green">
                            {item.article_id.titre.fr}{" "}
                          </p>
                          <p
                            className="user-work"
                            data-occupation="Web Developer"
                          >
                            {item.article_id.caracteristiques
                              .map(
                                (caracteristique: any) =>
                                  caracteristique.value + " "
                              )
                              .join("")}
                          </p>
                        </div>
                      </div>
                      <div className="user-profile col-3">
                        <p className="usr-location" data-location="Boston, USA">
                          {item.depots_id.libelle}{" "}
                        </p>
                      </div>
                      <div className="user-profile col-1">
                        <p
                          className="usr-email-addr"
                          data-email="alan@mail.com"
                        >
                          {item.stock}
                        </p>
                      </div>
                      <div className="user-profile col-3">
                        <p
                          className="usr-email-addr"
                          data-email="alan@mail.com"
                        >
                          {item.organisation_id?.nom}
                        </p>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </div>
  );
};

export default StockGlobale;
