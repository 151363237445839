import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";

import RaccourciProductList from "../../Components/Caisses/ProductsRaccourcis/RaccourciProductList";

import {
  ListCartes,
  findCartesApi,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import CaisseCartesList from "../../Components/Caisses/Cartes/CaisseCartesList";
import {
  ListCategories,
  ListCategoriesByCarte,
  findCategoriesApi,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import {
  ListSousCategories,
  ListSousCategoriesByCategorie,
  findSousCategoriesApi,
} from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import {
  Display,
  Filter,
  changeDisplay,
  layout,
  selectedTable,
} from "../../_App/Redux/Slices/Caisse/CaisseSlice";

import { findProductByOrganisationApi } from "../../_App/Redux/Slices/produits/produitSlice";
import CaisseCategoriesList from "../../Components/Caisses/Categories/CaisseCategoriesList";
import CaisseSousCategoriesList from "../../Components/Caisses/SousCategories/CaisseSousCategoriesList";
import { findProductsDetailApi } from "../../_App/Redux/Slices/product_detail/product_detailSlice";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import {
  ListRaccourci,
  findProductRaccourciByOrganisationApi,
} from "../../_App/Redux/Slices/product_raccourci/ProductRaccourciSlice";
import NavbarCaisse from "../../_Layouts/Navbar/NavbarCaisse";
import {
  SelectedOrganisationId,
  selectJournalCaisse,
  selectedSessionJournal,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { ActiveMenu } from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import {
  ListArticles,
  findArticlebysocieteApi,
} from "../../_App/Redux/Slices/article/articleSlice";
import CaisseArticlesList from "../../Components/Caisses/Articles/CaisseArticlesList";

import ClotureCaisse from "../../Components/Caisses/mouvementCaisse/ApportRetraitCaisse";
import { useNavigate, useParams } from "react-router-dom";
import { findDepotsDefautByOrganisationApi } from "../../_App/Redux/Slices/depot/depotSlice";
import Loader from "../../_Layouts/Loader/Loader";
import { ArticleType } from "../../_App/Types/Entites/ArticleType";
import { SousCategorieType } from "../../_App/Types/Entites/SousCategorieType";
import { SousCategorie } from "../../_App/Types/Entites/SousCategorie";
import { findParametragesCaisseByOrganisationApi } from "../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import RightBarPanier from "../../_Layouts/RightBar/RightBar/RightbarPanier";
import PaymentDrawer from "../../_Layouts/RightBar/PaymentDrawer/PaymentDrawer";

// Dispatching the action

const BarcodeOnlyCaissePage = () => {
  return <>
  </>;
};

export default BarcodeOnlyCaissePage;
