import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListJournalMouvementsCaisse,
  findMouvementsCaisseByJournalApi,
  listMouvementsCaisseCommandeBySession,
  listMouvementsCaisseParSession,
} from "../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useParams } from "react-router-dom";
import {
  Journal,
  getJournalCaisseByIdApi,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { formadate } from "../../_App/Helpers/helpers";
import NavbarCaisse from "../../_Layouts/Navbar/NavbarCaisse";
import NavbarDefault from "../../_Layouts/Navbar/NavbarDefault";
import Navbar from "../../_Layouts/Navbar/Navbar";
import GenerationFactureModal from "../../Components/Caisses/JournalCaisse/GenerationFactureModal";
import { MouvementCaisseType } from "../../_App/Types/Entites/MouvementCaisseType";
import { useTranslation } from "react-i18next";

const MouvementsJournal = () => {
  let { id } = useParams<{ id?: string }>(); // Explicitly define the type

  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const journal = useAppSelector(Journal);
  const { t } = useTranslation();

  const sessionXorderMouvements = useAppSelector(
    listMouvementsCaisseCommandeBySession
  );
  const mouvementsCaisseParJournal = useAppSelector(
    ListJournalMouvementsCaisse
  );
  const sessionMouvements = useAppSelector(listMouvementsCaisseParSession);

  useEffect(() => {
    if (currentOrganisation && id) {
      dispatch(findMouvementsCaisseByJournalApi(id));
      dispatch(getJournalCaisseByIdApi(id));
    }
  }, [dispatch]);

  const CalculatefondDeCaisse = (
    mouvements: MouvementCaisseType[],
    fondInitial: any
  ) => {
    mouvements &&
      mouvements.forEach((operation) => {
        operation.type_mouvement_id.sens === "c"
          ? (fondInitial += operation.montant)
          : (fondInitial -= operation.montant);
      });

    return fondInitial;
  };
  const [showModalFacture, setShowModalFacture] = useState(false);

  return (
    <>
      <Navbar />

      <div className="d-flex" style={{ width: "100%", margin: "15px 0" }}>
        <div
          className="page-header row"
          style={{ textAlign: "center", width: "100%" }}
        >
          <div className="page-title col-4">
            <h3> {"Journal  " + journal?.code_journal}</h3>
          </div>
          <div className="page-title col-4">
            <h3> {t("Date") + journal?.date}</h3>
          </div>
          <div className="page-title col-4">
            <h3>{"Fond initial " + journal?.font_initial}</h3>
          </div>
        </div>
      </div>

      <div className="row layout-spacing">
        <div className="col-lg-12">
          <div className="statbox widget box box-shadow">
            <div
              className="widget-content widget-content-area"
              style={{ overflowX: "auto" }}
            >
              <table id="style-2" className="table style-2  table-hover">
                <thead>
                  <tr>
                    <th
                      style={{ fontSize: "15px", width: "10%" }}
                      onClick={() => console.log(sessionXorderMouvements)}
                    >
                      {t("Code opération")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Date")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Type")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Motif")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Commande")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Caissier")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("amount")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Encaissement")}
                    </th>
                    <th style={{ fontSize: "15px", width: "10%" }}>
                      {t("Methode de paiement")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(sessionXorderMouvements).map(
                    ([key, value]) => {
                      let session: any;

                      Object.values(value).forEach((item) => {
                        session = item[0].session_id;
                      });
                      return (
                        <React.Fragment key={key}>
                          {/* <tr>
                            <td
                              className="checkbox-column"
                              colSpan={9}
                              onClick={() => {
                                console.log(session);
                              }}
                            >
                              Session {key}
                            </td>
                          </tr> */}
                          {/* Iterate over the values of the inner object */}
                          {Object.entries(value).map(
                            ([innerKey, innerValue]) => (
                              <React.Fragment key={innerKey}>
                                <tr>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].operation_code}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {formadate(innerValue[0].date)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].type_mouvement_id.libelle}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].commentaire
                                      ? innerValue[0].commentaire
                                      : "_"}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].order_id?.order_number}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].user_id?.nom}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {innerValue[0].order_id?.total.toFixed(3)}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {/* Add other <td> elements for additional properties */}
                                  </td>
                                  <td
                                    style={{
                                      fontSize: "15px",
                                      background: "#c7e6e4",
                                    }}
                                  >
                                    {/* Add other <td> elements for additional properties */}
                                  </td>
                                </tr>
                                {innerValue.map((item: any, index: any) => (
                                  <tr key={item._id}>
                                    <td style={{ fontSize: "15px" }}></td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>

                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}> </td>
                                    <td style={{ fontSize: "15px" }}>
                                      {item.montant}{" "}
                                    </td>
                                    <td style={{ fontSize: "15px" }}>
                                      {item.payment_method}{" "}
                                    </td>
                                  </tr>
                                ))}
                              </React.Fragment>
                            )
                          )}
                          <tr>
                            <td
                              className="checkbox-column"
                              colSpan={1}
                              style={{ fontSize: "15px" }}
                            >
                              {t("Fin de session")}
                            </td>
                            <td
                              className="checkbox-column"
                              colSpan={2}
                              style={{ fontSize: "15px" }}
                            >
                              {t("Recette:")}{" "}
                              {CalculatefondDeCaisse(
                                sessionMouvements[session._id],
                                session.fond_initial
                              )}
                            </td>
                            <td
                              className="checkbox-column"
                              colSpan={3}
                              style={{ fontSize: "15px" }}
                            >
                              {t("Fond de cloture :")} {session.fond_final}
                            </td>
                            {session &&
                            session.cloture &&
                            CalculatefondDeCaisse(
                              sessionMouvements[session._id],
                              session.fond_initial
                            ) !== undefined &&
                            session.fond_final !== undefined ? (
                              <td
                                className="checkbox-column"
                                colSpan={2}
                                style={{ fontSize: "20px", color: "red" }}
                              >
                                {t("Ecart de")}{" "}
                                {Math.abs(
                                  CalculatefondDeCaisse(
                                    sessionMouvements[session._id],
                                    session.fond_initial
                                  ) - session.fond_final
                                )}
                              </td>
                            ) : null}
                          </tr>
                        </React.Fragment>
                      );
                    }
                  )}
                  <tr>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px", color: "red" }}
                    >
                      {t("Recette:")}{" "}
                      {CalculatefondDeCaisse(
                        mouvementsCaisseParJournal,
                        journal?.font_initial
                      )}
                    </td>
                    <td
                      className="checkbox-column"
                      style={{ fontSize: "20px", color: "red" }}
                      colSpan={2}
                    >
                      {journal?.cloture
                        ? "fond de caisse de cloture:" + journal?.font_final
                        : null}
                    </td>

                    {journal &&
                    journal.cloture &&
                    CalculatefondDeCaisse(
                      mouvementsCaisseParJournal,
                      journal?.font_initial
                    ) !== undefined &&
                    journal.font_final !== undefined ? (
                      <td
                        className="checkbox-column"
                        colSpan={7}
                        style={{ fontSize: "20px", color: "red" }}
                      >
                        {t("Ecart de")}{" "}
                        {Math.abs(
                          CalculatefondDeCaisse(
                            mouvementsCaisseParJournal,
                            journal?.font_initial
                          ) - journal.font_final
                        )}
                      </td>
                    ) : null}
                  </tr>
                </tbody>
              </table>
              <div className="row" style={{ justifyContent: "center" }}>
                <button
                  className="btn btn-success mb-22"
                  style={{
                    width: "80%",
                    background: "#009688",
                  }}
                  onClick={(e) => (window.location.href = "/facture/" + id)}
                >
                  {t("Generer une facture")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MouvementsJournal;
