import React, { useEffect, useState } from 'react'
import { Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import ReceptionTable from '../../Components/Reception/ReceptionTable';
import { societeId } from '../../_App/Redux/Slices/Auth/authSlice';
import { useAppSelector } from '../../_App/Redux/hooks';
import ReceptionForm from '../../Components/Reception/ReceptionForm';

export default function ReceptionPage() {
    const { t } = useTranslation();
    const societe_id = useAppSelector(societeId)
    const [showModalReception ,setshowReception]= useState(false)
    const [reception ,setreception]= useState(null)
    useEffect(() => {
      if (!showModalReception) {
    
        setreception(null)
      }
    }, [showModalReception]);
  return (
    <div>
    <Navbar />
    <div className="d-flex justify-content-end mb-3">
      <button
        onClick={() => {
          setshowReception(true);
        }}
        className="btn btn-primary-app"
      >
        {t("Ajouter Reception")}
      </button>{" "}
    </div>
   <ReceptionForm 
   ShowModal={showModalReception}
   setShowModal={setshowReception}
   reception={reception}
  
  />
  <ReceptionTable  
    t={t} 
    societeId={societe_id}
    setshowReception={setshowReception}

    setreception={setreception}
   
     />
  </div>
  )
}
