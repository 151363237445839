import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Listreceptions_articles,
  coloturerReceptionbysocieteApi,
  confirmerReceptionbysocieteApi,
  findReceptionbysocieteApi,
} from "../../_App/Redux/Slices/receptionArticles/receptionArticlesSlice";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ReceptionArticlesType } from "../../_App/Types/Entites/ReceptionArticles/ReceptionArticlesType";

export default function ReceptionTable({
  t,
  societeId,
  setshowReception,
  setreception,
}: any) {
  const dispatch = useAppDispatch();
  const listReception = useAppSelector(Listreceptions_articles);
  useEffect(() => {
    if (societeId) {
      dispatch(findReceptionbysocieteApi(societeId));
    }
  }, [dispatch, societeId]);

  const confirmer =async (id: string) => {
    const data ={reception_id:id}
    const response = await dispatch(confirmerReceptionbysocieteApi(data))
    if (response?.payload.success) {
      ToastSuccess(response?.payload.message);
      window.location.reload()
      
    } else {
      ToastWarning(response?.payload.message);
    }
  };
 
  return (
    <div className="col-lg-12  mt-5   bg-white">
      <div className="inv--product-table-section ">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th className="text-center">{t("Action")}</th>
                <th className="text-center">{t("etat")}</th>
                <th> {t("Code Reception")}</th>
               
                <th>{t("Organisation")}</th>
                <th>{t("Depots")}</th>
              
              </tr>
            </thead>
            <tbody>
              {listReception.length !== 0 &&
                listReception.map((item: any, index: number) => (
                  <tr key={item._id}>
                    {!item.confirmer ? (
                      <td    className="c-primary pointer" onClick={() => confirmer(item._id)}>confirmer</td>
                    ) : !item.cloturer ? (
                      <td   className="c-primary pointer" >cloturer</td>
                    ) : (
                      <td></td>
                    )}

                    {!item.cloturer ? (
                      !item.confirmer ? (
                        <td >en cours</td>
                      ) : (
                        <td className="color-red">confirmé</td>
                      )
                    ) : (
                      <td className="color-red">cloturé</td>
                    )}
                    <td
                      className="lien-bleu-souligne"
                      onClick={() => {
                        setreception(item);
                        setshowReception(true);
                      }}
                    >
                      {item.code_reception}
                    </td>
                    
                    <td>{item.organisation_id?.nom}</td>
                    <td>{item.depot_id?.libelle}</td>
               
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
