// PanierSlice.js
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import store, { RootState } from "../../store";
import { OrderType } from "../../../Types/Entites/Orders/OrderType";
import axios from "axios";
import _ from "lodash";
import storage from "redux-persist/lib/storage";
import persistReducer from "redux-persist/es/persistReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";
import ReservationSlice from "../Reservation/ReservationSlice";
import { stat } from "fs";
import { updateUserLocally } from "../tickets/TicketSlice";

interface UpdateOrderParams {
  orderId?: string;
  status: string | null;
  paidProducts?: any;
  vendeur: String | null;
}

/**
 * create an order.
 * @public
 */

export const saveOrderApi = createAsyncThunk(
  "orders/saveOrderApi",
  async (data: OrderType, { rejectWithValue }) => {
    try {
      const response = await axios.post("orders/saveOrderApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * list  of orders by organisation.
 * @public
 */
export const findOrdersByOrganisation = createAsyncThunk(
  "orders/findOrdersByOrganisation",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findOrdersByOrganisation/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findOrdersByTableApi = createAsyncThunk(
  "orders/findOrdersByTableApi",
  async (idTable: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findOrdersByTableApi/${idTable}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findOrdersByJournalApi = createAsyncThunk(
  "orders/findOrdersByJournalApi",
  async (idJournal: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `orders/findOrdersByJournalApi/${idJournal}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getOrderByIdApi = createAsyncThunk(
  "orders/getOrderByIdApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`orders/getOrderByIdApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateOrderApi = createAsyncThunk(
  "orders/updateOrderApi",
  async (params: UpdateOrderParams, { rejectWithValue }) => {
    try {
      const { orderId, status, paidProducts, vendeur } = params;
      var response = await axios.put("orders/updateOrderAPi", {
        orderId,
        status,
        paidProducts,
        vendeur,
      });
      response.data.data.status = status;
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const findReservationsByOrganisationApi = createAsyncThunk(
  "reservation/findReservationsByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `reservation/findReservationsByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const PayerReservationApi = createAsyncThunk(
  "reservation/PayerReservationApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `reservation/PayerReservationApi`,data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface OrdersState {
  listOrders: OrderType[];
  listReservations: any[];

  listJournalOrders: OrderType[];
  listOrdersByID: {
    [Id: string]: OrderType[];
  };
  order: OrderType | null;
  selectedOrder: OrderType | null;
  listOrdersByTables: OrderType[];
  listOrdersByClients: { [client: string]: OrderType[] };
  
}

const initialState: OrdersState = {

  listReservations:[],
  listOrders: [],
  listJournalOrders: [],
  listOrdersByID: {},
  order: null,
  selectedOrder: null,
  listOrdersByTables: [],
  listOrdersByClients: {},
};

export const OrdersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    selectOrder: (
      state,
      action: PayloadAction<{
        order_id: string;
      }>
    ) => {
      const { order_id } = action.payload;
      const order = state.listOrders.find((item) => item._id === order_id);

      if (!order) {
        const reservation = state.listReservations.find((item)=>item.order._id===order_id)
        console.log('reservationreservation', reservation.order)

        if(!reservation){
          return state;

        }else{
          console.log('reservationreservation', reservation.order)
          return {
            ...state,
            selectedOrder: reservation.order,
          };
        }

      }
      return {
        ...state,
        selectedOrder: order,
      };
    },
    updateSelectedOrderStatus: (
      state,
      action: PayloadAction<{
        status: string;
      }>
    ) => {
      const { status } = action.payload;
      if (state.selectedOrder) state.selectedOrder.status = status;
      return {
        ...state,
        selectedOrder: state.selectedOrder,
      };
    },
    resetSelectedOrder: (state) => {
      return {
        ...state,
        selectedOrder: initialState.selectedOrder,
      };
    },
    resetRetrievedOrder: (state) => {
      return {
        ...state,
        order: initialState.order,
      };
    },
  },
  extraReducers: (builder) => {
 
    builder.addCase(saveOrderApi.fulfilled, (state, action) => {
      state.listOrders.splice(0, 0, action.payload.data);
    });
    builder.addCase(findReservationsByOrganisationApi.fulfilled, (state, action) => {
   

      return {
        ...state,
        listReservations: action.payload.reservation,
        
      };
    });
    builder.addCase(findOrdersByOrganisation.fulfilled, (state, action) => {
      const groupedByID = _.groupBy(action.payload, "_id");
      const groupedByTable = _.groupBy(action.payload, "table_id");
      const groupedByClient = _.groupBy(action.payload, "client_id._id");

      return {
        ...state,
        listOrders: action.payload,
        listOrdersByID: groupedByID,
        listOrdersGroupedByTables: groupedByTable,
        listOrdersByClients: groupedByClient,
      };
    });
    builder.addCase(findOrdersByJournalApi.fulfilled, (state, action) => {
      return {
        ...state,
        listJournalOrders: action.payload,
      };
    });
    builder.addCase(findOrdersByTableApi.fulfilled, (state, action) => {
      return {
        ...state,
        listOrdersByTables: action.payload,
      };
    });
    builder.addCase(updateOrderApi.fulfilled, (state, action) => {
      const index = _.findIndex(state.listOrders, {
        _id: action.payload.data._id,
      });
      if (index !== -1) {
        const updatedListOrders = [...state.listOrders];
        updatedListOrders[index] = {
          ...updatedListOrders[index],
          status: action.payload.data.status,
        };
        return {
          ...state,
          listOrders: updatedListOrders,
        };
      }

      // Return original state if index is not found
      return state;
    });

    builder.addCase(getOrderByIdApi.fulfilled, (state, action) => {
      return {
        ...state,
        order: action.payload,
      };
    });
  },
});

// Persist configuration
const persistConfig = {
  key: "orders",
  storage: storage,
  whitelist: ["selectedOrder"], // Only persist selectedOrder
};

// Wrap the ordersSlice reducer with persistReducer
const persistedOrdersReducer = persistReducer(
  persistConfig,
  OrdersSlice.reducer
);
export const {
  selectOrder,
  updateSelectedOrderStatus,
  resetSelectedOrder,
  resetRetrievedOrder,
} = OrdersSlice.actions;

export const ListOrders = (state: RootState) => state.orders.listOrders;
export const ListJournalOrders = (state: RootState) =>
  state.orders.listJournalOrders;
export const ListOrdersById = (state: RootState) => state.orders.listOrdersByID;
export const order = (state: RootState) => state.orders.order;

export const selectedOrder = (state: RootState) => state.orders.selectedOrder;
export const ListOrdersByTables = (state: RootState) =>
  state.orders.listOrdersByTables;
export const ListOrdersByClients = (state: RootState) =>
  state.orders.listOrdersByClients;
export default OrdersSlice.reducer;


export const ListReservations = (state: RootState) => state.reservation.listReservations;
