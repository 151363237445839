import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  SelectedOrganisationId,
  selectCaisse,
  selectUser,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  createJournalCaisseApi,
  getPrecedentJournalApi,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { JournalCaisseDataType } from "../../_App/Types/Forms/JournalCaisseDataType";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { createSessionJournalApi } from "../../_App/Redux/Slices/sessionJournal/sessionJournalSlice";
import { selectedSessionJournal } from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const CustomCheckbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    border: 2px solid #007bff;
  }
`;

const CheckboxLabel = styled.label`
  font-size: 16px;
  color: black;
`;

const StyledLabel = styled.label`
  margin-bottom: 16px;
`;

const StyledInput = styled.input`
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 16px;
  width: 200px;
  display: inline-block;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 4px;
`;

const Asterisk = styled.span`
  color: red;
  margin-right: 4px;
`;
const ConfirmationModal = styled(Modal)`
  .modal-dialog {
    max-width: 45%;
    margin: 1.75rem auto;
  }
`;
const JournalCaisseEntryForm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const caisse = useAppSelector(selectCaisse);
  const user = useAppSelector(selectUser);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [fontInitial, setFontInitial] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(true);
  const [initialFontValue, setInitialFontValue] = useState<string | undefined>(
    undefined
  );
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [codeJournal, setCodeJournal] = useState<string>("");
  const [showOpeningTicket, setShowOpeningTicket] = useState(false);
  const [Code_jrnl, setCode_jrnl] = useState<string>("");

  const sessionJournal = useAppSelector(selectedSessionJournal);
  const { t } = useTranslation();

  const [showSessionOpeningTicket, setShowSessionOpeningTicket] =
    useState(false);
  const handleSessionOpeningTicketCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowSessionOpeningTicket(e.target.checked);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(
          getPrecedentJournalApi(caisse?._id || "")
        );

        if (response.payload) {
          const initialFontValue =
            response.payload.font_final?.toString() || "";
          const initialCodeJournal =
            response.payload.code_journal?.toString() || "";

          setFontInitial(initialFontValue);
          setInitialFontValue(initialFontValue);
          setCodeJournal(initialCodeJournal);
        } else {
          ToastWarning("Erreur inconnue");
        }
      } catch (error) {
        console.error(error);
        ToastWarning("Erreur de traitement des données!!");
      }
    };

    fetchData();
  }, [dispatch, caisse]);

  const handleSave = async () => {
    if (caisse && user) {
      const newJournalEntry: JournalCaisseDataType = {
        id_caisse: caisse._id,
        libelle_caisse: caisse.libelle,
        code_a_barre_caisse: caisse.code_a_barre,
        user_id: user._id,
        font_initial: parseFloat(fontInitial),
        fond_courant: Number(fontInitial),
        date: moment().toISOString().split("T")[0],
        code_journal: generateCodeJournal(),
        cloture: false,
        organisation_id: currentOrganisation,
      };

      try {
        const response = await dispatch(
          createJournalCaisseApi(newJournalEntry)
        );

        if (response.payload && response.payload.success) {
          const journalId = response.payload.data._id;
          const journalFontInitial = response.payload.data.font_initial;
          const Code_jrnl = response.payload.data.code_journal;
          setCode_jrnl(Code_jrnl);

          ToastSuccess(response.payload.message);

          if (showOpeningTicket) {
            printOpeningTicket(response.payload.data);
          }
          const sessionJournalData = {
            journal_id: journalId,
            user_id: response.payload.data.user_id,
            fond_initial: parseFloat(journalFontInitial),
            cloture: false,
          };

          const sessionResponse = await dispatch(
            createSessionJournalApi(sessionJournalData)
          );

          if (sessionResponse.payload && sessionResponse.payload.success) {
            ToastSuccess("Session Journal ajouté avec succès");
            if (showSessionOpeningTicket) {
              printSessionOpeningTicket(
                sessionResponse.payload.data,
                Code_jrnl
              );
            }
          } else {
            ToastWarning(
              sessionResponse.payload
                ? sessionResponse.payload.message
                : "Erreur inconnue lors de la création de la session"
            );
          }

          navigate("/caisse");
          onClose();
        } else {
          ToastWarning(
            response.payload ? response.payload.message : "Erreur inconnue"
          );
        }
      } catch (error) {
        console.error(error);
        ToastWarning("Erreur de traitement des données!!");
      }
    }
  };

  const handleFontInitialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    value = value.replace(/[^0-9.]/g, "");

    if (!/^\d+(\.\d*)?$/.test(value)) {
      setErrorMessage("Veuillez saisir un montant valide");
    } else {
      setErrorMessage("");
    }

    setFontInitial(value);
  };

  const generateCodeJournal = () => {
    const currentCode = parseInt(codeJournal.substring(2), 10) || 0;
    const nextCode = currentCode + 1;
    const formattedNextCode = String(nextCode).padStart(6, "0");
    return `CJ${formattedNextCode}`;
  };

  const printOpeningTicket = (data: any) => {
    const formattedDateCreate = new Date(data.createdAt).toLocaleString();

    const printWindow = window.open("", "_blank");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Ticket d'ouverture de caisse</title>
            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color:#000;
                text-align: center;
              }
              h3 {
                font-size: 11px;
                color: #000;
                margin-bottom: 10px;
                text-align: center;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
              
            </style>
          </head>
          <body>
            <h2>Ouverture caisse </h2>
            <h3>${formattedDateCreate}</h3>
            <hr />
  
            <p>Organisation: ${user?.selected_organisation?.nom}</p>
            <p>Caisse: ${data.libelle_caisse}</p>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Fond initial: ${data.font_initial}</p>
            <p>Pièce N°: ${data.code_journal}</p>
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };

  const printSessionOpeningTicket = (data: any, Code_jrnl: any) => {
    const formattedDateCreate = new Date(data.createdAt).toLocaleString();

    const printWindow = window.open("", "_blank");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Ticket d'ouverture de session</title>
            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color: #000;
                text-align: center;
              }
              h3 {
                font-size: 11px;
                color: #000;
                margin-bottom: 10px;
                text-align: center;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
            </style>
          </head>
          <body>
            <h2>Ouverture de session </h2>
            <h3>${formattedDateCreate}</h3>
            <hr />

            <p>Organisation: ${user?.selected_organisation?.nom}</p>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Code Journal : ${Code_jrnl}</p>

            <p>Fond initial: ${data.fond_initial}</p>
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };
  const handleOpeningTicketCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowOpeningTicket(e.target.checked);
  };

  return (
    <Modal show={showModal} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Ouvrir journal caisse")}</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        <Modal.Body>
          <StyledLabel>
            {t("Fond initial")} <Asterisk>*</Asterisk>
            <StyledInput
              type="number"
              value={fontInitial}
              onChange={handleFontInitialChange}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </StyledLabel>
          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="printOpeningTicketCheckbox"
              checked={showOpeningTicket}
              onChange={handleOpeningTicketCheckboxChange}
            />
            <CheckboxLabel htmlFor="printOpeningTicketCheckbox">
              {t("Imprimer le ticket d'ouverture de caisse")}
            </CheckboxLabel>
          </CheckboxContainer>
          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="printSessionOpeningTicketCheckbox"
              checked={showSessionOpeningTicket}
              onChange={handleSessionOpeningTicketCheckboxChange}
            />
            <CheckboxLabel htmlFor="printSessionOpeningTicketCheckbox">
              {t("Imprimer le ticket d'ouverture de session caisse")}
            </CheckboxLabel>
          </CheckboxContainer>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            className="btn btn-primary-app"
            onClick={() => {
              if (fontInitial !== initialFontValue) {
                setIsConfirmationOpen(true);
              } else {
                handleSave();
              }
            }}
            disabled={!fontInitial.trim() || errorMessage !== ""}
          >
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>

      <ConfirmationModal
        show={isConfirmationOpen}
        onHide={() => setIsConfirmationOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title> {t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Êtes-vous sûr que votre fond initial est :")} {fontInitial} ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={() => setIsConfirmationOpen(false)}
          >
            {t("Annuler")}{" "}
          </Button>
          <Button className="btn btn-primary-app" onClick={handleSave}>
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </ConfirmationModal>
    </Modal>
  );
};
export default JournalCaisseEntryForm;
