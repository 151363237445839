import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListCartes,
  deleteCarteApi,
  findCartesApi,
} from "../../_App/Redux/Slices/cartes/carteSlice";
import CarteForm from "../../Components/Cartes/CarteForm";
import CarteList from "../../Components/Cartes/CartesList";
import { SelectedOrganisationId ,societeId } from "../../_App/Redux/Slices/Auth/authSlice";
const CartePage = () => {
  const [showModalCarte, setShowModalCarte] = useState(false);
  const [selectedCarte, setSelectedCarte] = useState(null); // State to hold the selected item
  const dispatch = useAppDispatch();
  const cartes = useAppSelector(ListCartes);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findCartesApi(currentOrganisation));
    }
  }, [dispatch]);

  const openEditForm = (carte: any) => {
    setSelectedCarte(carte);
    setShowModalCarte(true);
  };
  const deleteItem = (id: any) => {
    dispatch(deleteCarteApi(id));
  };
  return (
    <div>
      <div className="row">
        <button
          className="btn btn-primary btn-block mb-4 mr-2"
          onClick={() => setShowModalCarte(true)}
        >
          Ajouter une nouvelle carte
        </button>
      </div>
      <CarteForm
        ShowModal={showModalCarte}
        setShowModal={setShowModalCarte}
        carte={selectedCarte}
        currentOrganisation={currentOrganisation}
        societe={societe}
      />
      <CarteList
        carteListe={cartes}
        onEditClick={openEditForm}
        deleteItem={deleteItem}
      />
    </div>
  );
};

export default CartePage;
