import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { ProductCaracteristiqueType } from "../../../Types/Entites/ProductCaracteristiqueType";
import _ from "lodash";

/**
 * Get list of ProductCaracteristique.
 * @public
 */
export const findProductsCaracteristiquesApi = createAsyncThunk(
  "productscaracteristiques/findProductsCaracteristiquesApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productscaracteristiques/findProductsCaracteristiquesApi/${id}`
      );
      console.log('response.data',response.data)
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of ProductCaracteristique par societe.
 * @public
 */
export const findProductsCaracteristiquesApiBySociete = createAsyncThunk(
  "productscaracteristiques/findProductsCaracteristiquesApiBySociete",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productscaracteristiques/findProductsCaracteristiquesApiBySociete/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create Products Caracteristiques function
 * @private
 */
export const createProductsCaracteristiquesApi = createAsyncThunk(
  "productscaracteristiques/createProductsCaracteristiquesApi",
  async (data: ProductCaracteristiqueType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "productscaracteristiques/createProductsCaracteristiquesApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Update Products Caracteristiques
 * @private
 */
export const updateProductsCaracteristiquesApi = createAsyncThunk(
  `productscaracteristiques/updateProductsCaracteristiquesApi`,
  async (data: ProductCaracteristiqueType, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        "productscaracteristiques/updateProductsCaracteristiquesApi",
        data
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Delete  Products Caracteristiques.
 * @private
 */
export const deleteProductsCaracteristiquesApi = createAsyncThunk(
  "productscaracteristiques/deleteProductsCaracteristiquesApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `productscaracteristiques/deleteProductsCaracteristiquesApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface ProductCaracteristiqueState {
  listProductCaracteristiques: ProductCaracteristiqueType[];
  listProductCaracteristiquesParOrganisation : { [organisation: string]: ProductCaracteristiqueType[] };
}


const initialState: ProductCaracteristiqueState = {
  listProductCaracteristiques: [],
  listProductCaracteristiquesParOrganisation : {},
};

export const ProductCaracteristiqueSlice = createSlice({
  name: "productcaracteristique",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      findProductsCaracteristiquesApi.fulfilled,
      (state, action) => {
        state.listProductCaracteristiques = action.payload;
      }
    );

    builder.addCase(
      createProductsCaracteristiquesApi.fulfilled,
       (state, action) => {
        state.listProductCaracteristiques.splice(0, 0, action.payload.data);
      }
    );

    builder.addCase(
      updateProductsCaracteristiquesApi.fulfilled,
      (state, action) => {
        const updated_productscaracteristiques = action.payload.data;
        var index = _.findIndex(state.listProductCaracteristiques, {
          _id: updated_productscaracteristiques._id,
        });
        // Replace item at index using native splice
        state.listProductCaracteristiques.splice(index, 1, updated_productscaracteristiques);
      }
    );
    builder.addCase(
      deleteProductsCaracteristiquesApi.fulfilled,
      (state, action) => {
        const deleted_productscaracteristiques = action.payload.data;
        state.listProductCaracteristiques = _.remove(state.listProductCaracteristiques, { _id: deleted_productscaracteristiques._id });
      }
    );
    builder.addCase(findProductsCaracteristiquesApiBySociete.fulfilled, (state, action) => {
      const PCaracteristiquesparorganisationNotNull = action.payload.filter((item:any)=>item.organisation_id !== null)
      const groupedByOrganisation = _.groupBy(PCaracteristiquesparorganisationNotNull, "organisation_id");
    
      state.listProductCaracteristiques = action.payload;  // First update
      state.listProductCaracteristiquesParOrganisation = groupedByOrganisation;  // Second update
    });
  },
});

export const ListProductCaracteristiques = (state: RootState) =>
  state.productcaracteristiques.listProductCaracteristiques;

  export const ListProductCaracteristiquesParOrganisation = (state: RootState) =>
  state.productcaracteristiques.listProductCaracteristiquesParOrganisation;

export default ProductCaracteristiqueSlice.reducer;
