import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { ProductTailleType } from "../../../Types/Entites/ProductTailleType";
import _ from "lodash";

/**
 * create  Product Taille function
 * @private
 */
export const createProductTailleApi = createAsyncThunk(
  "producttailles/createProductTailleApi",
  async (data: ProductTailleType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "producttailles/createProductTailleApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of ProductTaille
 * @public
 */
export const findProductTailleApi = createAsyncThunk(
  "producttailles/findProductTailleApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `producttailles/findProductTailleApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);




/**
 * Update Product Taille
 * @private
 */
export const updateProductTailleApi = createAsyncThunk(
  `producttailles/updateProductTailleApi`,
  async (data: ProductTailleType, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        "producttailles/updateProductTailleApi",
        data
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Delete   Product Taille
 * @private
 */
export const deleteProductTailleApi = createAsyncThunk(
  "producttailles/deleteProductTailleApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `producttailles/deleteProductTailleApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const updateLocalProductTaille= (updateLocalProductTaille: ProductTailleType[]) => (
  dispatch: any
) => {
  dispatch(tailleActions.updateLocalProductTaille(updateLocalProductTaille));
};
export const storeProductTaille= (storeProductTaille: ProductTailleType[]) => (
  dispatch: any
) => {
  dispatch(tailleActions.storeProductTaille(storeProductTaille));
};


interface ProductDetailState {
  listProductsTaille: ProductTailleType[];  
  listSelectedProductsTaille: ProductTailleType[];
  listStoreProductsTaille:ProductTailleType[];
  listProductsTailleParLibelle: { [libelle: string]: ProductTailleType[] };
}

const initialState: ProductDetailState = {
  listProductsTaille: [],
  listSelectedProductsTaille:[],
  listStoreProductsTaille:[],
  listProductsTailleParLibelle: {},
};

export const ProductTailleSlice = createSlice({
  name: "producttaille",
  initialState: initialState,
  reducers: {
    updateLocalProductTaille: (state, action) => void(state.listSelectedProductsTaille = action.payload),
    storeProductTaille: (state, action) => void(state.listStoreProductsTaille = action.payload),

    

  },
  extraReducers: (builder) => {
    builder.addCase(findProductTailleApi.fulfilled, (state, action) => {
      const groupedProductsDetail = _.groupBy(action.payload, "libelle");
      state.listProductsTaille = action.payload;
  
      state.listProductsTailleParLibelle = groupedProductsDetail;
    });
   
  
 
    builder.addCase(createProductTailleApi.fulfilled, (state, action) => {
      state.listProductsTaille.splice(0, 0, action.payload.data);
    });

    builder.addCase(updateProductTailleApi.fulfilled, (state, action) => {
      const updated_producttaille = action.payload.data;
      var index = _.findIndex(state.listProductsTaille, { _id: updated_producttaille._id });
      // Replace item at index using native splice
      state.listProductsTaille.splice(index, 1, updated_producttaille);
    });

    builder.addCase(deleteProductTailleApi.fulfilled, (state, action) => {
      const deleted_producttaille = action.payload.data;
      state.listProductsTaille = _.remove(state.listProductsTaille, { _id: deleted_producttaille._id });
    });
  },
});
export const tailleActions = ProductTailleSlice.actions;

export const ListProductTailles = (state: RootState) => state.productailles.listProductsTaille;
export const ListProductTailleParLibelle = (state: RootState) => state.productailles.listProductsTailleParLibelle;
export const ListSelectedProductTailles = (state: RootState) => state.productailles.listSelectedProductsTaille;
export const ListStoreProductTailles = (state: RootState) => state.productailles.listStoreProductsTaille;

export default ProductTailleSlice.reducer;
 