import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUserId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  Listdemande_transfer_recp,
  annulerTransfertApi,
  approverTransfertApi,
  confirmretourTransfertApi,
  getlesDemandeTransfertApi,
} from "../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import TransfertArticleType from "../../_App/Types/Entites/TransfertArticle/TransfertArticleType";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";

export default function ApproverTransfert({ ShowModal, setShowModal }: any) {
  const { t } = useTranslation();
  const selectedOrganisationId = useAppSelector(SelectedOrganisationId);
  const listdemande_transfer_recp = useAppSelector(Listdemande_transfer_recp);
  const list_organisation = useAppSelector(SelectedOrganisations);
  const [etat, setetat] = useState("encours");
  const [list, setlist] = useState<TransfertArticleType[]>([]);
  const user = useAppSelector(selectUserId);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedOrganisationId)
      dispatch(getlesDemandeTransfertApi(selectedOrganisationId));
  }, [selectedOrganisationId]);
  useEffect(() => {
    if (etat === "apprové") {
      var newlist = listdemande_transfer_recp.filter(
        (item) => item.accepter_transfer.etat
      );
      setlist(newlist);
    } else if (etat === "nonapprové") {
      var newlist = listdemande_transfer_recp.filter(
        (item) => !item.accepter_transfer.etat && item.accepter_transfer.user_id
      );
      setlist(newlist);
    } else if (etat === "encours") {
      var newlist = listdemande_transfer_recp.filter(
        (item) =>
          !item.accepter_transfer.etat &&
          item.accepter_transfer.user_id === undefined
      );

      setlist(newlist);
    } else if (etat === "retour") {
      var newlist = listdemande_transfer_recp.filter(
        (item) =>
          !item.approver_reception.etat && item.approver_reception.user_id
      );
      setlist(newlist);
    }
  }, [listdemande_transfer_recp, etat]);
  const approver = async (idTransfert: string) => {
    const data = { _id: idTransfert, user: user };
    const resp = await dispatch(approverTransfertApi(data));
    if (resp.payload._id) {
      ToastSuccess("la demande est apprové");
    }
  };
  const annuler = async (idTransfert: string) => {
    const data = { _id: idTransfert, user: user };
    const resp = await dispatch(annulerTransfertApi(data));
    if (resp.payload._id) {
      ToastSuccess("la demande  annulé");
    }
  };
  const confirmretour = async (idTransfert: string) => {
    const data = { _id: idTransfert, user: user };
    const resp = await dispatch(confirmretourTransfertApi(data));
    if (resp.payload._id) {
      ToastSuccess("le retour  confirmé");
    }
  };
  return (
    <div>
      {/*<Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-2"
        dialogClassName="modal-40w"
        backdropClassName="modal-backdrop-2"
      >*/}

      <div className="row layout-spacing">
        <div className="col-lg-12    bg-white">
          <div className="inv--product-table-section ">
            <div className="row mt-5">
              <div className="col-6">
                <h3 className="c-primary"> {t("List des demandes de Transfert")} </h3>
              </div>
              <div className="col-1">
                <span>Etat :</span>
              </div>
              <div className=" col-4">
                <select
                  className="form-control"
                  onChange={(e) => setetat(e.target.value)}
                >
                  <option value="encours">{t("encours")}</option>
                  <option value="apprové">{t("approuvé")}</option>
                  <option value="retour">{t("retour")}</option>
                  <option value="nonapprové">{t("non approuvé")}</option>
                </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table mt-5  ">
                <thead>
                  <tr></tr>
                  <tr>
                    <th> {t("Article ")}</th>
                   
                    <th>{t("Depots")}</th>
                    <th>{t("Quantite")}</th>
                    <th>{t("De Organisation")}</th>
                    {etat === "encours" ? (
                      <th colSpan={2}></th>
                    ) : (
                      <th colSpan={2}></th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.map((item: any) => (
                      <tr key={item._id}>
                        <td>{item.article_id?.titre?.fr}</td>
                        <td>{item.depots_id.libelle}</td>
                        <td>{item.Quantite}</td>
                        <td>
                          { item.organisation_demande.nom}
                        </td>
                        {etat === "encours" && (
                          <>
                            <td>
                              <span
                                className=" btn-primary-app  pointer"
                                onClick={() => approver(item._id)}
                              >
                                <i className="fas fa-check-double "></i>
                                {t("confirmer")}
                              </span>
                            </td>
                            <td>
                              <span
                                className=" btn-primary-app pointer"
                                onClick={() => annuler(item._id)}
                              >
                                <i className="fas fa-ban"></i>
                                {t("Annuler")}
                              </span>
                            </td>
                          </>
                        )}
                        {etat === "retour" && (
                          <td>
                            <span
                              className=" btn-primary-app pointer"
                              onClick={() => confirmretour(item._id)}
                            >
                              <i className="fas fa-check-double"></i>
                              {t("Confirmer  retour")}
                            </span>
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/** </Modal>*/}
    </div>
  );
}
