import React, { useEffect, useState } from "react";
import { FournisseurType } from "../../../_App/Types/Entites/FournisseurType";
import PaiementFournisseur from "./PaiementFournisseur";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListPaiementAchat,
  ListPaiementAchatFournisseur,
  findPaiementAchatBySocieteId,
} from "../../../_App/Redux/Slices/achat/paiementachatsSlice";
import {
  ListAchat,
  findAchatsBySocieteApi,
} from "../../../_App/Redux/Slices/achat/achatSlice";
import FournisseurMouvement from "./FournisseurMouvement";
import { Listreceptions_articles, findReceptionbysocieteApi } from "../../../_App/Redux/Slices/receptionArticles/receptionArticlesSlice";

export default function FournisseurTable({
  t,
  listFounisseur,
  tvalist,
  societe,
  deletedfournisseur,
  setfounisseur,
  setShowModalFournisseur,
  listOrganisations
}: any) {
  const dispatch = useAppDispatch();
  const listpaiement_fournisseur = useAppSelector(ListPaiementAchatFournisseur);
  const list_achat = useAppSelector(ListAchat);
  const list_reception = useAppSelector(Listreceptions_articles)
  const [listfiltred, setlistfiltred] = useState<FournisseurType[]>([]);
  const [ShowModalMvtFournisseur, setShowModalMvtFournisseur] = useState(false);
  const [fournisser, setfournisser] = useState<FournisseurType>();
  const [updatedPaiementList, setupdatedPaiementList] = useState<any[]>([]); // Change 'any[]' to the correct type if possible
  const [keyword, setkeyword] = useState("");
  useEffect(() => {
    setlistfiltred(updatedPaiementList);
  }, [updatedPaiementList]);
  useEffect(() => {
    if (societe) {
      dispatch(findPaiementAchatBySocieteId(societe));
      dispatch(findAchatsBySocieteApi(societe));
      dispatch(findReceptionbysocieteApi(societe))
    }
  }, [societe]);

  useEffect(() => {
    const updatedListCopy: any[] = [];

    listFounisseur.forEach((element: any) => {
      // ajouter nom organisation
     
      const list_paiement = listpaiement_fournisseur[element._id];
      // get reception  par fournisseur
     /* const list_reception_fournisseur = list_reception.filter(
        (item) => item.fournisseur._id === element._id
      );*/
      const list_achat_fournisseur = list_achat.filter(
        (item) => item.fournisseur_id === element._id
      );
      const updatedElement = { ...element };
      if(updatedElement.organisation_id){
        updatedElement.nomorganisation = listOrganisations.find((item:any)=> item._id === updatedElement.organisation_id).nom
      }
      const total_MontantTotalachat: number = list_achat_fournisseur.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.total_net_payer),
        0
      );
      if (list_paiement && list_paiement.length !== 0) {
        const total_paye: number = list_paiement.reduce(
          (accumulator, currentValue) =>
            currentValue.sens === "credit"
              ? accumulator + Number(currentValue.Montant_paye)
              : accumulator - Number(currentValue.Montant_paye),
          0
        );
        updatedElement.total_paye = total_paye;
      } else {
        updatedElement.total_paye = 0;
      }
      updatedElement.Montant_total = total_MontantTotalachat;

      updatedListCopy.push(updatedElement);
    });

    // Assuming setupdatedPaiementList has the correct type signature
    setupdatedPaiementList(updatedListCopy);
  }, [listpaiement_fournisseur, list_achat, listFounisseur]);

  const handelsearch = () => {
    if (keyword === "") {

      setlistfiltred(updatedPaiementList);
    }else{
    const lowercaseKeyword = keyword.toLowerCase();
    const listFiltrer = updatedPaiementList.filter(
      (item: any) =>
        item.libelle.toLowerCase().includes(lowercaseKeyword) ||
        item.nom.toLowerCase().includes(lowercaseKeyword) ||
        item?.matricule_fiscale?.toLowerCase().includes(lowercaseKeyword)
    );

    setlistfiltred(listFiltrer);
    }
  
  };
  return (
    <div className="mt-5">
      <div className="col-md-4 mb-3">
        {/* Row 1 */}
        <div className="row">
          <div className=" col-8">
            <input
              type="text"
              className=" form-control product-search br-30"
              id="input-search"
              placeholder={t("chercher par mot cle")}
              onChange={(e) => setkeyword(e.target.value)}
            />
          </div>
          <div className="col-3">
            <button
              className="btn btn-primary-app"
              onClick={() => handelsearch()}
            >
              {" "}
              {t("chercher")}{" "}
            </button>
          </div>
        </div>
      </div>
      <div className="table-responsive">
        <table
          id="html5-extension"
          className="table table-hover non-hover dataTable no-footer"
          role="grid"
          aria-describedby="html5-extension_info"
        >
          <thead>
            <tr>
              <th>{t("Nom")}</th>
              <th>{t("matricule fiscale")}</th>
              <th>{t("code tva")} </th>
              <th>{t("mobile")}</th>
              <th>{t("telephone")}</th>
              <th>{t("email")} </th>
              <th>{t('adresse')}</th>
              <th>{t('organisation')}</th>
              <th>{t("Total debit")} </th>
              <th>{t("Total crédit")}</th>
              <th>{t("Solde")}</th>
              <th className="text-center" colSpan={2}>
                {t("Action")}
              </th>
            </tr>
          </thead>
          <tbody>
            {listfiltred.length !== 0 &&
              listfiltred.map((item: any, index: number) => (
                <tr key={item._id}>
                  <td>{item.libelle}</td>
                  <td>{item.matricule_fiscale}</td>
                  <td>
                    {
                      tvalist.find(
                        (element: any) => element._id === item.code_tva
                      )?.taux
                    }
                  </td>
                  <td>{item.mobile}</td>
                  <td>{item.tel}</td>
                  <td>{item.email}</td>
                  <td>{item.adresse}</td>
                  <td>{item.nomorganisation}</td>
                  <td>{item.Montant_total.toFixed(3)}</td>

                  <td>{item.total_paye.toFixed(3)}</td>
                  <td
                    className="lien-bleu-souligne"
                    onClick={() => {
                      setfournisser(item);
                      setShowModalMvtFournisseur(true);
                    }}
                  >
                    {(item.Montant_total - item.total_paye).toFixed(3)}
                  </td>
                  <td>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        marginRight: "10px",
                      }}
                      onClick={() => deletedfournisseur(item._id)}
                    >
                      {" "}
                      <i
                        className="fas fa-trash-alt"
                        style={{ color: "#FF0000" }}
                      ></i>
                    </span>
                  </td>
                  <td>
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1.5em",
                        marginRight: "10px",
                      }}
                      onClick={() => {
                        setfounisseur(item);
                        setShowModalFournisseur(true);
                      }}
                    >
                      <i
                        className="fas fa-edit"
                        style={{ color: "#06958d" }}
                      ></i>
                    </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      <FournisseurMouvement
        t={t}
        ShowModal={ShowModalMvtFournisseur}
        setShowModal={setShowModalMvtFournisseur}
        fournisseur={fournisser}
        societe={societe}
        idOrganisation={null}
      />
    </div>
  );
}
/* <PaiementFournisseur
        setShowModal={setShowModallistpaiement}
        ShowModal={ShowModallistpaiement}
        listpaiement={
          fournisser ? listpaiement_fournisseur[fournisser._id || 0] : null
        }
        Fournisseurs={fournisser}
        societe={societe}
        list_achat={list_achat}
        idOrganisation={null}
      />*/
{
  /*className="lien-bleu-souligne"
                    onClick={() => {
                      setfournisser(item);
                      setShowModallistpaiement(true);
                    }}*/
}
