import UserAutorisationsTable from "../../Components/UserAutorisations/UserAutorisationsTable";
import { selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../_App/Redux/hooks";
import React, { useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Navbar from "../../_Layouts/Navbar/Navbar";







  

const AutorisationPage = () => {
  
const autorisations = useAppSelector(selectedAutorisations);
const canViewAutorisationsAff = autorisations?.CAN_VIEW_AUTORISATION_AFFECTATION ;



const navigate = useNavigate();

useEffect(() => {
  if (!canViewAutorisationsAff) {
    navigate("/unauthorized");
  }
}, [canViewAutorisationsAff, navigate]);
  return (
    <>
      <Navbar  />
    <div>
      <UserAutorisationsTable />
    </div>
    </>
  );
};

export default AutorisationPage;
