import { useEffect, useState } from "react";
import DepotsForm from "../../Components/Depots/DepotsForm";
import { ListDepots, deleteDepotsApi, findDepotsBySocieteApi, updateDepotsApi } from "../../_App/Redux/Slices/depot/depotSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SelectedOrganisations, societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import DepotsTable from "../../Components/Depots/DepotsTable";
import { useTranslation } from "react-i18next";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import Swal from "sweetalert2";
import Navbar from "../../_Layouts/Navbar/Navbar";

const DepotsPage = () => {
        const { t } = useTranslation();
        const [showModelDepots , setShowModelDepots] = useState(false)
        const [depot , setdepot] = useState(null)

        const dispatch = useAppDispatch();
        const societe = useAppSelector(societeId);
        const listOrganisations = useAppSelector(SelectedOrganisations);
        const listDepots = useAppSelector(ListDepots)
        useEffect(() => {
          if(societe){
            dispatch(findDepotsBySocieteApi(societe))
          }
        }, [dispatch]);

        useEffect(() => {
          if(!showModelDepots){
            setdepot(null)
          }
        }, [showModelDepots]);
        const verifcationMsg = ( ) => {
          return Swal.fire({
            title: t("vous-êtes sûr?"),
            text: t(`vous voulez supprimer le depots?`),
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: t("Confirmer!"),
            cancelButtonText: t("Cancel!"),

          }).then((result) => {
            return result.isConfirmed ? true : false;
          });
        };
        const deletedepots = async(id:string)=>{
          if(await verifcationMsg()){
         const response = await dispatch(deleteDepotsApi(id))
         console.log(response)
         if(response.payload.success ){
            ToastSuccess(t('depot supprimé avec succès'))
         }else{
          ToastWarning(response.payload.message)
         }}
        }

    
    return (
      <div>
        <Navbar />
        <div className="d-flex justify-content-end mb-3">
        <button
          onClick={() => {
            setShowModelDepots(true);
          }}
          className="btn btn-primary-app"
        >
          {t("Ajouter Depots")}
        </button>{" "}
      </div>
          <DepotsForm 
         
          ShowModal={showModelDepots} 
          setShowModal={setShowModelDepots}
          depots={depot}
          />
          <DepotsTable 
          t={t} 
          listDepots={listDepots} 
          listOrganisations={listOrganisations} 
          setdepot={setdepot}  
          deletedepots={deletedepots} 
          setShowModelDepots={setShowModelDepots}/>
      </div>
    );
  };
  
  export default DepotsPage;

