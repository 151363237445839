import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { ProductTarifType } from "../../../Types/Entites/ProductTarifType";
import _ from "lodash";

/**
 * create  Product Tarif function
 * @private
 */
export const createProductTarifsApi = createAsyncThunk(
  "producttarif/createProductTarifsApi",
  async (data: ProductTarifType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "producttarif/createProductTarifsApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
/**
 * Get List ProductTarif.
 * @public
 */
export const findProductsTarifByOrganisationApi = createAsyncThunk('producttarif/findProductsTarifByOrganisationApi', async (idorganisation: any,{ rejectWithValue, dispatch }) => {

  try {
      const response = await axios.get(`producttarif/findProductsTarifByOrganisationApi/${idorganisation}`);
      return response.data;
  } catch (error: any) {
      console.log(error)
       return rejectWithValue({
          success: false,
          message: error.response.data.message
      });
  }
});

/**
 * Update Product Tarif
 * @private
 */
export const updateProductsTarifsApi = createAsyncThunk(
  `producttarif/updateProductsTarifsApi`,
  async (data: ProductTarifType, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.put(
        "producttarif/updateProductsTarifsApi",
        data
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface ProductTarifState {
  listProductTarif: ProductTarifType[];
  ProduitsParProduct: { [ProductId: string]: ProductTarifType[] };
}


const initialState: ProductTarifState = {
  listProductTarif: [],
  ProduitsParProduct: {},
};

export const ProductTarifSlice = createSlice({
  name: "producttarif",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProductTarifsApi.fulfilled, (state, action) => {
      state.listProductTarif = action.payload;
    });
       builder.addCase(findProductsTarifByOrganisationApi.fulfilled, (state, action) => {
      const groupedProducts = _.groupBy(action.payload, "produit_id");
      state.listProductTarif = action.payload;

      state.ProduitsParProduct = groupedProducts;
    });
    builder.addCase(updateProductsTarifsApi.fulfilled, (state, action) => {
      const updated_producttaille = action.payload.data;
      var index = _.findIndex(state.listProductTarif, { _id: updated_producttaille._id });
      // Replace item at index using native splice
      state.listProductTarif.splice(index, 1, updated_producttaille);
    });
  },
});

export const ListProductTarif = (state: RootState) => state.productarifs.listProductTarif;
export const ListProductTarifProduit = (state: RootState) => state.productarifs.ProduitsParProduct;

export default ProductTarifSlice.reducer;
 