import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import ZoneTableType from "../../../Types/Entites/ZoneTableType";
import _ from "lodash";

export const createZoneTableApi = createAsyncThunk(
  "zone/createZoneTableApi",
  async (data: ZoneTableType, { rejectWithValue }) => {
    try {
      const response = await axios.post("zone/createZoneTableApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const updateZoneTableApi = createAsyncThunk(
  "zone/updateZoneTableApi",
  async (data: ZoneTableType, { rejectWithValue }) => {
    try {
      const response = await axios.put("zone/updateZoneTableApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const removeZoneTableApi = createAsyncThunk(
  "zoneTable/removeZoneTableApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`zone/removeZoneTableApi/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findZoneTableByOrganisationApi = createAsyncThunk(
  "zoneTable/findZoneTableByOrganisationApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`zone/findZoneTableByOrganisationApi/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

type ZoneTableState = {
  listZoneTable: ZoneTableType[];
};

const initialState: ZoneTableState = {
  listZoneTable: [],
};

export const ZoneTableSlice = createSlice({
  name: "zoneTable",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createZoneTableApi.fulfilled, (state, action) => {
      state.listZoneTable.splice(0, 0, action.payload.data);
    });

    builder.addCase(updateZoneTableApi.fulfilled, (state, action) => {
      const updatedZoneTable = action.payload.data;
      var index = _.findIndex(state.listZoneTable, {
        _id: updatedZoneTable._id,
      });

      state.listZoneTable.splice(index, 1, updatedZoneTable);
    });

    builder.addCase(removeZoneTableApi.fulfilled, (state, action) => {
      const deletedZoneTable = action.payload.data;
      state.listZoneTable = state.listZoneTable.filter((zoneTable) => zoneTable._id !== deletedZoneTable._id);
    });

    builder.addCase(findZoneTableByOrganisationApi.fulfilled, (state, action) => {
      state.listZoneTable = action.payload;
    });
  },
});

export const ListZoneTable = (state: RootState) => state.zoneTables.listZoneTable;

export default ZoneTableSlice.reducer;
