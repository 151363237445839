import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FournisseurType } from "../../_App/Types/Entites/FournisseurType";
import PaiementAchatForm from "./PaiementAchatForm";
import {
  PaiementAchatType,
  mode_paimentObject,
} from "../../_App/Types/Entites/Achat/PaiementAchatType";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  createPaiementAchatApi,
  findPaiementAchatByAchatId,
  ListPaiementAchat,
} from "../../_App/Redux/Slices/achat/paiementachatsSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import Swal from "sweetalert2";
import {
  ListAchatFournisseur,
  findAchatByFournisseurApi,
} from "../../_App/Redux/Slices/achat/achatSlice";
import { AchatType } from "../../_App/Types/Entites/Achat/AchatType";
import Select from 'react-select';
import { findPaiementSoucheApi, souchepaiement } from "../../_App/Redux/Slices/souche/soucheSlice";
import _ from "lodash";
import PaiementPartielle from "./PaiementPartielle";

export default function PaiementAchat({
  t,
  societe,
  organisation,
  ShowModal,
  setShowModal,
  debittotal,
  Fournisseur,
  list_achat_selected,
  totalcredit,
}: any) {
  const dispatch = useAppDispatch();
  console.log()
  const achatpaiement = useAppSelector(ListPaiementAchat);
  const list_achat = useAppSelector(ListAchatFournisseur);
  const [total_input, settotal_input] = useState("");
  const [msgerr, setmsgerr] = useState(false);
  const [list_achats, setlist_achats] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>();
  const [list_payement, setlist_payement]=useState<any[]>([])

  const [sens, setsens] = useState("");
  const [typeoperation, settypeoperation] = useState("");
  const senslist = ["credit", "debit"];

  const [modePaiement, setModePaiement] = useState<mode_paimentObject>({
    cheque: {
      numCheque: "",
      dateCheque: "",
      montant: 0 ,
    },
    espece: 0,
    retenue: 0,
  });
  const souche_paiement = useAppSelector(souchepaiement);

  const [calcul , setcalcul]=useState({credit:0 ,debit:0 , solde: 0})
  const [showModalPaiementPartielle, setshowModalPaiementPartielle]=useState(false)

  useEffect(() => {
    if (societe) {
      
      dispatch(findPaiementSoucheApi(societe));
    }
  }, [dispatch, societe])

  useEffect(() => {
    setcalcul(({credit:totalcredit,debit:debittotal , solde: parseFloat((debittotal-totalcredit).toFixed(3))}))

    if(list_achat_selected){
      setlist_achats(list_achat_selected)
    }
  }, [list_achat_selected]);


  useEffect(() => {
    const newlist = list_achat.filter(
      (item) => item.payer !== item.total_net_payer
    );
    const option = newlist.map((item: any) => ({
      value: item._id,
      label: `${item?.refAchat} - ${item?.code_paiement[0]||''}`,
    }));
    setOptions(option);
  }, [list_achat]);


  useEffect(() => {
    if (Fournisseur) {
      dispatch(findAchatByFournisseurApi(Fournisseur._id));
    }
  }, [Fournisseur]);

  useEffect(() => {
    if (achatpaiement.length !== 0) {
      setModePaiement(achatpaiement[0].Mode_paiement);
    } else {
      setModePaiement({
        cheque: {
          numCheque: "",
          dateCheque: "",
          montant: 0,
        },
        espece: 0,
        retenue: 0,
      });
      settotal_input("");
    }
  }, [achatpaiement, ShowModal]);
 
  const handleChange = (selectedOptions:any) => {
    // calculer le debit credit et solde 
    // get achats 
    let listachat_selected:AchatType[]=[]
    let listachat_Id:string[]=[]

    selectedOptions.forEach((element:any )=> {
      let achat_selected = list_achat.find(item => item._id===element.value)
      if(achat_selected){

        listachat_selected.push(achat_selected)
        listachat_Id.push(element.value)
      }
    });
    setlist_achats(listachat_Id)
    //total debit 

    const total_debit: number = listachat_selected.reduce(
      (accumulator: number, currentValue: { total_net_payer: any }) =>
        accumulator + Number(currentValue.total_net_payer),
      0
    );
    //total credit 
    const total_credit : number = listachat_selected.reduce(
      (accumulator: number, currentValue: { payer: any }) =>
        accumulator + Number(currentValue.payer),
      0
    );
    // total solde 
    const total_solde: number = listachat_selected.reduce(
      (accumulator: number, currentValue: { total_net_payer: number, payer: number }) =>
        accumulator + Number(currentValue.total_net_payer - currentValue.payer),
      0
    );
    setcalcul({credit:total_credit ,debit:total_debit , solde: parseFloat(total_solde.toFixed(3))})

  };
  const paiement=()=>{ setshowModalPaiementPartielle(true)}
  const onSubmit = async () => {
   
   let data;
    if (typeoperation === "") {
      setmsgerr(true);
    }
  
    else {
        // cree code_paiement 
        const codepaiement = `${String(souche_paiement.souche).padStart(5, "0")}`;

      const total_paiment =
        (modePaiement.retenue !== 0? modePaiement.retenue : 0) +
        (modePaiement.espece !== 0 ? modePaiement.espece: 0) +
        (modePaiement.cheque.montant !== 0
          ? modePaiement.cheque.montant
          : 0);
        
        if(typeoperation=== 'credit' && total_paiment >calcul.solde){
          ToastWarning("le montant entrer > total ");
          return;
        }
        data = {
          Fournisseur:[...new Set(list_achats.map(obj => obj.fournisseur._id))],
          Mode_paiement: modePaiement,
          code_paiement: codepaiement,
          achat_id:list_achats.map(obj => obj.achat._id),
          list_achat_selected: list_achats.map(obj => obj.achat._id),
          societe_id: societe,
          Montant_paye: total_input,
          organisation_id: organisation,
          sens: sens,
          type_operation: typeoperation,
          credit:calcul.credit ,
          debit:calcul.debit,
          list_payement:list_payement
        };
      }
     if (true) {
        const response = await dispatch(createPaiementAchatApi(data));
        if (response?.payload.success) {
          ToastSuccess(response?.payload.message);
          setModePaiement({
            cheque: {
              numCheque: "",
              dateCheque: "",
              montant: 0,
            },
            espece: 0,
            retenue: 0,
          });
          setsens("");
          setlist_payement([])

         // window.location.reload();
        } else {
          ToastWarning(response?.payload.message);
        }
      
    }
  }
  const operation = (op: string) => {
    settypeoperation(op);
    setmsgerr(false);

    setsens("credit");
  };

  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-3"
        dialogClassName="modal-50w"
        backdropClassName="modal-backdrop-3"
      >
        <Modal.Header closeButton onClick={()=>setShowModal(false)}>
          <Modal.Title className="c-primary">{t("Paiement")} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Fournisseur Information */}
          <div className="section">
            <div className="row">
              <div className="row">
                <strong className="col-2"> {t("Fournisseur")}:</strong>{" "}
                <p className="col-10">{[...new Set(list_achats.map(obj => obj.fournisseur?.libelle))].join(', ')}</p>
              </div>
              
            </div>
          </div>

          {/* Payment Details */}
          {list_achat_selected &&list_achat_selected.length !== 0 ? (
            <div className="section">
           
              <div className="row">
                <div className="col-4">
                  <strong>
                    {" "}
                    {t("total debit")}: {calcul.debit}
                  </strong>
                </div>
                <div className="col-4">
                  <strong>
                    {" "}
                    {t("credit")} : {calcul.credit}
                  </strong>
                </div>
                <div className="col-4">
                  <strong>
                    {t("Solde")}: {calcul.solde}
                  </strong>
                </div>
              </div>
            </div>
          ) : (
            <div className="section">
              <div className="row">
                <div className="col-6">
                  <strong>{t("Document")}</strong>

                  <Select
                    isMulti
                    name="colors"
                    options={options}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-6">
                  <strong> {t("total debit")}: </strong>{" "}
                  <strong>
                      {calcul.debit}
                  </strong>
                  <strong> {t("total credit")}: </strong>{" "}
                  <strong>
                    {calcul.credit}
                  </strong>
                  <strong> {t("solde")}: </strong>{" "}
                  <strong>
                  {calcul.solde}

                  </strong>
                </div>
              </div>
            </div>
          )}

          {/* Type Operation and Sens */}

          {/* PaiementAchatForm */}
          <div className="section">
            <div className="row">
              <div className="col-6">
                <strong>
                  <span style={{ color: "red" }}>*</span>
                  {t("Type operation")}
                </strong>

                <select
                  className=" input form-control"
                  onChange={(event) => {
                    const selectedAchatId = event.target.value;
                    operation(selectedAchatId);
                  }}
                >
                  <option value="">{t("Select type")}</option>
                  <option value="paiement">{t("Paiement")}</option>
                  <option value="autre">{t("autre")}</option>
                </select>
                {msgerr && (
                  <p className="row color-red">
                    {" "}
                    {t("choisir type d'operation")}
                  </p>
                )}
              </div>
              <div className="col-6">
                <strong>{t("Sens")}</strong>

                <select
                  className=" input form-control"
                  onChange={(event) => {
                    const selectedAchatId = event.target.value;
                    setsens(selectedAchatId);
                  }}
                >
                  {typeoperation === "paiement" ? (
                    <option value="credit">{t("credit")}</option>
                  ) : (
                    <>
                      <option value="">{t("Select sens")}</option>
                      {senslist?.map((item: any, index: number) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </>
                  )}
                </select>
              </div>
            </div>
            <PaiementAchatForm
              t={t}
              modePaiement={modePaiement}
              setModePaiement={setModePaiement}
              total_input={total_input}
              settotal_input={settotal_input}
              totalcredit={totalcredit}
              totaldebit={debittotal}
              sens={sens}
            />
          </div>
          <PaiementPartielle 
             t={t}
             list_achat_selected={list_achat_selected}
             setShowModal={setshowModalPaiementPartielle}
             ShowModal={showModalPaiementPartielle}
             list_payement={list_payement}
             setlist_payement={setlist_payement}
             total_input={total_input}
             onSubmit={onSubmit}
          />
        </Modal.Body>

        <Modal.Footer>
          <>
            <button type="reset" className="btn btn-primary-app" onClick={()=>setShowModal(false)}>
              Annuler
            </button>
            <button onClick={paiement} className="btn btn-primary-app">
              Enregistrer
            </button>
          </>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
