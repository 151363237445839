import React, { useEffect } from "react";
import { changeFilter } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import { useAppDispatch } from "../../../_App/Redux/hooks";

const CaisseCarteItem = ({ Item, col }: any) => {
  // useEffect(() => {}, [console.log(Item)]);
  const dispatch = useAppDispatch();

  const handleLinkChange = async (table: any) => {
    dispatch(
      changeFilter({
        table: table,
        filteredBy: Item,
        rightSideSelectedProduct:""
      })
    );
  };

  return (
    <>
      <div
        className={col + " col layout-spacing product-item"}
        onClick={(e) => {
          handleLinkChange("categories");
        }}
      >
        <div className="color-box">
          <div className="round-container">
            <img src={Item.image ? 
                process.env.REACT_APP_API_PUBLIC_URL + "/carte/" + Item.image : "/assets/assets/img/no-image.jpg"
              } alt="Your" />
          </div>
          <div className="cl-info">
            <h6 className="cl-title">{Item.libelle.fr}</h6>
            {/* <span>#4361ee</span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CaisseCarteItem;
