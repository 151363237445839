import React, { useState,useEffect } from 'react';
import { ProduitDetailType } from '../../../../_App/Types/Entites/ProduitDetail'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import IngredientList from '../Ingredients/IngredientList';
import SupplementList from '../Supplements/SupplementList';
export const ProduitDetail: React.FC<ProduitDetailType> = ({ idProduit, show, onHide }) => {



  return (
    <Modal show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body className="grid-example">
        <Container>
          <Row>
          <Col xs={12} md={6}>
           <IngredientList idProduit={idProduit} />
           </Col>
           <Col xs={12} md={6}>
            <SupplementList  idProduit={idProduit} />
           </Col>

          </Row>
           
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onHide}>Close</Button>
      </Modal.Footer>
    </Modal>
  );
};
