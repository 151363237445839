import React, { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { updateOrganisationApi } from "../../_App/Redux/Slices/organisations/organisationSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { OrganisationType } from "../../_App/Types/Entites/OrganisationType";
import { OrganisationDataType } from "../../_App/Types/Forms/OrganisationDataType";
import { useTranslation } from "react-i18next";

const labelStyles = {
  fontWeight: "bold",
  marginBottom: "5px",
};

const inputStyles = {
  width: "100%",
  padding: "8px",
  borderRadius: "5px",
  border: "1px solid #ccc",
  marginBottom: "15px",
};

const checkboxStyles = {
  marginRight: "5px",
  marginBottom: "10px",
};

interface OrganisationsFormUpdateProps {
  organization: OrganisationDataType;
  onClose: () => void;
  onUpdate: (data: OrganisationType) => void;
}

const OrganisationsFormUpdate: React.FC<OrganisationsFormUpdateProps> = ({
  organization,
  onClose,
  onUpdate,
}) => {
  const [isEditing, setIsEditing] = useState(true);

  useEffect(() => {
  }, [isEditing]);

  const dispatch = useAppDispatch();
  const {t} = useTranslation();

  const { register, handleSubmit } = useForm<OrganisationType>({
    defaultValues: organization,
  });

  const onSubmit = async (data: OrganisationType) => {
    try {
      const updatedData = { orgId: organization._id, ...data };
      const response = await dispatch(updateOrganisationApi(updatedData));

      if (response.payload.success) {
        onUpdate(data);
        ToastSuccess(response.payload.message);
        onClose();
        setIsEditing(false);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      console.error("Submit error:", error);
      ToastWarning("Une erreur s'est produite lors de la sauvegarde.");
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontWeight: "bold", marginBottom: "5px" }}>
              {t("Nom de l'organisation")}
            </Form.Label>
            <Form.Control
              type="text"
              {...register("nom")}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "15px",
              }}
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group>
            <Form.Label style={{ fontWeight: "bold", marginBottom: "5px" }}>
              {t("Description")}
            </Form.Label>
            <Form.Control
              type="text"
              {...register("description")}
              style={{
                width: "100%",
                padding: "8px",
                borderRadius: "5px",
                border: "1px solid #ccc",
                marginBottom: "15px",
              }}
            />
          </Form.Group>
        </Col>
      </Row>

      <Form.Group>
        <Form.Label
          style={{
            fontWeight: "bold",
            marginRight: "5px",
            marginBottom: "10px",
          }}
        >
          {t("Mode de paiement")}
        </Form.Label>
        <Row>
          <Col>
            <Form.Check
              type="checkbox"
              id="cash"
              label="Cash"
              value="cash"
              {...register("paiementmode")}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              id="cheque"
              label="Chèque"
              value="cheque"
              {...register("paiementmode")}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              type="checkbox"
              id="carte_credit"
              label="Carte de crédit"
              value="carte_credit"
              {...register("paiementmode")}
              style={{ marginBottom: "10px" }}
            />
          </Col>
          <Col>
            <Form.Check
              type="checkbox"
              id="sodexo"
              label="Sodexo"
              value="sodexo"
              {...register("paiementmode")}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
      </Form.Group>

      <Row>
        <Col>
          <div className="form-group">
            <label
              htmlFor="currency"
              style={{ fontWeight: "bold", marginBottom: "5px" }}
            >
              {t("Devise")}
            </label>
            <select
              className="form-control"
              id="currency"
              {...register("currency")}
            >
              <option value="TND">TND</option>
            </select>
          </div>
        </Col>
      </Row>
      <Button
        className="btn btn-primary-app"
        onClick={onClose}
        style={{ marginRight: "7px" }}
      >
        {t("Annuler")}
      </Button>
      <Button type="submit" className="btn btn-primary-app">
      {t("Enregistrer")}
      </Button>
    </Form>
  );
};

export default OrganisationsFormUpdate;
