import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { ArticleType } from "../../../_App/Types/Entites/ArticleType";
import ArticleItem from "./ArticleItem";
import {
  SelectedOrganisations,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import Codebarreartice from "./codebarre";
import {
  activeArticleApi,
  desactiveArticleApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import Swal from "sweetalert2";
import ArticleFournisseur from "./ArticleFournisseur";
import {
  ListDepots,
  findDepotsBySocieteApi,
} from "../../../_App/Redux/Slices/depot/depotSlice";
import StockTable from "../../Stock/StockTable";
import { Spinner } from "react-bootstrap";

export default function ArticleList({
  index,
  setindex,
  t,
  list_Article,
  setShowModalArticle,
  setarticleitem,
  societe,
  setkeyword,
  handelsearch,keyword
}: any) {
  const dispatch = useAppDispatch();
  const [showModalStock, setshowModalStock] = useState(false);
  const [articleselected, setarticleselected] = useState();
  const listOrgaisations = useAppSelector(SelectedOrganisations);
  const [showModalcodeabarre, setshowModalcodeabarre] = useState(false);
  const [showDetailArticle, setshowDetailArticle] = useState(false);
  const [article, setarticle] = useState<ArticleType>();
  const [list_codeabarre, setlist_codeabarre] = useState<ArticleType[]>([]);
  const listdepots = useAppSelector(ListDepots);
  const [listArticle, setlistArticle] = useState<ArticleType[]>();

  useEffect(() => {
    if (list_Article && list_Article.length !== 0) {
      setlist_codeabarre(list_Article);
      setlistArticle(list_Article)
    }
  }, [list_Article]);
  useEffect(() => {
    if (societe) {
      dispatch(findDepotsBySocieteApi(societe));
    }
  }, [societe]);
  useEffect(() => {
    if (list_Article && !showModalcodeabarre) {
      setlist_codeabarre(list_Article);
    }
  }, [list_Article ,showModalcodeabarre]);
  const updateFunction = async (item: any) => {
    setarticleitem(item);
    setShowModalArticle(true);
  };
  const verifcationMsg = (action: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: `${t("vous voulez")} ${t(action)}${" article"}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t("Cancel!"),
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  const desactiverFunction = async (id: string) => {
    if (await verifcationMsg("desactiver ")) {
      const response = await dispatch(desactiveArticleApi(id));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };
  const activerFunction = async (id: string) => {
    if (await verifcationMsg("activer")) {
      const response = await dispatch(activeArticleApi(id));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
      } else {
        ToastWarning(response.payload.message);
      }
    }
  };

  const showonecodeabarre = (item: any) => {
    setlist_codeabarre([item]);
    setshowModalcodeabarre(true);
  };

  return (
    <div>
      <div className="row mt-3">
        <div className="col-md-3">
          <input
            type="text"
            className="wd-300 form-control product-search br-30 "
            id="input-search"
            placeholder={t("chercher par mots cle")}
            onChange={(e) => setkeyword(e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <button
            className=" btn btn-primary-app"
            onClick={() =>{handelsearch(keyword)}}
          >
            {t("chercher")}
          </button>{" "}
        </div>

       {/* <div className="col-md-3">
          <select
            id="organisation_id"
            className="form-control"
            onChange={(e) => {setkeyword(e.target.value) ;  handelsearch(e.target.value)}}
          >
            <option value=""> {t("tout les organisations")} </option>

            {listOrgaisations?.map((item: any, index: number) => (
              <option key={index} value={item._id}>
                {item.nom}
              </option>
            ))}
          </select>
        </div>*/}
        <div className="col-md-3">
          <button
            className=" btn btn-primary-app"
            onClick={() => setshowModalcodeabarre(true)}
          >
            {t("code a barre")}
          </button>{" "}
        </div>
        {listArticle && listArticle.length !== 0 ? (
          <>
            <div className="row mt-5">
              {listArticle.map((item: any) => (
                <div key={item._id} className="col-md-2 ">
                  <ArticleItem
                    t={t}
                    setarticle={setarticle}
                    setshowDetailArticle={setshowDetailArticle}
                    article={item}
                    updateFunction={updateFunction}
                    desactiverFunction={desactiverFunction}
                    activerFunction={activerFunction}
                    showonecodeabarre={showonecodeabarre}
                    listdepots={listdepots}
                    setshowModalStock={setshowModalStock}
                    setarticleselected={setarticleselected}
                  />
                </div>
              ))}
            </div>
            <div className="row">
              <div className="pagination-no_spacing">
                <ul className="pagination">
                  {index > 0  &&
                  <li>
                    <a onClick={() => setindex(index -10)} className="prev">
                      {" "}
                      <i className="fas fa-chevron-left"></i>{" "}
                    </a>{" "}
                  </li>}
                  <li>
                    <a onClick={() => setindex(index + 10)} className="next">
                      <i className="fas fa-chevron-right"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </>
        ) : (
          <div className="text-center" style={{marginTop:"300px" }}>
            <Spinner animation="grow" style={{width:"100px" , height:'100px' }} />
            <br/>
            <strong >LOADING</strong>
          </div>
        )}
      </div>

      <Codebarreartice
        t={t}
        ListArticle={list_codeabarre}
        ShowModal={showModalcodeabarre}
        setShowModal={setshowModalcodeabarre}
        societeId={societe}
      />
      <ArticleFournisseur
        t={t}
        showModal={showDetailArticle}
        setshowModal={setshowDetailArticle}
        idarticle={article?._id}
        refproduct={article?.produit_ref}
      />
      <StockTable
        t={t}
        showModal={showModalStock}
        setshowModal={setshowModalStock}
        article_id={articleselected}
        listdepots={listdepots}
      />
    </div>
  );
}
/* */
