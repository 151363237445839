import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import PaymentType from "../../../Types/Entites/PaymentType";

/**
 * create a payment.
 * @public
 */

export const savePaymentApi = createAsyncThunk(
  "payments/savePaymentApi",
  async (data: PaymentType, { rejectWithValue }) => {
    try {
      const response = await axios.post("payments/savePaymentApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * list  of payments by organisation.
 * @public
 */
export const findPaymentsByOrganisationApi = createAsyncThunk(
  "payments/findPaymentsByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findPaymentsByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findPaymentsByOrderApi = createAsyncThunk(
  "payments/findPaymentsByOrderApi",
  async (idOrder: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findPaymentsByOrderApi/${idOrder}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findPaymentsByTicketApi = createAsyncThunk(
  "payments/findPaymentsByTicketApi",
  async (idTicket: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `payments/findPaymentsByTicketApi/${idTicket}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface PaymentsState {
  listPaymentOperations: PaymentType[];
  listPaymentOperationsByTicket: PaymentType[];
  listPaymentsByPaymentMethod: {
    [paymentMethod: string]: PaymentType[];
  };
}

const initialState: PaymentsState = {
  listPaymentOperations: [],
  listPaymentOperationsByTicket: [],
  listPaymentsByPaymentMethod: {},
};

export const PaymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(savePaymentApi.fulfilled, (state, action) => {
      state.listPaymentOperations.splice(0, 0, action.payload.data);
    });
    builder.addCase(findPaymentsByOrderApi.fulfilled, (state, action) => {
      const groupedByPaymentMethod = _.groupBy(
        action.payload,
        "payment_method"
      );
      return {
        ...state,
        listPaymentOperations: action.payload,
        listPaymentsByPaymentMethod: groupedByPaymentMethod,
      };
    });
    builder.addCase(findPaymentsByTicketApi.fulfilled, (state, action) => {
      const groupedByPaymentMethod = _.groupBy(
        action.payload,
        "payment_method"
      );
      return {
        ...state,
        listPaymentsByPaymentMethod: groupedByPaymentMethod,
      };
    });
  },
});

export const listPaymentOperations = (state: RootState) =>
  state.payments.listPaymentOperations;
export const listOperationsByPaymentMethod = (state: RootState) =>
  state.payments.listPaymentsByPaymentMethod;
export const listPaymentOperationsByTicket = (state: RootState) =>
  state.payments.listPaymentOperationsByTicket;
export default PaymentsSlice.reducer;
