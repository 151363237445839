import { string, object, date, number, mixed, array } from "yup";

export const ArticleSchema = object({
    titre: object().shape({
        fr: string().required('Le titre (fr) est obligatoire'),
        en: string(),
        ar: string(),
      }),
      description: object().shape({
        fr: string().required('La description (fr) est obligatoire'),
        en: string(),
        ar: string(),
      }),
      prix_vente_ttc: number().required('Le prix ttc est obligatoire'),
      prix_vente_ht: number().required('Le prix ht est obligatoire'),
      image: mixed().optional().nullable(),

     
   
});
 