import React, { useEffect, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { getBonReductionById } from "../../../_App/Redux/Slices/reduction/reductionSlice";
import BonReductionType from "../../../_App/Types/Entites/ReductionType";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import Barcode from "react-barcode";
import { SelectedOrganisation } from "../../../_App/Redux/Slices/Auth/authSlice";

const BonDeReductionTicket = ({
  ShowModal,
  setShowModal,
  setShowModalForm,
  Bon_id,
}: any) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const parametrage = useAppSelector(parametrageCaisse);
  const [bon, setBon] = useState<BonReductionType>();
  const dispatch = useAppDispatch();
  const magasin = useAppSelector(SelectedOrganisation);

  useEffect(() => {
    const fetchData = async () => {
      if (Bon_id) {
        const res = await dispatch(getBonReductionById(Bon_id));
        if (res.payload._id) {
          setBon(res.payload);
        }
      }
    };

    fetchData();
  }, [dispatch, Bon_id]);

  const handlePrint = () => {
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      printFrame.contentWindow?.print();
    }
  };

  return (
    <Modal
      show={ShowModal}
      onHide={() => {
        setShowModal(false);
        if (setShowModalForm) setShowModalForm(false);
      }}
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <div ref={componentRef}>
        <div className="container-ticket" id="print-content">
          <div className="receipt_header">
            <h1 onClick={() => console.log(Bon_id)}>
              {bon && bon.type} <span>{magasin?.nom} </span>
            </h1>
          </div>

          <div className="receipt_body">
            <div className="date_time_con">
              <div className="date">
                {bon && bon.date.toString().split("T")[0]}
              </div>
              <div className="time">
                Expire le {bon && bon?.dateEcheance.toString().split("T")[0]}
              </div>
            </div>

            <div className="ticket-items">
              <h1 style={{ textAlign: "center" }}>
                {bon && bon.amount.toFixed(3)}{" "}
                {parametrage && parametrage.defaultCurrency}
              </h1>
            </div>
            <div
              className="barcode"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {bon && <Barcode value={bon.code} />}
            </div>
          </div>
          <div className="receipt-footer">
            <p style={{ textAlign: "center" }}>
              {parametrage && parametrage.message_promo}
            </p>
          </div>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            color=""
            onClick={handlePrint}
            className="btn-info"
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
      <iframe
        id="print-frame"
        title="print-frame"
        style={{ display: "none" }}
        onLoad={() => handlePrint()}
      />
    </Modal>
  );
};

export default BonDeReductionTicket;
