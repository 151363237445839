import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  activateParametrageMenuApi,
  deleteParametrageMenuApi,
} from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { useAppDispatch } from "../../../_App/Redux/hooks";
import AppMenuParametrageType from "../../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import ParametrageMenuButtons from "./ParametrageMenuButtons";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import Swal from "sweetalert2";

const StyledCard = styled.div`
  .component-card_1 {
    width: 100%;
    height: 260px;
  }
  .card-body {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  div.plus-form {
    position: absolute;
    font-size: 230px;
    top: -55px
  }
`;
const ParametrageMenuItemEmpty = ({
  ShowModalMenuForm,
  setShowModalMenuForm,
  setMenu,
}: {
  ShowModalMenuForm: boolean;
  setShowModalMenuForm: Dispatch<SetStateAction<boolean>>;
  setMenu: Dispatch<SetStateAction<AppMenuParametrageType | null>>;
}) => {
  const dispatch = useAppDispatch();

  return (
    <StyledCard>
      <div className={`card component-card_1 relative bg-primary-app`}
      onClick={(e) => {
        setMenu(null);
        setShowModalMenuForm(true);
      }}>
        <div className="card-body">
            <div className="plus-form c-primary">+</div>
      
        </div>
      </div>
    </StyledCard>
  );
};

export default ParametrageMenuItemEmpty;
