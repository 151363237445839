import React, { useEffect, useRef, LegacyRef } from 'react'
import styled from 'styled-components'
import { UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form'
import AppMenuItemsType from '../../../../_App/Types/Entites/AppMenus/AppMenuItems'
import _ from 'lodash'
import { DropTargetMonitor, useDrag, useDrop } from 'react-dnd'
import { swapPlacesItemsReducer } from '../../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice'
import { useAppDispatch } from '../../../../_App/Redux/hooks'
import AppMenuParametrageType from '../../../../_App/Types/Entites/AppMenus/AppMenuParametrageType'

const StyledCard = styled.div`
        .card{
            width: 100%;
        }
        .card-body{
            padding: 10px 20px 20px 20px!important;
        }
        .menuItemBtn-test{
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 15px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
        }
        .contentItem-test{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction:column;
            padding: 12px;
            border: 2px solid;
            border-radius: 10px;
            width: 150px;
            height: 100px;
        }
        .icone-title{
            color: blue;
            text-align: center;
            font-size: 14px;
            cursor:pointer;
        }
        
        .fs-25{
            font-size: 25px !important;
        }
        .selectedButton {
            border: 2px solid green;
        }
        .dnd-button{
            position: absolute;
            left: 41%;
            top: -10px;
            cursor: pointer;
        }
`
const MenuFormItem = ({ index, item, register, watch, getValues, setValue, ShowModalIcons, setShowModalIcons, setRegisterIndex }:
    {
        index: number, item: AppMenuItemsType,
        register: UseFormRegister<AppMenuParametrageType>,
        watch: UseFormWatch<AppMenuParametrageType>,
        getValues: UseFormGetValues<AppMenuParametrageType>,
        setValue: UseFormSetValue<AppMenuParametrageType>,
        ShowModalIcons: boolean,
        setShowModalIcons: React.Dispatch<React.SetStateAction<boolean>>,
        setRegisterIndex: React.Dispatch<React.SetStateAction<number>>,
    }) => {

    const dispatch = useAppDispatch();

    const background_menu = watch('menuPosition.bgColor')
    // const background_Buttons = watch('menuPosition.bgColorButtons')
    // const color_buttons = watch('menuPosition.colorButtons')
    const watchMenuItems: AppMenuItemsType[] | undefined = watch('menuItems');

      

    useEffect(() => {

        var current_button: AppMenuItemsType = {
            ...item,
            ordre: index,
            libelle: watchMenuItems ? watchMenuItems[index]?.libelle ? watchMenuItems[index]?.libelle : item.libelle : item.libelle,
            actif: watchMenuItems ? watchMenuItems[index]?.actif ? watchMenuItems[index]?.actif : false : false,
            icon: watchMenuItems ? watchMenuItems[index]?.icon ? watchMenuItems[index]?.icon : item.icon : item.icon,
            type_icon: watchMenuItems ? watchMenuItems[index]?.type_icon ? watchMenuItems[index]?.type_icon : item.type_icon : item.type_icon,
            color: watchMenuItems ? watchMenuItems[index]?.color ? watchMenuItems[index]?.color : item.color : item.color,
            bgColor: watchMenuItems ? watchMenuItems[index]?.bgColor ? watchMenuItems[index]?.bgColor : item.bgColor : item.bgColor,
            collapsed: watchMenuItems ? watchMenuItems[index]?.collapsed ? watchMenuItems[index]?.collapsed : false : false,
        }
        setValue(`menuItems.${index}`, current_button)

    }, [index, item, setValue])


    /**
     * open or close details form
     * @param collapsed boolean
     */
    const collapsedButtons = (collapsed: boolean) => {
        if (watchMenuItems) {
            setValue(`menuItems.${index}.collapsed`, collapsed)
        }
    }

    /**
     * select button to add it to the menu
     */
    const selectButton = () => {
        if (watchMenuItems) {
            if (_.isUndefined(watchMenuItems[index]?.actif)) {
                setValue(`menuItems.${index}.actif`, true)
            } else {
                setValue(`menuItems.${index}.actif`, !watchMenuItems[index]?.actif)
            }
        }
    }


    /**
     * sort data after drag and drop
     */
    const swapPlacesButtons = (dragIndex: number, dropIndex: number) => {
        // swap places on items in reducer :
        dispatch(swapPlacesItemsReducer({ dragIndex, dropIndex }));

        // // swap places in form menuItems :
        if (watchMenuItems) {
            const dragButton = watchMenuItems[dragIndex];
            const dropButton = watchMenuItems[dropIndex]
            // Swap places of dragButton and dropButton in the menuItems array
            setValue(`menuItems.${dragIndex}`, dropButton)
            setValue(`menuItems.${dropIndex}`, dragButton)
        }
    }

    // useDrag - the list item is draggable
    const [{ isDragging }, dragRef] = useDrag({
        type: 'ButtonMenu',
        item: watchMenuItems ? watchMenuItems[index] : item,
        collect: (monitor) => {
            return {
                isDragging: monitor.isDragging()
            };
        }
    })

    // useDrop - the list item is also a drop area
    const [spec, dropRef] = useDrop({
        accept: ['ButtonMenu'],
        hover: (item: AppMenuItemsType, monitor: DropTargetMonitor<AppMenuItemsType, unknown>) => {

        },
        drop: (item: AppMenuItemsType, monitor: DropTargetMonitor<AppMenuItemsType, unknown>) => {
            const dragIndex = item.ordre
            const hoverIndex = index
            // const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // let hoverMiddleY = 0;
            // let hoverActualY = 0;
            // if (hoverBoundingRect) {
            //     hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            //     const monitor_client = monitor.getClientOffset();
            //     if (monitor_client) {
            //         hoverActualY = monitor_client.y - hoverBoundingRect.top
            //     }

            // }

            swapPlacesButtons(dragIndex, hoverIndex)
        },
    })

    const ref = useRef<HTMLInputElement>(null)

    const dragDropRef = dragRef(dropRef(ref)) as LegacyRef<HTMLDivElement> | undefined


    const opacity = isDragging ? 0.2 : 1


    return (
        <div className={`col-6 col-xs-6 col-sm-4 col-md-4 col-lg-2 glob-card`}>
            <StyledCard>
                <div ref={dragDropRef}
                    className={`${watchMenuItems && watchMenuItems[index]?.actif ? 'selectedButton card component-card_1' : 'card component-card_1'}`}>
                    <div style={{ opacity }} className={`${isDragging ? 'bg-beige' : ''}`}>
                        {/* Navigation Tesy Button */}
                        <div className="menuItemLi-test" onClick={e => selectButton()} key={`menuItemLi-test-${index}`}>
                            <div className="menuItemBtn-test" style={{ background: background_menu ? background_menu : "#e0f0ef" }}>
                                <div className="contentItem-test"
                                    style={{
                                        borderColor: watchMenuItems ? watchMenuItems[index]?.color : "#009688",
                                        color: watchMenuItems ? watchMenuItems[index]?.color : "#009688",
                                        background: watchMenuItems ? `${watchMenuItems[index]?.bgColor}` : "rgb(0 150 136 / 11%)"
                                    }} key={`btn-test-${index}`}>

                                    {/* icone or ordre de bouton test*/}
                                    <div className="fs-25">
                                        {watchMenuItems ? (
                                            watchMenuItems[index]?.type_icon == "icone" ?
                                                (
                                                    <span key={`icone-${index}`}>
                                                        <i className={watchMenuItems[index]?.icon} ></i>
                                                    </span>
                                                ) :

                                                (<span className="ordre-menu" key={`ordre-${index}`}>
                                                    {watchMenuItems[index]?.ordre ? watchMenuItems[index]?.ordre + 1 : 1}
                                                </span>)
                                        ) : ''}
                                    </div>
                                    {/* libelle de bouton test */}
                                    <div>{watchMenuItems ? watchMenuItems[index]?.libelle : ''}</div>
                                </div>
                            </div>
                        </div>
                        <div className="dnd-button" >
                            <i className="fas fa-border-none"></i>
                            <i className="fas fa-border-none"></i>
                            <i className="fas fa-border-none"></i>
                            <i className="fas fa-border-none"></i>
                        </div>
                        <div className={`card-body ${isDragging ? 'bg-beige' : ''}`}>

                            {/* check collapsed item */}
                            <div className="float-left cursor-pointer" onClick={e => collapsedButtons(watchMenuItems ? !watchMenuItems[index]?.collapsed : true)}>
                                {
                                    watchMenuItems ?
                                        watchMenuItems[index]?.collapsed ?
                                            (<i className="fas fa-caret-down" ></i>) :
                                            (<i className="fas fa-caret-right" ></i>)
                                        : ''
                                }
                            </div>
                            {/* Libelle de boutton */}

                            <div className="card-text text-center mb-2 cursor-pointer"
                                onClick={e => collapsedButtons(watchMenuItems ? !watchMenuItems[index]?.collapsed : true)}>
                                {item.description}
                            </div>

                            {/* form de configuration de bouton */}
                            {
                                watchMenuItems ?
                                    watchMenuItems[index]?.collapsed ?
                                        (
                                            <div className="row">

                                                {/* Libelle de boutton */}
                                                <div className="col-4">Libelle : </div>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm mb-1">
                                                    <input type="hidden" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            {...register(`menuItems.${index}._id`)}
                                                            />
                                                        <input type="text" className="form-control" aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            {...register(`menuItems.${index}.libelle`)}
                                                            />
                                                    </div>
                                                </div>

                                                {/* Type de l'icone */}
                                                <div className="col-4">Type:</div>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm mb-1">
                                                        <div className="n-chk"  >
                                                            <label className="new-control new-radio radio-primary">
                                                                <input id={`icone-value-${index}`} type="radio" className="new-control-input"
                                                                    {...register(`menuItems.${index}.type_icon`)} value="icone"
                                                                    checked={watchMenuItems ? watchMenuItems[index]?.type_icon == 'icone' : false} />
                                                                <span className="new-control-indicator"></span> Icon
                                                            </label>
                                                        </div>
                                                        <div className="n-chk">
                                                            <label className="new-control new-radio radio-primary">
                                                                <input id={`ordre-value-${index}`} type="radio" className="new-control-input"
                                                                    {...register(`menuItems.${index}.type_icon`)} value="ordre"
                                                                    checked={watchMenuItems ? watchMenuItems[index]?.type_icon == 'ordre' : false} />
                                                                <span className="new-control-indicator"></span> Ordre
                                                            </label>
                                                        </div>
                                                    </div>

                                                </div>

                                                {/* ordre */}
                                                {/* <div className="col-4"> Num :</div>
                                                <div className="col-8">
                                                    <div className="input-group input-group-sm mb-1">
                                                        <input type="text" className="form-control"
                                                            aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            {...register(`menuItems.${index}.ordre`)} />
                                                    </div>
                                                </div> */}

                                                {/* ICONE */}
                                                <div className="col-4  mt-1"> Icon :</div>
                                                <div className="col-8  mt-1">
                                                    <div className="input-group input-group-sm mb-1">
                                                        <input type="hidden" className="form-control cursor-pointer"
                                                            aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            {...register(`menuItems.${index}.icon`)} />
                                                    </div>
                                                    <div className="icone-title" onClick={e => {
                                                        setRegisterIndex(index);
                                                        setShowModalIcons(true);
                                                    }}>
                                                        <span>{watchMenuItems ? watchMenuItems[index]?.icon : ''}</span>
                                                        <span className="icone-i" key={`key${index}icon`} >
                                                            <i className="fas fa-pencil-alt"></i>
                                                        </span>
                                                    </div>
                                                </div>

                                                {/* Couleur d'arriere plan du boutton */}
                                                <div className="col-3 mt-2" title="Couleur d'arriére plan"> BG:</div>
                                                <div className="col-3 mt-2">
                                                    <div className="input-group input-group-sm mb-1">
                                                        <input type="color" className="form-control"
                                                            aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            {...register(`menuItems.${index}.bgColor`)}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Couleur du boutton */}
                                                <div className="col-3 mt-2" title="couleur"> C:</div>
                                                <div className="col-3 mt-2">
                                                    <div className="input-group input-group-sm mb-1">
                                                        <input type="color" className="form-control"
                                                            aria-label="Small" aria-describedby="inputGroup-sizing-sm"
                                                            {...register(`menuItems.${index}.color`)} />
                                                    </div>
                                                </div>

                                                <div className="col-6"></div>
                                                <div className="col-6">

                                                    {/* Ajouter Button from menu */}
                                                    {
                                                        watchMenuItems && (_.isUndefined(watchMenuItems[index]?.actif) || !watchMenuItems[index]?.actif) ? (
                                                            <button className="btn btn-primary mt-3 btn-sm float-right" type="button"
                                                                onClick={e => setValue(`menuItems.${index}.actif`, true)}>
                                                                <i className="fas fa-plus"></i> Ajouter
                                                            </button>
                                                        ) : null
                                                    }

                                                    {/* retirer Button from menu */}
                                                    {
                                                        watchMenuItems && watchMenuItems[index]?.actif ? (
                                                            <button className="btn btn-danger mt-3 btn-sm float-right" type="button"
                                                                onClick={e => setValue(`menuItems.${index}.actif`, false)}>
                                                                <i className="fas fa-minus"></i> Retirer
                                                            </button>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        ) :
                                        ''
                                    : ''
                            }

                        </div>
                    </div>
                </div>
            </StyledCard >
        </div >
    )
}

export default MenuFormItem
