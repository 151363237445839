import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  All_autorisations,
  Autorisations,
  checkAll,
  fetchAllAffectations,
  fetchModules,
  fetchUserAutorisations,
  saveRoleAutorisations,
} from "../../_App/Redux/Slices/userAutorisations/userAutorisationsSlice";
import {
  ListUsersRoles,
  fetchUserRoles,
} from "../../_App/Redux/Slices/userRoles/userRoleSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { userAutorisationType } from "../../_App/Types/Entites/UserAutorisationType";
import { UserRoleDataType } from "../../_App/Types/Forms/UserRoleDataType";
import UserRoleForm from "../UserRole/UserRoleForm";
import { useTranslation } from "react-i18next";

const StyledAutorisation = styled.div`
  .matrix-table-container {
    max-width: 800px;
    margin: auto;
  }

  .matrix-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    overflow: hidden;
  }

  .matrix-table th,
  .matrix-table td {
    padding: 10px;
    text-align: center;
    border: 1px solid #b4eee7;
    font-family: "Arial", sans-serif;
  }

  .matrix-table th {
    background-color: #white;
  }

  .cell {
    cursor: pointer;
  }

  .activate {
    background: aliceblue;
    color: green;
  }

  .desactivate {
    background: #ffd8d8;
    color: red;
  }

  .checkbox-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .checkbox-container input {
    position: relative;
    width: 28px;
    height: 19px;
    appearance: none;
    background-color: #ffffff;
    border: 1.5px solid #4caf50;
    border-radius: 40px;
    cursor: pointer;
    transition: background-color 0.9s;
  }

  .checkbox-container input:checked {
    background-color: #4caf50;
  }

  .checkbox-slider {
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border: 2px solid #4caf50;
    border-radius: 50%;
    transition: transform 0.3s;
  }

  .checkbox-container input:checked + .checkbox-slider {
    transform: translateX(20px);
  }

  .checkbox-label {
    margin-top: 5px;
    color: #4caf50;
    font-size: 14px;
  }
  .module-row {
    background-color: #d3f3ef;
  }

  .checkbox-container.horizontal {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .checkbox-label {
    margin-top: 5px;
    color: black;
    font-size: 14px;
  }
`;

const UserAutorisationsTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const userRoles = useAppSelector(ListUsersRoles);
  const [showModal, setShowModal] = useState(false);
  const [selectedUserRole, setSelectedUserRole] =
    useState<UserRoleDataType | null>(null);
  const all_autorisations: userAutorisationType[] =
    useAppSelector(All_autorisations);
  const authorizations = useAppSelector(Autorisations);
  const moduleList = useAppSelector((state) => state.userAutorisation.modules);

  const [checkAllState, setCheckAllState] = useState<{
    [key: string]: boolean;
  }>({});
  const [moduleVisibility, setModuleVisibility] = useState<{
    [moduleId: string]: boolean;
  }>({});
  const [moduleRoleCheckState, setModuleRoleCheckState] = useState<{
    [moduleId: string]: {
      [roleId: string]: boolean;
    };
  }>({});
  const { t } = useTranslation();
  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchUserRoles());
      await dispatch(fetchUserAutorisations());
      await dispatch(fetchAllAffectations());
      await dispatch(fetchModules());
    };

    fetchData();
  }, [dispatch]);

  const handleCheckAll = (id: string, isChecked: boolean) => {
    setCheckAllState((prevState) => ({
      ...prevState,
      [id]: isChecked,
    }));

    if (userRoles.some((role) => role._id === id)) {
      dispatch(checkAll({ role_id: id, isChecked }));
    } else {
      Object.keys(moduleRoleCheckState[id]).forEach((roleId) => {
        handleModuleRoleCheck(id, roleId, isChecked);
      });
    }
  };
  const toggleModuleVisibility = (moduleId: string) => {
    setModuleVisibility((prevVisibility) => ({
      ...prevVisibility,
      [moduleId]: !prevVisibility[moduleId],
    }));
  };

  const getModuleNameById = (moduleId: string) => {
    const module = moduleList.find((module) => module._id === moduleId);
    return module ? module.moduleName : "";
  };

  const modules: { [moduleId: string]: userAutorisationType[] } = {};

  all_autorisations.forEach((autorisation) => {
    const moduleId = autorisation.app_module_id || "default";
    if (!modules[moduleId]) {
      modules[moduleId] = [];
    }
    modules[moduleId].push(autorisation);
  });

  const handleModuleRoleCheck = (
    moduleId: string,
    roleId: string,
    isChecked: boolean
  ) => {
    setModuleRoleCheckState((prevState) => {
      return {
        ...prevState,
        [moduleId]: {
          ...prevState[moduleId],
          [roleId]: isChecked,
        },
      };
    });

    const autorisationsModuleRole = modules[moduleId];

    autorisationsModuleRole.forEach((autorisation) => {
      dispatch(
        saveRoleAutorisations({
          role_id: roleId,
          autorisation: autorisation.autorisation,
          autorisation_id: autorisation._id,
          description: autorisation.description,
          actif: isChecked,
        })
      );
    });
  };

  return (
    <StyledAutorisation>
      <div className="matrix-table-container">
        <table className="matrix-table">
          <thead>
            <tr>
              <th>
                <span style={{ marginRight: "5px" }}></span>
                <i
                  className="fas fa-user-plus"
                  style={{ color: "#0e0adb", cursor: "pointer" }}
                  onClick={() => setShowModal(true)}
                  title="Créer un nouveau rôle"
                ></i>
              </th>
              <UserRoleForm
                ShowModal={showModal}
                setShowModal={setShowModal}
                userRole={selectedUserRole}
              />
              {userRoles.map((role, colIndex) => (
                <th key={colIndex} style={{ width: "150px" }}>
                  <h6 style={{ color: "black" }}>{role.libelle}</h6>
                  <div className="checkbox-container">
                    <input
                      type="checkbox"
                      checked={checkAllState[role._id] || false}
                      onChange={(e) =>
                        handleCheckAll(role._id, e.target.checked)
                      }
                    />
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(modules).map(([moduleId, autorisations]) => (
              <React.Fragment key={moduleId}>
                <tr className="module-row">
                  <td colSpan={1}>
                    <h6 style={{ textAlign: "left", marginLeft: "10px" }}>
                      Module: {getModuleNameById(moduleId)}
                      <i
                        className={`fas ${
                          moduleVisibility[moduleId]
                            ? "fa-chevron-up"
                            : "fa-chevron-down"
                        }`}
                        style={{
                          cursor: "pointer",
                          marginLeft: "30px",
                          color: "black",
                        }}
                        onClick={() => toggleModuleVisibility(moduleId)}
                      ></i>
                    </h6>
                  </td>
                  {userRoles.map((role, roleIndex) => (
                    <td key={roleIndex}>
                      <div className="checkbox-container">
                        <input
                          type="checkbox"
                          checked={
                            moduleRoleCheckState[moduleId]?.[role._id] || false
                          }
                          onChange={(e) =>
                            handleModuleRoleCheck(
                              moduleId,
                              role._id,
                              e.target.checked
                            )
                          }
                        />
                        <div className="checkbox-label">{role.libelle}</div>
                      </div>
                    </td>
                  ))}
                </tr>
                {moduleVisibility[moduleId] &&
                  autorisations.map((autorisation, indexAutorisation) => (
                    <tr key={indexAutorisation}>
                      <td colSpan={1}>
                        <h6>{autorisation.description} </h6>
                      </td>
                      {userRoles.map((role, roleIndex) => {
                        const autorisationState =
                          authorizations[role._id]?.[autorisation._id];
                        return (
                          <td key={roleIndex}>
                            <div
                              className={
                                autorisationState?.actif
                                  ? "activate"
                                  : "desactivate"
                              }
                              onClick={() =>
                                dispatch(
                                  saveRoleAutorisations({
                                    role_id: role._id,
                                    autorisation: autorisation.autorisation,
                                    autorisation_id: autorisation._id,
                                    description: autorisation.description,
                                    actif: !autorisationState?.actif,
                                  })
                                )
                              }
                            >
                              {autorisationState?.actif ? (
                                <i className="fas fa-check"></i>
                              ) : (
                                <i className="fas fa-times"></i>
                              )}
                            </div>
                          </td>
                        );
                      })}
                    </tr>
                  ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </StyledAutorisation>
  );
};

export default UserAutorisationsTable;
