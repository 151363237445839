import UserDisplayComponent from "../../Components/Users/UserDisplayComponent";
import Navbar from "../../_Layouts/Navbar/Navbar";
const UserItemPage = () => {
  return (
    <>
      <Navbar  />
    <div>
      <UserDisplayComponent />
    </div>
    </>
  );
};

export default UserItemPage;
