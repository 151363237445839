import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { DepotSchema } from "../../_App/Schemas/DepotSchema";
import { ListDepotByOrganisation, createDepotsApi, findDepotsBySocieteApi, updateDepotsApi } from "../../_App/Redux/Slices/depot/depotSlice";
import { DepotsType } from "../../_App/Types/Entites/DepotsType";

import {
  SelectedOrganisations,
  societeId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";

export default function DepotsForm({ ShowModal, setShowModal, depots }: any) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const listOrganisations = useAppSelector(SelectedOrganisations);
  const societe = useAppSelector(societeId);
  const [selecteOrganisations, setselecteOrganisations] = useState('');
  // init form hooks
  const formOptions = {
    resolver: yupResolver(DepotSchema) as unknown as Resolver<DepotsType>,
  };
  const listDepotByOrganisation = useAppSelector(ListDepotByOrganisation )
  const { register, handleSubmit, reset, formState } =
    useForm<DepotsType>(formOptions);
  const { errors } = formState;
  useEffect(() => {
    if(societe)dispatch(findDepotsBySocieteApi(societe))
  }, [dispatch]);
  useEffect(() => {
    if (depots) {
      reset({
        _id : depots._id,
        libelle: depots.libelle,
        societe: depots.societe,
        organisation_id: depots.organisation_id,
        defaut: depots.defaut,
      });
    } else {
      reset({
        libelle: "",
        societe: societe,
        organisation_id: listOrganisations ? listOrganisations[0]._id : "",
        defaut: false,

      });
    }
  }, [depots]);
  const verifcationMsg = (old_depot:string, new_depot:string ) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: t(`vous voulez modifier le depots par defauts ${old_depot} de ce organisation  a ${new_depot}?`),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t('Cancel!')

    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const verifcation = ( action:string) => {
    return Swal.fire({
      title: "vous-êtes sûr?",
      text: `vous voulez ${action} le depots`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmer!",
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const onSubmit = async (data: any) => {
    let response;

   
    if (data.organisation_id === "Aucune organisation spécifique") {
      data.organisation_id = null;
    }
    if(data.organisation_id && data.defaut){
      const list_depot = listDepotByOrganisation[data.organisation_id]
      
      if(list_depot&&list_depot.length > 0){
        
        const found_depot_defaut = list_depot.find(item => item.defaut=== true )
        console.log(found_depot_defaut)
        if(found_depot_defaut){
         if( !await verifcationMsg(found_depot_defaut.libelle ,data.libelle)) return
        }
      }
    }
   if(data._id){
      if(await verifcation(t('modifier')))
      response = await dispatch(updateDepotsApi(data));

    }else{
      if(await verifcation(t('ajouter')))

      response = await dispatch(createDepotsApi(data));

    }

    if (response&&response.payload.success) {
      ToastSuccess(t(response.payload.message));
      reset({
        libelle: "",
        societe: societe,
        organisation_id: listOrganisations ? listOrganisations[0]._id : "",
        defaut: false,

      });
      setShowModal(false);
      window.location.reload()
    } else if(response){
      ToastWarning(t(response.payload.message));
    }
  };
  return (
    <Modal 
    show={ShowModal} 
    onHide={() => setShowModal(false)}
    className="modal-2"
    dialogClassName="modal-40w"
    backdropClassName="modal-backdrop-2"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">{t("Ajouter Depots")}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
                <label htmlFor="libelle">
                  {" "}
                  <span style={{ color: "red" }}>*</span>{t('Libelle depots')}
                </label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle ? "is-invalid" : ""
                  }`}
                  id="libellefr"
                  {...register("libelle")}
                />

                <div className="invalid-feedback">
                  {errors.libelle &&
                    errors.libelle.message &&
                    errors.libelle.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="image">{t('Organisation')}</label>
              <div className="input-group mb-3">
                <label htmlFor="organisation_id"></label>
                <select
                  className={`form-control ${
                    errors.organisation_id ? "is-invalid" : ""
                  }`}
                  {...register("organisation_id", {
                    onChange: (e) => setselecteOrganisations(e.target.value),
                  })}
                >
                  <option>{t('aucune organisation spécifique')}</option>
                  {listOrganisations?.map((item: any, index: number) => (
                    <>
                    <option key={index} value={item._id}>
                      {item?.nom}
                    </option></>
                  ))}
                </select>
              </div>
            </div>
          
              <div className="col-12">
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    id="custom-switch"
                    {...register("defaut")}
                    className="form-check-input"
                
                  />
                  <label htmlFor="custom-switch" className="form-check-label">
                    {t('depots par defaut')}
                  </label>
                </div>
              </div>
           
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" className="btn btn-primary-app">
            {t('Annuler')}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t('Enregistrer')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
