//import Item from 'antd/es/list/Item';
import React ,{useState} from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';

export default function ProductMarquesTable(  {ListProductMarque,
    onEditClick,
    deleteitem,
  }: any) {

    const [show, setShow] = useState(false);
    const [image, setImage] = useState('');

    const handleClose = () => setShow(false);
    const handleShow = (item:any) => {
        setImage(item.image[0]); 
        setShow(true);
    }
  
  return (
    <>
    <table className="table table-bordered">
    <thead>
      <tr>
        <th>libelle Fr </th>
        <th>libelle Ar </th>
        <th>libelle En </th>
        <th>show image </th>

        <th className="text-center">Action</th>
      </tr>
    </thead>
    <tbody>
      {ListProductMarque.map((item: any) => (
        <tr key={item._id}>
          <td> {item.libelle.fr}</td>
          <td> {item.libelle.ar}</td>
          <td> {item.libelle.en}</td>
         <td> 
         <Button variant="primary" onClick={()=>handleShow(item)}>
        Show image
      </Button>

        </td>

          <td className="text-center">
          <ul className="table-controls">
              <li onClick={() => onEditClick(item)}>
              <i
                  className="fas fa-pen fa-lg"
                  style={{ color: "#14d8db"}}
                ></i>
              </li>
              <li onClick={() => deleteitem(item._id)}>
              <i
                  className="fas fa-trash-alt fa-lg"
                  style={{ color: "#14d8db" }}
                ></i>
             
              </li>
            </ul>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  <Modal show={show} onHide={handleClose} image={image}>
        <Modal.Header closeButton>
          <Modal.Title>Image</Modal.Title>
        </Modal.Header>
     <Modal.Body>
      <Image src={process.env.REACT_APP_API_BASE_URL + image} fluid />;

     </Modal.Body>
      </Modal>
</>
  )
}
