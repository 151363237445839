import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ProductMarquesForm from "../../../Components/Products/ProductMarques/ProductMarquesForm";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  findProductsMarquesApi,
  ListProductMarques,
} from "../../../_App/Redux/Slices/product_marque/product_marque";
import ProductMarquesTable from "../../../Components/Products/ProductMarques/ProductMarquesTable";
import {
  SelectedOrganisationId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
export default function ProductMarquesPage() {
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);
  const [ShowModalProductMarque, setShowModalProductMarque] = useState(false);
  const [selectedProductMarque, setProductMarque] = useState(null);
  const dispatch = useAppDispatch();
  const ProductMarques = useAppSelector(ListProductMarques);
  useEffect(() => {
    dispatch(findProductsMarquesApi(currentOrganisation));
  }, [dispatch]);

  const openEditForm = (ProductsCaracteristiques: any) => {};
  const deleteitem = (id: any) => {};
  return (
    <div>
      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className="btn btn-primary-app"
          onClick={() => setShowModalProductMarque(true)}
        >
          Create Product Marque
        </button>
      </div>
      <ProductMarquesForm
        ShowModal={ShowModalProductMarque}
        setShowModal={setShowModalProductMarque}
        ProductMarque={selectedProductMarque}
        organisation_id={currentOrganisation}
        societe={societe}
      />
      {ProductMarques && ProductMarques.length !== 0 ? (
        <ProductMarquesTable
          ListProductMarque={ProductMarques}
          onEditClick={openEditForm}
          deleteitem={deleteitem}
        />
      ) : (
        <p> aucune marque</p>
      )}
    </div>
  );
}
