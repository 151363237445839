import { string, boolean, object, bool, number } from "yup";

export const ProductTarifSchema = object().shape({
  libelle: string().required("Libellé obligatoire!!"),
  prix_achat_ttc: number().required("prix_achat_ttc obligatoire"),
  prix_achat_ht: number().required("prix_achat_ttc obligatoire"),
  prix_vente_ttc: number().required("prix_achat_ttc obligatoire"),
  prix_vente_ht: number().required("prix_achat_ttc obligatoire"),
  tva: number().required("prix_achat_ttc obligatoire"),
  tax1: number().required("prix_achat_ttc obligatoire"),
  tax2: number().notRequired(),
  tax3:number().notRequired(),
  organisation_id: string().notRequired(),
});
