import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";

import { ReceptionArticlesType } from "../../../Types/Entites/ReceptionArticles/ReceptionArticlesType";
/**
 * create ReceptionArticles
 * @private
 */

export const createReceptionApi = createAsyncThunk(
  "reception/createReceptionApi",
  async (data:any, { rejectWithValue }) => {
    try {
      const response = await axios.post("reception/createReceptionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * update ReceptionArticles
 * @private
 */

export const updateReceptionApi = createAsyncThunk(
  "reception/updateReceptionApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("reception/updateReceptionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


export const findReceptionbysocieteApi = createAsyncThunk(
  "reception/findReceptionbysocieteApi",
  async (idsociete: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`reception/findReceptionbysocieteApi/${idsociete}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const confirmerReceptionbysocieteApi = createAsyncThunk(
  "reception/confirmerReceptionbysocieteApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`reception/confirmerReceptionbysocieteApi`,data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


export const coloturerReceptionbysocieteApi = createAsyncThunk(
  "reception/coloturerReceptionbysocieteApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put(`reception/coloturerReceptionbysocieteApi`,data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);


  
  const initialState: ReceptionArticlesType[]=[]
  
  export const ReceptionArticleSlice = createSlice({
    name: "reception",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(
        findReceptionbysocieteApi.fulfilled,
         (state, action) => {
      
         return(state = action.payload.reverse())
        }
      );
      builder.addCase(
        createReceptionApi.fulfilled,
         (state, action) => {
      
          state.splice(0, 0, action.payload.data);
        }
      );
      builder.addCase(confirmerReceptionbysocieteApi.fulfilled, (state, action) => {
        const updated_reception = action.payload.data;
        console.log('updated_reception',updated_reception)
        var index = _.findIndex(state, {
          _id: updated_reception._id,
        });
        // Replace item at index using native splice
        state.splice(index, 1, updated_reception);
      });
      builder.addCase(updateReceptionApi.fulfilled, (state, action) => {
        const updated_reception = action.payload.data;
        console.log('updated_reception',updated_reception)
        var index = _.findIndex(state, {
          _id: updated_reception._id,
        });
        // Replace item at index using native splice
        state.splice(index, 1, updated_reception);
      });
      builder.addCase(coloturerReceptionbysocieteApi.fulfilled, (state, action) => {
        const updated_reception = action.payload.data;
        console.log('updated_reception',updated_reception)
        var index = _.findIndex(state, {
          _id: updated_reception._id,
        });
        // Replace item at index using native splice
        state.splice(index, 1, updated_reception);
      });
    },
  })

  
  export const Listreceptions_articles = (state: RootState) =>state.receptions;

export default ReceptionArticleSlice.reducer;
