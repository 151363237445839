import React, { useEffect, useState } from "react";
import TableItem from "./TableItem";
import { Col, Row } from "react-bootstrap";
import { useAppDispatch } from "../../_App/Redux/hooks";
import {
  removeTableApi,
  updateTableApi,
} from "../../_App/Redux/Slices/table/tableSlice";
import TableType from "../../_App/Types/Entites/TableType";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import _ from "lodash";
import ZoneTableForm from "../ZoneTables/ZoneTableForm";
import Swal from "sweetalert2";

export default function TableList({
  ListTable,
  settableitem,
  setshowModelTable,
  listZoneTable,
  t,
  dispatch,
  OrganisationId,
  verifcationMsg

}: any) {
  const [pressedItemId, setPressedItemId] = useState<string | null>(null);
  const [searchKeyword, setSearchKeyword] = useState(""); // État pour stocker la valeur de recherche

  const updateFunction = (item: any) => {
    settableitem(item);
    setshowModelTable(true);
    setPressedItemId(null);
  };
  const activeFunction = async (item: any) => {
    const response = await dispatch(updateTableApi(item));
    if (response.payload.success) {
      ToastSuccess(t(response.payload.message));
    } else {
      ToastWarning(t(response.payload.message));
    }
  };
  const removeFunction = async (id: any) => {
    if(await verifcationMsg(t("Vous voulez supprimer ce table ?"))){
      const response = await dispatch(removeTableApi(id));
      setPressedItemId(null);
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        window.location.reload()
      } else {
        ToastWarning(response.payload.message);
      }
    }
   
  };

  // Fonction handleSearch pour mettre à jour l'état de la valeur de recherche
  const handleSearch = (e: any) => {
    setSearchKeyword(e.target.value);
  };

  // Obtenir les clés de l'objet ListTable
  const tableKeys = Object.keys(ListTable);

  return (
    <div>
      <div className="row mt-5">
        <table className="table">
          <thead>
            <tr>
              <th>{t("Table Number")}</th>
              <th>{t("Chairs")}</th>
              <th>{t("Status")}</th>
              <th>{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {tableKeys.map((key) => (
              <React.Fragment key={key}>
                <tr>
                  <td colSpan={4} className="highlighted-row">
                    Zone{" "}
                    {listZoneTable.find((item: any) => item._id === key)
                      ?.libelle || "no zone"}
                  </td>
                </tr>
                {ListTable[key].map((table: any) => (
                  <tr key={table._id}>
                    <td>
                      {t("Numero")} {table.numero}
                    </td>
                    <td>{table.nmb_chaise}</td>
                    <td >
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          id={`custom-switch-${table._id}`}
                          checked={table.active}
                          className="form-check-input "
                        
                          
                        />
                       
                      </div>
                    </td>
                    <td>
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                        onClick={() => removeFunction(table._id)}
                      >
                        {" "}
                        <i
                          className="fas fa-trash-alt"
                          style={{ color: "#FF0000" }}
                        ></i>
                      </span>

                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                        onClick={() => updateFunction(table)}
                      >
                        <i
                          className="fas fa-edit"
                          style={{ color: "#06958d" }}
                        ></i>
                      </span>
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
            <tr >
             <td colSpan={4}><ZoneTableForm verifcationMsg={verifcationMsg} organisationId={OrganisationId}/></td> 
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}

/*

   <div className="row mr-8">
        <div className="col-3 ">
          <input
            type="text"
            className="form-control product-search br-30 "
            id="input-search"
            placeholder="chercher par numero de table"
            onChange={handleSearch} // Appeler handleSearch lors de la modification du champ de recherche
          />
        </div>

        <div className="col-2 mt-2">
          <button className=" btn btn-primary-app">{t("chercher")}</button>
        </div>
      </div>
 */

/*{list_Table.map((item: any) => (
    <div key={item._id} className="col-md-2 ">
      <TableItem
        table={item}
        updateFunction={updateFunction}
        removeFunction={removeFunction}
        isPressed={pressedItemId === item._id}
        activeFunction={activeFunction}
        setPressedItemId={setPressedItemId}
      />
    </div>
  ))}*/
