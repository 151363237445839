import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { UserRole } from "../../../Types/Entites/UserRole";
import { UserRoleDataType } from "../../../Types/Forms/UserRoleDataType";

export const fetchUserRoles = createAsyncThunk(
  "role/findAllRolesApi",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/role/findAllRolesApi");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


/**
 * get list of roles with users.
 * @private
 */
export const RolesWithUsersApi = createAsyncThunk("role/RolesWithUsersApi", async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("role/RolesWithUsersApi");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create role.
 * @private
 */
export const createUserRoleApi = createAsyncThunk( "role/createRoleApi", async (newRole: UserRoleDataType, { rejectWithValue }) => {
    try {
      const response = await axios.post("/role/createRoleApi", newRole);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const updateRoleApi = createAsyncThunk(
  "role/updateRoleApi",
  async (updatedRole: UserRoleDataType, { rejectWithValue }) => {
    try {
      const response = await axios.put("/role/updateRoleApi", updatedRole);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const deleteRoleApi = createAsyncThunk(
  "role/deleteRoleApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/role/deleteRoleApi/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

const initialState: {
  roles : UserRole[],
  rolesWithUsers : UserRole[],
} = {
  roles: [],
  rolesWithUsers: [],
};

const userRoleSlice = createSlice({
  name: "userRoles",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoles.fulfilled, (state, action) => {
         state.roles = action.payload;
      })
      .addCase(createUserRoleApi.fulfilled, (state, action) => {
        state.roles.push(action.payload.data);
      })
      .addCase(createUserRoleApi.rejected, (state, action) => {
        console.error(action.payload);
      })
      .addCase(updateRoleApi.fulfilled, (state, action) => {
        const updatedRoleIndex = state.roles.findIndex(
          (role) => role._id === action.payload.data._id
        );
        if (updatedRoleIndex !== -1) {
          state.roles[updatedRoleIndex] = action.payload.data;
        }
      })
      .addCase(deleteRoleApi.fulfilled, (state, action) => {
        state.roles = state.roles.filter((role) => role._id !== action.payload.data._id);
      })
      
      .addCase(RolesWithUsersApi.fulfilled, (state, action) => {
        state.rolesWithUsers = action.payload
      });
      
  },
});

export const ListUsersRoles = (state: RootState) => state.roles.roles;
export const ListRolesWithUsers = (state: RootState) => state.roles.rolesWithUsers;

export default userRoleSlice.reducer;