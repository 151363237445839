import React, { useEffect, useState } from "react";
import FournisseurForm from "../../../Components/Products/Fournisseur/FournisseurForm";
import { SelectedOrganisationId ,SelectedOrganisations,societeId} from "../../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { ListFournisseurs, deleteFournisseurApi, findFourinsseurBySocieteApi } from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import FournisseurTable from "../../../Components/Products/Fournisseur/FournisseurTable";
import { ListTaxTva, findTaxTvaApi } from "../../../_App/Redux/Slices/tax/taxSlice";
import Navbar from "../../../_Layouts/Navbar/Navbar";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
export default function FournisseurPage() {
  const { t } = useTranslation();
  const listOrganisations = useAppSelector(SelectedOrganisations);

  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const listFounisseur = useAppSelector(ListFournisseurs)
  const societe = useAppSelector(societeId);
  const tvalist = useAppSelector(ListTaxTva)
  const dispatch = useAppDispatch();
  const [ShowModalFournisseur, setShowModalFournisseur] = useState(false);
  const [fournisser, setfounisseur] = useState(null);

  useEffect(() => {
    if(societe) {
    dispatch(findFourinsseurBySocieteApi(societe));
    dispatch(findTaxTvaApi(societe))
    
    }

  }, [societe]);
  
  useEffect(() => {
    if(!ShowModalFournisseur){
      setfounisseur(null)
    }
  }, [ShowModalFournisseur]);

  const verifcationMsg = ( ) => {
    return Swal.fire({
      title: "vous-êtes sûr?",
      text: `vous voulez supprimer le fournisseur?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmer!",
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const deletedfournisseur = async(id:string)=>{
    if(await verifcationMsg()){
   const response = await dispatch(deleteFournisseurApi(id))
   console.log(response)
   if(response.payload.success ){
      ToastSuccess(t('depot supprimé avec succès'))
   }else{
    ToastWarning(response.payload.message)
   }}
  }

  return (
    <div>
      <Navbar/>
       <button
        className="btn btn-primary-app"
        onClick={() => {
          setfounisseur(null);
          setShowModalFournisseur(true);
        }}
      >
        {t('Ajouter Fournisseur')}
      </button>
      <FournisseurForm 

        ShowModal={ShowModalFournisseur}
        setShowModal={setShowModalFournisseur}
        ProductCaracteristique={null}
        organisation_id={currentOrganisation}
        societe={societe}
        Fournisseur={fournisser}
      />
      <FournisseurTable 
      t={t}
      listFounisseur={listFounisseur} 
      tvalist={tvalist} 
      societe={societe} 
      deletedfournisseur={deletedfournisseur}
      setfounisseur={setfounisseur}
      setShowModalFournisseur={setShowModalFournisseur}
      listOrganisations={listOrganisations}
      />
    </div>
  );
}
