import React, { useState } from 'react'
import DemandeTransfert from '../../Components/TransfertArticles/DemandeTransfert'
import ApproverTransfert from '../../Components/TransfertArticles/ApproverTransfert'
import ListTransfertCree from '../../Components/TransfertArticles/ListTransfertCree'

export default function DemandeReception() {
    const [showModal , setshowModal]=useState(true)
  return (
    <div>
      <ApproverTransfert setShowModal={setshowModal} ShowModal={showModal} />
    </div>
  )
}
//<DemandeTransfert setShowModal={setshowModal} ShowModal={showModal}/>
//      <ApproverTransfert setShowModal={setshowModal} ShowModal={showModal} />
//        <ListTransfertCree setShowModal={setshowModal} ShowModal={showModal}/>
