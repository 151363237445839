import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListFournisseurs,
  findFourinsseurBySocieteApi,
} from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import {
  ListProduits,
  findProductBySocieteApi,
} from "../../../_App/Redux/Slices/produits/produitSlice";
import { Produit } from "../../../_App/Types/Entites/Produit";
import { Modal } from "react-bootstrap";

export default function ProduitReferenceForm({
  ShowModal,
  setShowModal,
  societe,
  setValue,
  listproducts,
  changeprice,
  listtaxtva,
}: any) {
  const dispatch = useAppDispatch();
  const listFournisseur = useAppSelector(ListFournisseurs);
  const [selectedfournisseur, setselectedfournisseur] = useState("");
  const [list_product, setlist_product] = useState<Produit[]>([]);
  const [selectedproduit, setselectedproduit] = useState("");
  useEffect(() => {
    dispatch(findFourinsseurBySocieteApi(societe));
    setselectedfournisseur("");
    setlist_product([]);
  }, []);
  const onselectFournisseur = (idfournisseur: string) => {
    setselectedfournisseur(idfournisseur);
    const filtredproduct = listproducts.filter(
      (item: Produit) =>
        item.fournisseurs === idfournisseur && item.article_id === null
    );
    setlist_product(filtredproduct);
  };

  const selectproduit = (item: any) => {
    setselectedproduit(item._id);
    setValue("produit_ref", item._id);
    setValue("tva", item.tva);
    const selectedItem = listtaxtva.find(
      (element: any) => element._id === item.tva
    );

    if (selectedItem) {
      changeprice("tva", selectedItem.taux);
    }
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-2"
      dialogClassName="modal-40w"
      backdropClassName="modal-backdrop-2"
    >
      <div className="row mt-3">
        <div className="col-5 ml-5">
          <span> choisir fournisseur</span>
          <ul className="list-group mt-3 ">
            {listFournisseur.map((item: any) => (
              <>
                <li
                  key={item._id}
                  className={`list-group-item ${
                    item._id === selectedfournisseur ? "active" : null
                  } `}
                  onClick={() => {
                    onselectFournisseur(item._id);
                  }}
                >
                  {item.libelle}
                </li>
              </>
            ))}
          </ul>
        </div>

        <div className="col-5 ">
          <span> choisir produit</span>
          <ul
            className="list-group mt-3"
            style={{ height: "300px", overflow: "auto" }}
          >
            {list_product.map((item: any) => (
              <>
                <li
                  key={item._id}
                  className={`list-group-item ${
                    item._id === selectedproduit ? "active" : null
                  }`}
                  onClick={() => {
                    selectproduit(item);
                  }}
                >
                  {item.titre.fr}
                  {": "}
                  {item.type}
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>
    </Modal>
  );
}
