import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { OrderType } from "../../../Types/Entites/Orders/OrderType";

interface CaisseState {
  payment: {
    givenAmount: number;
  };
  filter: {
    table: string;
    filteredBy: any;
    rightSideSelectedProduct: any;
  };
  display: {
    colLeft: string;
    containerLeft: string;
    displayColLeft: string;
    colRight: string;
    containerRight: string;
  };
  layout: {
    marginLeft: string;
    LeftBarDisplay: string;
    collapseButtonMargin: string;
  };
  selectedTable: any;
  selectedFullTable: any;
  selectedOrder: OrderType | null;
}

const initialState: CaisseState = {
  payment: {
    givenAmount: 0,
  },
  filter: {
    table: "products", // carte/ categorie / souscategorie // produits
    filteredBy: {},
    rightSideSelectedProduct: "",
  },
  display: {
    colLeft: "col-xl-6 col-lg-12 col-md-6 col-sm-12",
    containerLeft: "col-4",
    displayColLeft: "none",
    colRight: "col-xl-2 col-lg-3 col-md-4 col-sm-6",
    containerRight: "col-12",
  },
  layout: {
    marginLeft: "0px",
    LeftBarDisplay: "none",
    collapseButtonMargin: "0px",
  },
  selectedTable: null,
  selectedFullTable: null,
  selectedOrder: null,
};

const CaisseSlice = createSlice({
  name: "Caisse",
  initialState,
  reducers: {
    payment: (
      state,
      action: PayloadAction<{
        givingAmount: number;
      }>
    ) => {
      const { givingAmount } = action.payload;

      return {
        ...state,
        payment: {
          givenAmount: givingAmount,
        },
      };
    },

    selectTable: (
      state,
      action: PayloadAction<{
        table: any;
      }>
    ) => {
      const { table } = action.payload;

      return {
        ...state,
        selectedTable: table,
      };
    },
    selectFullTable: (
      state,
      action: PayloadAction<{
        table: any;
      }>
    ) => {
      const { table } = action.payload;

      return {
        ...state,
        selectedFullTable: table,
      };
    },

    resetSelectedTable: (state) => {
      return {
        ...state,
        selectedTable: initialState.selectedFullTable,
      };
    },
    resetSelectedFullTable: (state) => {
      return {
        ...state,
        selectedFullTable: initialState.selectedFullTable,
      };
    },
    changeFilter: (
      state,
      action: PayloadAction<{
        table: string;
        filteredBy: any;
        rightSideSelectedProduct: any;
      }>
    ) => {
      const { table, filteredBy, rightSideSelectedProduct } = action.payload;

      return {
        ...state,
        filter: {
          table,
          filteredBy,
          rightSideSelectedProduct,
        },
      };
    },
    changeDisplay: (
      state,
      action: PayloadAction<{
        colLeft: string;
        containerLeft: string;
        displayColLeft: string;
        colRight: string;
        containerRight: string;
      }>
    ) => {
      const {
        colLeft,
        containerLeft,
        colRight,
        displayColLeft,
        containerRight,
      } = action.payload;

      return {
        ...state,
        display: {
          colLeft,
          containerLeft,
          displayColLeft,
          colRight,
          containerRight,
        },
      };
    },
    closeLeftBar: (
      state,
      action: PayloadAction<{
        marginLeft: string;
        LeftBarDisplay: string;
        collapseButtonMargin: string;
      }>
    ) => {
      const { marginLeft, LeftBarDisplay, collapseButtonMargin } =
        action.payload;

      return {
        ...state,
        layout: {
          marginLeft,
          LeftBarDisplay,
          collapseButtonMargin,
        },
      };
    },
  },
});

export const {
  payment,
  changeFilter,
  changeDisplay,
  closeLeftBar,
  selectTable,
  resetSelectedTable,
  selectFullTable,
  resetSelectedFullTable,
} = CaisseSlice.actions;
export const Filter = (state: RootState) => state.caisse.filter;
export const Display = (state: RootState) => state.caisse.display;
export const layout = (state: RootState) => state.caisse.layout;
export const selectedTable = (state: RootState) => state.caisse.selectedTable;
export const selectedFullTable = (state: RootState) =>
  state.caisse.selectedFullTable;

export default CaisseSlice.reducer;
