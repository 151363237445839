import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { ProductsCouleurType } from "../../../Types/Entites/ProductCouleurType";
import _ from "lodash";
import { ProductImageType } from "../../../Types/Entites/ProductIlmageType";

/**
 * Get list of ProductCaracteristique.
 * @public
 */
export const findProductImageApi = createAsyncThunk(
  "productimage/findProductImageApi",
  async (data: null, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productimage/findProductImageApi`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);





const initialState: ProductImageType [] = []
export const ProductImageSlice = createSlice({
  name: "productimage",
  initialState: initialState,
  reducers: {
     
  },
  extraReducers: (builder) => {
    builder.addCase(findProductImageApi.fulfilled, (state, action) => {
      return state = action.payload;
    });

 
  },
});

export const ListProductimage = (state: RootState) => state.productimages

export default ProductImageSlice.reducer;
