import React, { useEffect, useState } from "react";
import {
  closeLeftBar,
  layout,
  resetSelectedTable,
} from "../../_App/Redux/Slices/Caisse/CaisseSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { useTranslation } from "react-i18next";
import { userLang } from "../../_App/Redux/Slices/Auth/authSlice";
import _ from "lodash";
import LanguageSwitcher from "../../Shared/LanguageSwitcher/LanguageSwitcher";

const NavbarCaisse = ({ Table, Title }: any) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const Layout = useAppSelector(layout);
  const handleCloseLeftBar = async ({
    marginLeft,
    LeftBarDisplay,
    collapseButtonMargin,
  }: any) => {
    dispatch(
      closeLeftBar({
        marginLeft: marginLeft,
        LeftBarDisplay: LeftBarDisplay,
        collapseButtonMargin: collapseButtonMargin,
      })
    );
  };
  const langue = useAppSelector(userLang);

  return (
    <div className="">
      <div className="content-container">
        <div className="col-left layout-top-spacing">
          <div className="col-left-content">
            <div
              className="header-container"
              style={{
                marginLeft: Layout.collapseButtonMargin,
                width: "100%",
              }}
            >
              <header className="header navbar navbar-expand-sm">
                <div className="d-flex col-8">
                  <button
                    className="sidebarCollapse"
                    onClick={(e) => {
                      handleCloseLeftBar({
                        marginLeft:
                          Layout && Layout.marginLeft === "0px"
                            ? "200px"
                            : "0px",
                        LeftBarDisplay:
                          Layout && Layout.LeftBarDisplay === "none"
                            ? "unset"
                            : "none",
                        collapseButtonMargin:
                          Layout && Layout.collapseButtonMargin === "180px"
                            ? "0px"
                            : "180px",
                      });
                    }}
                  >
                    <div className="bt-menu-trigger">
                      <span></span>
                    </div>
                  </button>
                  <div className="page-header">
                    <div className="page-title">
                      <h3
                        onClick={(e) =>
                          console.log("langue in redux : ", langue)
                        }
                      >
                        {t(Title)}
                      </h3>
                    </div>
                  </div>
                  {Table ? (
                    <h6 style={{ margin: "9px 42px 0px 100px" }}>
                      table : {Table.numero}{" "}
                      <i
                        className="fas fa-times-circle"
                        onClick={(e) => dispatch(resetSelectedTable())}
                        style={{ color: "#e7515a" }}
                      ></i>
                    </h6>
                  ) : null}
                </div>

                <div className="header-actions">
                  <LanguageSwitcher />
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavbarCaisse;
