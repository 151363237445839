import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { changeDisplay } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";
import { ActiveMenu } from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import CaisseArticlesItem from "./CaisseArticlesItem";
import { DepotDefautOrganisation } from "../../../_App/Redux/Slices/depot/depotSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { findStockByDepot } from "../../../_App/Redux/Slices/article/articleSlice";

const CaisseArticlesList = ({
  productsDetailsList,
  display,
  parentProduct,
  setlist_Article,
  list_Article,
  index,
  setindex,
}: any) => {
  const Menu = useAppSelector(ActiveMenu);
  const dispatch = useAppDispatch();

  return (
    <div>
      <div className="row" id="cancel-row">
        <div className="col-lg-12 layout-spacing layout-top-spacing">
          <div
            className="statbox widget box box-shadow"
            style={{ padding: "0px" }}
          >
            <div className="widget-header">
              <div className="row">
                <div className="col-xl-12 col-md-12 col-sm-12 col-12">
                  <h6
                    style={{
                      padding: "0px",
                      margin: "33px 0px",
                      fontSize: "14px",
                      fontWeight: "bold",
                    }}
                  >
                    {parentProduct ? parentProduct.titre.fr : "Tous piéces"}{" "}
                    {/* <i
                      className="fas fa-columns left-side-trigger"
                      onClick={(e) => {
                        handleDisplayChange({
                          colLeft:
                            display &&
                            display.colLeft ===
                              "col-xl-6 col-lg-12 col-md-6 col-sm-12"
                              ? "col-xl-12 col-lg-12 col-md-12 col-sm-12"
                              : "col-xl-6 col-lg-12 col-md-6 col-sm-12",
                          containerLeft:
                            display && display.containerLeft === "col-4"
                              ? "col-2"
                              : "col-4",
                          colRight:
                            display &&
                            display.colRight ===
                              "col-xl-4 col-lg-6 col-md-4 col-sm-6"
                              ? "col-xl-3 col-lg-6 col-md-3 col-sm-6"
                              : "col-xl-4 col-lg-6 col-md-4 col-sm-6",
                          containerRight:
                            display && display.containerRight === "col-8"
                              ? "col-10"
                              : "col-8",
                        });
                      }}
                    ></i> */}
                  </h6>
                </div>
              </div>
              <div
                className="row layout-top-spacing"
                style={{
                  overflowY: "auto",
                  overflowX:'hidden',
                  maxHeight:
                    Menu?.menuPosition.position === "left"
                      ? "calc(81vh - 43px)"
                      : "calc(81vh - 135px)",
                  minHeight:
                    Menu?.menuPosition.position === "left"
                      ? "calc(81vh - 43px)"
                      : "calc(81vh - 135px)",
                  alignContent: "baseline",
                }}
              >
                {productsDetailsList && productsDetailsList.length > 0 ? (
                  <>
                    {list_Article &&
                      list_Article.map((item: any) => (
                        <CaisseArticlesItem
                          key={item._id}
                          Item={item}
                          col={display?.colLeft}
                          parentProduct={parentProduct}
                        />
                      ))}
                  </>
                ) : (
                  <>
                    <p>no data found</p>
                  </>
                )}
              </div>
              <div className="row">
                <div className="pagination-no_spacing">
                  <ul className="pagination">
                    <li>
                      <a onClick={() => setindex(index + 10)} className="prev">
                        {" "}
                        <i className="fas fa-chevron-left"></i>{" "}
                      </a>{" "}
                    </li>
                    <li>
                      <a onClick={() => setindex(index - 10)} className="next">
                        <i className="fas fa-chevron-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CaisseArticlesList;
