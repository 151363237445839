import React from 'react'
import { UseFormSetValue, UseFormWatch } from 'react-hook-form'
import styled from 'styled-components'
import AppMenuItemsType from '../../../../_App/Types/Entites/AppMenus/AppMenuItems'
import AppMenuParametrageType from '../../../../_App/Types/Entites/AppMenus/AppMenuParametrageType'

const StyledDiv = styled.div`
    .menu_config_buttons{
        display: flex;
        justify-content: space-arround;
    }
    .menu_config_buttons > div{
        width: 250px;
        margin-bottom: 20px;

    }
`
const MenuFormConfigs = ({ setValue, watch, pages }: {
    watch: UseFormWatch<AppMenuParametrageType>,
    setValue: UseFormSetValue<AppMenuParametrageType>,
    pages: AppMenuItemsType[]
}) => {

    const watchMenuItems: AppMenuItemsType[] | undefined = watch('menuItems');


    /**
     * check all buttons
     * @param actif boolean
     */
    const checkAllButtons = (actif: boolean) => {
        if (watchMenuItems) {
            setValue("menuItems", watchMenuItems?.map((item: AppMenuItemsType) => {
                return {
                    ...item,
                    actif: actif
                }
            }))
        }
    }

   

    /**
     * change icon or ordre
     * @param type_icon string
     */
    const selectTypeIcon = (type_icon: string) => {
        if (watchMenuItems) {
            setValue("menuItems", watchMenuItems?.map((item: AppMenuItemsType) => {
                return {
                    ...item,
                    type_icon: type_icon
                }
            }))
        }
    }

    /**
     * close or open details buttons
     * @param collapsed boolean
     */
    const collapsedButtons = (collapsed: boolean) => {
        if (watchMenuItems) {
            setValue("menuItems", watchMenuItems?.map((item: AppMenuItemsType) => {
                return {
                    ...item,
                    collapsed: collapsed
                }
            }))
        }
    }

    /**
     * revenir a l'etat par defaut
     */
    const retablirDefaultConfig = () => {
        setValue("menuItems", [...pages])
        setValue("menuPosition.bgColor",  "#e0f0ef")
        setValue("menuPosition.colorButtons",  "#009688")
        setValue("menuPosition.bgColorButtons",  "#e0f0ef")
    }

    return (
        <StyledDiv>
            <div className="row menu_config_buttons">
                <div className="">
                    <button className="btn btn-success btn-block btn-primary-app" type="button" onClick={e => checkAllButtons(true)}>
                        <i className="fas fa-check-double"></i> Choisir tous les boutons
                    </button>
                </div>
                <div className="">
                    <button className="btn btn-danger btn-block btn-danger-app" type="button" onClick={e => checkAllButtons(false)}>
                        <i className="fas fa-times"></i> Retirer tous les boutons
                    </button>
                </div>
                <div className="">
                    <button className="btn btn-warning btn-block btn-warning-app" type="button" onClick={e => selectTypeIcon('icone')}>
                        <i className="fas fa-icons"></i> Utiliser que les icones
                    </button>
                </div>
                <div className="">
                    <button className="btn btn-warning btn-block btn-warning-app" type="button" onClick={e => selectTypeIcon('ordre')}>
                        <i className="fas fa-sort-numeric-up-alt"></i> Utiliser que les Numeros
                    </button>
                </div>
                <div className="">
                    <button className="btn btn-info btn-block btn-info-app" type="button" onClick={e => collapsedButtons(true)}>
                        <i className="fas fa-folder-open"></i> Ouvrir details
                    </button>
                </div>
                <div className="">
                    <button className="btn btn-info btn-block btn-info-app" type="button" onClick={e => collapsedButtons(false)}>
                        <i className="fas fa-folder-minus"></i> Fermer détails
                    </button>
                </div>
                <div className="">
                    <button className="btn btn-info btn-block btn-info-app" type="button" onClick={e => retablirDefaultConfig()}>
                        <i className="fas fa-palette"></i> Menu Par défaut
                    </button>
                </div>
            </div>
        </StyledDiv>
    )
}

export default MenuFormConfigs
