import { array, boolean, number, object, string } from 'yup';
// import { array, number, ref, object, string, mixed, BooleanSchema, boolean, bool } from 'yup';


export const AppParametrageMenuSchema  = object({
    _id : string().optional(),
    user_id : string().optional().nullable(),
    libelle: string().required('Libelle Obligatoire!!'),
    menuPosition: object().shape({
        position: string().required('Position Obligatoire!!'),
        bgColor: string().required("Couleur d'arriére plan Obligatoire!!"),
        colorButtons : string().optional(),
        bgColorButtons : string().optional()
    }),
    menuItems: array().defined()
    
    // array(object().shape({
    //     actif: boolean().optional(),
    //     libelle: string().required(),
    //     description: string().required(),
    //     link: string().optional(),
    //     type: string().required(),
    //     icon: string().required(),
    //     type_icon: string().required(),
    //     componentName: string().optional().nullable(),
    //     color: string().optional(),
    //     bgColor: string().optional(),
    //     module: string().optional(),
    //     moduleName: string().optional(),
    //     module_name: string().optional(),
    //     ordre: number(),
    //     collapsed: boolean().optional(),
    // }))
});