import { string, boolean, object, mixed } from "yup";

export const SousCategorieSchema = object({
  libelle: object().shape({
    fr: string().required("Libellé (fr) obligatoire!!"),
    en: string().required("Libellé (en) obligatoire!!"),
    ar: string().required("Libellé (ar) obligatoire!!"),
  }),

  image: mixed().optional().nullable(),
  categorie_id: string(),
});
