import React from 'react'
import CreationMatrice from '../../../Components/Products/Matrice/CreationMatrice'
import { useTranslation } from 'react-i18next';

export default function Matrice() {
    const { t } = useTranslation();

  return (
    <div>
      <CreationMatrice t={t} />
    </div>
  )
}
