import React, { useState,useEffect } from 'react';
import { findSousCategoriesApi ,ListSousCategories ,updatepagination,PaginationSousCategories } from '../../../../_App/Redux/Slices/souscategories/souscategorieSlice';
import { useAppDispatch , useAppSelector} from '../../../../_App/Redux/hooks';
import { useParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import SousCategorieItem from './SousCategorieItem';
import Pagination from '../../../../Shared/Pagination/Pagination';
import { SousCategorie } from '../../../../_App/Types/Entites/SousCategorie';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { PaginationType } from '../../../../_App/Types/Entites/PaginationType';
import Header from '../../../../Shared/Header/Header';


interface PageProps {
  id: string | undefined
}

export default function SousCategorieList({id}:PageProps) {

  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const souscategories = useAppSelector(ListSousCategories);
  const [souscategorielist, setSousCategorielist] = useState<SousCategorie[]>([]);
  const paginationcontroller = useAppSelector(PaginationSousCategories); 

  const [numColumns, setNumColumns] = useState(paginationcontroller.NumberColone); 
  const [numitemPage, setNumitemPage] = useState(paginationcontroller.itemsPerPage); 
  useEffect(() => {
    const idOrganisation = localStorage.getItem('idOrganisation') || "63e1130f9c17708ead8be889";
    // dispatch(findSousCategoriesApi(idOrganisation));

  }, [dispatch]);

  useEffect(() => {
    if(id!== undefined){
      const categoriesfilter = souscategories.filter((categorieitem : SousCategorie) => categorieitem.categorie_id === id);
      setSousCategorielist(categoriesfilter)
    }else{
      setSousCategorielist(souscategories);
    }
  }, [souscategories]);

  const GetProductBySousCategorie = (souscategorieId: String) => {
  
    navigate(`/ventes/souscategorie/${souscategorieId}`);

  };




  //pagination et parametres

  const handleNumColumnsChange = (numbcol : number ) => {
    const newNumColumns = numbcol;

    const PaginationData: PaginationType = {
      itemsPerPage: numitemPage ? numitemPage : 0, 
      NumberColone: newNumColumns ? newNumColumns : 0 ,  
    }
    dispatch(updatepagination(PaginationData));

    setNumColumns(newNumColumns);
  };
 const handleNumitemChange = (numbitem : number ) => {
    const newNumitems = numbitem ;
    
    const PaginationData: PaginationType = {
          itemsPerPage: newNumitems ? newNumitems : 0, 
          NumberColone: numColumns ?numColumns : 0,  
        }
    dispatch(updatepagination(PaginationData));
    setNumitemPage(newNumitems);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * numitemPage;
  const indexOfFirstItem = indexOfLastItem - numitemPage;
  const currentItems = souscategorielist.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(souscategorielist.length / numitemPage);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const searchByKeyword =(keyword: string) =>{
    // Utilisez la méthode `filter` pour filtrer les éléments qui correspondent au mot-clé
  
    return [];
  }
  return (
    <> 
    
    <Header handleNumColumnsChange={handleNumColumnsChange}  handleNumitemChange={handleNumitemChange} searchByKeyword={searchByKeyword}/>
    <div className='mt'>
       <Container>
        <Row>
        {currentItems.map((souscategorieItem, index) => (
              <Col key={index} xs={12 / numColumns}>

          <div key={index}>
             <SousCategorieItem
              souscategorie={souscategorieItem}
              onClick={() => GetProductBySousCategorie(souscategorieItem._id)}
            />
          </div>
          </Col>

        ))}
     </Row>

   </Container>
     
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
        </div>
    </>


  );
}
