import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { ProductsCouleurType } from "../../../Types/Entites/ProductCouleurType";
import _ from "lodash";

/**
 * Get list of ProductCaracteristique.
 * @public
 */
export const findProductCouleurApi = createAsyncThunk(
  "productcouleur/findProductCouleurApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productcouleur/findProductCouleurApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create Products Couleur function
 * @private
 */
export const createProductCouleurApi = createAsyncThunk(
  "productcouleur/createProductCouleurApi",
  async (data: ProductsCouleurType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "productcouleur/createProductCouleurApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Update Product Couleur
 * @private
 */
export const updateProductCouleurApi = createAsyncThunk(
  `productcouleur/updateProductCouleurApi`,
  async (data: ProductsCouleurType, { rejectWithValue, dispatch }) => {
    try {
 
      const response = await axios.put(
        "productcouleur/updateProductCouleurApi",
        data
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Delete   Product Couleur
 * @private
 */
export const deleteProductCouleurApi = createAsyncThunk(
  "productcouleur/deleteProductCouleurApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `productcouleur/deleteProductCouleurApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


export const updateLocalProductCouleur = (updatedProductCouleur: ProductsCouleurType[]) => (
  dispatch: any
) => {
  dispatch(couleursActions.updateLocalProductCouleur(updatedProductCouleur));
};
export const storeProductCouleur = (storeProductCouleur: ProductsCouleurType[]) => (
  dispatch: any
) => {
  dispatch(couleursActions.storeProductCouleur(storeProductCouleur));
};

interface ProductColorState {
  listProductColor: ProductsCouleurType[];
  listSelectedProductColor: ProductsCouleurType[];
  listStoreProductColor: ProductsCouleurType[];
}
const initialState: ProductColorState= {
  listProductColor: [],
  listSelectedProductColor: [],
  listStoreProductColor: [],
};

export const ProductCouleurSlice = createSlice({
  name: "productcouleur",
  initialState: initialState,
  reducers: {
    updateLocalProductCouleur: (state, action) => void(state.listSelectedProductColor = action.payload),
    storeProductCouleur: (state, action) => void(state.listStoreProductColor = action.payload)

  },
  extraReducers: (builder) => {
    builder.addCase(findProductCouleurApi.fulfilled, (state, action) => {
      state.listProductColor = action.payload;
    });

    builder.addCase(createProductCouleurApi.fulfilled, (state, action) => {
      state.listProductColor.splice(0, 0, action.payload.data);
    });

    builder.addCase(updateProductCouleurApi.fulfilled, (state, action) => {
      const updated_productcouleur = action.payload.data;
      var index = _.findIndex(state.listProductColor, { _id: updated_productcouleur._id });
      // Replace item at index using native splice
      state.listProductColor.splice(index, 1, updated_productcouleur);
    });
    builder.addCase(deleteProductCouleurApi.fulfilled, (state, action) => {
      const deleted_productcouleur = action.payload.data;
      state.listProductColor = _.remove(state.listProductColor, { _id: deleted_productcouleur._id });
    });
  },
});
export const couleursActions = ProductCouleurSlice.actions;

export const ListProductCouleurs = (state: RootState) => state.productcouleurs.listProductColor;
export const ListSelectedProductCouleurs = (state: RootState) => state.productcouleurs.listSelectedProductColor;
export const ListStoreProductColor = (state: RootState) => state.productcouleurs.listStoreProductColor;


export default ProductCouleurSlice.reducer;
