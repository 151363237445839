import React, { useEffect, useState } from 'react'
import InventaireForm from '../../Components/Inventaire/InventaireForm'
import { Navbar } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import InventaireTable from '../../Components/Inventaire/InventaireTable';
import { useAppDispatch, useAppSelector } from '../../_App/Redux/hooks';
import { societeId } from '../../_App/Redux/Slices/Auth/authSlice';
import { Listinventaire, Listinventairedetails, findInventaireApi, findInventaireDetailsApi } from '../../_App/Redux/Slices/inventaire/inventaireSlice';
import InventaireHeader from '../../Components/Inventaire/InventaireHeader';
import { InventaireType } from '../../_App/Types/Entites/Inventaire/InventaireType';

export default function InventairePage() {
    const { t } = useTranslation();
    const dispatch=useAppDispatch()
    const [ShowModalInventaire , setshowModalInventaire]=useState(false)
    const societe = useAppSelector(societeId)
    const list_inventaires = useAppSelector(Listinventaire)
    const list_inventaire_details = useAppSelector(Listinventairedetails)
    const [filtred_list ,setlistfiltred] = useState<InventaireType[]>([])
    useEffect(() => {
      if(list_inventaires.length !== 0){
        console.log([...list_inventaires].reverse())
        setlistfiltred([...list_inventaires].reverse())

      }
    }, [list_inventaires]);
    useEffect(() => {
        if (societe) {
          dispatch(findInventaireApi(societe));
          dispatch(findInventaireDetailsApi(societe))
        }
      }, [dispatch, societe]);
  return (
    <div>
         <div>
        <Navbar />
        <div className="d-flex justify-content-end mb-3">
        <button
          onClick={() => {
            setshowModalInventaire(true);
          }}
          className="btn btn-primary-app"
        >
          {t("Ajouter Inventaire")}
        </button>{" "}
      </div>
      </div>
      <InventaireForm 
      ShowModal={ShowModalInventaire} 
      setShowModal={setshowModalInventaire}
      Inventaire={null}
       />
       <InventaireHeader t={t} listInventaire={list_inventaires} setlistfiltred={setlistfiltred} />
       <InventaireTable t={t}  list_inventaires={filtred_list} list_inventaire_details={list_inventaire_details}/>
    </div>
  )
}
