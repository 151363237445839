import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../_App/Redux/hooks'
import { ListItems, ListPages, findListPagesApi, setListItems } from '../../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice'
import MenuFormItem from './MenuFormItem'
import { Control, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch, useFieldArray } from 'react-hook-form'
import _ from 'lodash'
import ListIcons from '../../Icons/ListIcons'
import MenuFormConfigs from './MenuFormConfigs'
import AppMenuItemsType from '../../../../_App/Types/Entites/AppMenus/AppMenuItems'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import AppMenuParametrageType from '../../../../_App/Types/Entites/AppMenus/AppMenuParametrageType'

const MenuFormItems = ({ register, control, watch, setValue, getValues, menu }:
    {
        register: UseFormRegister<AppMenuParametrageType>,
        control: Control<AppMenuParametrageType, any>,
        watch: UseFormWatch<AppMenuParametrageType>,
        setValue: UseFormSetValue<AppMenuParametrageType>,
        getValues: UseFormGetValues<AppMenuParametrageType>,
        menu: AppMenuParametrageType | null
    }) => {

    //ShowModal Icons
    const [ShowModalIcons, setShowModalIcons] = useState(false)
    const [RegisterIndex, setRegisterIndex] = useState(0)

    const dispatch = useAppDispatch();


    const { fields, append, prepend, update, remove, swap, move, insert } = useFieldArray({
        control, // control props comes from useForm (optional: if you are using FormContext)
        name: "menuItems", // unique name for your Field Array 
    });

    // watch color and bgColor
    // const background_buttons = watch('menuPosition.bgColorButtons')
    // const color_buttons = watch('menuPosition.colorButtons')
    // const watchMenuItems: AppMenuItemsType[] | undefined = watch('menuItems');

    //change color and bgColor in all buttons
    // useEffect(() => {
    //     if (watchMenuItems) {
    //         setValue("menuItems", watchMenuItems?.map((item: AppMenuItemsType, index) => {
    //             console.log('background_buttons, color_buttons', background_buttons, color_buttons)
    //             return {
    //                 ...item,
    //                 color: watchMenuItems[index]?.color ? watchMenuItems[index]?.color : item.color,
    //                 bgColor: watchMenuItems[index]?.bgColor ? watchMenuItems[index]?.bgColor : item.bgColor,
            
    //             }
    //         }))
    //     }
    // }, [background_buttons, color_buttons])


    useEffect(() => {
        //get list of all pages and Components
        dispatch(findListPagesApi(null))
    }, [dispatch])

    const pages: AppMenuItemsType[] = useAppSelector(ListPages)
    const Items: AppMenuItemsType[] = useAppSelector(ListItems)

    useEffect(() => {
      if(menu){
        setValue("menuItems", menu.menuItems?.map((item: AppMenuItemsType) => {
            return {
                ...item, actif:true
            }
        }))
        const restOfButtons = pages.filter((page : AppMenuItemsType) => _.findIndex(menu.menuItems, {_id: page._id})==-1 )
        dispatch(setListItems([...menu.menuItems, ...restOfButtons]))
      }else{
        dispatch(setListItems([...pages]))
        setValue("menuItems", pages?.map((item: AppMenuItemsType) => {
            return {
                ...item, actif:true
            }
        }))
      }
    }, [menu])
    




    return (
        <>
            <>
                <MenuFormConfigs setValue={setValue} watch={watch} pages={pages} />
            </>
            <DndProvider backend={HTML5Backend}>
                {
                    Items && Items.map((item: AppMenuItemsType, index: number) => (
                        <MenuFormItem key={index} index={index} item={item}
                            register={register} watch={watch} getValues={getValues}
                            setValue={setValue}
                            ShowModalIcons={ShowModalIcons}
                            setShowModalIcons={setShowModalIcons}
                            setRegisterIndex={setRegisterIndex}
                        />
                    ))
                }
            </DndProvider>
            <>
                <ListIcons ShowModal={ShowModalIcons} setShowModal={setShowModalIcons}
                    setValue={setValue}
                    menuItems={true}
                    RegisterIndex={Number(RegisterIndex)} />
            </>
        </>
    )
}

export default MenuFormItems
