import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Display,
  Filter,
  changeDisplay,
  changeFilter,
  closeLeftBar,
  layout,
} from "../../_App/Redux/Slices/Caisse/CaisseSlice";

const LeftBar = () => {
  const dispatch = useAppDispatch();
  const filter = useAppSelector(Filter);
  const display = useAppSelector(Display);

  const Layout = useAppSelector(layout);

  const handleFIlterChange = async (table: any) => {
    dispatch(
      changeFilter({
        table: table,
        filteredBy: null,
        rightSideSelectedProduct: "",
      })
    );
  };
  const handleDisplayChange = async ({
    displayColLeft,
    colRight,
    containerRight,
  }: any) => {
    dispatch(
      changeDisplay({
        colLeft: display.colLeft,
        containerLeft: display.containerLeft,
        displayColLeft: displayColLeft,
        colRight: colRight,
        containerRight: containerRight,
      })
    );
  };

  return (
    <>
      <div className="wrapper">
        <div
          id="compact_submenuSidebar"
          className="submenu-sidebar"
          style={{ display: Layout.LeftBarDisplay }}
        >
          <div className="submenu" id="components">
            <ul className="submenu-list" data-parent-element="#components">
              <li>
                <a
                  onClick={(e) => {
                    handleFIlterChange("cartes");
                    if (display.displayColLeft === "none") {
                      handleDisplayChange({
                        displayColLeft: "unset",
                        colRight: "col-xl-3 col-lg-4 col-md-4 col-sm-6",
                        containerRight: "col-8",
                      });
                    }
                  }}
                  className={
                    filter && filter.table === "cartes" ? "active" : ""
                  }
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-git-commit"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <line x1="1.05" y1="12" x2="7" y2="12"></line>
                      <line x1="17.01" y1="12" x2="22.96" y2="12"></line>
                    </svg>
                  </span>{" "}
                  Cartes{" "}
                </a>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    handleFIlterChange("categories");
                    if (display.displayColLeft === "none") {
                      handleDisplayChange({
                        displayColLeft: "unset",
                        colRight: "col-xl-3 col-lg-4 col-md-4 col-sm-6",
                        containerRight: "col-8",
                      });
                    }
                  }}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-git-commit"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <line x1="1.05" y1="12" x2="7" y2="12"></line>
                      <line x1="17.01" y1="12" x2="22.96" y2="12"></line>
                    </svg>
                  </span>{" "}
                  Catégories{" "}
                </a>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    handleFIlterChange("sous-categories");
                    if (display.displayColLeft === "none") {
                      handleDisplayChange({
                        displayColLeft: "unset",
                        colRight: "col-xl-3 col-lg-4 col-md-4 col-sm-6",
                        containerRight: "col-8",
                      });
                    }
                  }}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-git-commit"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <line x1="1.05" y1="12" x2="7" y2="12"></line>
                      <line x1="17.01" y1="12" x2="22.96" y2="12"></line>
                    </svg>
                  </span>{" "}
                  Sous Catégories{" "}
                </a>
              </li>
              <li>
                <a
                  onClick={(e) => {
                    handleFIlterChange("products");
                    if (display.displayColLeft === "none") {
                      handleDisplayChange({
                        displayColLeft: "unset",
                        colRight: "col-xl-3 col-lg-4 col-md-4 col-sm-6",
                        containerRight: "col-8",
                      });
                    }
                  }}
                >
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-git-commit"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <line x1="1.05" y1="12" x2="7" y2="12"></line>
                      <line x1="17.01" y1="12" x2="22.96" y2="12"></line>
                    </svg>
                  </span>{" "}
                  Articles{" "}
                </a>
              </li>
              {/* <li>
                <a onClick={(e) => handleFIlterChange("all-products")}>
                  <span className="icon">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-git-commit"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <line x1="1.05" y1="12" x2="7" y2="12"></line>
                      <line x1="17.01" y1="12" x2="22.96" y2="12"></line>
                    </svg>
                  </span>{" "}
                  tous Produits{" "}
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeftBar;
