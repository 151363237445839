import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { ProductTailleSchema } from "../../../_App/Schemas/ProductTailleSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  createFournisseurApi,
  ListFournisseurs,
  updateFournisseurApi,
} from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { FournisseurType } from "../../../_App/Types/Entites/FournisseurType";
import { FournisseurSchema } from "../../../_App/Schemas/FournisseurSchema";
import {
  ListTaxTva,
  findTaxTvaApi,
} from "../../../_App/Redux/Slices/tax/taxSlice";
import { useTranslation } from "react-i18next";
import { updateFondCourantApi } from "../../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import Swal from "sweetalert2";
export default function FournisseurForm({
  ShowModal,
  setShowModal,
  Fournisseur,
  organisation_id,
  societe,
  
}: any) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  // init form hooks
  const [selectedOrganisation, setselectedOrganisation] = useState(true);

  const formOptions = {
    resolver: yupResolver(
      FournisseurSchema
    ) as unknown as Resolver<FournisseurType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<FournisseurType>(formOptions);
  const { errors } = formState;
  const listTaxTva = useAppSelector(ListTaxTva);

  useEffect(() => {
    if(societe)
    dispatch(findTaxTvaApi(societe));
  }, [societe]);

  useEffect(() => {
    if(Fournisseur){
      reset({
        _id:Fournisseur._id,
        libelle: Fournisseur.libelle,
        organisation_id: Fournisseur.organisation_id,
        tel: Fournisseur.tel,
        mobile: Fournisseur.mobile,
        email: Fournisseur.email,
        adresse: Fournisseur.adresse,
        code_tva:Fournisseur.code_tva,
        matricule_fiscale: Fournisseur.matricule_fiscale
      });
      setselectedOrganisation(Fournisseur.organisation_id)
    }else{
      reset({
        libelle: "",
        organisation_id: "",
        tel: "",
        mobile: "",
        email: "",
        adresse: "",
        code_tva: "",
        matricule_fiscale: "",
      });
    }
    
  }, [Fournisseur]);

  const verifcation = ( action:string) => {
    return Swal.fire({
      title: "vous-êtes sûr?",
      text: `vous voulez ${action} le fournisseur`,
      icon: "warning",
      customClass: {
        container: 'my-swal'
      },
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmer!",
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const onSubmit = async (data: any) => {
    let response ;
    if (selectedOrganisation) {
      data.organisation_id = organisation_id;
    } else {
      data.organisation_id = null;
    }
    data.societe_id = societe;
      if(Fournisseur?._id){
        if(await verifcation('modifier'))
        response = await dispatch(updateFournisseurApi(data));

      }else{
        if(await verifcation('ajouter'))
        response = await dispatch(createFournisseurApi(data));

      }
      if(response){  
        if ( response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          libelle: "",
          organisation_id: "",
          tel: "",
          mobile: "",
          email: "",
          adresse: "",
          code_tva: "",
          matricule_fiscale: "",
        });
        setShowModal(false);
        window.location.reload()
      } else {
        ToastWarning(t(response.payload.message));
      }}
  
  };
  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-2"
        dialogClassName="modal-40w"
        backdropClassName="modal-backdrop-2"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">{t('Ajouter Fournisseur')}</Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  
                  <div className="col-md-6">
                    <label htmlFor="libelle">{t('Nom')}</label>
                    <div className="col-auto">
                      <input
                        type="text"
                        className={`form-control ${
                          errors?.libelle ? "is-invalid" : ""
                        }`}
                        {...register("libelle")}
                      />
                      <div className="invalid-feedback">
                        {errors.libelle &&
                          errors.libelle.message &&
                          errors.libelle.message.toString()}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="matricule_fiscale">{t('matricule_fiscale')}</label>
                    <div className="col-auto">
                      <input
                        type="text"
                        className={`form-control  ${
                          errors?.matricule_fiscale ? "is-invalid" : ""
                        }`} 
                        {...register("matricule_fiscale")}
                      />
                      <div className="invalid-feedback">
                        {errors.matricule_fiscale &&
                          errors.matricule_fiscale.message &&
                          errors.matricule_fiscale.message.toString()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="tel">{t('tel')}</label>
                    <div className="col-auto">
                      <input
                        type="number"
                        className={`form-control numberinput ${
                          errors?.tel ? "is-invalid" : ""
                        }`}
                        {...register("tel")}
                      />
                      <div className="invalid-feedback"></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="mobile">{t('mobile')}</label>
                    <div className="col-auto">
                      <input
                        type="number"
                        className={`form-control numberinput ${
                          errors?.mobile ? "is-invalid" : ""
                        }`}
                        {...register("mobile")}
                      />
                      <div className="invalid-feedback">
                        {errors.mobile &&
                          errors.mobile.message &&
                          errors.mobile.message.toString()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="email">{t('email')}</label>
                    <div className="col-auto">
                      <input
                        type="email"
                        className={`form-control  ${
                          errors?.email ? "is-invalid" : ""
                        }`}                   
                       {...register("email")}
                      />
                      <div className="invalid-feedback">
                        {errors.email &&
                          errors.email.message &&
                          errors.email.message.toString()}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="tva">{t('adresse')}</label>
                    <div className="col-auto">
                      <input
                        type="text"
                        className={`form-control  ${
                          errors?.adresse ? "is-invalid" : ""
                        }`}   
                      {...register("adresse")}
                      />
                      <div className="invalid-feedback">
                        {errors.adresse &&
                          errors.adresse.message &&
                          errors.adresse.message.toString()}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <label htmlFor="code_tva">{t('code_tva')}</label>
                    <div className="col-auto">
                      <select
                        id="code_tva"
                        className={`form-control ${
                          errors?.code_tva ? "is-invalid" : ""
                        }`}
                        {...register("code_tva")}
                      >
                        <option>{t('select code tva')}  </option>
                        {listTaxTva?.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.taux}
                          </option>
                        ))}
                      </select>
                      <div className="invalid-feedback">
                        {errors.code_tva &&
                          errors.code_tva.message &&
                          errors.code_tva.message.toString()}
                      </div>
                    </div>
                  </div>
                 
                  <div className="col-md-6">
                  <div className="n-chk">
                      <label className="new-control new-radio radio-classic-primary">
                        <input
                          type="radio"
                          checked={selectedOrganisation}
                          onChange={(e) => setselectedOrganisation(true)}
                          value="connectedOnly"
                          className="new-control-input"
                          name="custom-radio-2"
                        />
                        <span className="new-control-indicator" />
                        <p className="fs-15">
                          {t("l'organisation connecté seulement")}
                        </p>
                      </label>
                    </div>
                    <div className="n-chk">
                      <label className="new-control new-radio radio-classic-primary">
                        <input
                          type="radio"
                          checked={!selectedOrganisation}
                          onChange={(e) => setselectedOrganisation(false)}
                          value="allOrganizations"
                          className="new-control-input"
                          name="custom-radio-2"
                        />
                        <span className="new-control-indicator" />
                        <p className="fs-15">
                          {t('Tous les organisations de la societe')}
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t('Annuler')}
            </Button>
            <Button type="submit" className="btn btn-primary-app">
              {t('Enregistrer')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
