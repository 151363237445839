import React from "react";
import { closeLeftBar } from "../../_App/Redux/Slices/Caisse/CaisseSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import LanguageSwitcher from "../../Shared/LanguageSwitcher/LanguageSwitcher";

const Navbar = ({ titre }: any) => {
  const dispatch = useAppDispatch();

  return (
    <div className="">
      <div className="content-container">
        <div className="col-left layout-top-spacing">
          <div className="col-left-content">
            <div
              className="header-container"
              style={{
                width: "100%",
              }}
            >
              <header className="header navbar navbar-expand-sm">
                <div className="d-flex col-8">
                  <div className="page-header">
                    <div className="page-title">
                      <h3>{titre}</h3>
                    </div>
                  </div>
                </div>
                <div
                  className="header-actions"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "right",
                  }}
                >
                  <LanguageSwitcher />
                </div>
              </header>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
