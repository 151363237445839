import React from "react";

export default function ProductTaillesTable({
  ListProductTaille,
  onEditClick,
  deleteitem,
}: any) {
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th>libelle</th>
          <th>type_taille</th>
          <th>type_taille_chaussures</th>
          <th className="text-center">Action</th>
        </tr>
      </thead>
      <tbody>
        {ListProductTaille.map((item: any) => (
          <tr key={item._id}>
            <td> {item.libelle}</td>
            <td> {item.type_taille}</td>
            <td> {item.type_taille_chaussures}</td>
            <td className="text-center">
            <ul className="table-controls">
                <li onClick={() => onEditClick(item)}>
                  <i
                    className="fas fa-trash-alt fa-lg"
                    style={{ color: "#14d8db" }}
                  ></i>
                </li>
                <li onClick={() => deleteitem(item._id)}>
                  <i
                    className="fas fa-pen fa-lg"
                    style={{ color: "#14d8db"}}
                  ></i>
                </li>
              </ul>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
