import React from 'react'

export default function ProductDetailsTable({  productList}:any) {
  return (
    <div style={{ overflowY: 'auto' , height:'70vh'}}>
       <table className="table table-bordered">
    <thead>
      <tr>
        <th>produit id</th>

        <th>taille</th>
        <th>couleur</th>
        <th>code a barre  fournisseur</th>
        <th>code a barre  </th>
        <th>quantite</th>

      </tr>
    </thead>
    <tbody>
      {productList.map((item: any) => (
        <tr key={item._id}>
          <td> {item.produit_id}</td>
          <td> {item.taille.libelle}</td>
          <td> {item.couleur.libelle}</td>
          <td>{item.code_a_barre_fournisseur}</td>
          <td> {item.code_a_barre}</td>
          <td> {item.quantity}</td>
        
        
     
        </tr>
      ))}
    </tbody>
  </table>
    </div>
     )
}
