import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { userAutorisationType } from "../../../Types/Entites/UserAutorisationType";
import { userRolesAutorisationType } from "../../../Types/Entites/userRoleAutorisationType";
import { RootState } from "../../store";
import { AppModuleType } from "../../../Types/Entites/AppModuleType";

export const fetchModules = createAsyncThunk(
  "autorisation/modules",
  async () => {
    try {
      const response = await axios.get("autorisation/modules");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const saveRoleAutorisations = createAsyncThunk(
  "autorisation/saveRoleAutorisations",
  async (data: {
    role_id: string;
    autorisation: string;
    autorisation_id: string;
    description: string;
    actif: boolean;
  }) => {
    try {
      const adaptedData = {
        role_id: data.role_id,
        autorisation: data.autorisation,
        autorisation_id: data.autorisation_id,
        description: data.description,
        actif: data.actif,
      };

      const response = await axios.post(
        "/roleAutorisation/createUserRolesAutorisations",
        adaptedData
      );

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAllAffectations = createAsyncThunk(
  "autorisation/fetchAllAffectations",
  async () => {
    try {
      const response = await axios.get("/roleAutorisation/getAllAffectations");
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchUserAutorisations = createAsyncThunk(
  "autorisation/autorisations",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get("/autorisation/autorisations");
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

const initialState: {
  all_autorisations: userAutorisationType[];
  autorisations: {
    [role_id: string]: {
      [autorisation_id: string]: userAutorisationType;
    };
  };
  modules: AppModuleType[];
} = {
  all_autorisations: [],
  autorisations: {},
  modules: [],
};

const userAutorisationsSlice = createSlice({
  name: "userAutorisationsSlice",
  initialState,
  reducers: {
    manageAutorisation: (
      state,
      action: PayloadAction<{
        role_id: string;
        autorisation: userAutorisationType;
        actif: boolean;
      }>
    ) => {
      const { role_id, autorisation, actif } = action.payload;

      if (!state.autorisations[role_id]) {
        state.autorisations[role_id] = {};
      }

      if (actif) {
        state.autorisations[role_id][autorisation._id] = {
          ...autorisation,
          actif: true,
        };
      } else {
        delete state.autorisations[role_id][autorisation._id];
      }
    },

    checkAll: (
      state,
      action: PayloadAction<{ role_id: string; isChecked: boolean }>
    ) => {
      const { role_id, isChecked } = action.payload;

      if (!state.autorisations[role_id]) {
        state.autorisations[role_id] = {};
      }

      state.all_autorisations.forEach((autorisation) => {
        state.autorisations[role_id][autorisation._id] = {
          ...autorisation,
          actif: isChecked,
        };

        const data = {
          role_id,
          autorisation: autorisation.autorisation,
          description: autorisation.description,
          actif: isChecked,
        };

        axios
          .post("/roleAutorisation/createUserRolesAutorisations", data)
          .then((response) => {})
          .catch((error) => {});
      });
    },
  },

  extraReducers: (builder) => {
    builder.addCase(fetchUserAutorisations.fulfilled, (state, action) => {
      state.all_autorisations = action.payload;
    });
    builder.addCase(saveRoleAutorisations.fulfilled, (state, action) => {
      const autorisation = action.payload.data;

      const isAlreadyActive =
        state.autorisations[autorisation.role_id]?.[
          autorisation.autorisation_id
        ]?.actif;

      state.autorisations = {
        ...state.autorisations,
        [autorisation.role_id]: {
          ...state.autorisations[autorisation.role_id],
          [autorisation.autorisation_id]: {
            ...autorisation,
            actif: isAlreadyActive ? false : true,
          },
        },
      };
    });
    builder.addCase(fetchModules.fulfilled, (state, action) => {
      state.modules = action.payload;
    });
    builder.addCase(fetchAllAffectations.fulfilled, (state, action) => {
      const affectations: userRolesAutorisationType[] = action.payload;

      affectations.forEach((affectation) => {
        const { role_id, _id, autorisation, description, actif } = affectation;

        const existingAutorisation = state.all_autorisations.find(
          (a) => a.autorisation === autorisation
        );

        const autorisation_id = existingAutorisation?._id || _id;

        if (!state.autorisations[role_id]) {
          state.autorisations[role_id] = {};
        }

        state.autorisations[role_id][autorisation_id] = {
          _id: autorisation_id,
          autorisation,
          description,
          actif: actif || true,
        };
      });
    });
  },
});

export const { manageAutorisation, checkAll } = userAutorisationsSlice.actions;

export const All_autorisations = (state: RootState) =>
  state.userAutorisation.all_autorisations;
export const Autorisations = (state: RootState) =>
  state.userAutorisation.autorisations;
export const moduleList = (state: RootState) => state.userAutorisation.modules;

export default userAutorisationsSlice.reducer;
