import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../Navbar/Navbar";
import { ToastContainer } from "react-toastify";
import MenuBottom from "../Menus/MenuBottom";
import NavbarDefault from "../Navbar/NavbarDefault";
import styled from "styled-components";
import MenuLeft from "../Menus/MenuLeft";
import { useAppSelector } from "../../_App/Redux/hooks";
import { ActiveMenu } from "../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import AppMenuParametrageType from "../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import MenuRight from "../Menus/MenuRight";
import MenuTop from "../Menus/MenuTop";
import { userLang } from "../../_App/Redux/Slices/Auth/authSlice";

const RestyledLayout = styled.div<{ $menu: AppMenuParametrageType | null }>`
  ${(props) => {
    var style = ``;
    // check the postion of menu right, left up , down
    switch (props.$menu?.menuPosition.position) {
      case `down`:
        style = `#content {
                    width: 100%;
                    flex-grow: none;
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;
      case `left`:
        style = `#content {
                    width: calc(100% - 150px);
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      case `right`:
        style = `#content {
                    width: calc(100% - 150px);
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      case `up`:
        style = `.main-container {
                   flex-direction: column;
                  }
                  #content {
                    width: 100%;
                    margin-bottom: 0;
                    margin-left: 0px;
                  }`;
        break;

      default:
        console.log(`Sorry, we are out of parms.`);
    }
    return style;
  }}

  #content .col-left {
    padding: 0;
    -webkit-box-flex: 1;
    flex: 1 auto;
    -ms-flex: 1 0 0px;
    min-width: 0;
    margin-right: 0px;
  }
`;

export default function Layout() {
  const Menu = useAppSelector(ActiveMenu);
  const langue = useAppSelector(userLang);

  return (
    <RestyledLayout $menu={Menu}>
      <ToastContainer />
      <div
        className="dashboard-analytics admin-header"
        style={{
          color: "#888ea8",
          height: "100%",
          fontSize: "0.875rem",
          background: "#f1f2f3",
          overflowX: "hidden",
          overflowY: "auto",
          letterSpacing: "0.0312rem",
          fontFamily: "Quicksand, sans-serif",
        }}
      >
        <div className="main-container" id="container">
          {Menu && Menu.menuPosition.position == "up" ? <MenuTop /> : ""}
          {Menu && Menu.menuPosition.position == "down" ? <MenuBottom /> : ""}
          {Menu && Menu.menuPosition.position == "left" ? <MenuLeft /> : ""}
          {!Menu ? <MenuBottom /> : ""}

          <div
            id="content"
            className="main-content"
            style={{ direction: langue === "ar" ? "rtl" : "unset" }}
          >
            <div className="layout-px-spacing">
              {/* content-container */}
              <div className="">
                <div className="col-left">
                  <div className="col-left-content" style={{ margin: "50px" }}>
                    {/* <NavbarDefault /> */}

                    <Outlet />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {Menu && Menu.menuPosition.position == "right" ? <MenuRight /> : ""}
        </div>
      </div>
    </RestyledLayout>
  );
}
