import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import ProductCouleurForm from "../../../Components/Products/ProductCouleurs/ProductCouleurForm";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import {
  findProductCouleurApi,
  ListProductCouleurs,
  deleteProductCouleurApi,
} from "../../../_App/Redux/Slices/product_couleur/product_couleurSlice";
import ProductCouleurTable from "../../../Components/Products/ProductCouleurs/ProductCouleurTable";
export default function ProductCouleursPage() {
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [ShowModalProductCouleur, setShowModalProductCouleur] = useState(false);
  const [selectedProductCouleur, setProductCouleur] = useState(null);
  const dispatch = useAppDispatch();
  const ProductCouleurs = useAppSelector(ListProductCouleurs);
  useEffect(() => {
   
    dispatch(findProductCouleurApi(currentOrganisation));
  }, [dispatch]);
  const openEditForm = (ProductsCaracteristiques: any) => {
    setProductCouleur(ProductsCaracteristiques);
    setShowModalProductCouleur(true);
  };
  const deleteitem = (id: any) => {
    dispatch(deleteProductCouleurApi(id));
  };
  return ( 
    <div>
      <Button
        variant="outline-primary"
        onClick={() => setShowModalProductCouleur(true)}
      >
        Create Product Couleur
      </Button>{" "}
      <ProductCouleurForm
        ShowModal={ShowModalProductCouleur}
        setShowModal={setShowModalProductCouleur}
        ProductCouleur={selectedProductCouleur}
        organisation_id={currentOrganisation}
      />
      <ProductCouleurTable
        ListProductCouleur={ProductCouleurs}
        onEditClick={openEditForm}
        deleteitem={deleteitem}
      />
    </div>
  );
}
