import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../../../_App/Redux/hooks';
import AppMenuParametrageType from '../../../_App/Types/Entites/AppMenus/AppMenuParametrageType';
import AppMenuItemsType from '../../../_App/Types/Entites/AppMenus/AppMenuItems';


const MenuStyledBottom = styled.div<{ $menu: AppMenuParametrageType | null }>`
        div.sidebar-wrapper{
          width: 100%;
          height:"150px";
          bottom: 0px;
          top: auto;
          border-top-right-radius : 0px;
          background: ${({ $menu }) => ($menu ? $menu?.menuPosition?.bgColor : "#e0f0ef")}
        }
        div.sidebar-wrapper>nav#compactSidebar{
          position:relative;
          width: 100%;
          height:"150px";
          bottom: 0px;
          top: auto;
          border-top-right-radius : 0px;
          display: flex;
          justify-content: flex-start;
        }
        div.sidebar-wrapper>nav#compactSidebar>ul.menu-categories{
          position:relative;
          display: flex;
          justify-content: flex-start;
          align-content: flex-start;
          width: 100%;
          height: 100%;
          flex-direction: row;
          flex-wrap: nowrap;
        }
        
        div.sidebar-wrapper>nav#compactSidebar>ul.menu-categories>li.menu{
          position:relative;
          display: flex;
          width: 140px;
          height: 140px;
          flex: auto;
        }
        
        div.sidebar-wrapper>nav#compactSidebar>ul.menu-categories>li.menu>a.menu-toggle{
          width: 140px;
        }
         

        .fs-25 > span{
          font-size: 25px !important;
        }
`;

const ParametrageMenuButtons = ({ menu=null }: {menu: AppMenuParametrageType | null}) => {


  return (
    <MenuStyledBottom $menu={menu}>

      <div className="sidebar-wrapper sidebar-theme">
    
        <div className="sidebarCollapseFixed">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-arrow-left"><line x1={19} y1={12} x2={5} y2={12} /><polyline points="12 19 5 12 12 5" /></svg>
        </div>
        <nav id="compactSidebar">
          <ul className="menu-categories">

            {
              menu && menu.menuItems.map((item: AppMenuItemsType, index: number) => (
                <li className="menu" key={index}>
                  <a href="#" className="menu-toggle" style={{ backgroundColor: item.bgColor, border: `2px solid ${item.color}` }}>
                    <div className="base-menu">
                      <div className="base-icons">
                        {
                          item.type_icon == 'icone' ?
                            (<i className={item.icon} style={{ color: item.color }} />) :
                            (<span className="fs-25" style={{ color: item.color }}>{item.ordre + 1}</span>)
                        }
                      </div>
                      <span style={{ color: item.color }}>{item.libelle}</span>
                    </div>
                  </a>
                </li>
              ))
            }

          </ul>
        </nav>
      </div>

    </MenuStyledBottom>

  )
}

export default ParametrageMenuButtons
