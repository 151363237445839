import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { formadate } from "../../_App/Helpers/helpers";
import InventaireForm from "./InventaireForm";

export default function InventaireDetaliTable({
  t,
  ShowModal,
  setShowModal,
  inventaire,
  listInventairedetails,
  listdOrganisations,
}: any) {
  const [showModalFormInventaire, setshowModalFormInventaire] = useState(false);
  const updateinventaire = () => {
    setShowModal(false);
    setshowModalFormInventaire(true);
  };
  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-2"
        dialogClassName="modal-70w"
        backdropClassName="modal-backdrop-2"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">
            {" "}
            <span>{t("Inventaire details")}</span>
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row mb-2">
            <div className="col-4">
              <strong>{t("Code inventaire")}</strong>{" "}
              <span>{inventaire?.code_inventaire}</span>
            </div>
            <div className="col-4">
              <strong>{t("Organisation")}</strong>{" "}
              <span>
                {listdOrganisations?.find(
                  (element: any) => element._id === inventaire?.organisation_id
                )?.nom || inventaire?.organisation_id}
              </span>
            </div>
            <div className="col-3">
              <strong>{t("Depots")} </strong>{" "}
              <span>{inventaire?.depots_id.libelle}</span>
            </div>
          </div>
          <div className="row mb-4">
            <div className="col-4">
              <strong>{t("user")} </strong>{" "}
              <span>
                {inventaire?.user.nom}
                {""}
                {inventaire?.user?.prenom}
              </span>
            </div>
            <div className="col-4">
              <strong>{t("date debut")}</strong>{" "}
              <span>{formadate(inventaire?.date_debut)}</span>
            </div>
            <div className="col-4">
              <strong>{t("date fin")} </strong>{" "}
              <span>{formadate(inventaire?.date_fin)}</span>
            </div>
          </div>
          <div className="row">
            <table className="table table-bordered mb-5">
              <thead>
                <tr>
                  <th>{t("Article")}</th>
                  <th>{t("code Article")}</th>
                  <th>{t("Prix Achat TTC")}</th>
                  <th>{t("Prix Vente TTC")}</th>
                  <th>{t("Qunatite")}</th>
                </tr>
              </thead>
              {listInventairedetails[inventaire?._id] &&
              listInventairedetails[inventaire?._id].length !== 0 ? (
                <tbody>
                  {listInventairedetails[inventaire._id]?.map(
                    (item: any, index: number) => (
                      <tr key={index}>
                        <td>{item.article_id?.titre.fr}</td>
                        <td>{item.article_id?.code_article}</td>
                        <td>{item.prix_achat_ttc}</td>
                        <td>{item.prix_vente_ttc}</td>
                        <td>{item.quantite}</td>
                      </tr>
                    )
                  )}
                </tbody>
              ) : (
                <tr>{t("aucun detail")}</tr>
              )}

              <tbody></tbody>
            </table>
          </div>
        </Modal.Body>

        <Modal.Footer>
          {!inventaire?.confirmer ? (
            <button onClick={updateinventaire} className="btn btn-primary-app">
              {t("Modifier")}
            </button>
          ) : null}
        </Modal.Footer>
      </Modal>
      <InventaireForm
        Inventaire={inventaire}
        list_details_inventaire={listInventairedetails[inventaire?._id]}
        ShowModal={showModalFormInventaire}
        setShowModal={setshowModalFormInventaire}
      />
    </div>
  );
}
