import React, { useEffect, useState } from 'react'
import Stocklist from '../../Components/Stock/Stocklist'
import { useAppDispatch, useAppSelector } from '../../_App/Redux/hooks'
import { societeId  } from '../../_App/Redux/Slices/Auth/authSlice'
import {ListArticles, Liststock, Stocks, StocksbyOrganisation, findArticleApi, findStockBySocieteApi, findStockByarticleApi } from '../../_App/Redux/Slices/article/articleSlice'
import _ from "lodash";
import StockType from '../../_App/Types/Entites/StockType'
import { useTranslation } from 'react-i18next'
import Navbar from '../../_Layouts/Navbar/Navbar'
import StockTable from '../../Components/Stock/StockTable'
import StockTableArticle from '../../Components/Stock/StockTableArticle'
import { ListDepots, findDepotsBySocieteApi } from '../../_App/Redux/Slices/depot/depotSlice'

export default function StockPage() {
  const { t } = useTranslation();
  const [index , setindex]=useState(60600);

   const dispatch = useAppDispatch()
   const societe = useAppSelector(societeId)
   const stockOrganisation = useAppSelector(StocksbyOrganisation)
   const liststock = useAppSelector(Liststock)
   const [stock_Organisation, setStockOrganisation] = useState<{ [key: string]: any[] }>({});
   const listDepots = useAppSelector(ListDepots)
   const listarticle = useAppSelector(ListArticles)
    const [keyword ,setkeyword]=useState('')
    const [spinner ,setspinner]=useState(false)


   useEffect(() => {
    if(keyword === ''){
      if(societe){
        setspinner(true)

        const data={societe:societe , index:index}
        dispatch(findStockBySocieteApi(data))
       /* dispatch(findArticleApi(societe))*/
    
        dispatch(findDepotsBySocieteApi(societe))
    
       } 
    }else{       
       setspinner(true)

      
      const data={article:keyword , index:index}
      dispatch(findStockByarticleApi(data))
    }
  
  }, [dispatch,societe,index]);
  useEffect(() => {
    if(liststock.length !== 0){
      setspinner(false)
    }
  }, [liststock]);

  const handleSearch =()=>{
    if(keyword === ''){
      if(societe){
        setindex(60600)
        setspinner(true)
        const data={societe:societe , index:60600}
        dispatch(findStockBySocieteApi(data))
       /* dispatch(findArticleApi(societe))*/
    
        //dispatch(findDepotsBySocieteApi(societe))
    
       } 
    }else{
      setspinner(true)

      const data={article:keyword , index:0}
      setindex(0)
      dispatch(findStockByarticleApi(data))
    }
   
  }
  useEffect(() => {
    if (stockOrganisation) {
        const updatedStockOrganisation = { ...stockOrganisation }; // Create a copy of stockOrganisation

        for (const key in stockOrganisation) {
            let entries = stockOrganisation[key]
            const groupedBydepots = _.groupBy(entries, "depots_id._id");
            updatedStockOrganisation[key] = groupedBydepots; // Update the entry in the copied object
        }
        // Update the state with the modified stockOrganisation
        setStockOrganisation(updatedStockOrganisation);
    } 
}, [stockOrganisation]);
const selectedstocks = liststock.slice(index, index + 10);

  return (

    <div>
      <Navbar/>
      <StockTableArticle  
t={t} 
listDepots={listDepots} 
stock={liststock} 
stock_Organisation={stock_Organisation}
setindex={setindex}
index={index}
listarticle={listarticle}
liststock= {liststock}
setkeyword={setkeyword}
keyword={keyword}
handleSearch={handleSearch}
spinner={spinner}
 />

    </div>
  )
}
/*
      
  <Stocklist t={t} stockOrganisation={stock_Organisation}/>

<StockTableArticle  
t={t} 
listDepots={listDepots} 
stock={selectedstocks} 
stock_Organisation={stock_Organisation}
setindex={setindex}
index={index}
 />*/