import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import CommissionType from "../../../Types/Entites/Commission/parametrage_Commission";
import _ from "lodash";
/**
 * create commission function
 * @private
 */
export const createCommissionApi = createAsyncThunk(
  "commission/createCommissionApi",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.post("commission/createCommissionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const updateCommissionUserApi = createAsyncThunk(
  "commission/updateCommissionUserApi",
  async (data: any[], { rejectWithValue }) => {
    try {
      const response = await axios.put("commission/updateCommissionUserApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const updateparametrageCommissionApi = createAsyncThunk(
  "commission/updateparametrageCommissionApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("commission/updateparametrageCommissionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const active_parametrageCommissionApi = createAsyncThunk(
  "commission/active_parametrageCommissionApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.put("commission/active_parametrageCommissionApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const findCommissionApi = createAsyncThunk(
  "commission/findCommissionApi",
  async (societeId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`commission/findCommissionApi/${societeId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findCommissionByOrganisationApi = createAsyncThunk(
  "commission/findCommissionByOrganisationApi",
  async (organisationId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(`commission/findCommissionByOrganisationApi/${organisationId}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface CommissionState {
  listCommissionCree: CommissionType[];
  listCommissionUser: {
    [organisation_id: string]: any[];
  };

}
const initialState: CommissionState = {
  listCommissionCree: [],
  listCommissionUser: {},

};

export const CommissionSlice = createSlice({
    name: "commissionSlice",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
      builder.addCase(createCommissionApi.fulfilled, (state, action) => {
        state.listCommissionCree.splice(0, 0, action.payload.data);
      });
      builder.addCase(findCommissionApi.fulfilled, (state, action) => {
        state.listCommissionCree= action.payload;
      });
      builder.addCase(findCommissionByOrganisationApi.fulfilled, (state, action) => {
        const groupedByUserId= _.groupBy(action.payload, "user_id");

 
        state.listCommissionUser= groupedByUserId;
      });

    

    },
    
  });

  export const ListCommission = (state: RootState) => state.commissions.listCommissionCree;
  export const ListCommissionUser = (state: RootState) => state.commissions.listCommissionUser;

  
  export default CommissionSlice.reducer;
 