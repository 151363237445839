import React, { useEffect, forwardRef, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  getOrderByIdApi,
  order,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  findPaymentsByOrderApi,
  listOperationsByPaymentMethod,
  listPaymentOperations,
} from "../../../_App/Redux/Slices/payments/PaymentSlice";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";

const TicketDeCaissePaid = ({ ShowModal, setShowModal, order_id }: any) => {
  const formatDate = ({ date }: { date: string }) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const formatTime = ({ date }: { date: string }) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(date).toLocaleTimeString(undefined, options);
  };

  const currentDate = new Date();
  const mainOrder = useAppSelector(order);
  const paymentDetails = useAppSelector(listOperationsByPaymentMethod);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (order_id) {
      dispatch(getOrderByIdApi(order_id));
      dispatch(findPaymentsByOrderApi(order_id));
    }
  }, [dispatch, order_id]);
  const componentRef = useRef<HTMLDivElement>(null);

  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="ticket-modal"
      style={{ display: "flex", justifyContent: "center" }}
      size="sm"
    >
      <div ref={componentRef}>
        <div className="container-ticket" id="print-content">
          <div className="receipt_header">
            <h1>
              Ticket de caisse <span>Clediss</span>
            </h1>
            <h2>
              Address: Lorem Ipsum, 1234-5 <span>Tel: +1 012 345 67 89</span>
              <span> {"Ticket n°" + mainOrder?.order_number} </span>
            </h2>
          </div>

          <div className="row">
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0" style={{ color: "#000" }}>
                Numro
              </p>
              <p className="inv-email-address" style={{ color: "#000" }}>
                {mainOrder?.order_number}
              </p>
            </div>
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0" style={{ color: "#000" }}>
                Etab
              </p>
              <p className="inv-email-address" style={{ color: "#000" }}>
                101
              </p>
            </div>
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0" style={{ color: "#000" }}>
                Etab
              </p>
              <p className="inv-email-address" style={{ color: "#000" }}>
                101
              </p>
            </div>
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0" style={{ color: "#000" }}>
                Caisse
              </p>
              <p className="inv-email-address" style={{ color: "#000" }}>
                2
              </p>
            </div>
          </div>

          <div className="receipt_body">
            <div className="date_time_con">
              <div className="date">
                {" "}
                {formatDate({
                  date: currentDate.toISOString(),
                })}{" "}
              </div>
              <div className="time">
                {" "}
                {formatTime({ date: new Date().toISOString() })}
              </div>
            </div>

            <div className="ticket-items">
              <table style={{ whiteSpace: "normal" }}>
                <thead>
                  <tr>
                    <th style={{ width: "auto" }}>QTE</th>
                    <th>Désignation</th>
                    <th>Total</th>
                  </tr>
                </thead>

                <tbody>
                  {mainOrder &&
                    mainOrder?.items.map((item: any, index: number) => (
                      <tr key={item.article._id}>
                        <td style={{ width: "auto" }}>{item.quantity}</td>
                        <td>
                          {item.article.titre.fr}
                          {item.article.caracteristiques &&
                          item.article.caracteristiques.length
                            ? item.article.caracteristiques.map(
                                (caracteristique: any) => caracteristique.value
                              )
                            : null}
                        </td>
                        <td> {item.prix_total}</td>
                      </tr>
                    ))}
                </tbody>

                <tfoot>
                  <tr>
                    <td>Total</td>
                    <td></td>
                    <td> {mainOrder?.total.toFixed(3)} </td>
                  </tr>

                  <>
                    <tr>
                      <td onClick={() => console.log(paymentDetails)}>
                        Réglement(s)
                      </td>
                    </tr>
                    {Object.entries(paymentDetails).map(([key, value]) => (
                      <tr key={key}>
                        <td> {key} </td>
                        <td></td>
                        <td> {value[0].amount} </td>
                      </tr>
                    ))}

                    {Object.entries(paymentDetails).map(([key, value]) =>
                      value[0].rendu ? (
                        <tr key={key}>
                          <td> Rendu </td>
                          <td></td>
                          <td> {value[0].rendu} </td>
                        </tr>
                      ) : null
                    )}
                  </>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <Button variant="contained" color="primary">
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
    </Modal>
  );
};

export default TicketDeCaissePaid;
