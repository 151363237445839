
import { string, boolean, object, mixed,number } from "yup";

export const FournisseurSchema = object({
    libelle: string().required('libelle obligatoire !'),
    tel:string().notRequired(),
    mobile: string().required('numero de telephone obligatoire !'),
    email: string().notRequired(),
    adresse:string().required('adresse obligatoire !'),
    code_tva : string().required("code_tva obligatoire"),
    matricule_fiscale: string().required('matricule_fiscale obligatoire !'),
});