import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import moment from "moment";
import { SessionJournalDataType } from "../../../Types/Forms/SessionJournalDataType";
import { RootState } from "../../store";

export const getAllSessionJournals = createAsyncThunk(
  "sessionJournal/getAllSessionJournals",
  async () => {
    const response = await axios.get("/sessionJournal/getAllSessionJournals");
    return response.data;
  }
);

export const getSessionJournalByIdApi = createAsyncThunk(
  "sessionJournal/getSessionJournalByIdApi",
  async (id: string) => {
    const response = await axios.get(
      `/sessionJournal/getSessionJournalByIdApi/${id}`
    );
    return response.data;
  }
);

export const createSessionJournalApi = createAsyncThunk(
  "sessionJournal/createSessionJournalApi",
  async (newSessionJournal: SessionJournalDataType) => {
    try {
      const sessionJournalData: SessionJournalDataType = {
        ...newSessionJournal,
      };

      const response = await axios.post(
        "/sessionJournal/createSessionJournalApi",
        sessionJournalData
      );

      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }
);

export const clotureSessionApi = createAsyncThunk(
  "sessionJournal/clotureSessionApi",
  async (data: { id: string; fond_final: number ,garde_caisse?: number | null}, { rejectWithValue }) => {
    try {

      const response = await axios.put(
        "/sessionJournal/clotureSessionApi",
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        success: false,
      });
    }
  }
);

export const getPrecedentSessionApi = createAsyncThunk(
  "sessionJournal/getPrecedentJournalSessionApi/",
  async (journalId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `sessionJournal/getPrecedentJournalSessionApi/${journalId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface SessionJournalState {
  sessionJournals: SessionJournalDataType[];
  selectedSessionJournal: SessionJournalDataType | null;
}

const initialState: SessionJournalState = {
  sessionJournals: [],
  selectedSessionJournal: null,
};

const sessionJournalSlice = createSlice({
  name: "sessionJournal",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllSessionJournals.fulfilled, (state, action) => {
        return {
          ...state,
          sessionJournals: action.payload,
        };
      })
      .addCase(getSessionJournalByIdApi.fulfilled, (state, action) => {
        return {
          ...state,
          selectedSessionJournal: action.payload,
        };
      })
      .addCase(createSessionJournalApi.fulfilled, (state, action) => {
        state.sessionJournals.push(action.payload);
        state.selectedSessionJournal = action.payload;
      })
      .addCase(createSessionJournalApi.rejected, (state, action) => {
        console.error(action.error);
      })

      .addCase(clotureSessionApi.fulfilled, (state, action) => {
        if (state.selectedSessionJournal) {
          state.selectedSessionJournal.fond_final =
            action.payload.data.fond_final;
        }
      });
  },
});

export const SessionJournals = (state: RootState) =>
  state.sessionJournal.sessionJournals;
export const SelectedSessionJournal = (state: RootState) =>
  state.sessionJournal.selectedSessionJournal;

export default sessionJournalSlice.reducer;
