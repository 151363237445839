import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { handleKeyPress } from "../../_App/Helpers/helpers";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { updateCommissionUserApi } from "../../_App/Redux/Slices/commission/commissionSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function CommissionUserList({
  t,
  listcommission,
  showModal,
  setshowModal,
  user
}: any) {
    const dispatch = useAppDispatch()
  const [payer, setpayer] = useState(false);
  const [newlistCommission, setnewlistCommission] = useState<any[]>([]);

  useEffect(() => {
    if (listcommission) {
      // Ajouter attribut
      setnewlistCommission([...listcommission.map((item :any)=> ({ ...item, montant: 0 }))]);
    }
  }, [listcommission]);
  
  const onChangeinput = (index: number, valeur: number) => {
    setnewlistCommission(prev => {
      const updatedList = [...prev];
      updatedList[index] = { ...updatedList[index], montant: valeur };
      return updatedList;
    });
  };
  const valider =async()=>{
    const list_filtrer = newlistCommission.filter(item => item.montant !==0 || item.montant !== '')
    const response = await dispatch(updateCommissionUserApi(list_filtrer))
    if(response.payload.success) {
        setshowModal(false);
        ToastSuccess(response.payload.message)
      }else{
        ToastWarning(response.payload.message)
      }
  }
  
  return (
    <Modal
      show={showModal}
      onHide={() => setshowModal(false)}
      className="modal-1"
      dialogClassName="modal-50w"
    >
      <Modal.Header>
        <Modal.Title className="c-primary"> {t("List Commission   ")}{user?.nom } {' '} {user?.prenom}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setshowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <table className="table">
          <thead>
            <tr>
              <th>{t("Article")}</th>
              <th>{t("Code Article")}</th>
              <th>{t("Commission")}</th>
              <th>{t("montant_servis")}</th>
              <th>{t("reste")}</th>
              <th>{t("servis")}</th>
            </tr>
          </thead>
          <tbody>
            {newlistCommission?.map((element: any ,index:number) => (
              <tr key={index}>
                <td>{element?.article.article.titre.fr}</td>
                <td>{element?.article.article.code_article}</td>
                <td>{element?.commission}</td>
                <td>{element?.montant_servis}</td>
                <td>{element?.commission-element?.montant_servis}</td>

                <td>
                  {element.servis
                    ? "servi"
                    : element.montant_servis !== 0
                    ? "servi_partiellement"
                    : "non servis"}
                </td>
                {payer ? (
                  <td>
                    <input
                      type="text"
                      className="form-control "
                      onChange={(e)=>onChangeinput(index , parseFloat(e.target.value))}
                        value={element.montant}
                      onKeyPress={handleKeyPress}
                    />
                  </td>
                ) : null}
              </tr>
            ))}
            <tr>
              <td colSpan={4}>
                {!payer ? (<button className="btn btn-primary-app" onClick={()=>setpayer(true)}>payer</button>):
                <button  className="btn btn-primary-app" onClick={valider}>valider</button>}
              </td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
}
