import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { TableSchema } from "../../_App/Schemas/TableSchema";
import TableType from "../../_App/Types/Entites/TableType";
import {
  NumerTable,
  createTableApi,
  updateTableApi,
} from "../../_App/Redux/Slices/table/tableSlice";
import { ListZoneTable, findZoneTableByOrganisationApi } from "../../_App/Redux/Slices/zoneTable/zoneTableSlice";

export default function TableForm({
  ShowModal,
  setShowModal,
  table,
  OrganisationId,
  verifcationMsg,
  t
}: any) {
  const dispatch = useAppDispatch();
  const listzonetable = useAppSelector(ListZoneTable)
  const formOptions = {
    resolver: yupResolver(TableSchema) as unknown as Resolver<TableType>,
  };
  const { register, handleSubmit, reset, formState ,setValue ,getValues } =
    useForm<TableType>(formOptions);
  const { errors } = formState;
  const [activestate ,setactivestate]=useState(false)

  const maxnumerotable = useAppSelector(NumerTable);
  useEffect(() => {
    if(OrganisationId){
      dispatch(findZoneTableByOrganisationApi(OrganisationId))

    }
  }, [dispatch]);

  useEffect(() => {
    if (table && maxnumerotable) {
      reset({
        _id: table._id,
        numero: table.numero,
        nmb_chaise: table.nmb_chaise,
        organisation_id: table.organisation_id,
        reserver: table.reserver,
        active:table.active
      });
      setactivestate(table.active)
    } else {
      reset({
        numero: maxnumerotable + 1,
        nmb_chaise: 0,
        organisation_id: OrganisationId,
        reserver: false,
        active:false

      });
      setactivestate(false)

    }
  }, [table, maxnumerotable, ShowModal]);
  const onSubmit = async (data: any) => {
    let response;
    if (data._id) {
      if(await verifcationMsg(t('vous voulez modifier la table ?')))

      response = await dispatch(updateTableApi(data));
    } else {
      if(await verifcationMsg(t('vous voulez ajouter une table ?')))
      response = await dispatch(createTableApi(data));
    }
    if(response){
      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        reset({
          numero: 0,
          nmb_chaise: 0,
        });
        setShowModal(false);
        window.location.reload()
  
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
   
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-1"
      dialogClassName="modal-30w"
      backdropClassName="modal-backdrop-1"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">{t('Ajouter Table')}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setShowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="nom">
                  {" "}
                  <span style={{ color: "red" }}>*</span>{t('Numero du table')}
                </label>

                <input
                  type="number"
                  className={`form-control numberinput ${
                    errors.numero ? "is-invalid" : ""
                  }`}
                  id="numero"
                  {...register("numero")}
                />

                <div className="invalid-feedback">
                  {errors.numero &&
                    errors.numero.message &&
                    errors.numero.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="form-group">
                <label htmlFor="prenom">
                  {" "}
                  <span style={{ color: "red" }}>*</span>{t('Numbre des chaise')}
                </label>
                <input
                  type="number"
                  className={`form-control numberinput ${
                    errors.nmb_chaise ? "is-invalid" : ""
                  }`}
                  id="nmb_chaise"
                  {...register("nmb_chaise")}
                />

                <div className="invalid-feedback">
                  {errors.nmb_chaise &&
                    errors.nmb_chaise.message &&
                    errors.nmb_chaise.message.toString()}
                </div>
              </div>
            </div>
        
          </div>
          <div className="row">
          <div className="col-6">
              <div className="form-group">
                <label htmlFor="zonetable">
                  {" "}
                  {t('Zone table')}
                </label>
                    <select
                        id="zonetable"
                        className="form-control "
                        {...register("zonetable")}
                       
                      >
                        {listzonetable?.map((item: any, index: number) => (
                          <option key={index} value={item._id}>
                            {item.libelle}
                          </option>
                        ))}
                      </select>

                <div className="invalid-feedback">
                  {errors.zonetable &&
                    errors.zonetable.message &&
                    errors.zonetable.message.toString()}
                </div>
              </div>
            </div>
            <div className="col-6 mt-5">
              <div className="form-group">
                <div
                  className="switch"
                >
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      id="custom-switch"
                      {...register("active")}
                      checked={activestate}
                      onChange={()=>{setactivestate(!activestate) ;setValue('active',!activestate)}}
                      className="form-check-input"
                    />
                    <label htmlFor="custom-switch" className="form-check-label">
                      {t('activer')}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="reset" className="btn btn-primary-app">
            {t('Annuler')}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            {t('Enregistrer')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
