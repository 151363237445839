import React, { useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { createProductRaccourciApi } from "../../../_App/Redux/Slices/product_raccourci/ProductRaccourciSlice";
import { useAppDispatch } from "../../../_App/Redux/hooks";
import { yupResolver } from "@hookform/resolvers/yup";
import { ProductRaccourciSchema } from "../../../_App/Schemas/ProductRaccourciSchema";
import { Resolver, useForm } from "react-hook-form";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ProductRaccourciType } from "../../../_App/Types/Entites/ProductRaccourciType";

export default function AddNewRaccourciList({
  ShowModal,
  setShowModal,
  raccourci,
  currentOrganisation,
}: any) {
  const dispatch = useAppDispatch();

  // init form hooks

  const formOptions = {
    resolver: yupResolver(
      ProductRaccourciSchema
    ) as unknown as Resolver<ProductRaccourciType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ProductRaccourciType>(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (raccourci) {
      reset({
        libelle: raccourci.libelle,
        organisation_id: raccourci.organisation_id,
       
      });
    } else {
      reset({
        libelle: "",
        ordre: 0,
      });
    }
  }, [raccourci]);

  const onSubmit = async (data: any) => {
    let response;
    data.organisation_id = currentOrganisation;
    response = await dispatch(createProductRaccourciApi(data));   
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
        reset({
          libelle: "",
          organisation_id: currentOrganisation
        });
        setShowModal(false);
      } else {
        ToastWarning(response.payload.message);
      }
    
  };

  return (
    <>
      <Modal show={ShowModal} onHide={() => setShowModal(false)}>
        <Modal.Header>
          <Modal.Title>
            <span className="modal-title" id="addCategorieLabel">
              {" "}
              add raccourcis
            </span>
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={(e) => {
              setShowModal(false);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Modal.Body>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="libelle">Libelle</label>
                  <input
                    className="form-control mb-2"
                    type="text"
                    id="libelle"
                    {...register("libelle")}
                  />
                  <div>{errors.libelle?.message}</div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => {
                setShowModal(false);
              }}
              className="add-raccourci-btn"
            >
              Annuler
            </button>
            <button type="submit" className="add-raccourci-btn">
              Enregistrer
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
}
