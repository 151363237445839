import React, { useState, useEffect } from "react";
import { formadate } from "../../../_App/Helpers/helpers";

export default function HeaderAchatTable({
  t,
  setlistfiltred,
  newlistAchats,
}: any) {

  // Utiliser dateDebutMoins30Jours pour initialiser la date de début
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
  const initialDate = thirtyDaysAgo.toISOString().substr(0, 10);
  
  const [datedebut, setdatedebut] = useState('');
  
  const [datefin, setdatefin] = useState('');

  const [keyword, setkeyword] = useState("");


  const handleSearch = () => {
    if (datefin === null || datedebut === null ) {
      setlistfiltred(newlistAchats);
      return;
    }
  
    const lowercaseKeyword = keyword.toLowerCase();
    const listFiltrer = newlistAchats.filter(
      
      (item:any) =>
        (new Date(formadate(item.date_document))  >= new Date(formadate( datedebut) )&&
          new Date(formadate(item.date_document) )<= new Date(formadate(datefin)) ) &&
        (item.fournisseur.libelle.toLowerCase().includes(lowercaseKeyword) ||
          item.reference_document.toLowerCase().includes(lowercaseKeyword) ||
          item?.refAchat?.toLowerCase().includes(lowercaseKeyword))
    );

    setlistfiltred(listFiltrer.reverse());
    if (datefin=== null && datedebut=== null && keyword === "" || null) {
   
      setlistfiltred(newlistAchats.reverse());
    }
  };
  



  return (
    <div className="container mt-4 mb-4">
      <div className="row ">
      <div className="col-md-3">
        <label>{t('date debut')}</label>
        <input
          type="date"
          className="form-control mb-2"
          value={datedebut}
          onChange={(e) => {

            setdatedebut(e.target.value);
          }}
          
        />
      </div>
      <div className="col-md-3">
        <label>{t('date fin')}</label>
        <input
          type="date"
          className="form-control mb-2"
          value={datefin}
          onChange={(e) => {
            setdatefin(e.target.value);
          }}
        />
      </div>
        <div className="col-md-4 mt-4">
          {/* Row 1 */}
          <input
            type="text"
            className="w-100 form-control product-search br-30"
            id="input-search"
            placeholder={t('chercher par mot cle')}
            onChange={(e) => setkeyword(e.target.value)}
          />
        </div>
        <div className="col-md-2 mt-4"> 
         <button  className="btn btn-primary-app" onClick={()=> handleSearch()}> {t('chercher')}</button>
        </div>
      </div>
    </div>
  );
}
