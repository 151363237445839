import React, { useEffect, useState } from 'react';
import { Ingredient } from '../../../../_App/Types/Entites/Ingredient';
import { IngredientItem } from './IngredientItem';
import { findProductByOrganisationApi  ,ListProduits} from '../../../../_App/Redux/Slices/produits/produitSlice';
import { useAppDispatch , useAppSelector} from '../../../../_App/Redux/hooks';
import { findIngredientByProductApi, Listingredients } from '../../../../_App/Redux/Slices/ingredients/ingredientSlice';

interface Props {
  idProduit: String;
}
export default function IngredientList({ idProduit }:Props) {
  const dispatch = useAppDispatch();
  const ingredients = useAppSelector(Listingredients);
  
  useEffect(() => {
    dispatch(findIngredientByProductApi(idProduit));
  }, []);
  return (

    <div>
      {ingredients.length !== 0 ? (
  <div > 
            <p>Ingredients</p>

          {ingredients.map((item, index) => (
          <div key={index} >
                  <IngredientItem ingredient={item} />
            </div>
          ))}
          </div>          ) : (
              <p>aucun ingredient</p>
)}
   </div>
  
  )
}
