import React, { useEffect, useState } from "react";
import ZoneTableForm from "./ZoneTableForm";

import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  ListZoneTable,
  findZoneTableByOrganisationApi,
  removeZoneTableApi,
} from "../../_App/Redux/Slices/zoneTable/zoneTableSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import ZoneTableType from "../../_App/Types/Entites/ZoneTableType";

const ZoneTableList=({selectedOrganisationId}:any) => {
  const dispatch = useAppDispatch();
  const zoneTables = useAppSelector(ListZoneTable);
  const [showModal, setShowModal] = useState(false);
  const [selectedZoneTable, setSelectedZoneTable] =
    useState<ZoneTableType | null>(null);

  const handleDeleteZoneTable = async (id: string | undefined) => {
    if (id) {
      const response = await dispatch(removeZoneTableApi(id));
      if (response.payload.success) {
        ToastSuccess(response.payload.message);
      } else {
        ToastWarning(response.payload.message);
      }
    } 
  };

  const handleUpdateZoneTable = (updatedZoneTable: ZoneTableType) => {
    setSelectedZoneTable(updatedZoneTable);
    setShowModal(true);
  };

  useEffect(() => {
    if (selectedOrganisationId) {
      dispatch(findZoneTableByOrganisationApi(selectedOrganisationId));
    }
  }, [dispatch, selectedOrganisationId]);

  return (
    <div>
      
        <div className="d-flex justify-content-end mb-3">
        
        </div>
      

      <ZoneTableForm
        showModal={showModal}
        setShowModal={setShowModal}
        zoneTable={selectedZoneTable}
        organisationId={selectedOrganisationId}
      />

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">Libellé</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {zoneTables.map((zoneTable) => (
              <tr key={zoneTable._id}>
                <td>{zoneTable.libelle}</td>
                <td>
                  <div className="d-flex">
                    <span
                      style={{ cursor: "pointer", fontSize: "1.5em" }}
                      onClick={() => handleUpdateZoneTable(zoneTable)}
                    >
                      <i
                        className="fas fa-edit"
                        style={{ color: "#06958d", marginRight: "15px" }}
                      ></i>
                    </span>
                    <span
                      style={{ cursor: "pointer", fontSize: "1.5em" }}
                      onClick={() => handleDeleteZoneTable(zoneTable._id)}
                    >
                      <i
                        className="fas fa-trash-alt"
                        style={{ color: "#FF0000" }}
                      ></i>
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ZoneTableList;
