import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import { FournisseurType } from "../../../Types/Entites/FournisseurType";
import _ from "lodash";

/**
 * create Fournisseur function
 * @private
 */
export const createFournisseurApi = createAsyncThunk(
  "fournisseur/createFournisseurApi",
  async (data: FournisseurType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "fournisseur/createFournisseurApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of fournisseur.
 * @private
 */
export const findFourinsseurByOrganisationApi = createAsyncThunk(
  "fournisseur/findFourinsseurByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`fournisseur/findFourinsseurByOrganisationApi/${idorganisation}`);
      
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of fournisseur.
 * @private
 */
export const findFourinsseurBySocieteApi = createAsyncThunk(
  "fournisseur/findFourinsseurBySocieteApi",
  async (idsociete: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`fournisseur/findFourinsseurBySocieteApi/${idsociete}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


/**
 * update fournisseur.
 * @private
 */

export const updateFournisseurApi = createAsyncThunk(
  "fournisseur/updateFournisseurApi",
  async (data: FournisseurType, { rejectWithValue, dispatch }) => {
    try {
     

      const response = await axios.put( "fournisseur/updateFournisseurApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * delete fournisseur.
 * @private
 */

export const deleteFournisseurApi = createAsyncThunk(
  "fournisseur/deleteFournisseurApi",
  async (id: String, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.delete(
        `fournisseur/deleteFournisseurApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


interface FournisseurState {
  listFournisseur: FournisseurType[];
  listFournisseurParOrganisation : { [organisation: string]: FournisseurType[] };
}
 

const initialState: FournisseurState = {
  listFournisseur: [],
  listFournisseurParOrganisation : {},
};
export const fournisseurSlice = createSlice({
  name: "fournisseur",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createFournisseurApi.fulfilled, (state, action) => {
      state.listFournisseur.splice(0, 0, action.payload.data);
    });
    builder.addCase(findFourinsseurByOrganisationApi.fulfilled, (state, action) => {
      state.listFournisseur = action.payload;
  
    });
    builder.addCase(findFourinsseurBySocieteApi.fulfilled, (state, action) => {
      const fournisseurparorganisationNotNull = action.payload.filter((item:any)=>item.organisation_id !== null)
      const groupedByOrganisation = _.groupBy(fournisseurparorganisationNotNull, "organisation_id");
    
      state.listFournisseur = action.payload;  // First update
      state.listFournisseurParOrganisation = groupedByOrganisation;  // Second update
    });
    builder.addCase(updateFournisseurApi.fulfilled, (state, action) => {
      const updated_fournisseur = action.payload.data;
      var index = _.findIndex(state.listFournisseur, {
        _id: updated_fournisseur._id,
      });
      // Replace item at index using native splice
      state.listFournisseur.splice(index, 1, updated_fournisseur);
    });

    builder.addCase(deleteFournisseurApi.fulfilled, (state, action) => {
      const deleted_fournisseur = action.payload.data;
      state.listFournisseur = state.listFournisseur.filter(
        (item) => item._id !== deleted_fournisseur._id
      );
    });
   
  },
  
});

export const ListFournisseurs = (state: RootState) => state.fournisseurs.listFournisseur;
export const ListFournisseurParOrganisation = (state: RootState) => state.fournisseurs.listFournisseurParOrganisation;

export default fournisseurSlice.reducer;
