import React from 'react'
import { Helmet } from 'react-helmet';


interface PageTitleProps {
    title: string;
  }
  
export default function PageTitle({title}:PageTitleProps) {
  return (
    <div className="App">
    <Helmet>
      <title>{title}</title>
      <meta name="description" content="App Description" />
      <meta name="theme-color" content="#008f68" />
    </Helmet>
  </div>
  )
}
