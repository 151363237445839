import React ,{useState} from 'react';

import { TfiLayoutGrid4Alt, TfiLayoutGrid3Alt } from 'react-icons/tfi';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown'

type HeaderProps = {
  handleNumColumnsChange: (numColumns: number) => void;
  handleNumitemChange: (numbitem: number) => void;
  searchByKeyword:(keyword: string) => void;
};

export default function Header({ handleNumColumnsChange ,handleNumitemChange,searchByKeyword}: HeaderProps) {


  
  return (
    <Navbar className="bg-body-tertiary" expand="lg">
    <Container fluid>
      <Navbar.Toggle aria-controls="navbarScroll" />
      <Navbar.Collapse id="navbarScroll">
        <Nav
          className="me-auto my-2 my-lg-0"
          style={{ maxHeight: '100px' }}
          navbarScroll
        >
          <Nav.Link href="#action1">
            <TfiLayoutGrid3Alt
              style={{ fontSize: '24px' }}
              onClick={() => handleNumColumnsChange(3)}
            />
          </Nav.Link>
          <Nav.Link href="#action2">
            <TfiLayoutGrid4Alt
              style={{ fontSize: '24px' }}
              onClick={() => handleNumColumnsChange(4)}
            />
          </Nav.Link>
          <NavDropdown title="nombre des items " id="navbarScrollingDropdown">
            <NavDropdown.Item onClick={() => handleNumitemChange(5)}>5</NavDropdown.Item>
            <NavDropdown.Item onClick={() => handleNumitemChange(4)}>4</NavDropdown.Item>
          </NavDropdown>
        </Nav>
        <Form className="d-flex">
          <Form.Control
            type="search"
            placeholder="Search"
            className="me-2"
            aria-label="Search"
            onChange={(e) => searchByKeyword(e.target.value)}
          />
        
        </Form>
      </Navbar.Collapse>
    </Container>
  </Navbar>
  );
}

  
  
/**  <div className="alert custom-alert-1 mb-4" role="alert">
 <div className="media-body">
 <div className="alert-text">
   <TfiLayoutGrid3Alt
     style={{ fontSize: '24px' }}
     onClick={() => handleNumColumnsChange(3)}
   />
 </div>
 <div className="media-body">
   <div className="alert-text">
     <TfiLayoutGrid4Alt
       style={{ fontSize: '24px' }}
       onClick={() => handleNumColumnsChange(4)}
     />
   </div>
   <div className="alert-btn">
     <form className="form-inline my-2 my-lg-0 justify-content-center ">
       <div className="w-100">
         <input
           type="text"
           className="w-100 form-control product-search br-30"
           id="input-search"
           placeholder="Search Attendees..."
         />
       </div>
     </form>
   </div>
<div className="btn-group dropright mb-4 mr-2" role="group">
<button id="btnDropRightOutline" type="button" className="btn btn-outline-warning dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Right <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-chevron-right"><polyline points="9 18 15 12 9 6" /></svg></button>
<div className="dropdown-menu" aria-labelledby="btnDropRightOutline">
<a href="javascript:void(0);" className="dropdown-item">Action</a>
<a href="javascript:void(0);" className="dropdown-item">Another action</a>
<a href="javascript:void(0);" className="dropdown-item">Something else here</a>
<div className="dropdown-divider" />
<a href="javascript:void(0);" className="dropdown-item">Separated link</a>
</div>
</div>

 </div>
</div>
</div> */