// CategorieItem.js
import React from 'react';
import { CategorieType } from '../../../../_App/Types/Entites/CategorieType';

interface CategorieItemProps {
  categorie: CategorieType;
  onClick: () => void;
}

const CategorieItem: React.FC<CategorieItemProps> = ({ categorie, onClick }) => {
  return (
    <div>
    <div className="card " onClick={onClick}>
      <div className="card-body">
        <h5 className="card-title">{categorie.libelle.fr}</h5>
      </div>
    </div>
  </div> 
  );
}
 



export default CategorieItem;

