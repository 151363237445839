import { useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import UpdateUserForm from "./EditUserForm";
import AddUserForm from "./UserForm";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { SelectedOrganisationId, selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListUsers,
  closeSessionUserApi,
  deleteUserApi,
  findUsersByOrganisationsListApi,
} from "../../_App/Redux/Slices/users/userSlice";
import { useTranslation } from "react-i18next";

const UserList = () => {
  const dispatch = useAppDispatch();
  const users = useAppSelector(ListUsers);
  const [addUserModalVisible, setAddUserModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [updateUserModalVisible, setUpdateUserModalVisible] = useState(false);

  const id_organisation = useAppSelector(SelectedOrganisationId);

  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [deleteUserId, setDeleteUserId] = useState<string | null>(null);

  

  const handleDeleteUser = (userId: string) => {
    setDeleteUserId(userId);
    setShowDeleteConfirmationModal(true);
  };

  const handleDeleteConfirmation = () => {
    if (deleteUserId) {
      dispatch(deleteUserApi(deleteUserId));
      setShowDeleteConfirmationModal(false);
    }
  };
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  const handleEditUser = (user: any) => {
    setSelectedUser(user);
    setUpdateUserModalVisible(true);
  };
  const [showSessionModal, setShowSessionModal] = useState(false);

  const [showLogoutConfirmationModal, setShowLogoutConfirmationModal] =
  useState(false);
const [logoutUserId, setLogoutUserId] = useState<string | null>(null);

const handleLogoutUser = (userId: string) => {
  setLogoutUserId(userId);
  setShowLogoutConfirmationModal(true);
};

const handleLogoutConfirmation = () => {
  if (logoutUserId) {
    const selectedUser = users.find((user) => user._id === logoutUserId);

    if (selectedUser && selectedUser.online !== undefined) {
      if (!selectedUser.online) {
        ToastWarning(
          `L'utilisateur ${selectedUser.nom} ${selectedUser.prenom} est déjà déconnecté.`
        );
        setShowSessionModal(true);
      } else {
        dispatch(closeSessionUserApi(logoutUserId));
        ToastSuccess(
          `L'utilisateur ${selectedUser.nom} ${selectedUser.prenom} est déconnecté.`
        );
      }
    } else {
      console.error(
        "Impossible de déterminer l'état de connexion de l'utilisateur."
      );
    }

    setShowLogoutConfirmationModal(false);
  }
};

  const filteredUsers = users.filter((user) => {
    const userString = Object.values(user)
      .map((value) => {
        if (Array.isArray(value)) {
          return value.map((org) => org.nom).join(" ");
        }
        return String(value);
      })
      .join(" ")
      .toLowerCase();

    const roleLibelle = user.role_id?.libelle
      ? String(user.role_id.libelle).toLowerCase()
      : "";

    return (
      userString.includes(searchTerm.toLowerCase()) ||
      roleLibelle.includes(searchTerm.toLowerCase())
    );
  });

  useEffect(() => {
    if (id_organisation) {
      dispatch(findUsersByOrganisationsListApi());
    }
  }, [dispatch, id_organisation]);

  useEffect(() => {
    if (Array.isArray(users) && users.length > 0) {
    }
  }, [users]);

  const { t } = useTranslation();

  const autorisations = useAppSelector(selectedAutorisations);
  const CanUpdateUser = autorisations?.CAN_UPDATE_USER ;
  const CanCreateUser = autorisations?.CAN_CREATE_USER ;
  const CanDeleteUser = autorisations?.CAN_DELETE_USER;
 const CanCloseSession =autorisations?.CAN_CLOSE_SESSION_USER;



  return (
    <div>
      <Modal show={showSessionModal} onHide={() => setShowSessionModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("L'utilisateur est déjà déconnecté.")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("L'utilisateur est déjà déconnecté.")}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowSessionModal(false)}
          >
            {t("Fermer")}
          </Button>
        </Modal.Footer>
      </Modal>
      <div className="d-flex justify-content-end align-items-center mt-3">
        <Form.Group className="mb-3">
          <Form.Control
            type="text"
            placeholder={t("Rechercher")}
            value={searchTerm}
            onChange={handleSearch}
          />
        </Form.Group>
        {CanCreateUser && (

        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip id="tooltip-add-user">
              {t("Ajouter un nouveau utilisateur")}
            </Tooltip>
          }
        >

          <span
            style={{
              cursor: "pointer",
              marginLeft: "15px",
              marginBottom: "16px",
            }}
            onClick={() => setAddUserModalVisible(true)}
          >
            <i
              className="fas fa-plus-circle"
              style={{ color: "#07a96d", fontSize: "32px" }}
            ></i>
          </span>
        </OverlayTrigger>
        )}
      </div>

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Nom")}</th>
              <th scope="col">{t("Prénom")}</th>
              <th scope="col">{t("Téléphone")}</th>
              <th scope="col">{t("Email")}</th>
              <th scope="col">{t("Role")}</th>
              <th scope="col">{t("Compte")}</th>
              <th scope="col">{t("Status")}</th>

              <th scope="col">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {Array.isArray(users) && users.length > 0 ? (
              filteredUsers.map((user) => (
                <tr key={user._id}>
                  <td>{user.nom}</td>
                  <td>{user.prenom}</td>
                  <td>{user.mobile}</td>
                  <td>{user.email}</td>
                  <td>{user.role_id?.libelle}</td>

                  <td>
                    <p className="d-flex align-items-center">
                      {user.is_active ? (
                        <i
                          className="fas fa-unlock"
                          style={{
                            color: "green",
                            marginRight: "20px",
                            fontSize: "20px",
                          }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-ban"
                          style={{
                            color: "red",
                            marginRight: "20px",
                            fontSize: "20px",
                          }}
                        ></i>
                      )}
                      <span>{user.is_active ? "Actif" : "Inactif"}</span>
                    </p>
                  </td>
                  <td>
                    <p>
                      {user.online ? (
                        <i
                          className="fas fa-circle"
                          style={{ color: "green", marginRight: "5px" }}
                        ></i>
                      ) : (
                        <i
                          className="fas fa-circle"
                          style={{ color: "red", marginRight: "5px" }}
                        ></i>
                      )}
                      {user.online ? "Connecté" : "Déconnecté"}
                    </p>
                  </td>
                  <td>
                    <div className="d-flex">
                    {CanUpdateUser && (

                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                        onClick={() => handleEditUser(user)}
                      >
                        <i
                          className="fas fa-edit"
                          style={{ color: "#06958d" }}
                        ></i>
                      </span>
                    )}
                     {CanDeleteUser && (
                      <span
                        style={{
                          cursor: "pointer",
                          fontSize: "1.5em",
                          marginRight: "10px",
                        }}
                        onClick={() => handleDeleteUser(user._id)}
                      >
                        <i
                          className="fas fa-trash-alt"
                          style={{ color: "#FF0000" }}
                        ></i>
                      </span>
                     )}

{CanCloseSession && (
                        <span
                          style={{
                            cursor: "pointer",
                            fontSize: "1.5em",
                            marginRight: "10px",
                          }}
                          onClick={() => handleLogoutUser(user._id)}
                        >
                          <i
                            className="fas fa-power-off"
                            style={{ color: "#FFA500" }}
                          ></i>
                        </span>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>Aucun utilisateur à afficher.</td>
              </tr>
            )}
          </tbody>
        </table>
        <Modal
          show={showDeleteConfirmationModal}
          onHide={() => setShowDeleteConfirmationModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation de suppression</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            Êtes-vous sûr de vouloir supprimer cet utilisateur?
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn btn-primary-app"
              onClick={() => setShowDeleteConfirmationModal(false)}
            >
              Annuler
            </Button>
            <Button
              className="btn btn-primary-app"
              onClick={handleDeleteConfirmation}
            >
              Enregistrer
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
        show={showLogoutConfirmationModal}
        onHide={() => setShowLogoutConfirmationModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de déconnexion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir déconnecter cet utilisateur?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={() => setShowLogoutConfirmationModal(false)}
          >
            Annuler
          </Button>
          <Button
            className="btn btn-primary-app"
            onClick={handleLogoutConfirmation}
          >
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>
      </div>

      <AddUserForm
        ShowModal={addUserModalVisible}
        setShowModal={setAddUserModalVisible}
      />

      <UpdateUserForm
        ShowModal={updateUserModalVisible}
        setShowModal={setUpdateUserModalVisible}
        userData={selectedUser}
      />
    </div>
  );
};

export default UserList;
