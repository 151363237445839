import { Dispatch, SetStateAction, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import AppMenuParametrageType from "../../../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import { Modal } from "react-bootstrap";
import styled from "styled-components";

const StyledCard = styled.div`

    width: 100%;
    min-height: 500px!important;
    display: flex;
    align-items: center;
    justify-content: space-around;
  .component-card_1 {
    width: 100%;
    height: 260px;
  }
  .card-body {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  div.plus-form {
    font-size: 40px;
  }
  .body-card-selection{
    
  }
`;

const SelectTypeAffectation = ({
  ShowModal,
  setShowModal,
  ShowModalListOrganisations,
  setShowModalListOrganisations,
  ShowModalListRoles,
  setShowModalListRoles,
  menu,
}: {
  ShowModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  ShowModalListOrganisations: boolean;
  setShowModalListOrganisations: Dispatch<SetStateAction<boolean>>;
  ShowModalListRoles: boolean;
  setShowModalListRoles: Dispatch<SetStateAction<boolean>>;
  menu: AppMenuParametrageType | null;
}) => {
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="affectation-menu"
      dialogClassName="modal-80w"
      className="modal-2"
      backdropClassName="modal-2"
    >
      <Modal.Header closeButton>
        <Modal.Title id="affectation-menu" className="custom-modal-title">
          <div className="titleWithButton">
            <div>
              {" "}
              Affecter le menu{" "}
              <span className="c-primary">{menu && menu.libelle}</span> à :{" "}
            </div>
            <div></div>
          </div>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="body-modal-selection" >
        <StyledCard>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div
                className={`card component-card_1 relative bg-primary-app`}
                onClick={(e) => {
                  setShowModalListOrganisations(true);
                }}
              >
                <div className="card-body">
                  <div className="plus-form c-primary">Par Organisations</div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
              <div
                className={`card component-card_1 relative bg-primary-app`}
                onClick={(e) => {
                  setShowModalListRoles(true);
                }}
              >
                <div className="card-body">
                  <div className="plus-form c-primary">Par Utilisateurs</div>
                </div>
              </div>
            </div>
          </div>
        </StyledCard>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

export default SelectTypeAffectation;
