import React, { useEffect } from "react";
import { useAppDispatch } from "../../../_App/Redux/hooks";
import { changeFilter } from "../../../_App/Redux/Slices/Caisse/CaisseSlice";

const CaisseSousCategorieItem = ({ Item, col }: any) => {
  const dispatch = useAppDispatch();
  const handleLinkChange = async (table: any) => {
    dispatch(
      changeFilter({
        table: table,
        filteredBy: Item,
        rightSideSelectedProduct: "",
      })
    );
  };
  return (
    <>
      <div
        className={col + " col layout-spacing product-item"}
        onClick={(e) => {
          handleLinkChange("products");
        }}
      >
        <div className="color-box">
          <div className="round-container">
            <img
              src={
                Item.image
                  ? process.env.REACT_APP_API_PUBLIC_URL +
                    "/sous-catgeorie/" +
                    Item.image
                  : "/assets/assets/img/no-image.jpg"
              }
              alt="Your"
            />
          </div>
          <div className="cl-info">
            <h6 className="cl-title">{Item.libelle.fr}</h6>
            {/* <span>#4361ee</span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default CaisseSousCategorieItem;
