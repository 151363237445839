import React, { useState, useEffect } from "react";
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  getAddressByMacApi,
  createUserAddressMacApi,
} from "../../_App/Redux/Slices/userMacAdress/userAddressMacSlice";
import { selectUser } from "../../_App/Redux/Slices/Auth/authSlice";
import { UserMacAdressDataType } from "../../_App/Types/Forms/UserMacAdressDataType";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { useTranslation } from "react-i18next";

const Container = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const Label = styled.label`
  font-weight: bold;
  margin-right: 8px;
  white-space: nowrap;
  width:120px;
`;

const Input = styled.input`
  width: 200px; /* Vous pouvez ajuster la largeur selon vos besoins */
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const MacAddressComponent: React.FC<{ onClosePopup: (success: boolean) => void }> = ({
  onClosePopup,
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(selectUser);
  const user_id = currentUser?._id;
  const currentMacAddress = currentUser?.current_mac_address;

  const [mounted, setMounted] = useState(false);
  const [codeBarre, setCodeBarre] = useState("");
  const [codeCaisse, setCodeCaisse] = useState("");
  const [libelle, setLibelle] = useState("");
  const [formValid, setFormValid] = useState(false);
  const {t} =useTranslation();
  

  useEffect(() => {
    if (!mounted) {
      setMounted(true);
      return;
    }

    const checkAddressExistence = async () => {
      if (currentMacAddress) {
        try {
          const response = await dispatch(getAddressByMacApi(currentMacAddress));

          if (response.payload.data === true) {
          } else {
            ToastWarning("Attention : votre appareil n'est pas enregistré");
          }
        } catch (error) {
        }
      }
    };

    checkAddressExistence();
  }, [currentMacAddress, dispatch, mounted]);

  const handleAddMacAddress = async () => {
    if (currentMacAddress && codeCaisse) {
      const userMacAddress: UserMacAdressDataType = {
        mac_address: currentMacAddress,
        code_a_barre: codeBarre,
        code_caisse: codeCaisse,
        libelle: libelle,
        user_id : user_id,
      };

      try {
        const response = await dispatch(createUserAddressMacApi(userMacAddress));
    
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
          setFormValid(true);
          onClosePopup(true); 
        } else {
          ToastWarning("L'adresse MAC existe déjà !");
          setFormValid(false);
          onClosePopup(false);
        }
      } catch (error) {
        ToastWarning("Une erreur s'est produite lors de l'appel API.");
        setFormValid(false);
        onClosePopup(false); 
      }
    } else {
      ToastWarning("Veuillez remplir tous les champs obligatoires.");
      setFormValid(false);
    }
  };

  const handleClosePopup = () => {
    onClosePopup(formValid);
  };

  return (
    <Container>
      <InputContainer>
        <Label>{t("Code Caisse:")}  *</Label>
        <Input
          type="text"
          value={codeCaisse}
          onChange={(e) => setCodeCaisse(e.target.value)}
          required
        />
      </InputContainer>

      <InputContainer>
        <Label>{t("Libellé")} :</Label>
        <Input
          type="text"
          value={libelle}
          onChange={(e) => setLibelle(e.target.value)}
        />
      </InputContainer>

      <InputContainer>
        <Label>{t("Code à Barre")}:</Label>
        <Input
          type="text"
          value={codeBarre}
          onChange={(e) => setCodeBarre(e.target.value)}
        />
      </InputContainer>

      <div>
        <ButtonContainer>
          <button onClick={handleAddMacAddress} className="btn btn-primary-app">
          {t("Enregistrer")}  <i className="fas fa-check"></i>
          </button>
        </ButtonContainer>
      </div>
    </Container>
  );
};

export default MacAddressComponent;
