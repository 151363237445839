import React, { useEffect, useState } from "react";
import CommissionForm from "../../Components/Commission/CommissionForm";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { Navbar } from "react-bootstrap";
import { ListCommission, findCommissionApi } from "../../_App/Redux/Slices/commission/commissionSlice";
import CommissionList from "../../Components/Commission/CommissionList";

export default function CommissionPage() {
  const [ShowModal, setShowModal] = useState(false);
  const { t } = useTranslation();
  const societe_id = useAppSelector(societeId);
  const dispatch = useAppDispatch()
  const listcommission = useAppSelector(ListCommission)

  useEffect(() => {
    if (societe_id) {
      dispatch(findCommissionApi(societe_id));
    }
  }, [dispatch, societe_id]);

  return (
    <div>
      <Navbar />

      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className="btn btn-primary-app"
          onClick={() => setShowModal(true)}
        >
          {t("Ajouter commission")}
        </button>
      </div>
      <CommissionForm
        t={t}
        societe_id={societe_id}
        ShowModal={ShowModal}
        setShowModal={setShowModal}
      />
      <CommissionList t={t} listcommission={listcommission} />
    </div>
  );
}
