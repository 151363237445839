import React, { useEffect } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { ProductTailleSchema } from "../../../_App/Schemas/ProductTailleSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  createProductTailleApi,
  updateProductTailleApi,
} from "../../../_App/Redux/Slices/product_taille/product_tailleSlice";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ProductTailleType } from "../../../_App/Types/Entites/ProductTailleType";
export default function ProductTaillesForm({
  ShowModal,
  setShowModal,
  ProductTaille,
  organisation_id
}: any) {
  const dispatch = useAppDispatch();

  // init form hooks
  const formOptions = {
    resolver: yupResolver(
      ProductTailleSchema
    ) as unknown as Resolver<ProductTailleType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ProductTailleType>(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (ProductTaille) {
      reset({
        _id: ProductTaille._id,
        organisation_id: ProductTaille.organisation_id,
        libelle: ProductTaille.libelle,
        type_taille: ProductTaille.type_taille,
        type_taille_chaussures: ProductTaille.type_taille_chaussures,
      });
    } else {
      reset({
        libelle: "",
        type_taille: "",
        type_taille_chaussures: "",
      });
    }
  }, [ProductTaille]);

  const onSubmit = async (data: any) => {
    let response;

    if (data._id) {
      response = await dispatch(updateProductTailleApi(data));
    } else {
      data.organisation_id = organisation_id;
      response = await dispatch(createProductTailleApi(data));
    }
    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        libelle: "",
        type_taille: "",
        type_taille_chaussures: "",
      });
      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  return (
    <Modal show={ShowModal} onHide={() => setShowModal(false)}     className="modal-3"
    backdropClassName="modal-backdrop-3">
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">Ajouter des Taille</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-row align-items-center justify-content-center">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>libelle</Form.Label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("libelle")}
                />
                <div>{errors.libelle?.message}</div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>type_taille</Form.Label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("type_taille")}
                />
                <div>{errors.type_taille?.message}</div>
              </div>
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>type_taille_chaussures</Form.Label>
              <div className="col-auto">
                <input
                  type="text"
                  className="form-control mb-2"
                  {...register("type_taille_chaussures")}
                />
                <div>{errors.type_taille_chaussures?.message}</div>
              </div>
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => setShowModal(false)}
            className="btn btn-grey"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
