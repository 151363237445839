import { array, boolean, number, object, string } from 'yup';
// import { array, number, ref, object, string, mixed, BooleanSchema, boolean, bool } from 'yup';


export const AffectationMenuSchema  = object({
    menu_id : string().required(),
    // organisations: object().shape({
    //     user_id: string().required('Position Obligatoire!!'),
    //     checked: boolean().optional()
    // })
    
});