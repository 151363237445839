import React, { useEffect, useState } from "react";
import ProduitItem from "./ProduitItem";
import Pagination from "../../../../Shared/Pagination/Pagination";
import { Produit } from "../../../../_App/Types/Entites/Produit";
import {
  findProductByOrganisationApi,
  ListProduits,
  ListProduitsParCategorie,
  updatepagination,
  PaginationProduits,
} from "../../../../_App/Redux/Slices/produits/produitSlice";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Form } from "react-bootstrap";
import { PaginationType } from "../../../../_App/Types/Entites/PaginationType";
import Header from "../../../../Shared/Header/Header";

interface PageProps {
  id: string | undefined;
}

export default function ProduitList({ id }: PageProps) {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const produits = useAppSelector(ListProduits);
  const produitspercategorie = useAppSelector(ListProduitsParCategorie);
  const paginationcontroller = useAppSelector(PaginationProduits);

  const [numColumns, setNumColumns] = useState(
    paginationcontroller.NumberColone
  );
  const [numitemPage, setNumitemPage] = useState(
    paginationcontroller.itemsPerPage
  );
  const [produitlist, setProduitlist] = useState<Produit[]>([]);

  useEffect(() => {
    const idOrganisation =
      localStorage.getItem("idOrganisation") || "63e1130f9c17708ead8be889";
    dispatch(findProductByOrganisationApi(idOrganisation));
  }, [dispatch]);

  useEffect(() => {
    if (id !== undefined) {
      const productsForCategory = produitspercategorie[id] || [];
      console.log(productsForCategory);
      setProduitlist(productsForCategory);
    } else {
      setProduitlist(produits);
    }
  }, [produits, produitspercategorie]);

  const searchByKeyword = (keyword: string) => {
    const filteredArticles = produitlist.filter((produit: Produit) => {
      const hasKeywordInTitles =
        produit.titre.fr.includes(keyword) ||
        produit.titre.en.includes(keyword) ||
        produit.titre.ar.includes(keyword);

      const hasKeywordInDescription =
        produit.description.fr.includes(keyword) ||
        produit.description.en.includes(keyword) ||
        produit.description.ar.includes(keyword);

      return hasKeywordInTitles || hasKeywordInDescription;
    });
    setProduitlist(filteredArticles);
  };

  //pagination et colones

  const handleNumColumnsChange = (numbcol: number) => {
    const newNumColumns = numbcol;

    const PaginationData: PaginationType = {
      itemsPerPage: numitemPage ? numitemPage : 0,
      NumberColone: newNumColumns ? newNumColumns : 0,
    };
    dispatch(updatepagination(PaginationData));

    setNumColumns(newNumColumns);
  };

  const handleNumitemChange = (numbitem: number) => {
    const newNumitems = numbitem;

    const PaginationData: PaginationType = {
      itemsPerPage: newNumitems ? newNumitems : 0,
      NumberColone: numColumns ? numColumns : 0,
    };
    dispatch(updatepagination(PaginationData));
    setNumitemPage(newNumitems);
  };
  const [currentPage, setCurrentPage] = useState(1);

  const indexOfLastItem = currentPage * numitemPage;
  const indexOfFirstItem = indexOfLastItem - numitemPage;
  const currentItems = produitlist.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(produitlist.length / numitemPage);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  return (
    <>
      <Header
        handleNumColumnsChange={handleNumColumnsChange}
        handleNumitemChange={handleNumitemChange}
        searchByKeyword={searchByKeyword}
      />

      <div className="mt">
        {produitlist.length !== 0 ? (
          <Container>
            <Row>
              {currentItems.map((produitItem, index) => (
                <Col key={index} xs={12 / numColumns}>
                  <div>
                    <ProduitItem produit={produitItem} />
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        ) : (
          <div className="spinner-grow text-info align-self-center">
            Loading...
          </div>
        )}
      </div>

      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={onPageChange}
      />
    </>
  );
}
