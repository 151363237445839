import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListTable,
  findTableByOrganisationApi,
  updateTableStatusApi,
} from "../../_App/Redux/Slices/table/tableSlice";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  layout,
  selectFullTable,
  selectTable,
} from "../../_App/Redux/Slices/Caisse/CaisseSlice";
import NavbarCaisse from "../../_Layouts/Navbar/NavbarCaisse";

const TablesPage = () => {
  const dispatch = useAppDispatch();
  const tables = useAppSelector(ListTable);
  const Layout = useAppSelector(layout);

  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const selectATable = (table: any) => {
    dispatch(
      selectTable({
        table: table,
      })
    );
  };

  const openTableOrders = (table: any) => {
    console.log(table);
    dispatch(
      selectFullTable({
        table: table,
      })
    );
  };

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(findTableByOrganisationApi(currentOrganisation));
    }
  }, [dispatch]);
  return (
    <>
      <NavbarCaisse Title={"Tables"} />
      <div
        className="caisse-data-content"
        style={{ marginLeft: Layout.marginLeft }}
      >
        <div className="row" id="cancel-row">
          <div className="col-lg-12 layout-spacing layout-top-spacing">
            <div
              className="statbox widget box box-shadow"
              style={{ padding: "0px", paddingBottom: "15px" }}
            >
              <div className="widget-header">
                <div className="row">
                  <div className="col-xl-12 col-md-12 col-sm-12 col-12"></div>
                </div>{" "}
                <div className="row layout-top-spacing">
                  {tables &&
                    tables.map((item: any) => (
                      <div
                        className={" col layout-spacing product-item"}
                        onClick={(e) => {
                          console.log(item.status);
                          if (item.status === "Occupée") {
                            openTableOrders(item);
                          } else {
                            window.location.href = "/caisse";
                            selectATable(item);
                          }
                        }}
                        key={item._id}
                      >
                        <div className="color-box">
                          <div className="round-container"> 
                            <img
                              src={
                                item.status && item.status === "Occupée"
                                  ? "/assets/assets/img/full.png"
                                  : "/assets/assets/img/available.png"
                              }
                              alt="Your"
                            />
                          </div>
                          <div className="cl-info">
                            <h6 className="cl-title">{item.numero}</h6>
                            {/* <span>#4361ee</span> */}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TablesPage;
