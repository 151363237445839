import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { FournisseurType } from '../../../_App/Types/Entites/FournisseurType'
import { Produit } from '../../../_App/Types/Entites/Produit'
import { useAppSelector } from '../../../_App/Redux/hooks'
import { ListProduits } from '../../../_App/Redux/Slices/produits/produitSlice'
import { Modal } from 'react-bootstrap'
import { ListFournisseurs } from '../../../_App/Redux/Slices/fournisseur/fournisseurSlice'


export default function ArticleFournisseur({t,showModal,setshowModal,refproduct  , idarticle }: any) {
    const listproduct = useAppSelector(ListProduits)
    const listfounisseur = useAppSelector(ListFournisseurs)
    const [list_produitArticle , setlistProduitArticle] = useState<Produit[]>([])

      //useEffect
  useEffect(() => {
    if(idarticle){
        const findlistproduct = listproduct.filter((item:any) => item.article_id === idarticle)
        const updatedList = findlistproduct.map(element => {
          const found_fournisseur = listfounisseur.find((item :any)=> item._id === element.fournisseurs);
          console.log('listfounisseur',listfounisseur)

          if (found_fournisseur) {
              return {
                  ...element,
                  nom_fournisseur: found_fournisseur 
              };
          } else {
              return element;
          }

      });
      console.log("updatedList" ,updatedList)
      setlistProduitArticle(updatedList);
  
    }
  }, [idarticle]);


  return (
    <Modal
    show={showModal}
    onHide={() => setshowModal(false)}
    dialogClassName="modal-70w"
  >
    <Modal.Header closeButton>
      <Modal.Title className="c-primary">
        <div className="row">
          <div className="col-md-12"> {t(" Article")}</div>
        </div>
      </Modal.Title>
    </Modal.Header>

   
      <Modal.Body>
      <table className="table table-bordered">
    <thead>
      <tr>
        <th>{t('Produit')}</th>
        <th>{t('Fournisseur')}</th>
        <th>{t('Prix achat ht')}</th>
        <th>{t('Prix achat ttc')}</th>
   



      </tr>
    </thead>
    <tbody>
      {list_produitArticle.map((item: any) => (
        <tr key={item._id} >
     
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item.titre.fr}</td>
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item?.nom_fournisseur?.libelle}</td>
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item.prix_achat_ht}</td>  
          <td className={item._id === refproduct ? 'highlighted-row' : ''}> {item.prix_achat_ttc}</td>
          
        
        </tr>
      ))}
    </tbody>
  </table>
      </Modal.Body>

 
  </Modal>
  
  )
}
