import React, { useState, useEffect } from "react";
import ProductCaracteristiquesForm from "../../../Components/Products/ProductCaracteristiques/ProductCaracteristiquesForm";
import ProductCaracteristiqueTable from "../../../Components/Products/ProductCaracteristiques/ProductCaracteristiqueTable";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListProductCaracteristiques,
  deleteProductsCaracteristiquesApi,
  findProductsCaracteristiquesApiBySociete,
} from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { SelectedOrganisationId , societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
export default function ProductCaracteristiquesPage() {
  const { t } = useTranslation();

  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);

  const [ShowModalProductCaracteristique, setShowModalProductCaracteristique] =
    useState(false);
  const [selectedProductCaracteristique, setSelectedProductCaracteristique] =
    useState(null);
  const dispatch = useAppDispatch();
  const ProductCaracteristiques = useAppSelector(ListProductCaracteristiques);

  useEffect(() => {
   if(societe) dispatch(findProductsCaracteristiquesApiBySociete(societe));
  }, [dispatch]);

  const openEditForm = (ProductsCaracteristiques: any) => {
    setSelectedProductCaracteristique(ProductsCaracteristiques);
    setShowModalProductCaracteristique(true);
  };
  const deleteitem = (id: any) => {
    dispatch(deleteProductsCaracteristiquesApi(id));
  };

  return (
    <div>
      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className=" btn btn-primary-app"
          onClick={() => setShowModalProductCaracteristique(true)}
        >
          {t('Ajouter  Caracteristiques produit')}
        </button>
      </div>
      <ProductCaracteristiquesForm
        ShowModal={ShowModalProductCaracteristique}
        setShowModal={setShowModalProductCaracteristique}
        ProductCaracteristique={selectedProductCaracteristique}
        organisation_id={currentOrganisation}
        societe={societe}
      />
      {ProductCaracteristiques && ProductCaracteristiques.length !== 0 ? (
        <ProductCaracteristiqueTable
          ListProductCaracteristiques={ProductCaracteristiques}
          onEditClick={openEditForm}
          deleteitem={deleteitem}
        />
      ) : (
        <p>{t('aucune Caracteristiques')}</p>
      )}
    </div>
  );
}
