import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import { PaiementAchatType } from "../../../Types/Entites/Achat/PaiementAchatType";

/**
 * post paiement achat
 * @public
 */
export const createPaiementAchatApi = createAsyncThunk(
  "paiementachat/createPaiementAchatApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.post(
        `paiementachat/createPaiementAchatApi`,
        data
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
/**
 * Get list of Paiement par achat
 * @public
 */
export const findPaiementAchatByAchatId = createAsyncThunk(
  "paiementachat/findPaiementAchatByAchatId",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `paiementachat/findPaiementAchatByAchatId/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of Paiement par fournisseur
 * @public
 */
export const findPaiementAchatByFournisseurId = createAsyncThunk(
  "paiementachat/findPaiementAchatByFournisseurId",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `paiementachat/findPaiementAchatByFournisseurId/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of Paiement par societe
 * @public
 */
export const findPaiementAchatBySocieteId = createAsyncThunk(
  "paiementachat/findPaiementAchatBySocieteId",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `paiementachat/findPaiementAchatBySocieteId/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
interface AchatState {
  listpaiementAchat: PaiementAchatType[];
  listpaiementAchatParFournisseur: { [fournisseur: string]: PaiementAchatType[] };
  
}



const initialState:AchatState={
  listpaiementAchat: [] ,
  listpaiementAchatParFournisseur: {},
}
export const PaiementAchatSlice = createSlice({
  name: "PaiementAchat",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createPaiementAchatApi.fulfilled, (state, action) => {
      state.listpaiementAchat.splice(0, 0, action.payload.data);
    });
    builder.addCase(findPaiementAchatByAchatId.fulfilled, (state, action) => {
      state.listpaiementAchat = action.payload;
    });
    builder.addCase(findPaiementAchatByFournisseurId.fulfilled, (state, action) => {
      state.listpaiementAchat = action.payload;
    });
    builder.addCase(findPaiementAchatBySocieteId.fulfilled, (state, action) => {
      const groupedpaiement= _.groupBy(action.payload, "Fournisseur");
 
      state.listpaiementAchatParFournisseur = groupedpaiement;
      state = action.payload;
    });
  },
});

export const ListPaiementAchat = (state: RootState) => state.paiementachats.listpaiementAchat;
export const ListPaiementAchatFournisseur = (state: RootState) => state.paiementachats.listpaiementAchatParFournisseur;

export default PaiementAchatSlice.reducer;
