import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { ProductDetailType } from "../../../Types/Entites/ProductDetailType";
import axios from "axios";
import _map from "lodash/map";
import _ from "lodash";

/**
 * create Products  detail  function
 * @private
 */
export const createProductsDetailApi = createAsyncThunk(
  "productdetail/createProductsDetailApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { imagedata, listProductdetail, mouvementdata, fournisseurdetaildata } = data;
      var body = new FormData();
      body.append("listProductdetail", JSON.stringify(listProductdetail));
      body.append("mouvementdata", JSON.stringify(mouvementdata));
      body.append("fournisseurdetaildata", JSON.stringify(fournisseurdetaildata));

      _map(imagedata, (item, index) => {
        body.append("image", item.path);
      });
      imagedata.forEach((element: any) => {
        element.path = element.path.name;
      });

      body.append("imagedata", JSON.stringify(imagedata));
      const response = await axios.post(
        "productdetail/createProductsDetailApi",
        body,
        config
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * initProductsDetail  function
 * @private
 */
export const createInstanceProductsDetailApi = createAsyncThunk(
  "productdetail/createInstanceProductsDetailApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };

      const { imagedata, listProductdetail } = data;
      var body = new FormData();
      body.append("listProductdetail", JSON.stringify(listProductdetail));

      _map(imagedata, (item, index) => {
        body.append("image", item.path);
      });
      imagedata.forEach((element: any) => {
        element.path = element.path.name;
      });

      body.append("imagedata", JSON.stringify(imagedata));
      const response = await axios.post(
        "productdetail/createInstanceProductsDetailApi",
        body,
        config
      );
        console.log(response)
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of ProductDetail.
 * @public
 */
export const findProductsDetailApi = createAsyncThunk(
  "productdetail/findProductsDetailApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `productdetail/findProductsDetailApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

interface ProductDetailState {
  listProductsDetail: ProductDetailType[];
  listProductsDetailByProduct: { [product: string]: ProductDetailType[] };
}

const initialState: ProductDetailState = {
  listProductsDetail: [],
  listProductsDetailByProduct: {},
};

export const ProductDetailSlice = createSlice({
  name: "productdetail",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createProductsDetailApi.fulfilled, (state, action) => {
      state.listProductsDetail.splice(0, 0, action.payload.data);
    });
    builder.addCase(createInstanceProductsDetailApi.fulfilled, (state, action) => {
      state.listProductsDetail.splice(0, 0, action.payload.data);
    });


    builder.addCase(
      findProductsDetailApi.fulfilled,
      (state, action) => {
        const groupedByProduct = _.groupBy(action.payload, "produit_id._id");
        state.listProductsDetail = action.payload;
        state.listProductsDetailByProduct = groupedByProduct;
      }
    );
  },
});

export const ListProductDetails = (state: RootState) =>
  state.productdetails.listProductsDetail;
export const listProductsDetailByProduct = (state: RootState) =>
  state.productdetails.listProductsDetailByProduct;

export default ProductDetailSlice.reducer;
