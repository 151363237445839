import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { CarteSchema } from "../../_App/Schemas/CarteSchema";
import {
  createCarteApi,
  updateCarteApi,
} from "../../_App/Redux/Slices/cartes/carteSlice";

export default function CarteForm({ ShowModal, setShowModal, carte, currentOrganisation ,societe}: any) {
  const dispatch = useAppDispatch();

  // init form hooks
  const formOptions = { resolver: yupResolver(CarteSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const [modalHeader, setModalHeader] = useState("");
  const [selectedOrganisation, setselectedOrganisation] = useState(true);

  useEffect(() => {
    if (carte) {
      setModalHeader("Modifier la Catégorie N° : " + carte._id);
      reset({
        organisation_id : carte.organisation_id,
        libelle: carte.libelle,
        image: carte.image,
      });
    carte.organisation_id !==  null ? setselectedOrganisation(true) : setselectedOrganisation(false)
    
    } else {
      setModalHeader("Ajouter une nouvelle carte");
            reset({
              libelle: {
                fr: "",
                en: "",
                ar: "",
              },
              image: null,
            });
    }
  }, [carte]);

  const onSubmit = async (data: any) => {
    let response;
    if(selectedOrganisation ){
      data.organisation_id = currentOrganisation;
    }
    else{
      data.organisation_id = null;

    }
    data.societe_id = societe
    if (carte && carte._id) {
      // If the carte has an _id, it means it's an update
      data._id = carte._id;
      response = await dispatch(updateCarteApi(data));
    } else {
      response = await dispatch(createCarteApi(data));
    }

    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
      });
      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  return (
    <Modal 
    className="modal-2"
    dialogClassName="modal-40w"
    backdropClassName="modal-backdrop-2"
    show={ShowModal} onHide={() => setShowModal(false)}>
      <Modal.Header>
        <Modal.Title>
          <span className="modal-title c-primary" id="addCategorieLabel">
            {modalHeader}
          </span>
        </Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={(e) => {
            setShowModal(false);
            reset({
              libelle: {
                fr: "",
                en: "",
                ar: "",
              },
              image: null,
            });
            setModalHeader("Ajouter une nouvelle carte");
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <div className="form-group">
               
                <label htmlFor="libellefr"> <span style={{ color: "red" }}>*</span>Libelle fr</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.fr ? "is-invalid" : ""
                  }`}
                  id="libellefr"
                  {...register("libelle.fr")}
                />
                   <div className="invalid-feedback">
                    {errors?.libelle?.fr &&
                      errors.libelle.fr.message &&
                      errors.libelle.fr.message.toString()}
                  </div>
                <label htmlFor="libelleen"> <span style={{ color: "red" }}>*</span>Libelle en</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.en ? "is-invalid" : ""
                  }`}
                  id="libelleen"
                  {...register("libelle.en")}
                />
                    <div className="invalid-feedback">
                    {errors?.libelle?.en &&
                      errors.libelle.en.message &&
                      errors.libelle.en.message.toString()}
                  </div>
                <label htmlFor="libellear"> <span style={{ color: "red" }}>*</span>Libelle ar</label>
                <input
                  type="text"
                  className={`form-control ${
                    errors.libelle?.ar ? "is-invalid" : ""
                  }`}
                  id="libellear"
                  {...register("libelle.ar")}
                />
                  <div className="invalid-feedback">
                    {errors?.libelle?.ar &&
                      errors.libelle.ar.message &&
                      errors.libelle.ar.message.toString()}
                  </div>
              </div>
            </div>
            <div className="col-12">
              <label htmlFor="image">Image</label>
              <div className="input-group mb-3">
                <label className="input-group-text" htmlFor="image">
                  <i className="os-icon os-icon-documents-07"></i>
                </label>
                <input
                  className="form-control mb-2"
                  type="file"
                  id="image"
                  {...register("image")}
                />
              </div>
              <div className="invalid-feedback">
                {errors.image &&
                  errors.image.message &&
                  errors.image.message.toString()}
              </div>
            </div>
            <div className="n-chk">
                    <label className="new-control new-radio radio-classic-primary">
                      <input
                        type="radio"
                        checked={selectedOrganisation}
                        onChange={(e)=>setselectedOrganisation(true)}
                        value="connectedOnly"
                        className="new-control-input"
                        name="custom-radio-2"
                      />
                      <span className="new-control-indicator" />
                      <p className="fs-15">l'organisation connecté seulement</p>
                    </label>
                  </div>
                  <div className="n-chk">
                    <label className="new-control new-radio radio-classic-primary">
                      <input
                        type="radio"
                        checked={!selectedOrganisation}
                        onChange={(e)=>setselectedOrganisation(false)}
                        value="allOrganizations"
                        className="new-control-input"
                        name="custom-radio-2"
                      />
                      <span className="new-control-indicator" />
                      <p className="fs-15">
                        Tous les organisations de la societe
                      </p>
                    </label>
                  </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => {
              setShowModal(false);
              reset({
                libelle: {
                  fr: "",
                  en: "",
                  ar: "",
                },
                image: null,
              });
              setModalHeader("Ajouter une nouvelle carte");
            }}
            className="btn btn-primary-app"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
