import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import axios from "axios";
import _ from "lodash";
import TicketType from "../../../Types/Entites/TicketType";
import { OrderType } from "../../../Types/Entites/Orders/OrderType";
import { groupBy } from "lodash";
import { sumBy } from "lodash";

export const saveTicketApi = createAsyncThunk(
  "ticket/saveTicketApi",
  async (data: TicketType, { rejectWithValue }) => {
    try {
      const response = await axios.post("ticket/saveTicketApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const findTicketsByOrganisationApi = createAsyncThunk(
  "ticket/findTicketsByOrganisationApi",
  async (idorganisation: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `ticket/findTicketsByOrganisationApi/${idorganisation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const findTicketsByOrderApi = createAsyncThunk(
  "ticket/findTicketsByOrderApi",
  async (orderId: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `ticket/findTicketsByOrderApi/${orderId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

export const getTicketById = createAsyncThunk(
  "ticket/getTicketById",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`ticket/getTicketById/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


interface TicketsState {
  ListTickets: TicketType[];
  ListOrderTickets: TicketType[];
  ticket: TicketType | null;
  PaidTicket: any;
  UnpaidTicket: OrderType | null;
}

const initialState: TicketsState = {
  ListTickets: [],
  ListOrderTickets: [],
  ticket: null,
  PaidTicket: null,
  UnpaidTicket: null,
};

export const TicketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    updateUserLocally(state, action) {
      console.log('rtttt',action.payload)
      state.ListTickets.splice(0, 0, action.payload);

  }
  },
  extraReducers: (builder) => {
    builder.addCase(saveTicketApi.fulfilled, (state, action) => {
      state.ListTickets.splice(0, 0, action.payload.data);
    });
    builder.addCase(findTicketsByOrganisationApi.fulfilled, (state, action) => {
      return {
        ...state,
        ListTickets: action.payload,
      };
    });
    builder.addCase(getTicketById.fulfilled, (state, action) => {
      return {
        ...state,
        ticket: action.payload,
      };
    });
    builder.addCase(findTicketsByOrderApi.fulfilled, (state, action) => {
      if (action.payload.length) {
        const groupedTickets: any = {};

        action.payload.forEach((ticket: any) => {
          const orderNumber = ticket.ticket_order.order_number;

          if (!groupedTickets[orderNumber]) {
            groupedTickets[orderNumber] = {
              _id: ticket._id,
              ticket_order: {
                _id: ticket.ticket_order._id,
                order_number: orderNumber,
                items: [],
                total: 0,
                date: ticket.ticket_order.date,
                table_id: ticket.ticket_order.table_id,
                status: ticket.ticket_order.status,
                organisation_id: ticket.ticket_order.organisation_id,
                journal_id: ticket.ticket_order.journal_id,
                createdAt: ticket.ticket_order.createdAt,
                updatedAt: ticket.ticket_order.updatedAt,
                __v: ticket.ticket_order.__v,
              },
              createdAt: ticket.createdAt,
              updatedAt: ticket.updatedAt,
              __v: ticket.__v,
            };
          }

          groupedTickets[orderNumber].ticket_order.items.push(
            ...ticket.ticket_order.items
          );
          groupedTickets[orderNumber].ticket_order.total +=
            ticket.ticket_order.total;
        });
        // const newTicketOrder = Object.values(groupedTickets)[0].ticket_order;

        const paidTicket: any = {
          _id: "",
          main_order: action.payload[0]?.main_order,
          ticket_order: Object.values(groupedTickets)[0],
        };
        let unpaidOrder = _.cloneDeep(action.payload[0]?.main_order);
        unpaidOrder.items.map((item: any, index: any) => {
          paidTicket.ticket_order.ticket_order.items.forEach((element: any) => {
            if (item.article._id === element.article._id) {
              item.quantity -= element.quantity;
              item.prix_total = item.prix_unitaire * item.quantity;
            }
          });
        });
        unpaidOrder.items = unpaidOrder.items.filter(
          (item: any) => item.quantity > 0
        );

        unpaidOrder.total = unpaidOrder.items.reduce(
          (sum: number, item: any) => sum + item.prix_total,
          0
        );
        return {
          ...state,
          PaidTicket: paidTicket,
          UnpaidTicket: unpaidOrder,
        };
      }
    });
  },
});
export const {
  updateUserLocally
} = TicketsSlice.actions;
export const ListTickets = (state: RootState) => state.tickets.ListTickets;
export const ListOrderTickets = (state: RootState) =>
  state.tickets.ListOrderTickets;
export const Ticket = (state: RootState) => state.tickets.ticket;
export const PaidTicket = (state: RootState) => state.tickets.PaidTicket;
export const UnpaidTicket = (state: RootState) => state.tickets.UnpaidTicket;

export default TicketsSlice.reducer;
