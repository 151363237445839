import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import {
  ListProduits,
  activeProductApi,
  desactiveProductApi,
  findProductBySocieteApi,
} from "../../../_App/Redux/Slices/produits/produitSlice";
import ProductsForm from "../../../Components/Products/Products/ProductsForm";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import ProductsList from "../../../Components/Products/Products/ProductsList";
import ProductTable from "../../../Components/Products/Products/ProductTable";
import { SelectedOrganisationId ,societeId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { ListArticles, findArticleApi } from "../../../_App/Redux/Slices/article/articleSlice";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";
import Navbar from "../../../_Layouts/Navbar/Navbar";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";

export default function ProductsPage() {
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const Products = useAppSelector(ListProduits);
  const Article = useAppSelector(ListArticles)
  const [ShowModalProduct, setShowModalProduct] = useState(false);
  const [product, setproduct] = useState(null);
  const [showlist, setshowlist] = useState(false);

  useEffect(() => {
   /* dispatch(findProductBySocieteApi(societe));
    dispatch(findArticleApi(societe))*/
  }, [dispatch,societe]);
  const verifcationMsg = (action:string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: `${t('vous voulez')} ${ t(action) } ${t('ce produit')}`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t('Cancel!')

    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  const openEditForm = (Product: any) => {
    setproduct(Product);
    setShowModalProduct(true);
  };
  const desactiveitem = async(id: any) => {
    if(await verifcationMsg('desactiver')){
      const response = await dispatch(desactiveProductApi(id));
      ToastSuccess(t(response.payload.message));

    }
  };
  const activeitem = async(id: any) => {
    if(await verifcationMsg('activer')){
      const response = await dispatch(activeProductApi(id));
      ToastSuccess(t(response.payload.message));
    }
 

  };
  return (
    <div>
          <Navbar />

    <div className="d-flex justify-content-end mb-3">
    <button
        className="btn btn-primary-app"
        onClick={() => {
          setproduct(null);
          setShowModalProduct(true);
        }}
      >
        {t('Ajouter Produit')} 
      </button>
    </div>

      <ProductsForm
        t={t}
        ShowModal={ShowModalProduct}
        setShowModal={setShowModalProduct}
        Product={product}
        organisation_id={currentOrganisation|| ''}
        societe={societe || ''}
        productList={Products}
      />
   
 </div>

   
  );
}
  /*   {!showlist ? (
        <ProductsList
          t={t}
          productList={Products}
          openEditForm={openEditForm}
          desactiveitem={desactiveitem}
          activeitem={activeitem}
        />
      ) : (
        <ProductTable
          t={t}
          productList={Products}
          openEditForm={openEditForm}
          desactiveitem={desactiveitem}
          societe={societe}
        />
      )}  */  
/* <div>
      <button
        className="btn btn-primary-app"
        onClick={() => {
          setproduct(null);
          setShowModalProduct(true);
        }}
      >
        Create Product Marque
      </button>{" "}
      <ProductsForm
        ShowModal={ShowModalProduct}
        setShowModal={setShowModalProduct}
        Product={product}
        organisation_id={currentOrganisation|| ''}
        societe={societe || ''}
      />
     
      {true ? (
        <ProductsList
          productList={Products}
          openEditForm={openEditForm}
          deleteitem={deleteitem}
        />
      ) : (
        <ProductTable
          productList={Products}
          openEditForm={openEditForm}
          deleteitem={deleteitem}
          societe={societe}
        />
      )}
    </div>*/

/* <nav>
        <span
          onClick={() => setshowlist(false)}
          style={{
            color: showlist ? "rgb(0, 150, 136)" : "rgb(224, 240, 239)",
            fontSize: "50px",
            marginRight: "30px",
          }}
        >
          <i className="fas fa-table"></i>
        </span>
        <span
          onClick={() => setshowlist(true)}
          style={{
            color: !showlist ? "rgb(0, 150, 136)" : "rgb(224, 240, 239)",
            fontSize: "50px",
          }}
        >
          <i className="fas fa-th-large"></i>
        </span>
      </nav>*/