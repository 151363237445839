import { object, string, number, array } from 'yup';

export const OrganisationSchema = object({
  _id: string().notRequired(),
  nom: string().required('Le nom de l\'organisation est obligatoire'),
  description: string().required('La description de l\'organisation est obligatoire'),
  numero: string(),
  logo: string(),
  user_id: string(),
  
  background: number(),
  wifi: string(),
 
  currency: string(),
  paiementmode: array(string()),
  message: string(),
  societe_id: string(),
  createdAt: string().nullable(),
  updatedAt: string().nullable(),
  users_ids: array(string()),
})
