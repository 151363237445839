import React from 'react'
import { Ingredient } from '../../../../_App/Types/Entites/Ingredient';

interface IngredientItemProps {
  ingredient: Ingredient;
}

export const IngredientItem: React.FC<IngredientItemProps> = ({ ingredient }) => {
  return (
    <table id="invoice-list" className="table table-hover" style={{width: '100%'}}>
           
    <tbody>
      <tr role="row" >
      <td>
      <label className="new-control new-checkbox checkbox-primary m-auto">
        <input type="checkbox"  checked   className="new-control-input chk-parent select-customers-info" />
        <span className="new-control-indicator" /><span style={{visibility: 'hidden'}}>c</span>
      </label>
      </td>
        <td><a href="apps_invoice-preview.html"><span className="inv-number">{ingredient.titre}</span></a></td>
      </tr>
      
    </tbody>
  </table>
  )
}
