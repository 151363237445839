import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import axios from "axios";
import _ from "lodash";
import { RootState } from "../../../store";
import ParametragesCaisseType from "../../../../Types/Entites/CaisseParametragesType";

/**
 * create an parametrage caisse.
 * @public
 */

export const saveParametrageCaisseApi = createAsyncThunk(
  "parametragescaisse/saveParametrageCaisseApi",
  async (data: ParametragesCaisseType, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "parametragescaisse/saveParametrageCaisseApi",
        data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * parametrage caisse of organisation
 * @public
 */
export const findParametragesCaisseByOrganisationApi = createAsyncThunk(
  "parametragescaisse/findParametragesCaisseByOrganisationApi",
  async (idOrgansation: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `parametragescaisse/findParametragesCaisseByOrganisationApi/${idOrgansation}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Update the product_id of a product raccourci.
 * @public
 */
export const updateParametrageCaisseApi = createAsyncThunk(
  "parametragescaisse/updateParametrageCaisseApi",
  async (params: any, { rejectWithValue }) => {
    try {
      const { id, data } = params;
      console.log(id, data);
      var response = await axios.put(
        `parametragescaisse/updateParametrageCaisseApi/${id}`,
        {
          data,
        }
      );
      console.log(response.data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface CaisseParametrageState {
  parametrageCaisse: ParametragesCaisseType[];
}

const initialState: CaisseParametrageState = {
  parametrageCaisse: [],
};

export const CaisseParametrageSlice = createSlice({
  name: "parametrageCaisse",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(saveParametrageCaisseApi.fulfilled, (state, action) => {
      state.parametrageCaisse.splice(0, 0, action.payload.data);
    });
    builder.addCase(
      findParametragesCaisseByOrganisationApi.fulfilled,
      (state, action) => {
        state.parametrageCaisse = action.payload;
        return state;
      }
    );
  },
});

export const parametrageCaisse = (state: RootState) =>
  state.parametrageCaisse.parametrageCaisse[0];

export default CaisseParametrageSlice.reducer;
