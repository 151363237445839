import React from 'react';
import { GrNext, GrPrevious } from 'react-icons/gr';

interface PaginationProps {
    currentPage: number;
    totalPages: number;
    onPageChange: (pageNumber: number) => void;
  }
  
  function Pagination({ currentPage, totalPages, onPageChange }: PaginationProps) {
    const pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);

  return (
    <div className="pagination-custom_solid">
      {currentPage !== 1 && (
        <a className="prev" onClick={() => onPageChange(currentPage - 1)}>
          <GrPrevious />
        </a>
      )}
      <ul className="pagination">
        {pageNumbers.map((number) => (
          <li key={number}>
            <a
              className={number === currentPage ? 'active' : ''}
              onClick={() => onPageChange(number)}
            >
              {number}
            </a>
          </li>
        ))}
      </ul>
      {currentPage !== totalPages && (
        <a className="next" onClick={() => onPageChange(currentPage + 1)}>
          <GrNext />
        </a>
      )}
    </div>
  );
}

export default Pagination;
