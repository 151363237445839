import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../_App/Redux/hooks';
import { StocksbyOrganisation, findStockByArticleIdApi } from '../../_App/Redux/Slices/article/articleSlice';
import {   SelectedOrganisations } from '../../_App/Redux/Slices/Auth/authSlice';

export default function StockTable({t,listdepots ,showModal , setshowModal , article_id}:any) {
  const list_stock = useAppSelector(StocksbyOrganisation)
  const list_organisation =useAppSelector(SelectedOrganisations)
  const [stockTotal,setstockTotal]=useState(0)
  const [StockKeys,setStockKeys]=useState<string[]>([])

  const dispatch = useAppDispatch()
  useEffect(() => {
    if (article_id) {
      dispatch(findStockByArticleIdApi(article_id._id))
    } 
  }, [article_id]);
  useEffect(() => {
    setStockKeys(Object.keys(list_stock));
 
  }, [list_stock]);
  useEffect(() => {
    setStockKeys(Object.keys(list_stock));
    let total = 0;
    for (const key in list_stock) {
      if (list_stock.hasOwnProperty(key)) {
        total += list_stock[key].reduce((acc: number, stock: any) => acc + stock.stock, 0);
      }
    }
    setstockTotal(total);
  }, [list_stock]);
  useEffect(() => {
      if(!showModal){
        setstockTotal(0)
        setStockKeys([]);
      }
  }, [showModal]);

  return (
    <Modal 
    show={showModal} 
    onHide={() => setshowModal(false)}
    className="modal-2"
    dialogClassName="modal-40w"
    backdropClassName="modal-backdrop-2"
    >
      <Modal.Header>
        <Modal.Title className="c-primary">{t("consulter stock")}{' '}{article_id?.titre?.fr}</Modal.Title>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => setshowModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
        <Modal.Body>
        <div>
         
      <div className="row mt-5">
        {StockKeys? (
 <table className="table">
 <thead>
 <tr>
         <td>{t('stock total')}</td>
         <td>
       {stockTotal}
       </td>
       </tr>
   <tr>
     <th>{t("Depots")}</th>
     <th>{t("Quantite")}</th>

   </tr>
 </thead>
 <tbody>
   {StockKeys&&StockKeys?.map((key) => (
     <React.Fragment key={key}>
       <tr>
         <td colSpan={4} className="highlighted-row">
           {t('Organisation')}{" "}
           {list_organisation?.find((item: any) => item._id === key)
             ?.nom || "Societe"}
         </td>
       </tr>
       {list_stock[key]&&list_stock[key].map((stock: any) => (
         <tr key={stock._id}>
           <td>
             {stock.depots_id?.libelle||'undefined'}
           </td>


           <td>{stock.stock}</td>
          
         </tr>
       ))}
    
     </React.Fragment>
   ))}
 
      
 </tbody>
</table>
        ):<>        <div className="spinner-border text-success align-self-center loader-lg">{t('Loading')}...</div>
        </>}
       
      </div>
    </div>
        </Modal.Body>
        <Modal.Footer>
         
        </Modal.Footer>
    </Modal>
  )
}
