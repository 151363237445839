import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  selectUser,
  updateUserLang,
  userLang,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";
import i18next, { changeLanguage } from "i18next";
import {
  updateUserApi,
  updateUserLangApi,
} from "../../_App/Redux/Slices/users/userSlice";
import _ from "lodash";

const LanguageSwitcher = () => {
  const dispatch = useAppDispatch();

  const [stateShow, setStateShow] = useState(false);
  const user = useAppSelector(selectUser);
  const langue = useAppSelector(userLang);
  const handleLanguageChange = (selectedLanguage: any) => {
    changeLanguage(selectedLanguage);
    dispatch(updateUserLangApi({ id: user?._id, lang: selectedLanguage }));
    dispatch(updateUserLang(selectedLanguage));
  };

  const [show, setShow] = useState("");

  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };
  return (
    <div>
      <div
        className="nav-item dropdown language-dropdown more-dropdown"
        onClick={open}
      >
        <div className={"dropdown custom-dropdown-icon " + show}>
          <a
            className="dropdown-toggle btn"
            href="#"
            role="button"
            id="customDropdown"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded={stateShow}
          >
            {i18next.language === "ar" ? (
              <>
                <img
                  src="/assets/assets/img/arabic.png"
                  className="flag-width"
                  alt="flag"
                />
                <span>العربية</span>
              </>
            ) : i18next.language === "fr" ? (
              <>
                <img
                  src="/assets/assets/img/french.png"
                  className="flag-width"
                  alt="flag"
                />
                <span>Français</span>
              </>
            ) : (
              <>
                <img
                  src="/assets/assets/img/english.png"
                  className="flag-width"
                  alt="flag"
                />
                <span>English</span>
              </>
            )}{" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-down"
            >
              <polyline points="6 9 12 15 18 9" />
            </svg>
          </a>
          <div
            className={"dropdown-menu dropdown-menu-right " + show}
            aria-labelledby="customDropdown"
          >
            <a
              className="dropdown-item"
              data-img-value="flag-de3"
              data-value="German"
              href="#"
              onClick={() => {
                handleLanguageChange("ar");
              }}
            >
              <img
                src="/assets/assets/img/arabic.png"
                className="flag-width"
                alt="flag"
              />{" "}
              العربية
            </a>
            <a
              className="dropdown-item"
              data-img-value="flag-fr3"
              data-value="French"
              href="#"
              onClick={() => {
                handleLanguageChange("fr");
              }}
            >
              <img
                src="/assets/assets/img/french.png"
                className="flag-width"
                alt="flag"
              />{" "}
              Français
            </a>
            <a
              className="dropdown-item"
              data-img-value="flag-ca2"
              data-value="English"
              href="#"
              onClick={() => {
                handleLanguageChange("en");
              }}
            >
              <img
                src="/assets/assets/img/english.png"
                className="flag-width"
                alt="flag"
              />{" "}
              English
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LanguageSwitcher;
