import React, { useState, useEffect } from "react";
import ArticleForm from "../../../Components/Products/Articles/ArticleForm";
import {
  SelectedOrganisationId,
  societeId,
} from "../../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  ListArticles,
  findArticleApi,
  findArticlebysocieteApi,
  findArticlebytitreApi,
} from "../../../_App/Redux/Slices/article/articleSlice";
import ArticleList from "../../../Components/Products/Articles/ArticleList";
import { useTranslation } from "react-i18next";
import Navbar from "../../../_Layouts/Navbar/Navbar";
import { findProductBySocieteApi } from "../../../_App/Redux/Slices/produits/produitSlice";
import { findFourinsseurBySocieteApi } from "../../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import { ArticleType } from "../../../_App/Types/Entites/ArticleType";
export default function ArticlePage() {
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const societe = useAppSelector(societeId);
  const { t } = useTranslation();
  const [index , setindex]=useState(0);
  
  const dispatch = useAppDispatch();
  const [ShowModalArticle, setShowModalArticle] = useState(false);
  const [articleitem, setarticleitem] = useState(null);
  const articles = useAppSelector(ListArticles);
  const [list_Article, setlist_Article] = useState<ArticleType[]>();
  const [list_filtredArticle, setlist_filtredArticle ]= useState<ArticleType[]>();

  const [keyword, setkeyword] = useState("");

  const handelsearch = async (keyword: string) => {
    //alert('ok')
    setindex(0)

    if(keyword === ''){
      const data={id:societe,index:0}
      dispatch(findArticlebysocieteApi(data));
    }else{
      const data ={keyword:keyword, index:0}
      await dispatch(findArticlebytitreApi(data))
    }
   
  };

  useEffect(() => {
    if (societe) {
      if(keyword === ''){
        const data={id:societe,index:index}
        dispatch(findArticlebysocieteApi(data));
      }else{
        const data ={keyword:keyword, index:0}

         dispatch(findArticlebytitreApi(data))
      }
     
  
    }
  }, [dispatch,index, societe]);

  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className=" btn btn-primary-app"
          onClick={() => setShowModalArticle(true)}
        >
          {t("Ajouter Article")}
        </button>
      </div>
      {ShowModalArticle ? (
        <ArticleForm
          ShowModal={ShowModalArticle}
          setShowModal={setShowModalArticle}
          Article={articleitem}
        />
      ) : null}
      <ArticleList
         articles={articles}
        t={t}
        setindex={setindex}
        index={index}
        setlist_Article={setlist_Article}
        list_Article={articles}
        setarticleitem={setarticleitem}
        setShowModalArticle={setShowModalArticle}
        societe={societe}
        handelsearch={handelsearch}
        keyword={keyword}
        setkeyword={setkeyword}
      />
    </div>
  );
}
