import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Listinventaire,
  confirmerInventaireApi,
  findInventaireApi,
} from "../../_App/Redux/Slices/inventaire/inventaireSlice";
import { formadate } from "../../_App/Helpers/helpers";
import { SelectedOrganisations, selectUserId } from "../../_App/Redux/Slices/Auth/authSlice";
import InventaireDetaliTable from "./InventaireDetaliTable";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";

export default function InventaireTable({
  t,
  list_inventaires,
  list_inventaire_details,
}: any) {
  const user = useAppSelector(selectUserId);
  const dispatch = useAppDispatch()
  const listdOrganisations = useAppSelector(SelectedOrganisations);
  const [showModalDetails, setshowModalDetails] = useState(false);
  const [inventaire, setinventaire] = useState(null);


  const Confirmer=async(InventaireId:string)=>{
    const data ={user: user , inventaire_id:InventaireId}
    const response = await dispatch(confirmerInventaireApi(data))
    if (response?.payload.success) {
      ToastSuccess(t(response?.payload.message));

      window.location.reload();
    } else {
      ToastWarning(t(response?.payload.message));

    }
  }
  return (
    <div>
      <table className="table table-bordered mb-5">
        <thead>
          <tr>
            <th></th>
            <th>{t("code inventaire")}</th>
            <th>{t("Organisation")}</th>
            <th>{t("Depot")}</th>
            <th>{t("User")}</th>
         
          </tr>
        </thead>
        {list_inventaires.length !== 0 ? (
          <tbody>
            {list_inventaires?.map((item: any, index: number) => (
              <tr key={index}>
                {item.confirmer ? (
                  <td className="color-red">confirmé</td>
                ) : (
                  <td
                    className="c-primary pointer"
                    onClick={() => Confirmer(item._id)}
                  >
                    confirmer
                  </td>
                )}
                <td
                  className="lien-bleu-souligne"
                  onClick={() => {
                    setinventaire(item);
                    setshowModalDetails(true);
                  }}
                >
                  {item.code_inventaire}
                </td>
                <td>
                  {listdOrganisations?.find(
                    (element) => element._id === item.organisation_id
                  )?.nom || item.organisation_id}
                </td>
                <td>{item?.depots_id?.libelle || item?.depots_id}</td>
                <td>
                  {item.user?.nom}
                  {item.user?.prenom}
                </td>
             
              </tr>
            ))}
          </tbody>
        ) : (
          <tr>{t("aucun inventaire")}</tr>
        )}

        <tbody></tbody>
      </table>
      <InventaireDetaliTable
        t={t}
        setShowModal={setshowModalDetails}
        ShowModal={showModalDetails}
        listInventairedetails={list_inventaire_details}
        inventaire={inventaire}
        listdOrganisations={listdOrganisations}
      />
    </div>
  );
}
