import React, { useEffect, useState } from "react";
import { Button, Modal, Tab, Tabs } from "react-bootstrap";
import styled from "styled-components";
import {
  selectCaisse,
  selectJournalCaisse,
  selectUser,
  selectedAutorisations,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListJournalOrders,
  findOrdersByJournalApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import {
  ListJournalMouvementsCaisse,
  findMouvementsCaisseByJournalApi,
} from "../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ArticleType } from "../../_App/Types/Entites/ArticleType";
import { MouvementCaisseType } from "../../_App/Types/Entites/MouvementCaisseType";
import { OrderType } from "../../_App/Types/Entites/Orders/OrderType";
import {
  ListCategories,
  findCategorieBySocieteApi,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import { societeId } from "../../_App/Redux/Slices/Auth/authSlice";
import { CategorieType } from "../../_App/Types/Entites/CategorieType";
import { ListSousCategories } from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import { findSousCategorieBySocieteApi } from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import { useTranslation } from "react-i18next";

interface StatistiqueJournalProps {
  onClose: () => void;
  journalId: string;
  show: boolean;
  getCodeJournalById: (journalId: string) => string | null;
  

}

const StatistiquesTicketWrapper = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const StatistiquesRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
`;

const BlueAndBoldTd = styled.td`
  color: red;
  font-weight: bold;
  font-size: 12px;
`;

const StatistiquesItem = styled.p`
  width: 48%;
`;
const DateAndButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10mm;
`;

const AllStatistique: React.FC<StatistiqueJournalProps> = ({
  onClose,
  journalId,
  getCodeJournalById,

}) => {
  const [showModal, setShowModal] = useState(true);
  const orders = useAppSelector(ListJournalOrders);
  const user = useAppSelector(selectUser);
  const dispatch = useAppDispatch();

  const currentDate = new Date();
  const formattedDate = `${currentDate.toLocaleDateString()} ${currentDate.toLocaleTimeString()}`;

  const listMouvementsCaisse = useAppSelector(ListJournalMouvementsCaisse);
  const caisse = useAppSelector(selectCaisse);
  const currentSocieteId = useAppSelector(societeId);
  const categories = useAppSelector(ListCategories);
  const souscategories = useAppSelector(ListSousCategories);

  const autorisations = useAppSelector(selectedAutorisations);
  const canViewStatisticAmount =autorisations?.CAN_VIEW_STATISTIQUE_JOURNAL_WITH_AMOUNT;

  useEffect(() => {
    if (currentSocieteId) {
      dispatch(findCategorieBySocieteApi(currentSocieteId));
      dispatch(findSousCategorieBySocieteApi(currentSocieteId));
    }
  }, [dispatch, currentSocieteId]);

  useEffect(() => {
    if (journalId) {
      dispatch(findOrdersByJournalApi(journalId));
      dispatch(findMouvementsCaisseByJournalApi(journalId));
    }
  }, [dispatch, journalId]);

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };
  const { t } = useTranslation();

  const findCategoryLabelById = (
    categoryId: string,
    categories: CategorieType[]
  ) => {
    const foundCategory = categories.find(
      (category) => category._id === categoryId
    );
    return foundCategory ? foundCategory.libelle.fr : "";
  };

  const findSousCategoryLabelById = (souscategoryId: string) => {
    const foundSousCategory = souscategories.find(
      (souscat) => souscat._id === souscategoryId
    );
    return foundSousCategory ? foundSousCategory.libelle.fr : "";
  };

  const calculateTotalAmount = () => {
    return orders.reduce((orderTotal, order) => {
      return (
        orderTotal +
        order.items.reduce((itemTotal, item) => {
          const itemTotalPrice =
            typeof item.prix_total === "number" ? item.prix_total : 0;
          return itemTotal + itemTotalPrice;
        }, 0)
      );
    }, 0);
  };

  const calculateTotalHT = () => {
    return orders.reduce((orderTotalHT, order) => {
      return (
        orderTotalHT +
        order.items.reduce((itemTotalHT, item) => {
          const itemTotalPriceHT =
            typeof item.article.prix_vente_ht === "number"
              ? item.article.prix_vente_ht
              : 0;
          return itemTotalHT + itemTotalPriceHT;
        }, 0)
      );
    }, 0);
  };

  const calculateTotalArticles = () => {
    return orders.reduce((orderTotalArticles, order) => {
      return (
        orderTotalArticles +
        order.items.reduce((itemTotalArticles, item) => {
          return itemTotalArticles + item.quantity;
        }, 0)
      );
    }, 0);
  };

  const calculateTicketMoyenTTC = () => {
    const totalAmount = calculateTotalAmount();
    return totalAmount / orders.length;
  };

  const calculateTicketMoyenHT = () => {
    const totalHT = calculateTotalHT();
    return totalHT / orders.length;
  };

  const calculateNombreDeTicket = () => {
    return orders.length;
  };

  const calculateNombreArticleMoyen = () => {
    const totalArticles = calculateTotalArticles();
    return totalArticles / orders.length;
  };

  const calculateTicketMaxTTC = () => {
    return orders.reduce((maxTTC, order) => {
      const orderTotalTTC = order.items.reduce((itemTotalTTC, item) => {
        return (
          itemTotalTTC +
          (typeof item.prix_total === "number" ? item.prix_total : 0)
        );
      }, 0);

      return Math.max(maxTTC, orderTotalTTC);
    }, 0);
  };

  const calculateTicketMinTTC = () => {
    return orders.reduce((minTTC, order) => {
      const orderTotalTTC = order.items.reduce((itemTotalTTC, item) => {
        return (
          itemTotalTTC +
          (typeof item.prix_total === "number" ? item.prix_total : 0)
        );
      }, 0);

      return minTTC === 0 ? orderTotalTTC : Math.min(minTTC, orderTotalTTC);
    }, 0);
  };

  const reglementData: MouvementCaisseType[] = listMouvementsCaisse;

  const cashMovements = reglementData.filter(
    (reglement) => reglement.payment_method === "Cash"
  );
  const chequeMovements = reglementData.filter(
    (reglement) => reglement.payment_method === "Chéque"
  );
  const creditMovements = reglementData.filter(
    (reglement) => reglement.montant !== 0
  );

  const totalCash = cashMovements.reduce((total, reglement) => {
    const montant =
      reglement.type_mouvement_id.sens === "d"
        ? -reglement.montant
        : reglement.montant;
    return total + montant;
  }, 0);

  const totalCheque = chequeMovements.reduce(
    (total, reglement) => total + reglement.montant,
    0
  );
  //const totalCredit = creditMovements.reduce((total, reglement) => total + reglement.credit, 0);
  const total = totalCheque + totalCash;

  const handlePrint = (contentId: any) => {
    const content = document.getElementById(contentId);
    if (content) {
      const printWindow = window.open("", "Print", "width=900,height=500");
      if (printWindow) {
        printWindow.document.write("<html><head><title>Print</title>");

        const styleElements = document.querySelectorAll("style");
        styleElements.forEach((styleElement) => {
          printWindow.document.head.appendChild(styleElement.cloneNode(true));
        });

        printWindow.document.write(`
      <style>
        body {
          margin: 10mm; 
          font-size: 12px; 
        }
        @media print {
          .hide-on-print {
            display: none;
          }
        }
      </style>
    `);
        printWindow.document.write("</head><body>");
        printWindow.document.write(content.innerHTML);
        printWindow.document.write("</body></html>");
        printWindow.document.close();
        printWindow.print();
      } else {
        console.error("Échec de l'ouverture de la fenêtre d'impression.");
      }
    }
  };

  const calculateArticleStatistics = (orders: OrderType[]) => {
    const articleStatistics: Record<
      string,
      { article: ArticleType; totalQuantity: number; totalSales: number }
    > = {};

    orders.forEach((order) => {
      order.items.forEach((item) => {
        const { article, quantity, prix_total } = item;

        if (!articleStatistics[article._id]) {
          articleStatistics[article._id] = {
            article: article,
            totalQuantity: 0,
            totalSales: 0,
          };
        }

        articleStatistics[article._id].totalQuantity += quantity;
        articleStatistics[article._id].totalSales += prix_total;
      });
    });

    return Object.values(articleStatistics);
  };
  const calculateCategoryStatistics = (orders: OrderType[]) => {
    const categoryStatistics: Record<
      string,
      { quantity: number; totalSales: number }
    > = {};

    orders.forEach((order) => {
      order.items.forEach((item) => {
        const { article, quantity, prix_total } = item;
        const category = article.categorie;

        if (!categoryStatistics[category]) {
          categoryStatistics[category] = {
            quantity: 0,
            totalSales: 0,
          };
        }

        categoryStatistics[category].quantity += quantity;
        categoryStatistics[category].totalSales += prix_total;
      });
    });

    return Object.entries(categoryStatistics).map(([category, stats]) => ({
      category,
      ...stats,
    }));
  };

  const calculateSubcategoryStatistics = (orders: OrderType[]) => {
    const subcategoryStatistics: Record<
      string,
      { quantity: number; totalSales: number }
    > = {};

    orders.forEach((order) => {
      order.items.forEach((item) => {
        const { article, quantity, prix_total } = item;
        const subcategory = article.souscategorie;

        if (!subcategoryStatistics[subcategory]) {
          subcategoryStatistics[subcategory] = {
            quantity: 0,
            totalSales: 0,
          };
        }

        subcategoryStatistics[subcategory].quantity += quantity;
        subcategoryStatistics[subcategory].totalSales += prix_total;
      });
    });

    return Object.entries(subcategoryStatistics).map(
      ([subcategory, stats]) => ({
        subcategory,
        ...stats,
      })
    );
  };
  return (
    <>
      <Modal
        show={showModal}
        onHide={handleClose}
        className="modal-1"
        dialogClassName="modal-80w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header>
          <Modal.Title className="c-primary">      <Modal.Title>Statistique  journal {getCodeJournalById(journalId) }</Modal.Title>
</Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <Tabs defaultActiveKey="part1" id="statTabs">
            <Tab eventKey="part1" title="Statistiques ticket">
              <div id="part1Content">
                <DateAndButtonWrapper>
                  <h6> </h6>
                  <Button
                    className="btn btn-link hide-on-print"
                    onClick={() => handlePrint("part1Content")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>
                </DateAndButtonWrapper>
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      {canViewStatisticAmount && <th>{t("Montant TTC")} </th>}
                      {canViewStatisticAmount && <th>{t("Montant H.T")} </th>}

                      <th>{t("Nombre d'articles")}</th>
                      <th>{t("Nb. ticket")}</th>
                      <th>{t("Ticket moyen")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {canViewStatisticAmount && (
                        <td>{calculateTotalAmount()}</td>
                      )}
                      {canViewStatisticAmount && <td>{calculateTotalHT()}</td>}
                      <td>{calculateTotalArticles()}</td>
                      <td>{calculateNombreDeTicket()}</td>
                      <td>{calculateTicketMoyenTTC().toFixed(2)}</td>
                    </tr>
                  </tbody>
                </table>
                {canViewStatisticAmount && (
                  <StatistiquesTicketWrapper>
                    <h6 className="statistiques-title">
                      {" "}
                      {t("Statistiques Ticket")}
                    </h6>
                    <StatistiquesRow>
                      <StatistiquesItem>
                        {t("Ticket moyen TTC")} :{" "}
                        {calculateTicketMoyenTTC().toFixed(2)}
                      </StatistiquesItem>
                      <StatistiquesItem>
                        {t("Ticket moyen HT")} :{" "}
                        {calculateTicketMoyenHT().toFixed(2)}
                      </StatistiquesItem>
                    </StatistiquesRow>
                    <StatistiquesRow>
                      <StatistiquesItem>
                        {t("Ticket max TTC")} :{" "}
                        {calculateTicketMaxTTC().toFixed(2)}
                      </StatistiquesItem>
                      <StatistiquesItem>
                        {t("Nb. ticket")} : {calculateNombreDeTicket()}
                      </StatistiquesItem>
                    </StatistiquesRow>
                    <StatistiquesRow>
                      <StatistiquesItem>
                        {t("Ticket min TTC")}:{" "}
                        {calculateTicketMinTTC().toFixed(2)}
                      </StatistiquesItem>
                      <StatistiquesItem>
                        {t("Nb. article moyen")} :{" "}
                        {calculateNombreArticleMoyen().toFixed(2)}
                      </StatistiquesItem>
                    </StatistiquesRow>
                  </StatistiquesTicketWrapper>
                )}
              </div>
            </Tab>
            <Tab eventKey="part2" title="Règlements ">
              <div id="part2Content">
                <DateAndButtonWrapper>
                  <h6> </h6>
                  <Button
                    className="btn btn-link hide-on-print"
                    onClick={() => handlePrint("part2Content")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>
                </DateAndButtonWrapper>

                <StatistiquesTicketWrapper>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>{t("Total Espèces")}</th>
                        <th>{t("Total Chèque")}</th>
                        <th>{t("total")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{totalCash}</td>
                        <td>{totalCheque}</td>
                        <td>{total}</td>
                      </tr>
                    </tbody>
                  </table>
                </StatistiquesTicketWrapper>

                <StatistiquesTicketWrapper>
                  <h6 className="statistiques-title">
                    {t("Détails des règlements")}
                  </h6>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>{t("Methode de paiement")}</th>
                        <th>{t("Type")}</th>
                        <th>{t("Code opération")}</th>
                        <th>{t("Montant")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {cashMovements.map((reglement, index) => (
                        <tr key={index}>
                          <td>{t("Espèces")}</td>
                          <td>{reglement.type_mouvement_id.libelle}</td>
                          <td>{reglement.operation_code}</td>
                          <td>
                            {reglement.type_mouvement_id.sens === "d"
                              ? "-"
                              : ""}
                            {reglement.montant}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <BlueAndBoldTd>{t("Total Espèces")}</BlueAndBoldTd>
                        <BlueAndBoldTd>{totalCash}</BlueAndBoldTd>
                      </tr>

                      {chequeMovements.map((reglement, index) => (
                        <tr key={index}>
                          <td>{t("Chèque")}</td>
                          <td>{reglement.type_mouvement_id.libelle}</td>{" "}
                          <td>{reglement.operation_code}</td>
                          <td>
                            {reglement.type_mouvement_id.sens === "d"
                              ? "-"
                              : ""}
                            {reglement.montant}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td></td>
                        <td></td>
                        <BlueAndBoldTd>{t("Total Chèque")}</BlueAndBoldTd>
                        <BlueAndBoldTd>{totalCheque}</BlueAndBoldTd>
                      </tr>

                      <tr>
                        <td></td>
                        <td></td>
                        <BlueAndBoldTd> {t("total")}</BlueAndBoldTd>
                        <BlueAndBoldTd> {total}</BlueAndBoldTd>
                      </tr>
                    </tbody>
                  </table>
                </StatistiquesTicketWrapper>
              </div>
            </Tab>

            <Tab eventKey="part3" title="Statistiques article">
              <div id="part3Content">
                <DateAndButtonWrapper>
                  <h6> </h6>
                  <Button
                    className="btn btn-link hide-on-print"
                    onClick={() => handlePrint("part3Content")}
                  >
                    <i className="fas fa-print"></i>
                  </Button>
                </DateAndButtonWrapper>
                {orders && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>{t("Article")}</th>
                        <th>{t("Quantité vendue")}</th>
                        {canViewStatisticAmount && <th>{t("Total TTC")}</th>}
                        <th>{t("Pourcentage")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calculateArticleStatistics(orders)
                        .sort((a, b) => b.totalQuantity - a.totalQuantity)
                        .map((stat, index) => (
                          <tr key={index}>
                            <td>{stat.article.titre.fr}</td>
                            <td>{stat.totalQuantity}</td>
                            {canViewStatisticAmount && (
                              <td>{stat.totalSales.toFixed(2)}</td>
                            )}
                            <td>
                              {(
                                (stat.totalQuantity /
                                  calculateTotalArticles()) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

                <h6 className="statistiques-title">
                  Statistiques par Catégorie
                </h6>
                {orders && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>{t("Categories")}</th>
                        <th>{t("Quantité vendue")}</th>
                        {canViewStatisticAmount && <th>{t("Total TTC")}</th>}
                        <th>{t("Pourcentage")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calculateCategoryStatistics(orders)
                        .sort((a, b) => b.quantity - a.quantity)
                        .map((stat, index) => (
                          <tr key={index}>
                            <td>
                              {findCategoryLabelById(stat.category, categories)}
                            </td>
                            <td>{stat.quantity}</td>
                            {canViewStatisticAmount && (
                              <td>{stat.totalSales.toFixed(2)}</td>
                            )}
                            <td>
                              {(
                                (stat.quantity / calculateTotalArticles()) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}

                <h6 className="statistiques-title">
                  Statistiques par Sous-Catégorie
                </h6>
                {orders && (
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>{t("Sous Categories")}</th>
                        <th>{t("Quantité vendue")}</th>
                        {canViewStatisticAmount && <th>{t("Total TTC")}</th>}
                        <th>{t("Pourcentage")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {calculateSubcategoryStatistics(orders)
                        .sort((a, b) => b.quantity - a.quantity)
                        .map((stat, index) => (
                          <tr key={index}>
                            <td>
                              {findSousCategoryLabelById(stat.subcategory)}
                            </td>
                            <td>{stat.quantity}</td>
                            {canViewStatisticAmount && (
                              <td>{stat.totalSales.toFixed(2)}</td>
                            )}
                            <td>
                              {(
                                (stat.quantity / calculateTotalArticles()) *
                                100
                              ).toFixed(2)}
                              %
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-primary-app" onClick={handleClose}>
            {t("Fermer")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default AllStatistique;
