
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import { AchatTypeType } from "../../../Types/Entites/Achat/AchatTypeType";

/**
 * Get list of Achattype
 * @public
 */
export const findAchatTypeBySocieteApi = createAsyncThunk(
    "achattype/findAchatTypeBySocieteApi",
    async (id: any, { rejectWithValue, dispatch }) => {
      try {
        const response = await axios.get(
          `achattype/findAchatTypeBySocieteApi/${id}`
        );
        return response.data;
        
      } catch (error: any) {
        console.log(error);
        return rejectWithValue({
          success: false,
          message: error.response.data.message,
        });
      }
    }
  );
   
  const initialState: AchatTypeType[]=[]
  export const AchatTypeSlice = createSlice({
    name: "Achattype",
    initialState: initialState,
    reducers: {},
    extraReducers: (builder) => {
   
      
      builder.addCase(findAchatTypeBySocieteApi.fulfilled, (state, action) => {
         return(state= action.payload)  
      });

      
    },
  });
  
  export const ListAchatType = (state: RootState) => state.achattypes;

  
  export default AchatTypeSlice.reducer;