import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { updateProductsCaracteristiquesApi } from "../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { useAppDispatch } from "../../../_App/Redux/hooks";

export default function ListDimension({
  listdimension,
  selecteddimension,
  ShowModal,
  setShowModal,
  dimension_change,
  updateMatrice,
  removeLigneMatrice,
  t
}: any) {
  const dispatch = useAppDispatch();
  const [newcarateristique, setnewcarateristique] = useState({
    valeur: "",
    libelle: "",
  });
  const [showAddCarac, setshowAddCarac] = useState(false);
  const addCarateristique = async () => {
    const data = { ...selecteddimension };
      if(selecteddimension.color){
        data.valeurs = [...data.valeurs, newcarateristique];

      }else{
        data.valeurs = [...data.valeurs, newcarateristique.valeur];

      }
    const response = await dispatch(updateProductsCaracteristiquesApi(data));
    if (response.payload.success) {
      // update matrice
      const datamatrice = selecteddimension?.color ? newcarateristique : newcarateristique.valeur ;
      const response = await updateMatrice(datamatrice);
      if (response.payload.success) {
        //  removeLigneMatrice(dimension_change)
        setShowModal(false);
        setshowAddCarac(false);
      }
    } else {
      ToastWarning(response.payload.message);
    }
  };

  const addCarateristiqueMatrice = async (valeur: string) => {
    await updateMatrice(valeur);
    // remove selected carateristique
    // removeLigneMatrice(dimension_change);
  };
  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      dialogClassName="modal-30w"
      className="modal-2"
      backdropClassName="modal-backdrop-2"
    >
      <Modal.Header closeButton>
        <div className="row">
          <div className="col-2">

            <button
              type="button"
              className="btn-form-sm"
              style={{
                width: "100px",
                backgroundColor: selecteddimension?.color
                  ? dimension_change.valeur
                  : null,
              }}
            >
         
              {selecteddimension?.color
                  ? dimension_change.libelle
                  : dimension_change}
            </button>
          </div>
            <div className="col-10">
            <span
            className="c-primary float-right my"
            onClick={() => setshowAddCarac(true)}
          >
            <i className="fas fa-plus-circle fa-lg"></i>{t('Ajouter autre carateristiques')}
          </span>
            </div>
         
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="col-md-12 ">
          {[...listdimension]
            .reduce((rows: any[], item, index) => {
              if (index % 3 === 0) {
                rows.push([]);
              }
              rows[rows.length - 1].push(item);
              return rows;
            }, [])
            .map((row, rowIndex) => (
              <div className="row " key={`row-${rowIndex}`}>
                {row.map((colItem: any, colIndex: any) => (
                  <div className="col-md-3 marginitems" key={colIndex}>
                    <div
                      style={{
                        cursor: "pointer",
                        height: "40px",
                        padding: "0 0 0 0",
                        border: "1px solid #ccc",
                        backgroundColor: selecteddimension?.color
                          ? colItem.valeur
                          : null,
                      }}
                      onClick={() => addCarateristiqueMatrice(colItem)}
                    >
                      {selecteddimension?.color ? colItem.libelle : colItem}
                    </div>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {showAddCarac ? (
          <>
            {selecteddimension?.color ? (
              <>
                <input
                  type="color"
                  className="form-control"
                  value={newcarateristique.valeur}
                  onChange={(e) =>
                    setnewcarateristique((prev) => ({
                      ...prev,
                      valeur: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  placeholder="libelle du couleur"
                  className="form-control"
                  value={newcarateristique.libelle}
                  onChange={(e) =>
                    setnewcarateristique((prev) => ({
                      ...prev,
                      libelle: e.target.value,
                    }))
                  }
                />
              </>
            ) : (
              <input
                type="text"
                className="form-control"
                onChange={(e) =>
                  setnewcarateristique((prev) => ({
                    ...prev,
                    valeur: e.target.value,
                  }))
                }
              />
            )}
            <button onClick={addCarateristique} className="btn btn-primary-app">
              Enregistrer
            </button>
          </>
        ) : null}
      </Modal.Footer>
    </Modal>
  );
}
