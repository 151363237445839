import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../../../store';
import { ToastSuccess } from '../../../../../Shared/Toasts/ToastSuccess';
import { ToastWarning } from '../../../../../Shared/Toasts/ToastWarning';
import _ from 'lodash';



/**
 * find parametrages .
 * @public
 */
export const findParametrageApi = createAsyncThunk('parametrage/findParametrageApi', async (data: null, { rejectWithValue }) => {
    try {
        const response = await axios.get('parametrage/findParametrageApi');
        return response.data;
    } catch (error: any) {
        
        return rejectWithValue({
            success: false,
            message: error.response.data.message,
            action: error.response.data.action,
            data: error.response.data.data,
        });
    }
}
);

const initialState: any[] = []
export const parametrageSlice = createSlice({
    name: 'appParametrage',
    initialState,
    reducers: {
 
    },

    extraReducers: (builder) => {

        builder.addCase(findParametrageApi.fulfilled, (state, action) => {
           return(state = action.payload) ;
        });



    },
});

export const ListParametrges = (state: RootState) => state.appParametrages

export default parametrageSlice.reducer;