import React from "react";
import { useParams } from "react-router-dom";
import PasswordChangeForm from "../../Components/Auth/PasswordUpdateForm";

const ResetPasswordPage: React.FC = () => {
  const { token } = useParams<{ token?: string }>();

  return (
    <div>
      {token ? (
        <PasswordChangeForm token={token} />
      ) : (
        <p>No token provided in the URL.</p>
      )}
    </div>
  );
};

export default ResetPasswordPage;
