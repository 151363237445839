import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { loginApi } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { selectUser } from "../../_App/Redux/Slices/Auth/authSlice";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
`;

const Input = styled.input`
  width: 320px;
  height: 60px;
  margin-bottom: 20px;
  padding: 10px;
  font-size: 40px;
  text-align: center;
  font-family: "Arial", sans-serif;
  border: 2px solid #ccc;
  border-radius: 5px;
  transition: box-shadow 0.3s ease;

  &:focus {
    outline: none;
    box-shadow: 0 0 10px #87c077;
  }
`;

const KeypadContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 320px;
  margin-top: 10px;
`;

const KeypadButton = styled.div<{ clicked: boolean }>`
  width: 100px;
  height: 100px;
  border: 2px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
    transform: translateY(-3px);
  }
`;

const ClearButton = styled(KeypadButton)<{ hasInput: boolean }>`
  background-color: ${(props: any) => (props.hasInput ? "#ff3333" : "white")};
  color: ${(props: any) => (props.hasInput ? "white" : "black")};
  &:hover {
    background-color: #ff6666;
    transform: translateY(-3px);
  }
`;

const ValidateButton = styled(KeypadButton)<{
  isValid: boolean;
  clicked: boolean;
}>`
  background-color: ${(props: any) => (props.isValid ? "#87c077" : "#ccc")};
  color: white;
  font-size: 24px;
  &:hover {
    transform: translateY(-3px);
  }
`;

interface NumericKeypadProps {
  onComplete: (value: string) => void;
  email: string;
  password: string;
}

const NumericKeypad: React.FC<NumericKeypadProps> = ({
  onComplete,
  email,
  password,
}) => {
  const navigate = useNavigate();
  const { register, handleSubmit } = useForm();
  const [inputValue, setInputValue] = useState<string>("");
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      const response = await dispatch(
        loginApi({ email, password, pin: inputValue })
      );

      if (response.payload?.success) {
        ToastSuccess(response.payload.message);

        onComplete(inputValue);
        setInputValue("");

        const redirectTo = response.payload.payload.user.role_id.redirectTo;
        if (redirectTo) {
          navigate("/" + redirectTo);
        }
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {}
  };

  const handleButtonClick = (value: string) => {
    if (inputValue.length < 4) {
      setInputValue((prevValue) => {
        return prevValue + value;
      });
    }
  };

  const handleClearClick = () => {
    setInputValue("");
  };

  const handleValidateClick = () => {
    if (inputValue.length === 4) {
      handleSubmit(onSubmit)();
    }
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          type="password"
          value={inputValue}
          readOnly
          {...register("pinInput")}
        />
        <Input type="hidden" value={email} readOnly />
        <Input type="hidden" value={password} readOnly />
        <KeypadContainer>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((number) => (
            <KeypadButton
              key={number}
              clicked={false}
              onClick={() => {
                handleButtonClick(String(number));
              }}
            >
              {number}
            </KeypadButton>
          ))}
        </KeypadContainer>
        <RowContainer>
          <ClearButton
            hasInput={inputValue.length > 0}
            clicked={false}
            onClick={handleClearClick}
          >
            Clear
          </ClearButton>
          <KeypadButton
            key={0}
            clicked={false}
            onClick={() => {
              handleButtonClick("0");
            }}
          >
            0
          </KeypadButton>
          <ValidateButton
            isValid={inputValue.length === 4}
            clicked={false}
            onClick={() => handleSubmit(onSubmit)()}
          >
            <i className="fas fa-check"></i>
          </ValidateButton>
        </RowContainer>
      </form>
    </Container>
  );
};

export default NumericKeypad;
