import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components';
import { LocalIconsList } from './icons';
import { UseFormSetValue } from 'react-hook-form';
import AppMenuParametrageType from '../../../_App/Types/Entites/AppMenus/AppMenuParametrageType';

const STATIC_ICONS_LIST = LocalIconsList;

const StyledIcons = styled.div`
    
    .one-icone{
        font-size: 20px!important;
        margin-bottom: 10px
    }
    .row>div{
        border: 1px solid #eee;
        min-height: 160px;
        padding: 15px
    }
    .row>div: hover{
        background: rgb(224, 240, 239);
        color: rgb(0, 150, 136);
    }
`;

const ListIcons = ({ ShowModal, setShowModal, setValue , menuItems, RegisterIndex }: {
    ShowModal: boolean,
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>,
    setValue: UseFormSetValue<AppMenuParametrageType>,
    menuItems: boolean,
    RegisterIndex: number,
}) => {

    const [icons, setIcons] = useState(STATIC_ICONS_LIST)

    return (
        <Modal show={ShowModal}
            onHide={() => setShowModal(false)}
            className="modal-3"
            dialogClassName="modal-90w"
            backdropClassName="modal-backdrop-3"
        >
            <Modal.Header closeButton>
                <Modal.Title id="add_new_parametrage_menu" className="custom-modal-title">
                    <div className="titleWithButton">
                        <div>Choisir une icone</div>
                        <div></div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <StyledIcons>
                    <div className="row">
                        {
                            icons.map((icone: string, index: number) => (
                                <div key={index} className="col-md-1 col-lg-1 col-sm-1 col-xs-3 card-icon"  
                                    onClick={e => {
                                        setValue(`menuItems.${RegisterIndex}.icon`, icone);
                                        setValue(`menuItems.${RegisterIndex}.type_icon`, 'icone');
                                        setShowModal(false)
                                    }}>
                                    <div className="text-center">
                                        <i className={`${icone} one-icone`}></i>
                                    </div>
                                    <div className="title text-center">
                                        {icone}
                                    </div>
                                </div>
                            )
                            )
                        }

                    </div>
                </StyledIcons>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}

export default ListIcons
