import { string, boolean, object, bool ,mixed ,array,number} from "yup";

export const produitSchema = object().shape({
    titre: object().shape({
      fr: string().required('Le titre  est obligatoire'),
      en: string(),
      ar: string(),
    }),
    description: object().shape({
      fr: string().required('La description  est obligatoire'),
      en: string(),
      ar: string(),
    }),
    fournisseurs : string().required('fournisseurs est obligatoire'),
    refProduit :string().required('reference produit est obligatoire ! '),
    code_a_barreProduit : string().required('code a barre  produit est obligatoire !'),
   
   
  });