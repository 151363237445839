import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  SelectedOrganisationId,
  selectUser,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  Journal,
  getJournalCaisseByIdApi,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import {
  ListJournalMouvementsCaisse,
  findMouvementsCaisseByJournalApi,
} from "../../_App/Redux/Slices/mouvementCaisse/MouvementCaisseSlice";
import {
  ListJournalOrders,
  findOrdersByJournalApi,
} from "../../_App/Redux/Slices/Orders/OrderSlice";
import { formadate } from "../../_App/Helpers/helpers";
import {
  ArticleData,
  OrderType,
} from "../../_App/Types/Entites/Orders/OrderType";
import ReactToPrint from "react-to-print";
import { Button } from "react-bootstrap";
import _ from "lodash";

const Facture = () => {
  //   Total HT 716.000
  // Total Remise 794.000
  // Total Net HT 24.000
  // Montant TVA 136.040
  // Total TTC 830.000
  // Timbre
  // Net a payer 36.000

  let { id } = useParams<{ id?: string }>(); // Explicitly define the type
  const [produits, setProduits] = useState<ArticleData[]>();
  var totalHt = 0;
  var TotalNetHT = 0;
  var montantTVA = 0;
  var totalTTC = 0;
  var NetToPay = 0;
  const timbre = 1;
  const dispatch = useAppDispatch();
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
  const journal = useAppSelector(Journal);
  const journalOrders = useAppSelector(ListJournalOrders);
  const user = useAppSelector(selectUser);

  const grouppingProducts = () => {
    let array: ArticleData[] = [];
    journalOrders.map((order: any) => {
      if (order.status === "Payée") {
        order.items.map((item: ArticleData) => {
          const index = array.findIndex(
            (product) => product.article._id === item.article._id
          );
          if (index >= 0) {
            // Make sure ArticleData properties are not read-only
            array[index].quantity += item.quantity;
            array[index].prix_total += item.prix_total;
          } else {
            array.push({ ...item }); // Create a new object to avoid read-only issues
          }
        });
      }
    });

    return array;
  };

  useEffect(() => {
    if (currentOrganisation && id) {
      dispatch(findMouvementsCaisseByJournalApi(id));
      dispatch(getJournalCaisseByIdApi(id));
      dispatch(findOrdersByJournalApi(id));
    }
  }, [dispatch]);

  let date = new Date();

  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [isPrinting, setIsPrinting] = useState(false);

  const handleCheckboxChange = (itemId: number) => {
    const isChecked = checkedItems.includes(itemId);

    if (isChecked) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
    } else {
      setCheckedItems([...checkedItems, itemId]);
    }
  };

  const componentRef = useRef<HTMLDivElement>(null);

  function eliminateItemsFromArray1(): ArticleData[] {
    let itemsCopy: ArticleData[] = JSON.parse(
      JSON.stringify(grouppingProducts())
    );

    return itemsCopy.filter((item) => !checkedItems.includes(item.article._id));
  }

  return (
    <>
      <div className="row invoice layout-spacing">
        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="doc-container">
            <div className="row">
              <div className="col-xl-12">
                <div className="invoice-container">
                  <div className="invoice-inbox" ref={componentRef}>
                    <div id="ct" className="">
                      <div className="invoice-00001">
                        <div className="content-section">
                          <div className="inv--head-section inv--detail-section">
                            <div className="row">
                              <div className="col-sm-6 col-12 mr-auto">
                                <div className="d-flex">
                                  {/* <img
                                    className="company-logo"
                                    src="/assets/assets/img/logo.png"
                                    alt="company"
                                  /> */}
                                  <h3 className="in-heading align-self-center">
                                    {journal?.organisation_id.nom}
                                  </h3>
                                </div>
                              </div>

                              <div className="col-sm-6 text-sm-right">
                                <p className="inv-list-number">
                                  <span className="inv-title">Facture : </span>{" "}
                                  <span className="inv-number">#0001</span>
                                </p>
                                <p className="inv-list-number">
                                  <span className="inv-title">Journal : </span>{" "}
                                  <span className="inv-number">
                                    {" "}
                                    {journal?.code_journal}{" "}
                                  </span>
                                </p>
                              </div>

                              <div className="col-sm-6 align-self-center mt-3">
                                <p className="inv-street-addr">
                                  XYZ Delta Street
                                </p>
                                <p className="inv-email-address">
                                  {user?.email}
                                </p>
                                <p className="inv-email-address">
                                  {journal?.organisation_id.numero}
                                </p>
                              </div>
                              <div className="col-sm-6 align-self-center mt-3 text-sm-right">
                                <p className="inv-created-date">
                                  <span className="inv-title">
                                    Date Facture :{" "}
                                  </span>{" "}
                                  <span className="inv-date">
                                    {" "}
                                    {formadate(date)}{" "}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>

                          <div className="inv--product-table-section">
                            <div className="table-responsive">
                              <table className="table">
                                <thead className="">
                                  <tr>
                                    <th scope="col" style={{ width: "5%" }}>
                                      N°
                                    </th>
                                    <th scope="col" style={{ width: "10%" }}>
                                      Ref
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "20%" }}
                                    >
                                      Article
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "10%" }}
                                    >
                                      Prix HT
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "5%" }}
                                    >
                                      Qte
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "10%" }}
                                    >
                                      TVA
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "10%" }}
                                    >
                                      Remise
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "10%" }}
                                    >
                                      Total HT
                                    </th>
                                    <th
                                      className="text-right"
                                      scope="col"
                                      style={{ width: "10%" }}
                                    >
                                      Total Net HT
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {!isPrinting
                                    ? grouppingProducts() &&
                                      grouppingProducts().map(
                                        (item: any, index: number) => {
                                          const calculatedRemise =
                                            (item.article.prix_vente_ht *
                                              item.quantity *
                                              item.article.remise) /
                                            100;
                                          totalHt +=
                                            item.article.prix_vente_ht *
                                            item.quantity;
                                          TotalNetHT +=
                                            item.article.prix_vente_ht *
                                              item.quantity -
                                            calculatedRemise;
                                          montantTVA +=
                                            ((item.article.prix_vente_ht *
                                              item.quantity -
                                              calculatedRemise) *
                                              item.article.tva) /
                                            100;

                                          const codeArticle =
                                            item.article.code_article;

                                          return (
                                            <tr key={index}>
                                              <td> {index + 1} </td>
                                              <td>{codeArticle}</td>
                                              <td className="text-right">
                                                {!isPrinting ? (
                                                  <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                      handleCheckboxChange(
                                                        item.article._id
                                                      )
                                                    }
                                                    checked={checkedItems.includes(
                                                      item.article._id
                                                    )}
                                                  />
                                                ) : null}{" "}
                                                {item.article.titre.fr}
                                              </td>
                                              <td className="text-right">
                                                {item.article.prix_vente_ht.toFixed(
                                                  3
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {item.quantity}
                                              </td>

                                              <td className="text-right">
                                                {item.article.tva}
                                              </td>
                                              <td className="text-right">
                                                {" "}
                                                {item.article.remise}{" "}
                                              </td>
                                              <td className="text-right">
                                                {(
                                                  item.article.prix_vente_ht *
                                                  item.quantity
                                                ).toFixed(3)}
                                              </td>
                                              <td className="text-right">
                                                {(
                                                  item.article.prix_vente_ht *
                                                    item.quantity -
                                                  calculatedRemise
                                                ).toFixed(3)}{" "}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    : eliminateItemsFromArray1() &&
                                      eliminateItemsFromArray1().map(
                                        (item: any, index: number) => {
                                          const calculatedRemise =
                                            (item.article.prix_vente_ht *
                                              item.quantity *
                                              item.article.remise) /
                                            100;
                                          totalHt +=
                                            item.article.prix_vente_ht *
                                            item.quantity;
                                          TotalNetHT +=
                                            item.article.prix_vente_ht *
                                              item.quantity -
                                            calculatedRemise;
                                          montantTVA +=
                                            ((item.article.prix_vente_ht *
                                              item.quantity -
                                              calculatedRemise) *
                                              item.article.tva) /
                                            100;
                                          const codeArticle =
                                            item.article.code_article;

                                          return (
                                            <tr key={index}>
                                              <td> {index + 1} </td>
                                              <td>{codeArticle}</td>
                                              <td className="text-right">
                                                {!isPrinting ? (
                                                  <input
                                                    type="checkbox"
                                                    onChange={() =>
                                                      handleCheckboxChange(
                                                        item.article._id
                                                      )
                                                    }
                                                    checked={checkedItems.includes(
                                                      item.article._id
                                                    )}
                                                  />
                                                ) : null}{" "}
                                                {item.article.titre.fr}
                                              </td>
                                              <td className="text-right">
                                                {item.article.prix_vente_ht.toFixed(
                                                  3
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {item.quantity}
                                              </td>

                                              <td className="text-right">
                                                {item.article.tva}
                                              </td>
                                              <td className="text-right">
                                                {item.article.remise}
                                              </td>
                                              <td className="text-right">
                                                {(
                                                  item.article.prix_vente_ht *
                                                  item.quantity
                                                ).toFixed(3)}
                                              </td>
                                              <td className="text-right">
                                                {/* Prix HT = Prix TTC ÷ (1 + Taux de TVA) */}{" "}
                                                {(
                                                  item.article.prix_vente_ht *
                                                    item.quantity -
                                                  calculatedRemise
                                                ).toFixed(3)}{" "}
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )}
                                </tbody>
                              </table>
                            </div>
                          </div>

                          <div className="inv--total-amounts">
                            <div className="row mt-4">
                              <div className="col-sm-5 col-12 order-sm-0 order-1"></div>
                              <div className="col-sm-7 col-12 order-sm-1 order-0">
                                <div className="text-sm-right">
                                  <div className="row">
                                    <div className="col-sm-8 col-7">
                                      <p className="">Total HT : </p>
                                    </div>
                                    <div className="col-sm-4 col-5">
                                      <p className=""> {totalHt.toFixed(3)} </p>
                                    </div>
                                    <div className="col-sm-8 col-7">
                                      <p className="">Total Net HT </p>
                                    </div>
                                    <div className="col-sm-4 col-5">
                                      <p className="">
                                        {" "}
                                        {TotalNetHT.toFixed(3)}{" "}
                                      </p>
                                    </div>
                                    <div className="col-sm-8 col-7">
                                      <p className="">Montant TVA </p>
                                    </div>
                                    <div className="col-sm-4 col-5">
                                      <p className="">
                                        {" "}
                                        {montantTVA.toFixed(3)}{" "}
                                      </p>
                                    </div>
                                    <div className="col-sm-8 col-7">
                                      <p className="">Total TTC </p>
                                    </div>
                                    <div className="col-sm-4 col-5">
                                      <p className="">
                                        {" "}
                                        {(TotalNetHT + montantTVA).toFixed(
                                          3
                                        )}{" "}
                                      </p>
                                    </div>
                                    <div className="col-sm-8 col-7">
                                      <p className="">Timbre</p>
                                    </div>
                                    <div className="col-sm-4 col-5">
                                      <p className=""> {timbre} </p>
                                    </div>
                                    <div className="col-sm-8 col-7 grand-total-title">
                                      <h4 className="">Total a payer</h4>
                                    </div>
                                    <div className="col-sm-4 col-5 grand-total-amount">
                                      <h4 className="">
                                        {(
                                          TotalNetHT +
                                          montantTVA +
                                          timbre
                                        ).toFixed(3)}
                                      </h4>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row" style={{ justifyContent: "center" }}>
                    <button
                      className="btn btn-success mb-2"
                      style={{
                        width: "80%",
                        background: "#009688",
                      }}
                      onClick={(e) => {
                        setIsPrinting(!isPrinting);
                      }}
                    >
                      {isPrinting ? "Editer" : "Confirmer"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPrinting ? (
        <ReactToPrint
          trigger={() => (
            <Button className="invoice-print-button" variant="contained">
              Print
            </Button>
          )}
          content={() => componentRef.current}
        />
      ) : null}
    </>
  );
};

export default Facture;
