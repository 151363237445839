import React, { useEffect, useState } from 'react';
import { Ingredient } from '../../../../_App/Types/Entites/Ingredient';
import {SupplementtItem}  from './SupplementItem';
import { useAppDispatch , useAppSelector} from '../../../../_App/Redux/hooks';
import { findIngredientByProductApi,  Listsupplements } from '../../../../_App/Redux/Slices/ingredients/ingredientSlice';

interface Props {
  idProduit: String;
}

export default function SupplementList({ idProduit }:Props) {
  const dispatch = useAppDispatch();
  const supplements = useAppSelector(Listsupplements);
  
  useEffect(() => {
    dispatch(findIngredientByProductApi(idProduit));
  }, []);

  return (


    <div>
    {supplements.length !== 0 ? (
          <div > 
          <p>Supplements</p>

          {supplements.map((item, index) => (
          <div key={index} >
                  <SupplementtItem supplement={item} />
            </div>
          ))}
          </div>        
          ) : (
            <p>aucun supplement</p>
)}
 </div>
 
  )
}
