import React ,{useState ,useEffect} from 'react';
import CategorieItem from './CategorieItem';
import Pagination from '../../../../Shared/Pagination/Pagination';
import { Container, Row, Col, Form } from 'react-bootstrap';
import { useAppDispatch , useAppSelector} from '../../../../_App/Redux/hooks';
import { updatePagination , findCategoriesApi ,ListCategories  ,PaginationCategories  } from '../../../../_App/Redux/Slices/categories/categorieSlice';
import { PaginationType } from '../../../../_App/Types/Entites/PaginationType';
import { useNavigate } from 'react-router-dom';
import Header from '../../../../Shared/Header/Header';

export default function CategorieList() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const categories = useAppSelector(ListCategories);
  const paginationcontroller = useAppSelector(PaginationCategories); 
  const [numColumns, setNumColumns] = useState(paginationcontroller.NumberColone); 
  const [numitemPage, setNumitemPage] = useState(paginationcontroller.itemsPerPage); 

  useEffect(() => {
    const idOrganisation = localStorage.getItem('idOrganisation') || "63e1130f9c17708ead8be889";
    dispatch(findCategoriesApi(idOrganisation));
    
 
  }, [dispatch]);

 
   const GetSousCategorieByCategorie = (categorieId: String) => {
  
    navigate(`/ventes/categorie/${categorieId}`);

  };

 

    //pagination et colone parametres
  
    const handleNumColumnsChange = (numbcol : number ) => {
      const newNumColumns = numbcol;

      const PaginationData: PaginationType = {
        itemsPerPage: numitemPage ? numitemPage : 0, 
        NumberColone: newNumColumns ? newNumColumns : 0 ,  
      }
      dispatch(updatePagination(PaginationData));

      setNumColumns(newNumColumns);
    };
    const handleNumitemChange = (numbitem : number ) => {
      const newNumitems = numbitem ;
      
      const PaginationData: PaginationType = {
            itemsPerPage: newNumitems ? newNumitems : 0, 
            NumberColone: numColumns ?numColumns : 0,  
          }
      dispatch(updatePagination(PaginationData));
      setNumitemPage(newNumitems);
    };
  const [currentPage, setCurrentPage] = useState(1);
  const indexOfLastItem = currentPage * numitemPage;
  const indexOfFirstItem = indexOfLastItem - numitemPage;
  const currentItems = categories.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(categories.length / numitemPage);

  const onPageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };
  const searchByKeyword =(keyword: string) =>{
    // Utilisez la méthode `filter` pour filtrer les éléments qui correspondent au mot-clé
  
    return [];
  }

  return (
    <>
    <Header handleNumColumnsChange={handleNumColumnsChange}  handleNumitemChange={handleNumitemChange} searchByKeyword={searchByKeyword}/>

    <div className='mt'>
      <Container>

      <Row>
  {currentItems.map((categorieItem, index) => (
    <Col key={index} xs={12 / numColumns}>
      <div>
        <CategorieItem categorie={categorieItem} onClick={() => GetSousCategorieByCategorie(categorieItem._id)} />
      </div>
    </Col>
  ))}
</Row>

    </Container> 
          <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={onPageChange}
        />
    </div>
      </>
  );
}


