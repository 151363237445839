import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosResponse } from "axios";
import { RootState } from "../../store";
import { AchatType } from "../../../Types/Entites/Achat/AchatType";
import _ from "lodash";
import { DetailAchatType } from "../../../Types/Entites/DetailAchatType";
import { MouvementType } from "../../../Types/Entites/MouvementType";

/**
 * create  Achat function
 * @private
 */
export const createAchatApi = createAsyncThunk(
  "achat/createAchatApi",
  async (data: any, { rejectWithValue }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };
      const {
       file
      } = data;


      var body = new FormData();
      body.append("data", JSON.stringify(data));
      // Check if 'file' is defined and has a valid file object
      if (file && file.length > 0 && file[0] instanceof File) {
        body.append("file", file[0]);
      }
      const response = await axios.post(
        "achat/createAchatApi",  
        body,
        config
      );

      return response.data;
    } catch (error: any) {
      console.error('Error in createAchatApi:', error);
      return rejectWithValue({
        success: false,
        message: error.response?.data?.message || "An error occurred",
        action: error.response?.data?.action || "",
        data: error.response?.data?.data || null,
      });
    }
  }
);



/**
 * update a Achat.
 * @public
 */

export const updateAchatApi = createAsyncThunk(
  "achat/updateAchatApi",
  async (data: AchatType, { rejectWithValue, dispatch }) => {
    try {
      const config = {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (event: any) => {
          console.log(
            `Current progress:`,
            Math.round((event.loaded * 100) / event.total)
          );
        },
      };
      const {
       file
      } = data;


      var body = new FormData();
      body.append("data", JSON.stringify(data));
      // Check if 'file' is defined and has a valid file object
      if (file && file.length > 0 && file[0] instanceof File) {
        body.append("file", file[0]);
      }

      const response = await axios.put("achat/updateAchatApi",body,config);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * annuler a Achat.
 * @public
 */

export const annulerAchatApi = createAsyncThunk(
  "achat/annulerAchatApi",
  async (data: AchatType, { rejectWithValue, dispatch }) => {
    try {
     

      const response = await axios.put("achat/annulerAchatApi",data);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * update a Achat.
 * @public
 */


export const clotureAchatApi = createAsyncThunk(
  "achat/clotureAchatApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
     

      const response = await axios.put("achat/clotureAchatApi",data);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Confirm a Achat.
 * @public
 */
export const condirmerAchatApi = createAsyncThunk(
  "achat/condirmerAchatApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
     

      const response = await axios.put("achat/condirmerAchatApi",data);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const clotureDetailAchatApi = createAsyncThunk(
  "achat/clotureDetailAchatApi",
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
     

      const response = await axios.put("achat/clotureDetailAchatApi",data);
      return response.data;
    } catch (error: any) {
      console.log("errorrr", error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of Achats
 * @public
 */
export const findAchatsBySocieteApi = createAsyncThunk(
  "achat/findAchatsBySocieteApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `achat/findAchatsBySocieteApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * Get list of Achats
 * @public
 */
export const findAchatByFournisseurApi = createAsyncThunk(
  "achat/findAchatByFournisseurApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `achat/findAchatByFournisseurApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
 
/**
 * Get list of Achats
 * @public
 */
export const findDetailAchatsBySocieteApi = createAsyncThunk(
  "achat/findDetailAchatsBySocieteApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `achat/findDetailAchatsBySocieteApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

 
/**
 * Get file
 * @public
 */
export const findFileApi = createAsyncThunk(
  'achat/findFileApi',
  async (filename: string): Promise<AxiosResponse> => {
    const response = await axios.get(`achat/findFileApi/${filename}`, {
      responseType: 'blob', 
    });
    return response;
  }
);
/**
 * Get list of Mouvement
 * @public
 */
export const findMouvementStockApi = createAsyncThunk(
  "achat/findMouvementStockApi",
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `achat/findMouvementStockApi/${id}`
      );
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


interface AchatState {
  listAchat: AchatType[];
  listMouvement:MouvementType[];
  listDetailAchats: { [achatId: string]: DetailAchatType[] };
  listAchatFournisseur:AchatType[];
}



const initialState:AchatState={
  listAchat: [] ,
  listMouvement: [] ,
  listDetailAchats: {},
  listAchatFournisseur:[] 
}
export const AchatSlice = createSlice({
  name: "achat",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createAchatApi.fulfilled, (state, action) => {
      state.listAchat.splice(0, 0, action.payload.data.achat);
      state.listDetailAchats = {
        ...state.listDetailAchats,
        [action.payload.data.achat]: action.payload.data.listDetailAchats,
      };
    });
    
    builder.addCase(findAchatsBySocieteApi.fulfilled, (state, action) => {
       state.listAchat= action.payload;
    });
    builder.addCase(findDetailAchatsBySocieteApi.fulfilled, (state, action) => {
      const groupedachats = _.groupBy(action.payload, "achat_id");
      state.listDetailAchats = groupedachats;
     });
     builder.addCase(findAchatByFournisseurApi.fulfilled, (state, action) => {
      state.listAchatFournisseur = action.payload;
     });
     builder.addCase(findMouvementStockApi.fulfilled, (state, action) => {
      state.listMouvement= action.payload;

     });
     builder.addCase(updateAchatApi.fulfilled, (state, action) => {
      const updated_achat = action.payload.data;
      var index = _.findIndex(state.listAchat, { _id: updated_achat._id });
     
      state.listAchat.splice(index, 1, updated_achat);
    });
    builder.addCase(annulerAchatApi.fulfilled, (state, action) => {
      const achat_annuler = action.payload.data;
      var index = _.findIndex(state.listAchat, { _id: achat_annuler._id });
     
      state.listAchat.splice(index, 1, achat_annuler);
    });
    builder.addCase(condirmerAchatApi.fulfilled, (state, action) => {
      const achat_annuler = action.payload.data;
      var index = _.findIndex(state.listAchat, { _id: achat_annuler._id });
     
      state.listAchat.splice(index, 1, achat_annuler);
    });
    builder.addCase(clotureAchatApi.fulfilled, (state, action) => {
      const updated_achat = action.payload.data;
    
      var index = _.findIndex(state.listAchat, { _id: updated_achat._id });
     
      state.listAchat.splice(index, 1, updated_achat);
    });
    builder.addCase(clotureDetailAchatApi.fulfilled, (state, action) => {
      const updatedDetailAchat = action.payload.data;
      const achatId = updatedDetailAchat.achat_id;
    
      // Find the index of the element in listDetailAchats with the specified achat_id
      const index = state.listDetailAchats[achatId]?.findIndex(
        (item) => item._id === updatedDetailAchat.id
      );
    
      if (index !== undefined && index !== -1) {
        // If the element is found, update it
        state.listDetailAchats[achatId][index] = updatedDetailAchat.listDetailAchats;
      } else {
        // If the element is not found, add it
        state.listDetailAchats = {
          ...state.listDetailAchats,
          [achatId]: [updatedDetailAchat.listDetailAchats],
        };
      }
    });
    
  },
});

export const ListAchat = (state: RootState) => state.achats.listAchat;
export const ListAchatFournisseur = (state: RootState) => state.achats.listAchatFournisseur;

export const ListDetailAchats = (state: RootState) => state.achats.listDetailAchats;
export const ListMouvements = (state: RootState) => state.achats.listMouvement;

export default AchatSlice.reducer;
