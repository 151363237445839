import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { Resolver, useForm } from "react-hook-form";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  createUserRoleApi,
  updateRoleApi,
} from "../../_App/Redux/Slices/userRoles/userRoleSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { AddUserRoleSchema } from "../../_App/Schemas/UserRoleSchema";
import { UserRoleDataType } from "../../_App/Types/Forms/UserRoleDataType";
import { useTranslation } from "react-i18next";

export default function UserRoleForm({
  ShowModal,
  setShowModal,
  userRole,
}: any) {
  const dispatch = useAppDispatch();

  const formOptions = {
    resolver: yupResolver(AddUserRoleSchema) as Resolver<UserRoleDataType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<UserRoleDataType>(formOptions);
  const { errors } = formState;
  const { t } = useTranslation();

  useEffect(() => {
    if (userRole) {
      reset({
        _id: userRole._id,
        libelle: userRole.libelle,
        redirectTo: userRole.redirectTo,
      });
    } else {
      reset({
        libelle: "",
        redirectTo: "",
      });
    }
  }, [userRole]);

  const onSubmit = async (data: UserRoleDataType) => {
    try {
      let response;

      if (data._id) {
        response = await dispatch(updateRoleApi(data));
      } else {
        response = await dispatch(createUserRoleApi(data));
      }

      if (response.payload.success) {
        ToastSuccess(response.payload.message);
        reset({
          libelle: "",
          redirectTo: "",
        });
        setShowModal(false);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      ToastWarning(
        "Une erreur s'est produite lors de l'ajout ou de la mise à jour du rôle utilisateur."
      );
    }
  };

  return (
    <Modal show={ShowModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>
          {userRole?._id ? (
            <span className="modal-title" id="addUserRoleLabel">
                 {t("Modifier le rôle")} {userRole?.libelle} 
            </span>
          ) : (
            <span className="modal-title" id="addUserRoleLabel">
                 {t("Ajouter un nouveau rôle utilisateur")}
            </span>
          )}
        </Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="form-row align-items-center justify-content-center">
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>{t("Libellé")}</Form.Label>
              <div className="col-auto">
                <input
                  type="text"
                  className={`form-control mb-2 ${
                    errors.libelle ? "is-invalid" : ""
                  }`}
                  {...register("libelle")}
                />
                <div className="invalid-feedback">
                  {errors.libelle?.message}
                </div>
              </div>
            </Form.Group>

            <Form.Group className="mb-3" controlId="exampleForm.ControlInput2">
              <Form.Label>{t("Rediriger vers")}</Form.Label>
              <div className="col-auto">
                <Form.Control
                  as="select"
                  className={`form-control mb-2 ${
                    errors.redirectTo ? "is-invalid" : ""
                  }`}
                  {...register("redirectTo")}
                >
                  <option value="">Choisir une option</option>
                  <option value="caisse">caisse</option>
                  <option value="dashboard">dashboard</option>
                  <option value="ventes">ventes</option>
                  <option value="achats">achat</option>
                  <option value="users">users</option>
                  <option value="organisations">organisations</option>
                </Form.Control>
                <div className="invalid-feedback">
                  {errors.redirectTo?.message}
                </div>
              </div>
            </Form.Group>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            type="reset"
            onClick={(e) => setShowModal(false)}
          >
           {t("Annuler")}
          </Button>
          <Button type="submit" className="btn btn-primary-app">
          {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
