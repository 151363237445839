import React, { useEffect, useState } from "react";
import { handleKeyPress } from "../../_App/Helpers/helpers";

export default function PaiementAchatForm({t, modePaiement ,sens,setModePaiement ,settotal_input , total_input,totalcredit,totaldebit}: any) {
  const list_retenue = [0 ,1 , 2 , 3 , 4,5]
  const [retenue , setretenue]=useState(false)
  
  useEffect(() => {
    const total_paiment =
    (modePaiement.retunue !== '' ? (totaldebit - totalcredit )*(parseFloat(modePaiement.retenue)/100) : 0) +
    (modePaiement.espece !== '' ? parseFloat(modePaiement.espece) : 0) +
    (modePaiement.cheque.montant !== '' ? parseFloat(modePaiement.cheque.montant) : 0);
  
  
    settotal_input(total_paiment.toFixed(3))
  }, [modePaiement]);

 

  return (
    <div className="row">
      <div className="row">
      <div className="col-6">
        <strong>{t('Espece')}</strong>
        <div className="search-bar" style={{ marginBottom: '7px' }}>
          <input
            type="text"
            className="form-control search-form-control ml-lg-auto"
            placeholder="Espèces"
            
            onKeyPress={handleKeyPress}
            value={modePaiement.espece}
            onChange={(e) => setModePaiement({ ...modePaiement, espece: e.target.value})}
        
           
          />
        </div>
      </div>
      <div className="col-6">
        <input type="checkbox"  onClick={()=>setretenue(!retenue)} /> <strong>{t('Retenue')}</strong>
        <form role="search">
          <div className="search-bar" style={{ marginBottom: '7px' }}>
            {retenue ? (
               <select 
               onChange={(e) => setModePaiement({ ...modePaiement, retenue: e.target.value})}
               className="form-control search-form-control ml-lg-auto"

               >
               {list_retenue.map((item :any , index:number)=>(
               <option key={index}> {item}%</option>
 
               ))}
             </select>
            ):null}
           
          </div>
        </form>
      </div>
      </div>
     
      <div className="row">
        <strong>{t('Chèque')}</strong>
       
          <div className="search-bar col-4" style={{ marginBottom: '7px' }}>
            <input
              type="number"
              className="form-control search-form-control ml-lg-auto numberinput"
              placeholder="Numéro de cheque"
              value={modePaiement.cheque.numCheque}
              onChange={(e) =>
                setModePaiement((prevModePaiement:any) => ({
                  ...prevModePaiement,
                  cheque: { ...prevModePaiement.cheque, numCheque: e.target.value },
                }))
              }
            />
          </div>
          <div className="search-bar col-4" style={{ marginBottom: '7px' }}>
            <input
              type="date"
              className="form-control search-form-control ml-lg-auto"
              placeholder="date d'echeance"
              value={modePaiement.cheque.dateCheque}
              onChange={(e) =>
                setModePaiement((prevModePaiement:any) => ({
                  ...prevModePaiement,
                  cheque: { ...prevModePaiement.cheque, dateCheque: e.target.value },
                }))
              }
            />
          </div>
          <div className="search-bar col-4" style={{ marginBottom: '7px' }}>
            <input
              type="text"
              className="form-control search-form-control ml-lg-auto"
              placeholder="Montant"
              value={modePaiement.cheque.montant}
              onChange={(e) =>
                setModePaiement((prevModePaiement:any) => ({
                  ...prevModePaiement,
                  cheque: { ...prevModePaiement.cheque, montant: e.target.value },
                }))
              }
              onKeyPress={handleKeyPress}
            />
          </div>
       
      </div>
       <div className="row">
        <strong className="col-6">{t('Total entre')}{' '}{total_input}</strong>   
        {totalcredit && sens === 'credit'  ?( <strong className="col-6"> {t('Montant reste a paye')}{' '}{((totaldebit-totalcredit)-total_input).toFixed(3)}</strong>):null}
        {totalcredit && sens === 'debit' ?(
   <strong className="col-6"> {t('Montant reste a paye')}{' '}{((totaldebit - totalcredit )+parseFloat(total_input)).toFixed(3)}</strong>
):null}

       </div>
     
    </div>
  );
}
