import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import _ from "lodash";
import { FounisseurMouvementType } from "../../../Types/Entites/FournisseurMouvementType";


/**
 * Get list of fournisseur.
 * @public
 */
export const findFournisseurMouvementApi = createAsyncThunk(
  "fournisseurmvt/findFournisseurMouvementApi",
  async (idFournisseur: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`fournisseurmvt/findFournisseurMouvementApi/${idFournisseur}`);
      console.log('response.data',response.data)
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);


/**
 * Get list of fournisseur.
 * @public
 */
export const findFournisseurMouvementBySocieteApi = createAsyncThunk(
  "fournisseurmvt/findFournisseurMouvementBySocieteApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      
      const response = await axios.get(`fournisseurmvt/findFournisseurMouvementBySocieteApi/${id}`);
      console.log('response.data',response.data)
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);



const initialState: FounisseurMouvementType []= []
export const fournisseur_mouevementSlice = createSlice({
  name: "fournisseurMouevement",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
  
    builder.addCase(findFournisseurMouvementApi.fulfilled, (state, action) => {
      return (state = action.payload);
  
    });
   
    builder.addCase(findFournisseurMouvementBySocieteApi.fulfilled, (state, action) => {
      return (state = action.payload);
  
    });
   
   
  },
  
});

export const ListFournisseursMouvement = (state: RootState) => state.fournisseurmouevements;

export default fournisseur_mouevementSlice.reducer;
