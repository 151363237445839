import React from 'react'
import { Ingredient } from '../../../../_App/Types/Entites/Ingredient';

interface SupplementsItemProps {
    supplement: Ingredient;
}

export const SupplementtItem: React.FC<SupplementsItemProps> = ({ supplement }) => {
    return (
       <table id="invoice-list" className="table table-hover" style={{width: '100%'}}>
        <thead>
          <tr>
            <th >  </th>
            <th>Titre</th>
            <th>Prix</th>
          </tr>
        </thead>
        <tbody>
          <tr>
          <td>
      <label className="new-control new-checkbox checkbox-primary m-auto">
         <input type="checkbox" className="new-control-input chk-parent select-customers-info" id="customer-all-info" />
         <span className="new-control-indicator" /><span style={{visibility: 'hidden'}}>c</span>
       </label>
       </td>               
             <td><a href="apps_invoice-preview.html"><span className="inv-number">{supplement.titre}</span></a></td>
            <td><a href="apps_invoice-preview.html"><span className="inv-number">{supplement.prix}</span></a></td>
          </tr>
         
        </tbody>
      </table>
      
      
      )
}
