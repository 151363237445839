import React, { useState, useEffect } from "react";
import { SelectedOrganisationId } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListTable,
  Listtableparzone,
  NumerTable,
  findTableByOrganisationApi,
} from "../../_App/Redux/Slices/table/tableSlice";
import TableForm from "../../Components/Table/TableForm";
import TableList from "../../Components/Table/TableList";
import {
  ListZoneTable,
  findZoneTableByOrganisationApi,
} from "../../_App/Redux/Slices/zoneTable/zoneTableSlice";
import { useTranslation } from "react-i18next";
import Navbar from "../../_Layouts/Navbar/Navbar";
import Swal from "sweetalert2";
import { DepotDefautOrganisation, findDepotsDefautByOrganisationApi } from "../../_App/Redux/Slices/depot/depotSlice";
const TablePage = () => {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const OrganisationId = useAppSelector(SelectedOrganisationId);
  const [showModelTable, setshowModelTable] = useState(false);

  const [tableitem, settableitem] = useState(null);

  const listtableparzone = useAppSelector(Listtableparzone);
  const listZoneTable = useAppSelector(ListZoneTable);
  useEffect(() => {
    if (OrganisationId) {
      dispatch(findTableByOrganisationApi(OrganisationId));

      dispatch(findZoneTableByOrganisationApi(OrganisationId));
    }
  }, [dispatch]);
 /* const dd = useAppSelector(DepotDefautOrganisation)
  console.log('dddddd',dd)
  useEffect(() => {
    if (OrganisationId) {
      dispatch(findDepotsDefautByOrganisationApi(OrganisationId));

    }
  }, [dispatch]);*/

  const verifcationMsg = (msg: string) => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: msg,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t('Cancel!')

    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };
  return (
    <div>
      <Navbar />
      <div className="d-flex justify-content-end mb-3 mt-5">
        <button
          className="btn btn-primary-app"
          onClick={() => setshowModelTable(true)}
        >
          {t("Ajouter Table")}
        </button>
      </div>

      <TableForm
        t={t}
        ShowModal={showModelTable}
        setShowModal={setshowModelTable}
        table={tableitem}
        OrganisationId={OrganisationId}
        verifcationMsg={verifcationMsg}
      />

      {listtableparzone ? (
        <TableList
          t={t}
          ListTable={listtableparzone}
          listZoneTable={listZoneTable}
          settableitem={settableitem}
          setshowModelTable={setshowModelTable}
          listTable={listtableparzone}
          dispatch={dispatch}
          OrganisationId={OrganisationId}
          verifcationMsg={verifcationMsg}
        />
      ) : (
        <p>aucune table</p>
      )}
    </div>
  );
};

export default TablePage;
