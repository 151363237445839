import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  createAchatApi,
  updateAchatApi,
} from "../../_App/Redux/Slices/achat/achatSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { AchatFormType } from "../../_App/Types/Forms/AchatFormType";
import { AchatSchema } from "../../_App/Schemas/AchatSchema";
import {
  ListFournisseurs,
  findFourinsseurByOrganisationApi,
  findFourinsseurBySocieteApi,
} from "../../_App/Redux/Slices/fournisseur/fournisseurSlice";
import FournisseurForm from "../Products/Fournisseur/FournisseurForm";
import {
  findProductByOrganisationApi,
  findProductBySocieteApi,
  findproductfournisseurApi,
  ListProduits,
} from "../../_App/Redux/Slices/produits/produitSlice";
import ProductsForm from "../Products/Products/ProductsForm";
import AchatFormTable from "./AchatForm/AchatFormTable";
import AchatConfirmModel from "./AchatForm/AchatConfirmModel";
import {
  findDepotsBySocieteApi,
  ListDepotByOrganisation,
  ListDepots,
} from "../../_App/Redux/Slices/depot/depotSlice";
import { selectUserId } from "../../_App/Redux/Slices/Auth/authSlice";
import { SelectedOrganisations } from "../../_App/Redux/Slices/Auth/authSlice";
import _ from "lodash";
import Select from "react-select";
import DepotsForm from "../Depots/DepotsForm";
import { DepotsType } from "../../_App/Types/Entites/DepotsType";
import { findMatriceBySocieteApi } from "../../_App/Redux/Slices/matrice/matriceSlice";
import {
  ListTaxTva,
  findTaxTvaApi,
} from "../../_App/Redux/Slices/tax/taxSlice";
import {
  ListAchatType,
  findAchatTypeBySocieteApi,
} from "../../_App/Redux/Slices/achat/achattypeSlice";
import Swal from "sweetalert2";
import { handleKeyPress } from "../../_App/Helpers/helpers";
import { useTranslation } from "react-i18next";

export default function AchatForm({
  
  ShowModal,
  setShowModal,
  Achat,
  Detailachat,
  parametrage,
  organisation_id,
  societe,
  etat,
}: any) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const formOptions = {
    resolver: yupResolver(AchatSchema) as unknown as Resolver<AchatFormType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<AchatFormType>(formOptions);
  const { errors } = formState;
  const [ShowModalFournisseur, setShowModalFournisseur] = useState(false);
  const [ShowModalProduct, setShowModalProduct] = useState(false);

  const listFournisseurs = useAppSelector(ListFournisseurs);
  const listProduits = useAppSelector(ListProduits);
  const listDepots = useAppSelector(ListDepots);
  const listdOrganisations = useAppSelector(SelectedOrganisations);
  const listDepotByOrganisation = useAppSelector(ListDepotByOrganisation);
  const listAchatType = useAppSelector(ListAchatType);

  const [inputs, setInputs] = useState<any[]>([]);
  const [totalHT, setTotalHT] = useState(0);
  const [totalTTC, setTotalTTC] = useState(0);
  const [totaltva, setTotaltva] = useState(0);
  const [totalNetTTc, settotalNetTTc] = useState(0);
  const [totalNetpayer, settotalNetpayer] = useState(0);
  const [options, setOptions] = useState<any[]>();
  const [selectproducts, setselectproducts] = useState<any[]>([]);
  const [selectedfournisseur, setselectedfournisseur] = useState("");
  const [selecteddepots, setselecteddepots] = useState("");
  const [selectedorganisation, setselectedorganisation] = useState("");

  const [Rpied, setRpied] = useState(0);
  const [Respece, setRespece] = useState(0);
  const [Rfacture, setRfacture] = useState(0);
  const [prixNet, setPrixNet] = useState(0);
  const [ShowAchatConfirmModel, setShowAchatConfirmModel] = useState(false);
  const [ShowModalDepots, setShowModalDepots] = useState(false);
  const [listdepotsfilter, setlistdepots] = useState<DepotsType[]>([]);

  const idUser = useAppSelector(selectUserId);
  const [data, setdata] = useState({});

  useEffect(() => {
    if (societe) {
      dispatch(findFourinsseurBySocieteApi(societe));
      dispatch(findDepotsBySocieteApi(societe));
      dispatch(findMatriceBySocieteApi(societe));
      dispatch(findTaxTvaApi(societe));
      dispatch(findAchatTypeBySocieteApi(societe));
    }
  }, [dispatch, societe]);
  useEffect(() => {
    if (selectedfournisseur) {
      dispatch(findproductfournisseurApi(selectedfournisseur));
      
    }
  }, [dispatch, selectedfournisseur]);
  useEffect(() => {
    if (!selectedorganisation) {
      setlistdepots(listDepots);
    } else {
      const list = listDepotByOrganisation[selectedorganisation];
      if (list) {
        const sortedList = [...list].sort((a, b) => {
          if (a.defaut === b.defaut) {
            return 0;
          } else if (a.defaut === true) {
            return -1;
          } else {
            return 1;
          }
        });
        setlistdepots(sortedList);
        setInputs(inputs.map(element => ({
          ...element,
          depots_id: sortedList[0]._id
        })));
        
      } else {
        setlistdepots([]);
      }
    }
  }, [listDepots, selectedorganisation]);
  const taxes = useAppSelector(ListTaxTva);
  useEffect(() => {
    if (Achat) {
      reset({
        _id: Achat._id,
        reference_document: Achat.reference_document,
        fournisseur_id: Achat.fournisseur_id,
        date_document: Achat.date_document
          ? new Date(Achat.date_document).toISOString().substr(0, 10)
          : "",
        date_livraison: Achat.date_livraison
          ? new Date(Achat.date_livraison).toISOString().substr(0, 10)
          : "",
        total_ht: Achat.total_ht,
        total_ttc: Achat.total_ttc,
        total_tva: Achat.total_tva,
        RFacture: Achat.RFacture,
        REspece: Achat.REspece,
        RPied: Achat.RPied,
        commentaire: Achat.commentaire,
        file: Achat.file,
        depots_id: Achat.depots_id,
        Type_id: Achat.Type_id,
        refAchat: Achat.refAchat,
      });
      setselectedfournisseur(Achat.fournisseur_id);
      setTotalHT(Achat.total_ht);
      setTotalTTC(Achat.total_ttc);
      setTotaltva(Achat.total_tva);
      Detailachat.forEach((element: any) => {
        element.show = true;
      });
      setInputs(Detailachat);
      settotalNetpayer(Achat.total_net_payer.toFixed(3));
      settotalNetTTc(Achat.total_net_ttc);
      setRfacture(Achat.RFacture);
      setRespece(Achat.REspece);
      setRpied(Achat.RPied);
      setPrixNet(0);
    } else {
      setTotalHT(0);
      setTotalTTC(0);
      setTotaltva(0);
      settotalNetpayer(0);
      settotalNetTTc(0);
      setInputs([]);
      setselectproducts([]);
      setPrixNet(0);
      reset({
        reference_document: "",
        fournisseur_id: "",
        date_document: "",
        date_livraison: "",
        organisation_id: "",
        RFacture: 0,
        REspece: 0,
        RPied: 0,
        commentaire: "",
        file: [],
      });
    }
  }, [Achat, ShowModal]);
  useEffect(() => {
    if (selecteddepots) {
      setInputs(inputs.map(element => ({
        ...element,
        depots_id: selecteddepots
      })));
    }
  }, [selecteddepots]);
  
  useEffect(() => {
    const listProduitFournisseur = listProduits.filter(
      (item: any) => item.fournisseurs === selectedfournisseur  && item.actif
    );

    const option = listProduitFournisseur.map((item: any) => ({
      value: item._id,
      label: `${item.titre.fr} - ${item.type}`,
    }));
    setOptions(option);
  }, [listProduits, selectedfournisseur, ]);

  const handleSelectChange = (selectedOption: any) => {
    const productid = selectedOption.value;
    const selectedproduct = listProduits.find(
      (item: any) => item._id === productid
    );

    setInputs((prevInputs) => [
      ...prevInputs,
      {
        produit: selectedproduct,
        product_id: selectedproduct?._id,
        tva: taxes.find((element) => element._id === selectedproduct?.tva)
          ?.taux,
        prix_ttc: selectedproduct?.prix_achat_ttc || 0,
        prix_ht: selectedproduct?.prix_achat_ht || 0,
        remise: 0,
        type: selectedproduct?.type === "fils" ||selectedproduct?.type === "import" ? "fils_sansP" : "P",
        depots_id: selecteddepots !== "" ? selecteddepots : listdepotsfilter[0]._id,
        caracteristique: [],
        show: true,
      },
    ]);
  };
  const verifcationMsg = () => {
    return Swal.fire({
      title: t("vous-êtes sûr?"),
      text: t('vous voulez enregitrer ce achat'),
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: t("Confirmer!"),
      cancelButtonText: t('Cancel!')
    }).then((result) => {
      return result.isConfirmed ? true : false;
    });
  };

  useEffect(() => {
    console.log(inputs);
    if (inputs.length !== 0) {
      const newTotalHT = inputs.reduce((acc, input) => {
        return acc + (input?.type !== "commentaire" ? input?.total_prix_ht : 0);
      }, 0);

      const newTotalTTC = inputs.reduce((acc, input) => {
        return (
          acc + (input?.type !== "commentaire" ? input?.total_prix_ttc : 0)
        );
      }, 0);
      if (!etat) setTotalHT(newTotalHT.toFixed(3));
      if (!etat) setTotalTTC(newTotalTTC.toFixed(3));
      const filteredItems = inputs.filter(
        (item) => item.quantite || item.prixttc || item.prixht
      );
      const totaltva = filteredItems.reduce((acc, produit) => {
        return acc + (produit.tva || 0);
      }, 0);
      if (!etat) setTotaltva(totaltva);
    }
  }, [inputs]);

  useEffect(() => {
    let newTotalNetTTC = inputs.reduce((acc, input) => {
      return acc + (input?.type !== "commentaire" ? input?.total_net_ttc : 0);
    }, 0);
    if (!Number.isNaN(Rpied)) {
      newTotalNetTTC = newTotalNetTTC - (newTotalNetTTC * Rpied) / 100;
    }
    if (!Number.isNaN(Rfacture)) {
      newTotalNetTTC = newTotalNetTTC - (newTotalNetTTC * Rfacture) / 100;
    }
    if (!Number.isNaN(Respece)) {
      newTotalNetTTC = newTotalNetTTC - (newTotalNetTTC * Respece) / 100;
    }
    if (etat) setPrixNet(newTotalNetTTC + 1);
    if (!etat) settotalNetTTc(newTotalNetTTC.toFixed(3));
    if (!etat) settotalNetpayer((newTotalNetTTC + 1).toFixed(3));
  }, [inputs, Rpied, Rfacture, Respece]);

  const handelTest = (data: any) => {
    const formattedPrixNet = prixNet.toFixed(3);
    console.log(totalNetpayer);
    if (formattedPrixNet !== String(totalNetpayer)) {
      if (!etat) data.confirmer = false;
      setdata(data);
      setShowAchatConfirmModel(true);
    } else {
      if (!etat) data.confirmer = false;
      onSubmit(data);
    }
  };

  const onSubmit = async (data: any) => {
    setShowAchatConfirmModel(false);
    const filteredItems = inputs.filter(
      (item) => item.quantite && item.prix_ttc && item.prix_ht
    );
    if (filteredItems.length === 0) {
      ToastWarning(t("Votre achat doit contenir au moins un produit"));
    } else {
      if (await verifcationMsg()) {
        let response;

        data.organisation_id =
          selectedorganisation !== "" ? selectedorganisation : null;
        data.societe_id = societe;
        data.total_ht = totalHT;
        data.total_ttc = totalTTC;
        data.cloture = false;
        data.annule = false;
        data.payer = false;
        data.detailachat = filteredItems;
        data.total_tva = totaltva;
        data.total_net_ttc = totalNetTTc;
        data.total_net_payer = totalNetpayer;
        data.timber = parametrage?.timber;
        data.depots_id =
          selecteddepots !== "" ? selecteddepots : listdepotsfilter[0]._id;
      if (data._id) {
          response = await dispatch(updateAchatApi(data));
        } else {
          data.refAchat = listAchatType.find(
            (item) => item._id === data.Type_id
          )?.souche;

          response = await dispatch(createAchatApi(data));
        }

        if (response?.payload.success) {
          ToastSuccess(response?.payload.message);
          reset({
            _id: "",
            reference_document: "",
            fournisseur_id: "",
            date_document: "",
            date_livraison: "",
            organisation_id: "",
            RFacture: 0,
            REspece: 0,
            RPied: 0,
            commentaire: "",
            file: [],
          });
          setTotalHT(0);
          setTotalTTC(0);
          setTotaltva(0);
          settotalNetpayer(0);
          settotalNetTTc(0);
          setInputs([]);
          setselectproducts([]);
          setShowModal(false);
          setPrixNet(0);
        } else {
          ToastWarning(response?.payload.message);
        }
        window.location.reload();
      }
    }
  };
  const handleInputChange = (event:any) => {
    const inputValue = event.target.value;
  
    // Assurez-vous que la valeur entrée est un nombre valide
    const floatValue = parseFloat(inputValue);

    if (!isNaN(floatValue)) {
      // Mettre à jour l'état avec la valeur convertie
      setPrixNet(floatValue);

    }
  };
  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-70w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">
            {" "}
            <span>{t("Piece D'achat")}</span>
          </Modal.Title>
        </Modal.Header>

        <form onSubmit={handleSubmit(handelTest)}>
          <Modal.Body>
            <div className="container mt-4 ">
              <div className="row mb-3 ">
                <div className="col-md-2  mr-3  ">
                  <div className="form-group ">
                    <label htmlFor="reference_document">
                      <span style={{ color: "red" }}>*</span>
                      {t("Ref-Document")}
                    </label>
                    <input
                      type="text"
                      disabled={etat}
                      className={`form-control numberinput ${
                        errors.reference_document ? "is-invalid" : ""
                      }`}
                      {...register("reference_document")}
                    />

                    <div>
                      <label htmlFor="fournisseur_id ">
                        <span style={{ color: "red" }}>*</span>
                        {t("Fournisseur")}
                      </label>
                      <span
                        className="c-primary float-right"
                        onClick={() => setShowModalFournisseur(true)}
                      >
                      <i className="fas fa-plus-circle fa-lg"></i>
                      </span>
                    </div>

                    <select
                      className={`form-control mb-2 ${
                        errors.fournisseur_id ? "is-invalid" : ""
                      }`}
                      disabled={etat}
                      {...register("fournisseur_id")}
                      onChange={(e) => setselectedfournisseur(e.target.value)}
                    >
                      <option value="">{t("Select Fournisseur")} </option>
                      {listFournisseurs.map((item: any) => (
                        <option key={item._id} value={item._id}>
                          {item.libelle}
                        </option>
                      ))}
                    </select>

                    <div>
                      <label htmlFor="Type_id">{t("Type de piece")}</label>
                    </div>
                    <select
                      className={`form-control mb-2 ${
                        errors.Type_id ? "is-invalid" : ""
                      }`}
                      disabled={etat}
                      {...register("Type_id")}
                    >
                      <option value="">{t("Select type de piece")} </option>
                      {listAchatType?.map((item: any, index: number) => (
                        <option key={index} value={item._id}>
                          {item.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-2 ml-2   mr-3 ">
                  <div className="form-group">
                    <label htmlFor="date_document">{t("date document")}</label>
                    <input
                      className={`form-control ${
                        errors.date_document ? "is-invalid" : ""
                      }`}
                      disabled={etat}
                      type="date"
                      id="date_document"
                      {...register("date_document")}
                    />

                    <label htmlFor="date_document">{t("date livraison")}</label>
                    <input
                      className={`form-control ${
                        errors.date_livraison ? "is-invalid" : ""
                      }`}
                      type="date"
                      id="date_livraison"
                      {...register("date_livraison")}
                      disabled={etat}
                    />

                    <label htmlFor="depot_id ">
                      <span style={{ color: "red" }}>*</span>
                      {t("Depots")}
                    </label>
                    <span
                      className="c-primary float-right"
                      onClick={() => setShowModalDepots(true)}
                    >
                      <i className="fas fa-plus-circle fa-lg"></i>
                    </span>

                    <select
                      className={`form-control mb-2 ${
                        errors.depots_id ? "is-invalid" : ""
                      }`}
                      disabled={etat}
                      {...register("depots_id")}
                      onChange={(e) => setselecteddepots(e.target.value)}
                    >
                      {listdepotsfilter.map((item: any, index: number) => (
                        <option key={index} value={item._id}>
                          {item.libelle}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-1 mr-3 ">
                  <div className="row">
                    <label htmlFor="RPied">{t("R.Pied")}</label>

                    <input
                      type="number"
                      className="form-control numberinput mb-3"
                      {...register("RPied")}
                      disabled={etat}
                      onChange={(e) => setRpied(parseFloat(e.target.value))}
                    />
                    <div>{errors.RPied?.message}</div>
                  </div>
                  <div className="row ">
                    <label htmlFor="REspece">{t("R.Espece")}</label>

                    <input
                      type="number"
                      className="form-control  numberinput mb-3"
                      {...register("REspece")}
                      disabled={etat}
                      onChange={(e) => setRespece(parseFloat(e.target.value))}
                    />
                    <div>{errors.REspece?.message}</div>
                  </div>
                  <div className="row ">
                    <label htmlFor="RFacture">{t("R.Facture")}</label>

                    <input
                      type="number"
                      className="form-control numberinput mb-3"
                      {...register("RFacture")}
                      disabled={etat}
                      onChange={(e) => setRfacture(parseFloat(e.target.value))}
                    />
                    <div>{errors.RFacture?.message}</div>
                  </div>
                </div>

                <div className="col-md-2 mt-3  mr-3 ">
                  <div className="form-group equal-height">
                    <label>
                      {t("Total HT : ")} <span>{totalHT}</span>
                    </label>

                    <label>
                      {t("Total TTC")} :<span>{totalTTC}</span>
                    </label>

                    <label>
                      {t("Total Net TTC")} : <span>{totalNetTTc}</span>
                    </label>

                    <label>
                      {t("timber")} : <span>{parametrage?.timber}</span>
                    </label>

                    <label>
                      {t("Total a payer")}: <span>{totalNetpayer}</span>
                    </label>
                    {!etat && (
                      <>
                        <label htmlFor="totalnetapayer">
                          {t("Totalnet a payer")}
                        </label>
                        <input
                          type="text"
                          className="form-control numberinput"
                      //  value={prixNet.toString()}
                          onChange={handleInputChange}

                          onKeyPress={handleKeyPress}

                        />
                      </>
                    )}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group ">
                    <div>
                      <label htmlFor="organisation_id ">
                        {t("Organistation")}
                      </label>
                    </div>
                    <select
                      className={`form-control mb-2 ${
                        errors.organisation_id ? "is-invalid" : ""
                      }`}
                      {...register("organisation_id")}
                      onChange={(e) => setselectedorganisation(e.target.value)}
                    >
                      <option value="">Select organisation </option>
                      {listdOrganisations?.map((item: any, index: number) => (
                        <option key={index} value={item._id}>
                          {item.nom}
                        </option>
                      ))}
                    </select>
                    <label htmlFor="commentaire">{t("commentaire")}</label>
                    <textarea
                      className="form-control mb-2"
                      {...register("commentaire")}
                    />
                    <div>{errors.commentaire?.message}</div>
                    <label htmlFor="file">{t("importer le ficher")}</label>
                    <input
                      type="file"
                      className="form-control mb-2"
                      {...register("file")}
                    />
                    <div>{errors.file?.message}</div>

                    <label htmlFor="file">
                      {t("scanner ficher")}{" "}
                      <i className="fas fa-file-import fa-lg"></i>
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div style={{ marginLeft: "35%" }} className=" mb-2">
              <div style={{ width: "40%" }}></div>
            </div>
            <div className="row">
              <AchatFormTable
                t={t}
                listdepots={listdepotsfilter}
                setShowModalProduct={setShowModalProduct}
                setInputs={setInputs}
                inputs={inputs}
                listProduits={listProduits}
                setselectproducts={setselectproducts}
                selectproducts={selectproducts}
                options={options}
                listtva={taxes}
                handleSelectChange={handleSelectChange}
                selecteddepots={selecteddepots}
                societe={societe}
              />
            </div>
          </Modal.Body>

          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t("Annuler")}
            </button>
            <button type="submit" className="btn btn-primary-app">
              {t("Enregistrer")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <FournisseurForm
        ShowModal={ShowModalFournisseur}
        setShowModal={setShowModalFournisseur}
        ProductCaracteristique={null}
        organisation_id={organisation_id}
      />
      <DepotsForm
        ShowModal={ShowModalDepots}
        setShowModal={setShowModalDepots}
        depots={null}
      />
      <ProductsForm
        t={t}
        ShowModal={ShowModalProduct}
        setShowModal={setShowModalProduct}
        Product={null}
        organisation_id={organisation_id || ""}
        societe={""}
        productList={[]}
      />
      <AchatConfirmModel
        t={t}
        ShowModal={ShowAchatConfirmModel}
        setShowModal={setShowAchatConfirmModel}
        totalttcInput={prixNet}
        totalttccalculer={totalNetpayer}
        data={data}
        etat={etat}
        onSubmit={onSubmit}
      />
    </div>
  );
}
