import { string, boolean, object, mixed } from "yup";

export const CarteSchema = object({
  libelle: object().shape({
    fr: string().required("Libellé (fr) obligatoire!!"),
    en: string().required("Libellé (en) obligatoire!!"),
    ar: string().required("Libellé (ar) obligatoire!!"),
  }),
  organisation_id : string(),
  image: mixed().optional().nullable(),
});
