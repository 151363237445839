import React, { Dispatch, SetStateAction, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { useAppDispatch } from '../../../_App/Redux/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { AppParametrageMenuSchema } from '../../../_App/Schemas/AppParametrages/AppParametrageMenuSchema';
import MenuFormDetails from './DetailsMenuForm/MenuFormDetails';
import _ from 'lodash';
import { addParametragesMenusApi, updateParametragesMenusApi } from '../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice';
import AppMenuParametrageType from '../../../_App/Types/Entites/AppMenus/AppMenuParametrageType';
import MenuFormItems from './DetailsMenuForm/MenuFormItems';
import { ToastSuccess } from '../../../Shared/Toasts/ToastSuccess';
import { ToastWarning } from '../../../Shared/Toasts/ToastWarning';
import AppMenuItemsType from '../../../_App/Types/Entites/AppMenus/AppMenuItems';

const ParametrageMenuForm = ({
    ShowModal, setShowModal, menu }:
    {
        ShowModal: boolean,
        setShowModal: Dispatch<SetStateAction<boolean>>
        menu: AppMenuParametrageType | null
    }
) => {

    const dispatch = useAppDispatch();
    const formOptions = { resolver: yupResolver(AppParametrageMenuSchema) };
    // get functions to build form with useForm() hook 
    const { register, handleSubmit, control, reset, watch, getValues, setValue, formState } = useForm<AppMenuParametrageType | any>(formOptions);
    const { errors } = formState;

    const resetForm = () => {
        reset({
            _id: "",
            libelle: "",
            menuPosition: { position: "left", bgColor: "#e0f0ef", colorButtons: "#009688", bgColorButtons: "#e0f0ef" },
            menuItems: []
        })
    }
    useEffect(() => {
        if (menu) {
            reset({
                _id: menu._id,
                libelle: menu.libelle,
                menuPosition: menu.menuPosition,
                menuItems: menu.menuItems
            })
        } else {
            resetForm();
        }
    }, [menu])


    const onSubmitDocument = async (data: AppMenuParametrageType) => {
        const menuItems = _.filter(data.menuItems, (item: AppMenuItemsType ) => item.actif == true)
        if (menuItems.length > 0) {
            if (data._id) {
                var response : any = await dispatch(updateParametragesMenusApi({ ...data, menuItems }))
            } else {
                var response : any = await dispatch(addParametragesMenusApi({ ...data, menuItems }))
            }

            if (response.payload.success) {
                ToastSuccess(response.payload.message)
                setShowModal(false)
                resetForm();
            }

        } else {
            ToastWarning('Selectionner au moins une boutton!!')
        }

    }


    return (
        <Modal show={ShowModal}
            onHide={() => setShowModal(false)}
            aria-labelledby="add_new_parametrage_menu"
            dialogClassName="modal-100w"
            className='modal-2'
            backdropClassName='modal-2'
        >
            <Modal.Header closeButton>
                <Modal.Title id="add_new_parametrage_menu" className="custom-modal-title">
                    <div className="titleWithButton">
                        <div> {
                            menu ? (<div>Modifier {menu.libelle}</div>) : (<div>Ajouter un nouveau Menu</div>)
                        }</div>
                        <div></div>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <form onSubmit={handleSubmit(onSubmitDocument)} >
                <Modal.Body>
                    <div className="row">

                        {/* menuform details : libelle, position , bgColorMenu, colorButtons, bgcolorbuttons */}
                        <MenuFormDetails register={register}
                            errors={errors}
                            menu={menu} watch={watch}
                            setValue={setValue} />

                        {/* List of buttons for manage bootons on menu */}
                        <MenuFormItems register={register} control={control}
                            watch={watch} setValue={setValue} getValues={getValues}
                            menu={menu} />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button type="reset" onClick={e => setShowModal(false)} className="btn btn-sm btn-secondary"> <i className="fas fa-arrow-left"></i> Annuler</button>
                    <button type="submit" className="btn btn-sm btn-primary"><i className="fas fa-save"></i> Enregistrer</button>
                </Modal.Footer>
            </form>
        </Modal>
    )
}

export default ParametrageMenuForm
