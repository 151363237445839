import { Action, ThunkAction, configureStore } from "@reduxjs/toolkit";
import souscategorieSlice from "./Slices/souscategories/souscategorieSlice";
import produitSlice from "./Slices/produits/produitSlice";
import userSlice from "./Slices/users/userSlice";
import authReducer from "./Slices/Auth/authSlice";
import userAddressMacSlice from "./Slices/userMacAdress/userAddressMacSlice";
import journalCaisseSlice from "./Slices/journalCaisse/journalCaisseSlice";
import sessionJournalSlice from "./Slices/sessionJournal/sessionJournalSlice";

import { combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import thunk from "redux-thunk";

import ingredientSlice from "./Slices/ingredients/ingredientSlice";
import categorieSlice from "./Slices/categories/categorieSlice";
import ProductCaracteristiqueSlice from "./Slices/product_caracteristique/product_caracteristiqueSlice";

import userRoleSlice from "./Slices/userRoles/userRoleSlice";

import affectationUserOrganisationSlice from "./Slices/affectationUserOrganisation/affectationUserOrganisationSlice";
import parametrageMenuSlice from "./Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import parametrageSlice from "./Slices/AppParametrages/parametrage/parametrageSlice";
import ProductCouleurSlice from "./Slices/product_couleur/product_couleurSlice";
import ProductTailleSlice from "./Slices/product_taille/product_tailleSlice";
import carteSlice from "./Slices/cartes/carteSlice";
import ProductMarqueSlice from "./Slices/product_marque/product_marque";
import ProductDetailSlice from "./Slices/product_detail/product_detailSlice";
import userAutorisationsSlice from "./Slices/userAutorisations/userAutorisationsSlice";
import ProductImageSlice from "./Slices/product_image/product_imageSlice";
import ProductTarifSlice from "./Slices/product_tarif/product_tarifSlice";
import fournisseurSlice from "./Slices/fournisseur/fournisseurSlice";
import fournisseurdetailsSlice from "./Slices/fournisseurdetails/fournisseurdetailsSlice";
import ProductRaccourciSlice from "./Slices/product_raccourci/ProductRaccourciSlice";
import AchatSlice from "./Slices/achat/achatSlice";
import organisationSlice from "./Slices/organisations/organisationSlice";
import depotSlice from "./Slices/depot/depotSlice";
import CaisseSlice from "./Slices/Caisse/CaisseSlice";
import OrderSlice from "./Slices/Orders/OrderSlice";
import PanierSlice from "./Slices/Caisse/PanierSlice";

import ArticleSlice from "./Slices/article/articleSlice";
import PaymentsSlice from "./Slices/payments/PaymentSlice";
import MouvementCaisseSlice from "./Slices/mouvementCaisse/MouvementCaisseSlice";
import ClientSlice from "./Slices/client/clientSlice";
import tableSlice from "./Slices/table/tableSlice";
import ZoneTableSlice from "./Slices/zoneTable/zoneTableSlice";
import taxSlice from "./Slices/tax/taxSlice";
import matriceSlice from "./Slices/matrice/matriceSlice";
import TicketsSlice from "./Slices/tickets/TicketSlice";
import AchatTypeSlice from "./Slices/achat/achattypeSlice";
import inventaireSlice from "./Slices/inventaire/inventaireSlice";
import soucheSlice from "./Slices/souche/soucheSlice";
import PaiementAchatSlice from "./Slices/achat/paiementachatsSlice";
import fournisseurmouvement from "./Slices/fournisseur/fournisseurmouvement";
import caisseParametrageSlice from "./Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import receptionArticlesSlice from "./Slices/receptionArticles/receptionArticlesSlice";
import commissionSlice from "./Slices/commission/commissionSlice";
import vendeurSlice from "./Slices/users/vendeurSlice";
import transferArticleSlice from "./Slices/transferArticle/transferArticleSlice";

import ReservationSlice from "./Slices/Reservation/ReservationSlice";
import reductionSlice from "./Slices/reduction/reductionSlice";
import remiseSlice from "./Slices/remise/remiseSlice";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth", "panier", "appParametragesMenus", "caisse", "orders"],
};

const rootReducer = combineReducers({
  // parametrage de l'application
  appParametragesMenus: parametrageMenuSlice,
  appParametrages: parametrageSlice,
  parametrageCaisse: caisseParametrageSlice,
  //Module users
  auth: authReducer,
  users: userSlice,
  vendeurs: vendeurSlice,
  roles: userRoleSlice,
  userAutorisation: userAutorisationsSlice,
  organisations: organisationSlice,
  depots: depotSlice,
  userAddressMacs: userAddressMacSlice,
  // Module produits
  produits: produitSlice,
  ingredient: ingredientSlice,
  souscategories: souscategorieSlice,
  categories: categorieSlice,
  productcaracteristiques: ProductCaracteristiqueSlice,
  productailles: ProductTailleSlice,
  productcouleurs: ProductCouleurSlice,
  cartes: carteSlice,
  productmarques: ProductMarqueSlice,
  productdetails: ProductDetailSlice,
  fournisseurs: fournisseurSlice,
  fournisseurmouevements: fournisseurmouvement,
  fournisseursdetails: fournisseurdetailsSlice,
  achats: AchatSlice,
  productimages: ProductImageSlice,
  productarifs: ProductTarifSlice,
  articles: ArticleSlice,
  matrices: matriceSlice,
  achattypes: AchatTypeSlice,
  taxs: taxSlice,
  souches: soucheSlice,
  paiementachats: PaiementAchatSlice,
  inventairs: inventaireSlice,
  receptions: receptionArticlesSlice,
  commissions: commissionSlice,
  transfertArticles: transferArticleSlice,
  // Module caisse
  caisse: CaisseSlice,
  panier: PanierSlice,
  mouvementsCaisse: MouvementCaisseSlice,
  tickets: TicketsSlice,
  reduction: reductionSlice,

  productRaccourci: ProductRaccourciSlice,
  orders: OrderSlice,
  payments: PaymentsSlice,

  reservation: ReservationSlice,

  zoneTables: ZoneTableSlice,

  clients: ClientSlice,
  journaux: journalCaisseSlice,
  sessionJournal: sessionJournalSlice,
  tables: tableSlice,
  affectationUserOrganisation: affectationUserOrganisationSlice,
  remise: remiseSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

// console.log('-------------> ', process.env.REACT_APP_NODE_ENV , ' <-------------')
const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_NODE_ENV === "developpement",
  middleware: [thunk],
  //middleware: [applyMiddleware(thunk), curryGetDefaultMiddleware()]
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
