import React, { useEffect, useState } from "react";
import Numpad from "../NumPad/NumPad";

const CashPaymentMethod = ({
  total,
  onAmountChange,
  enteredAmountCash,
}: any) => {
  const [givenAmountCash, setGivenAmountCash] = useState<string | null>(null);
  const [isInputFocused, setIsInputFocused] = useState(false);

  // const containsOnlyNumbers = (str: string) => !/[^0-9.]/.test(str) && /^\d+(\.\d+)?$/.test(str);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: any = e.target.value;
    setGivenAmountCash(isNaN(amount) ? null : amount);
    onAmountChange(isNaN(amount) ? null : parseFloat(amount));
  };

  useEffect(() => {
    setGivenAmountCash(null);
  }, [total]);

  const handleButtonClick = (value: any) => {
    if (givenAmountCash === null || givenAmountCash === "0") {
      if (value === "." && givenAmountCash === "0") {
        setGivenAmountCash(givenAmountCash + value.toString());
        onAmountChange(parseFloat(value));
      } else {
        setGivenAmountCash(value.toString());
        onAmountChange(parseFloat(value));
      }
    } else {
      if (value === "." && givenAmountCash.includes(".")) {
        // Do nothing if '.' is already present in the input
        return;
      }

      const newValue = givenAmountCash.toString() + value.toString();
      setGivenAmountCash(newValue);
      onAmountChange(parseFloat(newValue));
    }
  };

  const handleBackspaceClick = () => {
    if (givenAmountCash !== null) {
      if (String(givenAmountCash).length > 1) {
        let str = givenAmountCash.toString();
        setGivenAmountCash(str.slice(0, -1));
        onAmountChange(parseFloat(str.slice(0, -1)));
      } else if (String(givenAmountCash).length === 1) {
        setGivenAmountCash(null);
        onAmountChange(null);
      }
    } else {
      if (String(total).length > 1) {
        let str = total.toString();
        setGivenAmountCash(str.slice(0, -1));
        onAmountChange(parseFloat(str.slice(0, -1)));
      } else if (String(total).length === 1) {
        setGivenAmountCash(null);
        onAmountChange(null);
      }
    }
  };

  const hideNumpad = () => {
    setIsInputFocused(false);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget as HTMLElement).closest(".numpad-container")
    ) {
      return;
    }
    setIsInputFocused(false);
  };

  return (
    <div>
      <div className="navbar-item flex-row search-ul navbar-dropdown">
        <div className="nav-item align-self-center search-animated">
          <form
            className="form-inline search-full form-inline search"
            role="search"
          >
            <div className="search-bar" style={{ marginBottom: "7px" }}>
             
              <input
                type="text"
                className="form-control search-form-control ml-lg-auto"
                placeholder="Espèces"
                value={
                  enteredAmountCash
                    ? enteredAmountCash
                    : givenAmountCash !== null
                    ? givenAmountCash
                    : total?.toFixed(3)
                }
                onChange={handleAmountChange}
                onFocus={() => setIsInputFocused(true)}
                onBlur={handleBlur}
                readOnly
              />
            </div>
          </form>
        </div>
      </div>
      <div className={`numpad-container ${isInputFocused ? "show" : ""}`}>
        <Numpad
          onButtonClick={handleButtonClick}
          onBackspaceClick={handleBackspaceClick}
          onHideNumpad={hideNumpad}
        />
      </div>
    </div>
  );
};

export default CashPaymentMethod;
