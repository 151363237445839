import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import TransfertArticleType from "../../_App/Types/Entites/TransfertArticle/TransfertArticleType";
import {
  SelectedOrganisationId,
  SelectedOrganisations,
  selectUserId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  Listdemande_transfer_cree,
  confirmTransfertApi,
  getlesDemandeTransfertCreeApi,
  retourTransfertApi,
} from "../../_App/Redux/Slices/transferArticle/transferArticleSlice";
import { Modal } from "react-bootstrap";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";

export default function ListTransfertCree({ ShowModal, setShowModal }: any) {
  const { t } = useTranslation();
  const selectedOrganisationId = useAppSelector(SelectedOrganisationId);
  const list_organisation = useAppSelector(SelectedOrganisations);
  const [etat, setetat] = useState("apprové");
  const [list, setlist] = useState<TransfertArticleType[]>([]);
  const user = useAppSelector(selectUserId);
  const listdemande_transfer_cree = useAppSelector(Listdemande_transfer_cree);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedOrganisationId)
      dispatch(getlesDemandeTransfertCreeApi(selectedOrganisationId));
  }, [selectedOrganisationId]);
  useEffect(() => {
    if (etat === "apprové") {
      var newlist = listdemande_transfer_cree.filter(
        (item) =>
          !!item.accepter_transfer.etat &&
          !item.approver_reception.etat &&
          item.approver_reception.user_id === undefined
      );
      setlist(newlist);
    } else if (etat === "nonapprové") {
      var newlist = listdemande_transfer_cree.filter(
        (item) => !item.accepter_transfer.etat && item.accepter_transfer.user_id
      );
      setlist(newlist);
    } else if (etat === "encours") {
      var newlist = listdemande_transfer_cree.filter(
        (item) =>
          !item.accepter_transfer.etat &&
          item.accepter_transfer.user_id === undefined
      );

      setlist(newlist);
    } else if (etat === "confirmé") {
      var newlist = listdemande_transfer_cree.filter(
        (item) => !!item.approver_reception.etat
      );

      setlist(newlist);
    } else {
      var newlist = listdemande_transfer_cree.filter(
        (item) =>
          !item.approver_reception.etat &&
          item.approver_reception.user_id !== undefined
      );

      setlist(newlist);
    }
  }, [listdemande_transfer_cree, etat]);
  const confirmer = async (idTransfert: string) => {
    const data = { _id: idTransfert, user: user };
    const response = await dispatch(confirmTransfertApi(data));
    if (response?.payload.success) {
      ToastSuccess(response?.payload.message);
      // setShowModal(false);
    }
  };
  const retour = async (idTransfert: string) => {
    const data = { _id: idTransfert, user: user };
    const response = await dispatch(retourTransfertApi(data));
    if (response?.payload.success) {
      ToastSuccess(response?.payload.message);
      setShowModal(false);
    }
  };
  return (
    <div>
      {/*<Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-2"
        dialogClassName="modal-40w"
        backdropClassName="modal-backdrop-2"
      >*/}

      <div className="row layout-spacing">
        <div className="col-lg-12    bg-white">
          <div className="inv--product-table-section ">
            <div className="row mt-5">
              <div className="col-6">
                <h3 className="c-primary">
                  {" "}
                  {t("List les demandes  de Transfert cree")}{" "}
                </h3>
              </div>
              <div className="col-1 ">
                <span>Etat :</span>
              </div>
              <div className=" col-4 ">
                <select
                  className="form-control"
                  onChange={(e) => setetat(e.target.value)}
                >
                  {" "}
                  <option value="apprové">{t("approuvé")}</option>
                  <option value="confirmé"> {t("réception confirmé")}</option>
                  <option value="annulé"> {t("réception annulé")}</option>
                  <option value="encours">{t("encours")}</option>
                  <option value="nonapprové">{t("non approuvé")}</option>
                </select>
              </div>
            </div>
            <div className="table-responsive">
              <table className="table  mt-5 ">
                <thead>
                  <tr></tr>
                  <tr>
                    <th> {t("Article ")}</th>
                    <th>{t("Depots")}</th>
                    <th>{t("Quantite")}</th>
                    <th>{t(" Organisation")}</th>
                    {etat === "apprové" && <th colSpan={2}></th>}
                  </tr>
                </thead>
                <tbody>
                  {list &&
                    list.map((item: any) => (
                      <tr key={item._id}>
                        <td>{item.article_id.titre?.fr}</td>

                        <td>{item.depots_id.libelle}</td>
                        <td>{item.Quantite}</td>
                        <td>{item.organisation_transfer.nom}</td>
                        {etat === "apprové" && (
                          <>
                            <td>
                              <span
                                className=" btn-primary-app  pointer"
                                onClick={() => confirmer(item._id)}
                              >
                                <i className="fas fa-check-double "></i>
                                {t("confirmer reception")}
                              </span>
                            </td>
                            <td>
                              <span
                                className=" btn-primary-app  pointer"
                                onClick={() => retour(item._id)}
                              >
                                <i className="fas fa-undo"></i> {t("retour")}
                              </span>
                            </td>
                          </>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
