import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../_App/Redux/hooks';
import { ListFournisseursMouvement, findFournisseurMouvementApi } from '../../../_App/Redux/Slices/fournisseur/fournisseurmouvement';
import { Modal } from 'react-bootstrap';
import { formatdate } from '../../../_App/Helpers/helpers';
import PaiementAchat from '../../Achat/PaiementAchat';

export default function FournisseurMouvement({t,societe,idOrganisation ,ShowModal,setShowModal,fournisseur}:any) {
    const dispatch = useAppDispatch(); 
    const listFournisseursMouvement = useAppSelector(ListFournisseursMouvement)
    const [ModalshowPaiement, setshowModalPaiement] = useState(false);
  

    useEffect(() => {
        if(fournisseur)
        dispatch(findFournisseurMouvementApi(fournisseur._id));
      }, [fournisseur]);
  return (
    <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-2"
        dialogClassName="modal-60w"
        backdropClassName="modal-backdrop-2"
      >
        <Modal.Header closeButton>
          <Modal.Title className="c-primary">{t('list Mouevement fournisseur')} {fournisseur?.libelle}</Modal.Title>
        </Modal.Header>

          <Modal.Body>
          <div className="">
    <div className="row mb-3 ml-4"> 
            
              <div className="col-4"> 
                 <strong> {t('Total debit')} {' '}</strong> {fournisseur?.Montant_total?(fournisseur?.Montant_total).toFixed(3):0}
              </div>
              <div className="col-4"> 
              <strong> {t('Total crédit')} {' '}</strong>{fournisseur?.total_paye?fournisseur?.total_paye.toFixed(3):0}
              </div>
              <div className="col-4"> 
              <strong> {t('Solde')} {' '}</strong> {fournisseur?.Montant_total? (fournisseur.Montant_total-fournisseur.total_paye).toFixed(3) :0 }
              </div>
             
          
          </div>
   <div className="table-responsive">
     <table
       id="html5-extension"
       className="table table-hover non-hover dataTable no-footer"
       role="grid"
       aria-describedby="html5-extension_info"
     >
       <thead>
         <tr>
           <th >{t('date')}</th>
           <th>{t('docuement')}</th>
           <th>{t('Type operation')}</th>
           <th>{t('Montant')} </th>
           <th>{t('sens')}</th>
           <th>{t('type paiement')}</th>

         </tr>
       </thead>
       <tbody>
         {listFournisseursMouvement.length !== 0 &&
           listFournisseursMouvement.map((item: any, index: number) => (
             <tr key={item._id}>
                <td>{formatdate(item.createdAt)}</td>
               <td>{item.document}</td>
               <td>{item.type_operation}</td>
               <td>{item.sens === "debit" ? "-" : null }{item.montant}</td>
               <td>{item.sens}</td>
               <td>{item.type}{item.dateCheque}</td>

              

             </tr>
           ))}
       </tbody>
     </table>
   </div>
        <PaiementAchat
            t={t}
            debittotal={fournisseur?.Montant_total? (fournisseur.Montant_total).toFixed(3) :0 }
            ShowModal={ModalshowPaiement}
            setShowModal={setshowModalPaiement}
            Fournisseur={fournisseur}
            societe={societe}
            organisation={idOrganisation}
            totalcredit={fournisseur?.total_paye? (fournisseur.total_paye).toFixed(3) :0 }
          />
 </div>
          </Modal.Body>

          <Modal.Footer>
           
      {/**  <button
               className="btn btn-primary-app"
              onClick={() => {
                setshowModalPaiement(true);
              }}
            >
              {t('Ajouter Mouvement fournisser')}
            </button>*/}   
          </Modal.Footer>
      </Modal>
  )
}
