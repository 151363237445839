import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../_App/Redux/hooks";
import { SelectedOrganisations } from "../../_App/Redux/Slices/Auth/authSlice";
import { Spinner } from "react-bootstrap";

export default function StockTableArticle({
  t,
  listDepots,
  stock,
  stock_Organisation,
  index,
  setindex,
  liststock,
  handleSearch,
  setkeyword,
  spinner,
}: any) {
  const list_organisation = useAppSelector(SelectedOrganisations);
  const [list_article, setlist_article] = useState<any[]>([]);
  const [Filtredlist_article, setFiltredlist_article] = useState<any[]>([]);
  const data: any[] = [
    {
      article: {
        _id: "65e9f28b96c82267cd12f421",
        code_article: "81325-WOM-TU-TAUPE",
        code_ERP: null,
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe_id: "65798234aaa338e177c82e06",
        produit_ref: "65e9f28b96c82267cd12f41e",
        type: "import",
        image: [],
        marque: null,
        categorie: null,
        souscategorie: "65e9f28b96c82267cd12f41b",
        carte: null,
        remise: 0,
        code_a_barre: ["026581", "P_000000073224"],
        titre: {
          fr: "81325 WOMEN TAILLE TU TAUPE",
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
        caracteristiques: [],
        prix_vente_ttc: 32.922,
        prix_vente_ht: 0,
        actif: true,
        createdAt: "2024-03-07T16:59:55.494Z",
        updatedAt: "2024-03-07T16:59:55.494Z",
        __v: 0,
      },
      stock: {
        "654ba6e0c5e7c8412f202736": [
          {
            _id: "65e9f28b96c82267cd12f424",
            organisation_id: "654ba6e0c5e7c8412f202736",
            societe_id: "65798234aaa338e177c82e06",
            depots_id: {
              _id: "65c2021ae22615a125393e01",
              organisation_id: "654ba6e0c5e7c8412f202736",
              societe: "65798234aaa338e177c82e06",
              defaut: false,
              libelle: "dipotboutique3",
              createdAt: "2024-02-05T11:27:45.775Z",
              updatedAt: "2024-02-07T08:10:15.453Z",
              __v: 0,
            },
            article_id: "65e9f28b96c82267cd12f421",
            stock: 10,
            createdAt: "2024-03-07T16:59:55.582Z",
            updatedAt: "2024-03-07T16:59:55.582Z",
            __v: 0,
          },
        ],
      },
    },

    {
      article: {
        _id: "65e9f28f96c82267cd12f494",
        code_article: "8235-WOM-TU-MULTI.CL",
        code_ERP: null,
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe_id: "65798234aaa338e177c82e06",
        produit_ref: "65e9f28f96c82267cd12f491",
        type: "import",
        image: [],
        marque: null,
        categorie: null,
        souscategorie: "65de27e94498f1742a52f631",
        carte: null,
        remise: 0,
        code_a_barre: ["026596", "P_000000073233"],
        titre: {
          fr: "8235 WOMEN TAILLE TU MULTI.CL",
          en: "",
          ar: "",
        },
        description: {
          en: "",
          ar: "",
        },
        caracteristiques: [],
        prix_vente_ttc: 41.182,
        prix_vente_ht: 0,
        actif: true,
        createdAt: "2024-03-07T16:59:59.879Z",
        updatedAt: "2024-03-07T16:59:59.879Z",
        __v: 0,
      },
      stock: {
        "654ba6e0c5e7c8412f202736": [
          {
            _id: "65e9f28f96c82267cd12f497",
            organisation_id: "654ba6e0c5e7c8412f202736",
            societe_id: "65798234aaa338e177c82e06",
            depots_id: {
              _id: "65c0c631fd97a56df5e9300f",
              organisation_id: "654ba6e0c5e7c8412f202736",
              societe: "65798234aaa338e177c82e06",
              defaut: false,
              libelle: "dipotboutique3",
              createdAt: "2024-02-05T11:27:45.775Z",
              updatedAt: "2024-02-07T08:10:15.453Z",
              __v: 0,
            },
            article_id: "65e9f28f96c82267cd12f494",
            stock: 100,
            createdAt: "2024-03-07T16:59:59.971Z",
            updatedAt: "2024-03-07T16:59:59.971Z",
            __v: 0,
          },
        ],
      },
    },
  ];
  interface Depot {
    [key: string]: any[];
  }

  const depot: Depot = {
    null: [
      {
        _id: "65b3d962e3f9fa0eadaab9e5",
        organisation_id: null,
        societe: "65798234aaa338e177c82e06",
        defaut: true,
        libelle: "depots_defaut_societe",
        createdAt: "2024-01-26T16:10:10.197Z",
        updatedAt: "2024-01-26T16:10:10.197Z",
        __v: 0,
      },
      {
        _id: "65d4abf8e9ae833298bea433",
        organisation_id: null,
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "Ddepot_1",
        createdAt: "2024-02-20T13:41:12.858Z",
        updatedAt: "2024-02-20T13:41:12.858Z",
        __v: 0,
      },
    ],
    "654ba6e0c5e7c8412f202736": [
      {
        _id: "65c0c631fd97a56df5e9300f",
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "dipotboutique3",
        createdAt: "2024-02-05T11:27:45.775Z",
        updatedAt: "2024-02-07T08:10:15.453Z",
        __v: 0,
      },
      {
        _id: "65c2021ae22615a125393e01",
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "depot_1",
        createdAt: "2024-02-06T09:55:38.391Z",
        updatedAt: "2024-02-06T11:00:12.882Z",
        __v: 0,
      },
      {
        _id: "65c20237e22615a125393e3c",
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "depot_defaut_new",
        createdAt: "2024-02-06T09:56:07.217Z",
        updatedAt: "2024-02-06T10:49:09.001Z",
        __v: 0,
      },
      {
        _id: "65c33ae77610b92946c23515",
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe: "65798234aaa338e177c82e06",
        defaut: true,
        libelle: "DDefaut",
        createdAt: "2024-02-07T08:10:15.508Z",
        updatedAt: "2024-02-07T08:10:15.508Z",
        __v: 0,
      },
      {
        _id: "65cf92b1e3494c68051c22b3",
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "Ddefaut",
        createdAt: "2024-02-16T16:52:01.214Z",
        updatedAt: "2024-02-16T16:52:01.214Z",
        __v: 0,
      },
      {
        _id: "65cf9522fca650732029b062",
        organisation_id: "654ba6e0c5e7c8412f202736",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "Ddepot_1",
        createdAt: "2024-02-16T17:02:26.381Z",
        updatedAt: "2024-02-16T17:02:26.381Z",
        __v: 0,
      },
    ],
    "65889d8cec6affa2cb0a130a": [
      {
        _id: "65c1069b7558d9866709d872",
        organisation_id: "65889d8cec6affa2cb0a130a",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "cafedepots",
        createdAt: "2024-02-05T16:02:35.359Z",
        updatedAt: "2024-02-06T10:55:52.769Z",
        __v: 0,
      },
      {
        _id: "65c33bc761fcb7147c09be9f",
        organisation_id: "65889d8cec6affa2cb0a130a",
        societe: "65798234aaa338e177c82e06",
        defaut: true,
        libelle: "defaut",
        createdAt: "2024-02-07T08:13:59.982Z",
        updatedAt: "2024-02-07T08:13:59.982Z",
        __v: 0,
      },
      {
        _id: "65d4abf7e9ae833298bea41e",
        organisation_id: "65889d8cec6affa2cb0a130a",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "Ddefaut_café",
        createdAt: "2024-02-20T13:41:11.193Z",
        updatedAt: "2024-02-20T13:41:11.193Z",
        __v: 0,
      },
      {
        _id: "65d8953d8318289e2f1888a3",
        organisation_id: "65889d8cec6affa2cb0a130a",
        societe: "65798234aaa338e177c82e06",
        defaut: false,
        libelle: "Ddefaut",
        createdAt: "2024-02-23T12:53:17.994Z",
        updatedAt: "2024-02-23T12:53:17.994Z",
        __v: 0,
      },
    ],
  };

  const Keys = Object.keys(stock_Organisation);

  useEffect(() => {
    if (stock) {
      let nouveauTableau = stock.map((element: any) => element["article_id"]);
      function removeDuplicates(objects: any[]) {
        let seen = new Set();
        return objects.filter((obj) => {
          if (seen.has(obj?._id)) {
            return false;
          }
          seen.add(obj?._id);
          return true;
        });
      }

      // Appeler la fonction pour supprimer les doublons
      if (nouveauTableau.length !== 0) {
        let objectsWithoutDuplicates = removeDuplicates(nouveauTableau);
        console.log(objectsWithoutDuplicates, "objectsWithoutDuplicates");
        console.log(objectsWithoutDuplicates, "objectsWithoutDuplicates");

        setFiltredlist_article(objectsWithoutDuplicates);
        setlist_article(objectsWithoutDuplicates);
      }
    }
  }, [stock]);

  return (
    <div>
      <div className="row layout-spacing">
        <div className="col-lg-12   bg-white">
          <div className="col-md-6 mt-3 mb-2">
            <div className="wrapper">
              {!spinner ? (
                <div className="icon-input-col6 " onClick={handleSearch}>
                  {" "}
                  <i className="fas fa-search"></i>
                </div>
              ) : (
                <div className="icon-input-col6 " onClick={handleSearch}>
                  {" "}
                  <Spinner animation="border" />
                </div>
              )}
              <input
                type="text"
                className="w-100 form-control product-search br-30"
                id="input-search"
                placeholder={t("chercher par mot cle")}
                onChange={(e) => setkeyword(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="row layout-spacing">
        <div className="col-lg-12   bg-white  ">
          <div className="table-responsive">
            <table className="table">
              <thead>
                <th></th>
                <th style={{
                          borderRight: "1px solid black",
                        }}></th>
                
                {list_organisation &&
                  list_organisation?.map((item, index) => (
                    <>
                      <th
                        className="text-center "
                        colSpan={depot[item._id]?.length}
                      >
                        {
                          list_organisation?.find((org) => org._id === item._id)
                            ?.nom
                        }
                      </th>

                      <th
                        style={{
                          borderRight: "1px solid black",
                        }}
                      ></th>
                    </>
                  ))}
                <th>{t("societe")}</th>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>{t("Article")}</strong>
                  </td>

                  <td style={{
                          borderRight: "1px solid black",
                        }}>
                    <strong>{t("Prix")}</strong>
                  </td>
                
                  {list_organisation?.map((item, index) => (
                    <>
                      {depot[item._id] ? (
                        depot[item._id]?.map((dep) => (
                          <td key={dep._id}>{dep.libelle}</td>
                        ))
                      ) : (
                        <td></td>
                      )}
                      <td
                        style={{
                          borderRight: "1px solid black",
                        }}
                      ></td>
                    </>
                  ))}
                </tr>

                {stock.map((itemdata: any, dataIndex: any) => (
                  <tr key={dataIndex}>
                    <td>{itemdata.article.titre.fr}</td>
                    <td style={{
                          borderRight: "1px solid black",
                        }}>{itemdata.article.prix_vente_ttc}</td>
                    {list_organisation?.map((itemdepo, index) => (
                      <React.Fragment key={index}>
                        {depot[itemdepo._id] ? (
                          depot[itemdepo._id]?.map((dep, index) => (
                            <React.Fragment key={index}>
                              <td>
                                {itemdata.stock[itemdepo._id] !== undefined
                                  ? itemdata.stock[itemdepo._id].find(
                                      (e: any) => e.depots_id._id === dep._id
                                    ) !== undefined
                                    ? itemdata.stock[itemdepo._id].find(
                                        (e: any) => e.depots_id._id === dep._id
                                      ).stock
                                    : "0"
                                  : "0"}
                              </td>
                            </React.Fragment>
                          ))
                        ) : (
                          <td></td>
                        )}
                        <td
                          style={{
                            borderRight: "1px solid black",
                          }}
                        ></td>
                      </React.Fragment>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="pagination-no_spacing">
          <ul className="pagination">
            {index !== 60600 && (
              <li>
                <a onClick={() => setindex(index - 10)} className="prev">
                  {" "}
                  <i className="fas fa-chevron-left"></i>{" "}
                </a>{" "}
              </li>
            )}

            <li>
              <a onClick={() => setindex(index + 10)} className="next">
                <i className="fas fa-chevron-right"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
/*   <td key={key}> {/* Ensure each cell has a unique key */
//{stock.find((st: any) => (
//  st.depots_id._id === key && // Check if depots_id matches the current key
//st.article_id !== null && // Ensure article_id is not null
// st.article_id._id === item.article_id._id// Check if article_id matches the specific ID
//))?.stock || key}
//</td>*/
/*          {lists.map((item , indexl)=> (
    <tr key={indexl}> 
    <td>{item.article._id}</td>
    {list_depots.map((element:any , index:number) => (
        <td key={index}>{item[element]}</td>
    ))}
</tr>
))}*/
