import { Dispatch, SetStateAction } from "react";
import styled from "styled-components";
import {
  activateParametrageMenuApi,
  deleteParametrageMenuApi,
} from "../../../_App/Redux/Slices/AppParametrages/parametrageMenus/parametrageMenuSlice";
import { useAppDispatch } from "../../../_App/Redux/hooks";
import AppMenuParametrageType from "../../../_App/Types/Entites/AppMenus/AppMenuParametrageType";
import ParametrageMenuButtons from "./ParametrageMenuButtons";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import Swal from "sweetalert2";

const StyledCard = styled.div<{ $menu: AppMenuParametrageType }>`
  .component-card_1 {
    width: 100%;
  }
  .active-menu {
    border: 1px solid green;
  }
  .icone-position {
    margin-left: 5px;
    margin-right: 5px;
    font-weight: bold;
  }
  .flex-arround {
    display: flex;
    justify-content: center;
    align-items: center;
    & > div {
      margin-right: 15px;
      align-items: center;
    }
  }
  .manage-btns {
    width: 102px !important;
  }
  .center-vertical {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sidebar-wrapper {
    position: relative !important;
  }

  .card-body {
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }
`;
const ParametrageMenuItem = ({
  menu,
  ShowModalMenuForm,
  setShowModalMenuForm,
  ShowModalTypeAffectation,
  setShowModalTypeAffectation,
  setMenu,
}: {
  menu: AppMenuParametrageType;
  ShowModalMenuForm: boolean;
  setShowModalMenuForm: Dispatch<SetStateAction<boolean>>;
  ShowModalTypeAffectation: boolean;
  setShowModalTypeAffectation: Dispatch<SetStateAction<boolean>>;
  
  setMenu: Dispatch<SetStateAction<AppMenuParametrageType | null>>;
}) => {
  const dispatch = useAppDispatch();

  const onDelete = async (menu: AppMenuParametrageType) => {
    Swal.fire({
      title: "Voulez vous vraiment supprimer ce menu?",
      text: menu.libelle,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed && menu._id) {
        const response = await dispatch(deleteParametrageMenuApi(menu._id));
        if (response.payload.success) {
          ToastSuccess(response.payload.message);
        } else {
          ToastWarning(response.payload.message);
        }
      }
    });
  };

  return (
    <StyledCard $menu={menu}>
      <div
        key={`card-${menu._id}`}
        className={`card component-card_1 relative ${
          menu.actif ? "active-menu" : ""
        }`}
      >
        <div className="card-body">
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-3">
              <h6 className="text-center card-title">{menu.libelle}</h6>
              <div className="flex-arround">
                <div className="text-center">Position </div>
                <div className="float-center">
                  <i
                    className={`float-center c-primary icone-position fas fa-arrow-${menu.menuPosition?.position}`}
                  ></i>
                  {/* {menu.menuPosition?.position == "left" ? "A gauche" : ""}
                  {menu.menuPosition?.position == "right" ? "A droite" : ""}
                  {menu.menuPosition?.position == "down" ? "En bas" : ""}
                  {menu.menuPosition?.position == "up" ? "En_Haut" : ""} */}
                </div>
              </div>
            </div>

            {/* <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4">
             
            </div> */}

            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-2 ">
                {/* activate button */}
              <button
                className={`btn btn-block manage-btns ${
                  menu.actif ? "btn-primary-app" : "btn-danger-app"
                }`}
                disabled={!menu.actif ? false : true}
                key={`btn-actif-${menu._id}`}
                onClick={(e) =>
                  menu._id
                    ? dispatch(activateParametrageMenuApi(menu._id))
                    : null
                }
              >
                <i
                  key={`btn-icon-${menu._id}`}
                  className={`far ${
                    menu.actif ? "fa-check-circle" : "fa-times-circle"
                  }`}
                ></i>
                <div key={`btn-libelle-${menu._id}`}>
                  {menu.actif ? "Actif" : "Inactif"}
                </div>
              </button>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-lg-2 ">
              <button className="btn btn-warning-app btn-block manage-btns"
              
              onClick={ e => {
                setMenu(menu);
                setShowModalTypeAffectation(true)} 
              }>
                <i className="fas fa-users-cog"></i>
                <div >Affecter</div>
              </button>
            </div>

            {menu.user_id ? (
              <>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2 ">
                  <button
                    className="btn btn-info-app btn-block manage-btns"
                    onClick={(e) => {
                      setMenu(menu);
                      setShowModalMenuForm(true);
                    }}
                  >
                    <i className="fas fa-pencil-alt"></i>
                    <div>Modifier</div>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-3 col-lg-2 ">
                  {!menu.actif ? (
                    <button
                      className="btn btn-danger-app btn-block manage-btns"
                      onClick={(e) => {
                        onDelete(menu);
                      }}
                    >
                      <i className="far fa-trash-alt"></i>
                      <div>Supprimer</div>
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 text-center">
                  <div className="flex-arround center-vertical c-primary">
                    <div>Menu par défaut</div>
                  </div>
                </div>
              </>
            )}

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 menu-items mt-4">
              <ParametrageMenuButtons menu={menu} />
            </div>
          </div>
        </div>
      </div>
    </StyledCard>
  );
};

export default ParametrageMenuItem;
