import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../../_App/Redux/hooks";
import {
  ListProductCaracteristiques,
  ListProductCaracteristiquesParOrganisation,
} from "../../../../_App/Redux/Slices/product_caracteristique/product_caracteristiqueSlice";
import { useFieldArray } from "react-hook-form";
import { ProductCaracteristiqueType } from "../../../../_App/Types/Entites/ProductCaracteristiqueType";
import { societeId } from "../../../../_App/Redux/Slices/Auth/authSlice";
import { Style } from "escpos-buffer";
export default function ProductFormCaracteristiquesItem({
  t,
  setShowModalProductCaracteristique,
  control,
  register,
  watch,
  getValues,
  setValue,
  selectedOrganisation,
  organisation,
  article,
}: any) {
  const [selectedColor, setSelectedColor] = useState(""); // State to store the selected color

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "caracteristiques",
    }
  );
  const [list_PCaracteristiques, setlist_PCaracteristiques] = useState<
    ProductCaracteristiqueType[]
  >([]);

  const watch_caracteristique = watch("caracteristiques");
  const listProductCaracteristiques = useAppSelector(
    ListProductCaracteristiques
  );
  const listProductCaracteristiquesParOrganisation = useAppSelector(
    ListProductCaracteristiquesParOrganisation
  );

  useEffect(() => {
    if (organisation === null || organisation === "null") {
      setlist_PCaracteristiques(listProductCaracteristiques);
    } else if (organisation && organisation !== "null") {
      const list = listProductCaracteristiquesParOrganisation[organisation];
      list ? setlist_PCaracteristiques(list) : setlist_PCaracteristiques([]);

      setlist_PCaracteristiques(
        listProductCaracteristiquesParOrganisation[organisation]
      );
    }
  }, [selectedOrganisation, organisation, watch_caracteristique]);

  useEffect(() => {
    const listcarcteristiques: {
      color: boolean;
      libelle: string;
      caracteristique_id: String;
      value: number;
      valeurs: any[];
    }[] = [];
    if (list_PCaracteristiques && list_PCaracteristiques.length !== 0) {
      list_PCaracteristiques.forEach((element) => {
        const foundcaracteristique = watch_caracteristique?.find(
          (item: any) => item.caracteristique_id === element._id
        );
        const itemcaracteristique = {
          color: element.color,
          libelle: element.libelle,
          caracteristique_id: element._id,
          valeurs: element.valeurs,
          value: foundcaracteristique ? foundcaracteristique.value : 0,
        };
        listcarcteristiques.push(itemcaracteristique);
      });

      /*if (watch_caracteristique && watch_caracteristique.length !== 0) {
        const filterlistcarcteristiques = listcarcteristiques.filter(
          (item) =>
            !watch_caracteristique.some(
              (watchItem: any) => watchItem.caracteristique_id === item.caracteristique_id
            )
        );
  
      
      } else {
        alert('else')*/

      setValue("caracteristiques", listcarcteristiques);
      //}
    }
  }, [list_PCaracteristiques]);

  const handleRemoveClick = (index: number) => {
    setValue(`caracteristiques[${index}].value`, 0);
  };

  return (
    <div>
      {!article || article?.type === "import" ? (
        <>
          <label htmlFor="caracteristique">{t("Caracteristique")}</label>
          <span
            className="c-primary float-right"
            onClick={() => setShowModalProductCaracteristique(true)}
          >
            <i className="fas fa-plus-circle fa-lg"></i>
          </span>
          {watch_caracteristique &&
            watch_caracteristique.map((item: any, index: number) => (
              <div className="row mr-1" key={index}>
                <div className="col-md-4">
                  <label htmlFor="caracteristique">{item.libelle}</label>
                </div>
                <div className="col-md-8">
                  {!item.color ? (
                    <>
                    <p>    {getValues(`caracteristiques[${index}].value`)}</p>
                      <select
                        className="form-control mt-2"

                        {...register(`caracteristiques[${index}].value`)}
                      >
                        <option value={''}>
                        <span
                        onClick={() => handleRemoveClick(index)}
                        className="color_red  pointer"
                      >
                        {t('désélectionner')}
                      </span>
                      
                      </option>
                        {item?.valeurs?.map((carac: any) => (
                          <option key={carac} value={carac}>
                            {carac}
                          </option>
                        ))}
                      </select>
                    </>
                  ) : (
                    <>
                    <p>    {getValues(`caracteristiques[${index}].value`)}</p>


                      <select
                        className="form-control mt-2"
                        style={{backgroundColor:getValues(`caracteristiques[${index}].value`)}}
                        onChange={(e) => {
                          const selectedValue = e.target.value;
                          // Perform actions based on the selected value
                          // For example, you can set the background color here
                          e.target.style.backgroundColor =selectedValue
                       
                        }}
                        {...register(`caracteristiques[${index}].value`)}

                      >
                        <option
                          value={''}
                          style={{ backgroundColor: "white" }}
                        > <span
                        onClick={() => {
                          handleRemoveClick(index);
                        
                        }}
                        className="color_red  pointer"
                      >
                        {t('désélectionner')}
                      </span>
                        </option>

                        {item?.valeurs?.map((carac: any) => (
                          <option
                            key={carac}
                            value={carac}
                            style={{ backgroundColor: carac }}
                          >{carac}</option>
                        ))}
                      </select>
                    </>
                  )}
                </div>
              </div>
            ))}
        </>
      ) : (
        article?.type === "fils" &&
        article?.caracteristiques?.length !== 0 && (
          <div>
            <label htmlFor="caracteristique">{t("Caracteristique")}</label>
            {article.caracteristiques?.map((item: any, index: number) => {
              const characteristic = listProductCaracteristiques.find(
                (e) => e._id === item.caracteristique_id
              );
              if (characteristic) {
                return (
                  <div key={index}>
                    <p>
                      {characteristic.libelle || item.caracteristique_id} :{" "}
                      {characteristic.color ? (
                        <input
                          type="color"
                          value={item.value}
                          disabled
                          readOnly
                        />
                      ) : (
                        item.value
                      )}
                    </p>
                  </div>
                );
              } else {
                return (
                  <p key={index}>
                    {item.caracteristique_id} : {item.value}
                  </p>
                );
              }
            })}
          </div>
        )
      )}
    </div>
  );
}
