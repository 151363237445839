import React, { useState } from "react";
import ProductTarifsForm from "../../../Components/Products/ProductTarifs/ProductTarifsForm";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../../_App/Redux/hooks";
export default function () {
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [ShowModalProducTarif, setShowModalProducTarif] = useState(true);

  return (
    <div>
      <ProductTarifsForm
        ShowModal={ShowModalProducTarif}
        setShowModal={setShowModalProducTarif}
        ProductTarif={null}
        Product_id={null}
        organisation_id={currentOrganisation}
      />
    </div>
  );
}
