import React, { useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { ZoneTableSchema } from "../../_App/Schemas/ZoneTableSchema";
import ZoneTableType from "../../_App/Types/Entites/ZoneTableType";
import {
  createZoneTableApi,
  updateZoneTableApi,
} from "../../_App/Redux/Slices/zoneTable/zoneTableSlice";
import { useTranslation } from "react-i18next";



const ZoneTableForm= ({
  
  organisationId,
  verifcationMsg
}:any) => {
  const dispatch = useAppDispatch();
  const {t} =useTranslation();

  const formOptions = {
    resolver: yupResolver(ZoneTableSchema) as Resolver<ZoneTableType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ZoneTableType>(formOptions);
  const { errors } = formState;

 useEffect(() => {
  
      reset({
        id_organisation: organisationId || "", 
        tables: [],
      });
  
  }, [organisationId]);

  const onSubmit = async (data: ZoneTableType) => {
    let response;
    if (data._id) {
      response = await dispatch(updateZoneTableApi(data));
    } else {
      //if(await verifcationMsg(t('vous voulez ajouter une nouvelle zone de table ?')))
      response = await dispatch(createZoneTableApi(data));
    }
    if(response){
      if (t(response.payload.success)) {
        ToastSuccess(response.payload.message);
        reset();
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
   
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
    <div className="row">
      <div className="col-5">
        <div>
          <label htmlFor="libelle">{t('Libellé de la zone:')}</label>
          <input
            type="text"
            className={`form-control ${errors.libelle ? 'is-invalid' : ''}`}
            id="libelle"
            {...register('libelle')}
          />
          {errors.libelle && (
            <div className="invalid-feedback">{errors.libelle.message}</div>
          )}
        </div>
      </div>
      <div className="col-3 mt-4">
        <button type="submit" className="btn btn-primary-app">
          {t('Ajouter zone')}
        </button>
      </div>
    </div>
  </form>
);
};

export default ZoneTableForm;
