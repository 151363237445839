import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Resolver, useForm } from "react-hook-form";
import { ProductMarqueSchema } from "../../../_App/Schemas/ProductMarqueSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  createProductMarqueApi,
  updateProductMarqueApi,
} from "../../../_App/Redux/Slices/product_marque/product_marque";
import { ToastSuccess } from "../../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import { ProductMarqueType } from "../../../_App/Types/Entites/ProductMarqueType";
export default function ProductMarquesForm({
  ShowModal,
  setShowModal,
  ProductMarque,
  organisation_id,
  societe,
}: any) {
  const dispatch = useAppDispatch();
  const [selectedOrganisation, setselectedOrganisation] = useState(true);

  // init form hooks
  const formOptions = {
    resolver: yupResolver(
      ProductMarqueSchema
    ) as unknown as Resolver<ProductMarqueType>,
  };
  const { register, handleSubmit, reset, formState } =
    useForm<ProductMarqueType>(formOptions);
  const { errors } = formState;

  useEffect(() => {
    if (ProductMarque) {
      reset({
        societe_id: ProductMarque.societe_id,
        _id: ProductMarque._id,
        organisation_id: ProductMarque.organisation_id,
        libelle: ProductMarque.libelle,
        image: ProductMarque.image,
      });
      ProductMarque.organisation_id !== null
        ? setselectedOrganisation(true)
        : setselectedOrganisation(false);
    } else {
      reset({
        _id: "",
        organisation_id: "",
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
      });
    }
  }, [ProductMarque]);

  const onSubmit = async (data: any) => {
    let response;
    if (selectedOrganisation) {
      data.organisation_id = organisation_id;
    } else {
      data.organisation_id = null;
    }
    data.societe_id = societe;
    if (data._id) {
      response = await dispatch(updateProductMarqueApi(data));
    } else {
      response = await dispatch(createProductMarqueApi(data));
    }
    if (response.payload.success) {
      ToastSuccess(response.payload.message);
      reset({
        _id: "",
        organisation_id: "",
        libelle: {
          fr: "",
          en: "",
          ar: "",
        },
        image: null,
      });

      setShowModal(false);
    } else {
      ToastWarning(response.payload.message);
    }
  };

  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="modal-2"
      dialogClassName="modal-20w"
      backdropClassName="modal-backdrop-2"
    >
      <Modal.Header closeButton>
        <Modal.Title className="c-primary">Ajouter Marque</Modal.Title>
      </Modal.Header>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <div className="container mt-4">
            <div className="row mb-3">
              <div className="col-md-6 ">
                <div className="form-group equal-height">
                  <label htmlFor="titre.fr">
                    <span style={{ color: "red" }}>*</span>Libelle{" "}
                  </label>
                  <input
                    className={`form-control mb-2 ${
                      errors?.libelle?.fr ? "is-invalid" : ""
                    }`}
                    type="text"
                    id="libelle-fr"
                    placeholder="libelle français"
                    {...register("libelle.fr")}
                  />
                  <div className="invalid-feedback">
                    {errors?.libelle?.fr &&
                      errors.libelle.fr.message &&
                      errors.libelle.fr.message.toString()}
                  </div>
                  <input
                    type="text"
                    placeholder="libelle anglais"
                    className={`form-control mb-2 ${
                      errors?.libelle?.en ? "is-invalid" : ""
                    }`}
                    {...register("libelle.en")}
                  />
                  <div className="invalid-feedback">
                    {errors?.libelle?.en &&
                      errors.libelle.en.message &&
                      errors.libelle.en.message.toString()}
                  </div>

                  <input
                    type="text"
                    placeholder="libelle  arabe"
                    className={`form-control mb-2 ${
                      errors?.libelle?.ar ? "is-invalid" : ""
                    }`}
                    {...register("libelle.ar")}
                  />
                  <div className="invalid-feedback">
                    {errors?.libelle?.ar &&
                      errors.libelle.ar.message &&
                      errors.libelle.ar.message.toString()}
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group ">
                  <label htmlFor="image">
                    <span style={{ color: "red" }}>*</span>Image{" "}
                  </label>
                  <input
                    className={`form-control mb-2 ${
                      errors?.image ? "is-invalid" : ""
                    }`}
                    type="file"
                    id="image"
                    {...register("image")}
                  />{" "}
                  <div className="invalid-feedback">
                    {errors?.image &&
                      errors.image.message &&
                      errors.image.message.toString()}
                  </div>
                  <div>{errors.image?.message}</div>
                  <div className="n-chk">
                    <label className="new-control new-radio radio-classic-primary">
                      <input
                        type="radio"
                        checked={selectedOrganisation}
                        onChange={(e) => setselectedOrganisation(true)}
                        value="connectedOnly"
                        className="new-control-input"
                        name="custom-radio-2"
                      />
                      <span className="new-control-indicator" />
                      <p className="fs-15">l'organisation connecté seulement</p>
                    </label>
                  </div>
                  <div className="n-chk">
                    <label className="new-control new-radio radio-classic-primary">
                      <input
                        type="radio"
                        checked={!selectedOrganisation}
                        onChange={(e) => setselectedOrganisation(false)}
                        value="allOrganizations"
                        className="new-control-input"
                        name="custom-radio-2"
                      />
                      <span className="new-control-indicator" />
                      <p className="fs-15">
                        Tous les organisations de la societe
                      </p>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="reset"
            onClick={(e) => setShowModal(false)}
            className="btn btn-primary-app"
          >
            Annuler
          </Button>
          <Button type="submit" className="btn btn-primary-app">
            Enregistrer
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
/*   <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Image</Form.Label>
              <div className="col-auto">
                <input
                  className="form-control mb-2"
                  type="file"
                  id="image"
                  {...register("image")}
                />
                <div>{errors.image?.message}</div>
              </div>
            </Form.Group>
        */
