import React from 'react';
import Login from '../../Components/Auth/Login';
import LoginForm from '../../Components/Auth/LoginForm';

const LoginPage: React.FC = () => {
  return (
    <div>
      <Login />
    </div>
  );
}

export default LoginPage;

