import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import {
  ListUsersRoles,
  deleteRoleApi,
  fetchUserRoles,
} from "../../_App/Redux/Slices/userRoles/userRoleSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import UserRoleForm from "./UserRoleForm";
import { UserRoleDataType } from "../../_App/Types/Forms/UserRoleDataType";
import { useTranslation } from "react-i18next";
import { selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";

const UserRoleList = () => {
  const dispatch = useAppDispatch();
  const userRoles = useAppSelector(ListUsersRoles);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedUserRole, setSelectedUserRole] =
    useState<UserRoleDataType | null>(null);
  const [deleteRoleId, setDeleteRoleId] = useState<string | null>(null);

  const autorisations = useAppSelector(selectedAutorisations);
  const CanCreateRole = autorisations?.CAN_CREATE_ROLE;
  const CanUpdateRole = autorisations?.CAN_UPDATE_ROLE;
  const CanDeleteRole = autorisations?.CAN_DELETE_ROLE;

  const handleDeleteRole = (id: string) => {
    setDeleteRoleId(id);
    setShowDeleteModal(true);
  };

  const handleUpdateRole = (updatedRole: UserRoleDataType) => {
    setSelectedUserRole(updatedRole);
    setShowModal(true);
  };

  const handleModalClose = () => {
    setSelectedUserRole(null);
    setShowModal(false);
  };

  const handleOpenDeleteModal = (roleId: string) => {
    setDeleteRoleId(roleId);
    setShowDeleteModal(true);
  };

  const handleConfirmDelete = () => {
    if (deleteRoleId) {
      dispatch(deleteRoleApi(deleteRoleId));
      setShowDeleteModal(false);
    }
  };

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, [dispatch]);
  const { t } = useTranslation();

  return (
    <div>
      <div className="d-flex justify-content-end ">
        {CanCreateRole && (
          <button
            className="btn btn-primary-app"
            style={{
              marginLeft: "15px",
              marginBottom: "16px",
            }}
            onClick={() => setShowModal(true)}
          >
            {t("Ajouter un rôle")}
          </button>
        )}
      </div>

      <UserRoleForm
        ShowModal={showModal}
        setShowModal={handleModalClose}
        userRole={selectedUserRole}
      />

      <div className="table-responsive">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">{t("Libellé")}</th>
              <th scope="col">{t("Rediriger vers")}</th>
              <th scope="col">{t("Actions")}</th>
            </tr>
          </thead>
          <tbody>
            {userRoles.map((userRole) => (
              <tr key={userRole._id}>
                <td>{userRole.libelle}</td>
                <td>{userRole.redirectTo}</td>
                <td>
                  {CanUpdateRole && (
                    <i
                      className="far fa-edit"
                      style={{
                        color: "#005eff",
                        marginRight: "5px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => handleUpdateRole(userRole)}
                      title="Modifier un rôle"
                    ></i>
                  )}
                  {CanDeleteRole && (
                    <i
                      className="far fa-trash-alt"
                      onClick={() => handleOpenDeleteModal(userRole._id)}
                      style={{
                        color: "#ff0000",
                        marginRight: "5px",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                      title="Supprimer un rôle"
                    ></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirmation de suppression")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Êtes-vous sûr de vouloir supprimer ce rôle?")}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={() => setShowDeleteModal(false)}
          >
            {t("Annuler")}
          </Button>
          <Button className="btn btn-primary-app" onClick={handleConfirmDelete}>
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserRoleList;
