import React, { useState, useEffect } from "react";
import HomeCaisse from "../../Components/JournalCaisse/HomeCaisse";
const HomeCaissePage = () => {
  const [showHomeCaisse, setShowHomeCaisse] = useState(true);

  useEffect(() => {
   
    setShowHomeCaisse(true);
  }, []); 

  return (
    <>
      {showHomeCaisse && <HomeCaisse onCloseCaisse={() => setShowHomeCaisse(true)} />}
    </>
  );
};

export default HomeCaissePage;
