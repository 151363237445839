import React from "react";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";

export default function InventaireDetail({ t, list_details ,setlist_details }: any) {
    const ChangeQuantite=(value:number,index:number)=>{
        setlist_details((prev:any)=>{
            const newInputs = [...prev];
            newInputs[index] = { ...newInputs[index], quantite: value };
            return newInputs;

        }
            )
    }
  return (
    <div>
      <div className="table-responsive">
        <table className="table table-bordered mb-5">
          <thead>
            <tr>
              <th>{t("Article")}</th>
              <th>{t("code Article")}</th>
              <th>{t("Prix Achat TTC")}</th>
              <th>{t("Prix Vente TTC")}</th>
              <th>{t("Qunatite")}</th>

            </tr>
          </thead>
          {list_details.length !== 0 ? (
            <tbody>
              {list_details?.map((item: any, index: number) =>
            
                  <tr key={index}>
                    <td>{item.article_id?.titre.fr}</td>
                    <td>{item.article_id?.code_article}</td>
                    <td>{item.prix_achat_ttc}</td>
                    <td>{item.prix_vente_ttc}</td>
                    <td>
                        <input 
                        type="text"
                        value={ list_details[index]?.quantite || ""}
                        className="input-gray-border "
                        onKeyPress={handleKeyPress_number}

                        onChange={(e)=>ChangeQuantite(+e.target.value , index)}
                        />
                        
                    </td>

                  </tr>
            
              )}
            </tbody>
          ) : (
            <tr>{t("aucun article")}</tr>
          )}

          <tbody></tbody>
        </table>
      </div>
    </div>
  );
}
