import { object, string, boolean, array } from 'yup';

export const AddUserSchema = object({
  id: string().required(), 
  nom: string().required('Nom Obligatoire!!'),
  prenom: string().required('Prénom Obligatoire!!'),
  email: string().email("Veuillez bien saisir un email valide").required('Email Obligatoire!!'),
  password: string().required('Mot de passe Obligatoire!!'),
  mobile: string().required('Mobile Obligatoire!!'),
 
  organisations: array().of(string()), 
  gender: string().oneOf(['homme', 'femme']).required('Genre Obligatoire!!'),
  role_id: string(), 
});
