import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import {
  selectUserId,
  selectJournalCaisse,
  selectUser,
} from "../../_App/Redux/Slices/Auth/authSlice";
import {
  createSessionJournalApi,
  getPrecedentSessionApi,
} from "../../_App/Redux/Slices/sessionJournal/sessionJournalSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { SessionJournalDataType } from "../../_App/Types/Forms/SessionJournalDataType";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const CheckboxLabel = styled.label`
  font-size: 16px;
  color: black;
`;

const CustomCheckbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  cursor: pointer;

  &:checked {
    border: 2px solid #007bff;
  }
`;

const StyledLabel = styled.label`
  margin-bottom: 16px;
`;

const StyledInput = styled.input`
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 16px;
  margin-top: 8px;
  font-size: 16px;
  width: 200px;
  display: inline-block;
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 4px;
`;

const Asterisk = styled.span`
  color: red;
  margin-right: 4px;
`;

const ConfirmationModal = styled(Modal)`
  .modal-dialog {
    max-width: 45%;
    margin: 1.75rem auto;
  }
`;

const OuvertureSessionForm = ({ onClose }: { onClose: () => void }) => {
  const dispatch = useAppDispatch();
  const userId = useAppSelector(selectUserId);
  const journalCaisse = useAppSelector(selectJournalCaisse);
  const journalId = journalCaisse?._id;
  const user = useAppSelector(selectUser);

  const [fontInitial, setFontInitial] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showModal] = useState(true);
  const [fondFinal, setFondFinal] = useState<number | null>(null);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const [showSessionOpeningTicket, setShowSessionOpeningTicket] =
    useState(false);

  const handleSessionOpeningTicketCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setShowSessionOpeningTicket(e.target.checked);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (journalId) {
          const precedentSession = await dispatch(
            getPrecedentSessionApi(journalId)
          );
  
          if (precedentSession.payload) {
            let fondFinalFromApi;
  
            if (precedentSession.payload.garde_caisse !== null && precedentSession.payload.garde_caisse !== undefined) {
              fondFinalFromApi = precedentSession.payload.garde_caisse;
            } else {
              fondFinalFromApi = precedentSession.payload.fond_final;
            }
  
            setFondFinal(fondFinalFromApi);
            setFontInitial(fondFinalFromApi?.toString() || "");
          }
        }
      } catch (error) {
        console.error(error);
        ToastWarning("Erreur de traitement des données!!");
      }
    };
  
    fetchData();
  }, [dispatch, journalId]);
  

  const navigate = useNavigate();

  const handleSave = async () => {
    if (journalId && userId) {
      const newSessionJournal: SessionJournalDataType = {
        user_id: userId,
        journal_id: journalId,
        fond_initial: parseFloat(fontInitial),
        cloture: false,
      };

      try {
        const response = await dispatch(
          createSessionJournalApi(newSessionJournal)
        );

        if (response.payload && response.payload.success) {
          ToastSuccess(response.payload.message);
          onClose();
          navigate("/caisse");
          if (showSessionOpeningTicket) {
            printSessionOpeningTicket(response.payload.data);
          }
        } else {
          ToastWarning(
            response.payload ? response.payload.message : "Erreur inconnue"
          );
        }
      } catch (error) {
        console.error(error);
        ToastWarning("Erreur de traitement des données!!");
      }
    }
  };

  const handleFontInitialChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;

    value = value.replace(/[^0-9.]/g, "");

    if (!/^\d+(\.\d*)?$/.test(value)) {
      setErrorMessage("Veuillez saisir un montant valide");
    } else {
      setErrorMessage("");
    }

    setFontInitial(value);
  };

  const handleConfirmation = () => {
    setIsConfirmationOpen(false);
    handleSave();
  };

  const printSessionOpeningTicket = (data: any) => {
    const formattedDateCreate = new Date(data.createdAt).toLocaleString();

    const printWindow = window.open("", "ouverture", "width=900,height=500");

    if (printWindow) {
      printWindow.document.write(`
        <html>
          <head>
            <title>Ticket d'ouverture de session</title>
            <style>
              body {
                font-family: Verdana, sans-serif;
                margin: 0;
                padding: 0;
                width: 6.5cm;
              }
              h2 {
                font-size: 16px;
                color: #000;
                text-align: center;
              }
              h3 {
                font-size: 11px;
                color: #000;
                margin-bottom: 10px;
                text-align: center;
              }
              p {
                font-size: 12px;
                color: #000;
                margin: 5px 0;
                margin-bottom: 10px;
              }
              hr {
                margin: 10px 0;
                border: none;
                border-top: 1px dashed #494949;
              }
            </style>
          </head>
          <body>
            <h2>Ouverture de session </h2>
            <h3>${formattedDateCreate}</h3>
            <hr />

            <p>Organisation: ${user?.selected_organisation?.nom}</p>
            <p>Vendeur: ${user?.nom} ${user?.prenom}</p>
            <p>Code Journal : ${journalCaisse?.code_journal}</p>

            <p>Fond initial: ${data.fond_initial}</p>
          </body>
        </html>
      `);

      printWindow.document.close();
      printWindow.print();
    } else {
      alert("Erreur lors de l'ouverture de la fenêtre d'impression.");
    }
  };
  const { t } = useTranslation();

  return (
    <Modal show={showModal} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title> {t("Ouverture de session journal")}</Modal.Title>
      </Modal.Header>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (fondFinal !== null && parseFloat(fontInitial) !== fondFinal) {
            setIsConfirmationOpen(true);
          } else {
            handleSave();
          }
        }}
      >
        <Modal.Body>
          <StyledLabel>
            {t("Fond initial")} <Asterisk>*</Asterisk>
            <StyledInput
              type="number"
              value={fontInitial}
              onChange={handleFontInitialChange}
            />
            {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
          </StyledLabel>

          <CheckboxContainer>
            <CustomCheckbox
              type="checkbox"
              id="printSessionOpeningTicketCheckbox"
              checked={showSessionOpeningTicket}
              onChange={handleSessionOpeningTicketCheckboxChange}
            />
            <CheckboxLabel htmlFor="printSessionOpeningTicketCheckbox">
              {t("Imprimer le ticket d'ouverture de session caisse")}
            </CheckboxLabel>
          </CheckboxContainer>
        </Modal.Body>

        <Modal.Footer>
          <Button
            type="button"
            variant="primary"
            onClick={() => {
              if (fondFinal !== null && parseFloat(fontInitial) !== fondFinal) {
                setIsConfirmationOpen(true);
              } else {
                handleSave();
              }
            }}
            disabled={!fontInitial.trim() || errorMessage !== ""}
          >
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </form>

      <ConfirmationModal
        show={isConfirmationOpen}
        onHide={() => setIsConfirmationOpen(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t("Confirmation")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t("Êtes-vous sûr que votre fond initial est :")} {fontInitial} ?
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            onClick={() => setIsConfirmationOpen(false)}
          >
            {t("Annuler")}
          </Button>
          <Button className="btn btn-primary-app" onClick={handleConfirmation}>
            {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </ConfirmationModal>
    </Modal>
  );
};

export default OuvertureSessionForm;
