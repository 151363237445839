import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListJournaux,
  getAllJournalCaisses,
} from "../../_App/Redux/Slices/journalCaisse/journalCaisseSlice";
import {
  SelectedOrganisation,
  SelectedOrganisationId,
} from "../../_App/Redux/Slices/Auth/authSlice";
import { useTranslation } from "react-i18next";

const JournauxCaisse = () => {
  const dispatch = useAppDispatch();
  const listJournaux = useAppSelector(ListJournaux);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, "0");
    const day = today.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  const [selectedDate, setSelectedDate] = useState(getTodayDate());

  useEffect(() => {
    if (currentOrganisation) {
      dispatch(getAllJournalCaisses(currentOrganisation));
    }
  }, [dispatch, currentOrganisation]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = listJournaux.slice(indexOfFirstItem, indexOfLastItem);
  const { t } = useTranslation();
  const [journalFilter, setJournalFilter] = useState("#");

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("Form submitted with filter:", journalFilter);
  };
  const [stateShow, setStateShow] = useState(false);
  const [show, setShow] = useState("");
  const [status, setStatus] = useState<boolean | string>(false);

  const open = () => {
    if (show !== "show") {
      setShow("show");
      setStateShow(true);
    } else {
      setShow("");
      setStateShow(false);
    }
  };
  return (
    <div className="row layout-spacing">
      <div className="col-lg-12">
        <div className="statbox widget box box-shadow">
          <div className="navbar-item flex-row search-ul navbar-dropdown">
            {/* <div className="nav-item align-self-center search-animated">
              <i className="fas fa-search"></i>
              <form
                className="form-inline search-full form-inline search"
                role="search"
                onSubmit={handleSubmit}
              >
                <div className="search-bar">
                  <input
                    style={{ background: "#f1f2f3", border: "none" }}
                    type="text"
                    className="form-control search-form-control ml-lg-auto"
                    placeholder={t("search")}
                    autoFocus
                    onChange={(e) => setJournalFilter(e.target.value)}
                  />
                </div>
              </form>
            </div> */}
            {/* <input
              type="text"
              className="form-control"
              style={{
                background: "#f1f2f3",
                border: "none",
                width: "auto",
                height: "auto",
              }}
              id="datepicker"
              value={t("Date") + ": " + selectedDate}
              onChange={(e) => setSelectedDate(e.currentTarget.value)}
            />{" "} */}
            <div
              className="nav-item dropdown language-dropdown more-dropdown"
              onClick={open}
              style={{ display: "flex", justifyContent: "right", float:"right" }}
            >
              <div
                className={"dropdown custom-dropdown-icon " + show}
                style={{ cursor: "pointer" }}
              >
                <a
                  className="dropdown-toggle btn"
                  href="#"
                  role="button"
                  id="customDropdown"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded={stateShow}
                >
                  <span>
                    {" "}
                    {status === false ? t("Non cloturé") : t("Cloturé")}{" "}
                  </span>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down"
                  >
                    <polyline points="6 9 12 15 18 9" />
                  </svg>
                </a>
                <div
                  className={"dropdown-menu dropdown-menu-right " + show}
                  aria-labelledby="customDropdown"
                >
                  <div
                    className="dropdown-item"
                    data-img-value="flag-de3"
                    data-value="all"
                    onClick={(e) => setStatus("all")}
                  >
                    {t("all")}
                  </div>

                  <div
                    className="dropdown-item"
                    data-img-value="flag-ca2"
                    data-value="Cloturé"
                    onClick={(e) => setStatus(true)}
                  >
                    {t("Cloturé")}
                  </div>
                  <div
                    className="dropdown-item"
                    data-img-value="flag-ca2"
                    data-value="Non cloturé"
                    onClick={(e) => setStatus(false)}
                  >
                    {t("Non cloturé")}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="widget-content widget-content-area"
            style={{ overflowX: "auto" }}
          >
            <table
              id="style-2"
              className="table style-2 table-hover"
              onClick={(e) => console.log(listJournaux)}
            >
              <thead>
                <tr>
                  <th
                    className="checkbox-column dt-no-sorting"
                    style={{ fontSize: "15px" }}
                  >
                    Code Journal
                  </th>
                  <th style={{ fontSize: "15px" }}>Date</th>
                  <th style={{ fontSize: "15px" }}>Utilisateur</th>
                  <th style={{ fontSize: "15px" }}>Fond initial</th>
                  <th style={{ fontSize: "15px" }}>Fond final</th>
                  <th style={{ fontSize: "15px" }}>Cloturé</th>
                </tr>
              </thead>
              <tbody>
                {currentItems &&
                  currentItems
                    .filter(
                      (item) => item.cloture === status || status === "all"
                    )
                    .map((item) => (
                      <tr
                        key={item._id}
                        onClick={(e) =>
                          (window.location.href =
                            "/mouvementcaisse/" + item._id)
                        }
                      >
                        <td
                          className="checkbox-column"
                          style={{ fontSize: "15px" }}
                        >
                          {item.code_journal}
                        </td>
                        <td style={{ fontSize: "15px" }}>{item.date}</td>
                        <td style={{ fontSize: "15px" }}>
                          {item.user_id?.nom}
                        </td>
                        <td style={{ fontSize: "15px" }}>
                          {item.font_initial}
                        </td>
                        <td style={{ fontSize: "15px" }}>{item.font_final}</td>
                        <td style={{ fontSize: "15px" }}>
                          {item.cloture ? "oui" : "non"}
                        </td>
                      </tr>
                    ))}
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={6} className="text-center">
                    <ul
                      className="pagination"
                      style={{ justifyContent: "center" }}
                    >
                      {Array.from(
                        {
                          length: Math.ceil(listJournaux.length / itemsPerPage),
                        },
                        (_, index) => (
                          <li
                            key={index}
                            className={`page-item ${
                              currentPage === index + 1 ? "active" : ""
                            }`}
                            style={{ backgroundColor: "transparent" }}
                          >
                            <button
                              className="page-link"
                              onClick={() => setCurrentPage(index + 1)}
                            >
                              {index + 1}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JournauxCaisse;
