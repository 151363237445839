import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListArticles,
  findArticleApi,
  findArticleByBarCode,
  findArticlebysocieteApi,
} from "../../_App/Redux/Slices/article/articleSlice";
import { SelectedOrganisations } from "../../_App/Redux/Slices/Auth/authSlice";
import {
  ListUsers,
  findUserBySocieteIdApi,
} from "../../_App/Redux/Slices/users/userSlice";
import { Modal } from "react-bootstrap";
import CommissionType from "../../_App/Types/Entites/Commission/parametrage_Commission";
import { CommissionSchema } from "../../_App/Schemas/CommissionSchema";
import { Resolver, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { handleKeyPress_number } from "../../_App/Helpers/helpers";
import {
  createCommissionApi,
  updateparametrageCommissionApi,
} from "../../_App/Redux/Slices/commission/commissionSlice";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import parametrage_CommissionType from "../../_App/Types/Entites/Commission/parametrage_Commission";
import moment from "moment";
import ConditionCommission from "./ConditionCommission";
import { toast } from "react-toastify";
import condition_CommissionType from "../../_App/Types/Entites/Commission/condition_CommissionType";

export default function CommissionForm({
  t,
  societe_id,
  ShowModal,
  setShowModal,
  commission,
}: any) {
  // init form hooks
  const formOptions = {
    resolver: yupResolver(
      CommissionSchema
    ) as unknown as Resolver<parametrage_CommissionType>,
  };
    useForm<parametrage_CommissionType>(formOptions);
    const {
      register: registerForm1,
      handleSubmit: handleSubmitForm1,
      formState: { errors: errorsForm1 },
      reset: resetForm1,
      getValues: getValue1,
      setValue: setValue1,
      watch:watch1
    } = useForm<parametrage_CommissionType>();
  
    const {
      register: registerForm2,
      handleSubmit: handleSubmitForm2,
      formState: { errors: errorsForm2 },
      reset: resetForm2, 
      getValues: getValue2,
      setValue: setValue2,
      watch: watch2,
    } = useForm<condition_CommissionType>();
  const dispatch = useAppDispatch();
  const list_organisation = useAppSelector(SelectedOrganisations);
  const list_article = useAppSelector(ListArticles);
  const list_user = useAppSelector(ListUsers);
  const [optionsorg, setoptionsorg] = useState<any[]>();
  const [optionsUser, setoptionsUser] = useState<any[]>();
  const [optionsArticle, setoptionsArticler] = useState<any[]>();
  const [organisationchoix, setorganisationchoix] = useState(false);
  const [listuserselected, setlistuserselected] = useState<string[]>([]);
  const [listorganisationselected, setlistorganisationselected] = useState<
    string[]
  >([]);
  const [articleInputs, setarticleInputs] = useState<any[]>([""]);
  const [articles, setarticles] = useState<any[]>([""]);

  useEffect(() => {
    if (commission) {
      resetForm1({
        commission: commission.commission,
        date_debut: moment(commission.date_debut).format("YYYY-MM-DD"),
        date_fin: moment(commission.date_fin).format("YYYY-MM-DD"),
      });
      if (commission?.user_id && commission.user_id.length !== 0) {
        setlistuserselected(commission.user_id);
      } else {
        setorganisationchoix(true);
        setlistorganisationselected(commission.organisation_id);
      }
    }
  }, [commission]);
  useEffect(() => {
    if (societe_id) {
      dispatch(findUserBySocieteIdApi(societe_id));
    }
  }, [dispatch, societe_id]);

  useEffect(() => {
    if (list_organisation) {
      const option = list_organisation.map((item: any) => ({
        value: item._id,
        label: item.nom,
      }));
      setoptionsorg(option);
    }
  }, [list_organisation]);

  useEffect(() => {
    if (list_user) {
      const option = list_user.map((item: any) => ({
        value: item._id,
        label: `${item.nom}  ${item.prenom}`,
      }));
      setoptionsUser(option);
    }
  }, [list_user]);

  useEffect(() => {
    if (list_article) {
      const option = list_article.map((item: any) => ({
        value: item._id,
        label: item.titre?.fr,
      }));
      setoptionsArticler(option);
    }
  }, [list_article]);
  const addArticleInput = () => {
    setarticles([...articles, ""]);
  };
  const handleSelectChangeUser = (selectedOption: any) => {
    const listUser = selectedOption.map((item: any) => item.value);
    setlistuserselected(listUser);
    setValue2('user_id',listUser)

  };

  const handleSelectChangeOrganisation = (selectedOption: any) => {
    const listorg = selectedOption.map((item: any) => item.value);
    setlistorganisationselected(listorg);
    setValue2('organisation_id',listorg)
  };



  const onSubmit = async (data: any) => {
  
    let response: any;
    if (commission?._id) {
      const commissiondata = {
        _id: commission._id,
        article_id: commission.article_id._id,
        organisation_id: listorganisationselected,
        user_id: listuserselected,
        societe_id: societe_id,
        commission: data.commission,
        date_fin: data.date_fin,
        date_debut: data.date_debut,
      };
      response = await dispatch(updateparametrageCommissionApi(commissiondata));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));

        window.location.reload();
      } else {
        ToastWarning(t(response.payload.message));
      }
    } else {
      let listcommission: any[] = [];
      articleInputs.forEach((element) => {
        if (element !== "") {
          const commissiondata = {
            article_id: element,
            organisation_id: listorganisationselected,
            user_id: listuserselected,
            societe_id: societe_id,
            commission: data.commission,
            date_fin: data.date_fin,
            date_debut: data.date_debut,
          };
          listcommission.push(commissiondata);
        }
      });
      
      response = await dispatch(createCommissionApi(listcommission));

      if (response.payload.success) {
        ToastSuccess(t(response.payload.message));
        window.location.reload();
        setShowModal(false);
      } else {
        ToastWarning(t(response.payload.message));
      }
    }
  };
  const save = ()=>{
   /* organisation_id : String,
    societe_id : String , 
    user_id :String ,
    commission:number,
    active:Boolean,
    conditions:Boolean,
    date_debut:string,
    date_fin:string,*/
    
    // organisation 
    // user connecter 
    //active true
    const entet = watch1()
    const condition = watch2()
    console.log(entet,condition)
  }



  return (
    <div>
      <Modal
        show={ShowModal}
        onHide={() => setShowModal(false)}
        className="modal-1"
        dialogClassName="modal-50w"
        backdropClassName="modal-backdrop-1"
      >
        <Modal.Header>
          <Modal.Title className="c-primary">
            {" "}
            {t("Ajouter Commission")}
          </Modal.Title>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => setShowModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </Modal.Header>
        <button onClick={save}>ok</button>

        <form >
          <Modal.Body>
            <div className="row">
              <div className="col-3">
                <strong>{t("date debut")}</strong>

                <input
                  type="Date"
                  className={`form-control `}
                  {...registerForm1("date_debut")}
                />
              </div>
              <div className="col-3">
                <strong>{t("date fin")}</strong>

                <input
                  type="Date"
                  className={`form-control `}
                  {...registerForm1("date_fin")}
                />
              </div>
            

              <div className="col-3 ">
                <strong>{t("Commission")}</strong>

                <input
                  type="text"
                  className={`form-control `}
                  onKeyPress={handleKeyPress_number}
                  {...registerForm1("commission")}
                />
              </div>
              <div className="col-3 mt-3">
                <div className="form-group">
                  <label>
                  <strong>{t(" Et")}</strong>
                  
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="et"
                    {...registerForm1("conditions")}
                    value={"et"}
                  />
                  <label htmlFor="ou">
                  <strong>{t("Ou")}</strong>
                  </label>
                  <input
                    className="m-2"
                    type="radio"
                    id="ou"
                    value={"ou"}
                    {...registerForm1("conditions")}
                  />
                </div>
              </div>
            </div>
            <div className="row mt-5">
              <ConditionCommission
                t={t}
                optionsorg={optionsorg}
                handleSelectChangeOrganisation={handleSelectChangeOrganisation}
                listorganisationselected={listorganisationselected}
                optionsUser={optionsUser}
                handleSelectChangeUser={handleSelectChangeUser}
                listuserselected={listuserselected}
                register={registerForm2}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="reset"
              onClick={(e) => setShowModal(false)}
              className="btn btn-primary-app"
            >
              {t("Annuler")}
            </button>
            <button type="submit" className="btn btn-primary-app">
              {t("Enregistrer")}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
}
