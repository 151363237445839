import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../_App/Redux/hooks';
import { SelectedOrganisationId, societeId } from '../../_App/Redux/Slices/Auth/authSlice';
import { ListReservations, findReservationsByOrganisationApi } from '../../_App/Redux/Slices/Reservation/ReservationSlice';
import Reservationlist from '../../Components/Reservation/Reservationlist';

export default function ReservationPage() {
  const dispatch=useAppDispatch()
  const { t } = useTranslation();
  const organisation_id = useAppSelector(SelectedOrganisationId)
  const listReservation = useAppSelector(ListReservations)
  console.log(listReservation)
   useEffect(() => {
    if(organisation_id)
    dispatch(findReservationsByOrganisationApi(organisation_id))
  }, []);
  return (
    <div> 
   
     <Reservationlist />
      
    </div>
  )
}
