import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import ClientType from "../../../Types/Entites/ClientType";
import axios from "axios";
import { RootState } from "../../store";
import {
  ConditionAchatType,
  ConditionClientType,
  ConditionProduitType,
  GratuiteType,
  RemiseType,
} from "../../../Types/Entites/RemiseType";

/**
 * create clients function
 * @private
 */
export const createRemise = createAsyncThunk(
  "remises/saveRemise",
  async (data: RemiseType, { rejectWithValue }) => {
    try {
      const response = await axios.post("remises/saveRemise", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of clients.
 * @public
 */
export const findRemisesByOrganisation = createAsyncThunk(
  "remises/findRemises",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`remises/findRemises/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create clients function
 * @private
 */
export const createGratuite = createAsyncThunk(
  "remises/createGratuite",
  async (data: GratuiteType, { rejectWithValue }) => {
    try {
      const response = await axios.post("remises/createGratuite", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of clients.
 * @public
 */
export const findGratuitesByOrganisations = createAsyncThunk(
  "remises/findGratuite",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(`remises/findGratuite/${id}`);
      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);

/**
 * create clients function
 * @private
 */
export const createConditionAchat = createAsyncThunk(
  "remises/saveConditionAchat",
  async (data: ConditionAchatType, { rejectWithValue }) => {
    try {
      const response = await axios.post("remises/saveConditionAchat", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
/**
 * create clients function
 * @private
 */
export const createConditionClient = createAsyncThunk(
  "remises/saveConditionClient",
  async (data: ConditionClientType, { rejectWithValue }) => {
    try {
      const response = await axios.post("remises/saveConditionClient", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * create clients function
 * @private
 */
export const createConditionProduit = createAsyncThunk(
  "remises/saveConditionProduit",
  async (data: ConditionProduitType, { rejectWithValue }) => {
    try {
      const response = await axios.post("remises/saveConditionProduit", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
/**
 * create clients function
 * @private
 */
export const checkDiscountsByArticle = createAsyncThunk(
  "remises/checkDiscountsByArticle",
  async (article_id: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `remises/checkDiscountsByArticle/${article_id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const getRemiseApplicable = createAsyncThunk(
  "remises/remisesCheck",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `remises/remisesCheck/${data.article_id}/${data.client_id}/${data.achat}/${data.qte}/${data.prix}/${data.organisation_id}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const getTestRemiseApplicable = createAsyncThunk(
  "remises/remisesCheck",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `remises/testremiseCheck`,data
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);
export const getProgramFideliteApplicable = createAsyncThunk(
  "remises/fideliteCheck",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`remises/fideliteCheck`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const getGratuitesApplicable = createAsyncThunk(
  "remises/gratuiteCheck",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`remises/gratuiteCheck`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

export const allCartRemises = createAsyncThunk(
  "remises/remiseCheck",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axios.post(`remises/remiseCheck`, data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface RemiseState {
  ListRemises: RemiseType[];
  listGratuites: GratuiteType[];
}

const initialState: RemiseState = {
  ListRemises: [],
  listGratuites: [],
};

export const RemiseState = createSlice({
  name: "remiseSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createRemise.fulfilled, (state, action) => {
      state.ListRemises.splice(0, 0, action.payload.data);
    });
    builder.addCase(findRemisesByOrganisation.fulfilled, (state, action) => {
      return {
        ...state,
        ListRemises: action.payload,
      };
    });

    builder.addCase(createGratuite.fulfilled, (state, action) => {
      state.listGratuites.splice(0, 0, action.payload.data);
    });
    builder.addCase(findGratuitesByOrganisations.fulfilled, (state, action) => {
      return {
        ...state,
        listGratuites: action.payload,
      };
    });
  },
});

export const ListRemises = (state: RootState) => state.remise.ListRemises;
export const ListGratuite = (state: RootState) => state.remise.listGratuites;

export default RemiseState.reducer;
