// TicketDeCaisseModal.tsx
import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactToPrint from "react-to-print";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  getOrderByIdApi,
  order,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import {
  findPaymentsByOrderApi,
  findPaymentsByTicketApi,
  listOperationsByPaymentMethod,
} from "../../../_App/Redux/Slices/payments/PaymentSlice";
import {
  Ticket,
  getTicketById,
} from "../../../_App/Redux/Slices/tickets/TicketSlice";
import { Printer, Model, WebUSB } from "escpos-buffer";
import { parametrageCaisse } from "../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import Barcode from "react-barcode";
import {
  currentCaisse,
  SelectedOrganisation,
  selectUser,
} from "../../../_App/Redux/Slices/Auth/authSlice";

const TicketDeCaisseModal = ({
  ShowModal,
  setShowModal,
  ticket_id,
  order_id,
}: any) => {
  const componentRef = useRef<HTMLDivElement>(null);
  const formatDate = ({ date }: { date: string }) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "numeric",
      month: "short",
      year: "numeric",
    };
    return new Date(date).toLocaleDateString(undefined, options);
  };

  const formatTime = ({ date }: { date: string }) => {
    const options: Intl.DateTimeFormatOptions = {
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };
    return new Date(date).toLocaleTimeString(undefined, options);
  };

  const currentDate = new Date();
  const mainOrder = useAppSelector(order);
  const ticket = useAppSelector(Ticket);
  const paymentDetails = useAppSelector(listOperationsByPaymentMethod);
  const parametrage = useAppSelector(parametrageCaisse);

  const dispatch = useAppDispatch();
  const magasin = useAppSelector(SelectedOrganisation);
  const user = useAppSelector(selectUser);
  const caisse = useAppSelector(currentCaisse);

  useEffect(() => {
    if (order_id) {
      dispatch(getOrderByIdApi(order_id));
      dispatch(findPaymentsByOrderApi(order_id));
    }
    if (ticket_id) {
      dispatch(getTicketById(ticket_id));
      dispatch(findPaymentsByTicketApi(ticket_id));
    }
  }, [dispatch, order_id, ticket_id]);

  useEffect(() => {
    // Access the hidden iframe and add print styles
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      const printDocument = printFrame.contentDocument;
      if (printDocument) {
        const style = document.createElement("style");
        style.textContent =
          "@media print { body { visibility: visible !important; } }";
        printDocument.head.appendChild(style);
      }
    }
  }, []);

  const handlePrint = () => {
    // Access the hidden iframe and trigger its print method
    const printFrame = document.getElementById(
      "print-frame"
    ) as HTMLIFrameElement | null;
    if (printFrame) {
      printFrame.contentWindow?.print();
    }
  };

  const reduction = () => {
    let sum = 0;
    ticket_id &&
      ticket &&
      ticket.ticket_order.items.map((item) =>
        item.quantity < 0 ? (sum += item.prix_total) : null
      );
    return Number(sum.toFixed(3));
  };

  const orderItemsGroupedByTva = _.groupBy(
    ticket?.ticket_order.items,
    "article.tva"
  );

  return (
    <Modal
      show={ShowModal}
      onHide={() => setShowModal(false)}
      className="ticket-modal"
      style={{ display: "flex", justifyContent: "center" }}
      size="lg"
    >
      <div ref={componentRef}>
        <div className="container-ticket" id="print-content">
          <div className="receipt_header">
            <h1 onClick={() => console.log(orderItemsGroupedByTva)}>
              Ticket de caisse <span>{magasin?.nom}</span>
            </h1>
            <h2>
              {magasin?.description} <span>{"Tel: " + magasin?.numero}</span>
            </h2>
          </div>

          <div className="row">
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Numro</p>
              <p className="inv-email-address">
                {ticket?.ticket_order?.order_number}
              </p>
            </div>
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Etab</p>
              <p className="inv-email-address">101</p>
            </div>
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Caissier</p>
              <p className="inv-email-address">{user?.nom}</p>
            </div>
            <div className="col-3" style={{ fontSize: "12px" }}>
              <p className="inv-street-addr mb-0">Caisse</p>
              <p className="inv-email-address">{caisse?.libelle}</p>
            </div>
          </div>

          <div className="receipt_body">
            <div className="date_time_con">
              <div className="date">
                {" "}
                {formatDate({
                  date: currentDate.toISOString(),
                })}{" "}
              </div>
              <div className="time">
                {" "}
                {formatTime({ date: new Date().toISOString() })}
              </div>
            </div>

            <div className="ticket-items">
              <table
                style={{
                  whiteSpace: "normal",
                  borderBottom: "1px dashed #000",
                }}
              >
                <thead>
                  <tr>
                    <th>Désignation</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  {ticket_id && ticket
                    ? ticket.ticket_order.items.map((item: any) => {
                        return item.quantity >= 1 ? (
                          <tr key={item.article._id}>
                            <td>
                              {item.quantity + " x "}
                              {item.article.titre.fr}
                              {item.article.caracteristiques &&
                              item.article.caracteristiques.length
                                ? item.article.caracteristiques.map(
                                    (caracteristique: any) =>
                                      caracteristique.value
                                  )
                                : null}
                            </td>

                            <td>{item.prix_total}</td>
                          </tr>
                        ) : null;
                      })
                    : null}
                </tbody>

                <tfoot>
                  <tr></tr>
                  {reduction() > 0 ? (
                    <tr>
                      <td>Reduction</td>

                      <td> {-reduction()} </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>Total</td>

                    <td> {ticket?.ticket_order.total.toFixed(3)} </td>
                  </tr>
                  {/* <tr>
                    <td>Remise</td>
                    <td></td>
                    <td></td>
                  </tr> */}
                  <tr style={{ fontWeight: "bold", fontSize: "20px" }}>
                    <td>Total Net</td>

                    <td>{ticket?.ticket_order.totalNet.toFixed(3)} </td>
                  </tr>

                  <>
                    <tr>
                      <td style={{ textAlign: "left" }} colSpan={4}>
                        Réglement(s)
                      </td>
                    </tr>
                    {Object.entries(paymentDetails).map(([key, value]) => (
                      <tr key={key}>
                        <td> {key} </td>

                        <td> {value[0].amount.toFixed(3)} </td>
                      </tr>
                    ))}

                    {Object.entries(paymentDetails).map(([key, value]) =>
                      value[0].rendu ? (
                        <tr key={key}>
                          <td> Rendu </td>

                          <td> {value[0].rendu.toFixed(3)} </td>
                        </tr>
                      ) : null
                    )}
                  </>
                </tfoot>
              </table>
              {Object.entries(orderItemsGroupedByTva).map(
                ([key, items]: any) => (
                  <div
                    className="row"
                    key={key}
                    style={{
                      borderBottom: "1px dashed #000",
                      margin: "2px",
                    }}
                  >
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p className="inv-street-addr mb-0">Taxe</p>
                      <p className="inv-email-address">TVA</p>
                    </div>
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p className="inv-street-addr mb-0">Base HT</p>
                      <p className="inv-email-address">
                        {items.reduce(
                          (acc: any, val: any) =>
                            acc + val.article.prix_vente_ht,
                          0
                        )}
                      </p>
                    </div>
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p
                        className="inv-street-addr mb-0"
                        onClick={() => console.log(key)}
                      >
                        Taux
                      </p>
                      <p className="inv-email-address">
                        {key === "undefined" ? 0 : key}
                      </p>
                    </div>
                    <div className="col-3" style={{ fontSize: "12px" }}>
                      <p className="inv-street-addr mb-0">Montant</p>
                      <p className="inv-email-address">
                        {(items.reduce(
                          (acc: any, val: any) =>
                            acc + val.article.prix_vente_ht,
                          0
                        ) *
                          Number(key)) /
                          100}
                      </p>
                    </div>
                  </div>
                )
              )}
            </div>
            <div
              className="barcode"
              style={{ display: "flex", justifyContent: "center" }}
            >
              {ticket_id && ticket && ticket.main_order && (
                <Barcode value={ticket?.ticket_order.order_number} />
              )}
            </div>
          </div>
          <div className="receipt-footer mt-2">
            <p style={{ textAlign: "center" }}>
              {parametrage && parametrage.message_promo}
            </p>
          </div>
        </div>
      </div>
      <ReactToPrint
        trigger={() => (
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            className="btn-info"
          >
            Print
          </Button>
        )}
        content={() => componentRef.current}
      />
      {/* Hidden iframe for printing */}
      <iframe
        id="print-frame"
        title="print-frame"
        style={{ display: "none" }}
        onLoad={() => handlePrint()}
      />
    </Modal>
  );
};

export default TicketDeCaisseModal;
