import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "../../store";
import TableType from "../../../Types/Entites/TableType";
import _ from "lodash";

interface UpdateTableStatusParams {
  table_id?: string;
  status: string | null;
}
/**
 * create tables function
 * @private
 */
export const createTableApi = createAsyncThunk(
  "table/createTableApi",
  async (data: TableType, { rejectWithValue }) => {
    try {
      const response = await axios.post("table/createTableApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * update tables function
 * @private
 */
export const updateTableApi = createAsyncThunk(
  "table/updateTableApi",
  async (data: TableType, { rejectWithValue }) => {
    try {
      const response = await axios.put("table/updateTableApi", data);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * remove tables function
 * @private
 */
export const removeTableApi = createAsyncThunk(
  "table/removeTableApi",
  async (id: string, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`table/removeTableApi/${id}`);
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

/**
 * Get list of tables.
 * @public
 */
export const findTableByOrganisationApi = createAsyncThunk(
  "table/findTableByOrganisationApi",
  async (id: string, { rejectWithValue, dispatch }) => {
    try {
      const response = await axios.get(
        `table/findTableByOrganisationApi/${id}`
      );

      return response.data;
    } catch (error: any) {
      console.log(error);
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
      });
    }
  }
);
export const updateTableStatusApi = createAsyncThunk(
  "table/updateTableStatusApi",
  async (params: UpdateTableStatusParams, { rejectWithValue }) => {
    try {
      const { table_id, status } = params;
      console.log(table_id, status);
      const response = await axios.put("table/updateTableStatusApi", {
        table_id,
        status,
      });
      return response.data;
    } catch (error: any) {
      return rejectWithValue({
        success: false,
        message: error.response.data.message,
        action: error.response.data.action,
        data: error.response.data.data,
      });
    }
  }
);

interface TableState {
  listTable: TableType[];
  listtableparzone: { [zonetableid: string]: TableType[] };
  numeroTable: number;
}

const initialState: TableState = {
  listTable: [],
  listtableparzone: {},
  numeroTable: 0,
};
export const TableSlice = createSlice({
  name: "table",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(createTableApi.fulfilled, (state, action) => {
      state.listTable.splice(0, 0, action.payload.data);
    });

    builder.addCase(updateTableApi.fulfilled, (state, action) => {
      const updatedTable = action.payload.data;
      var index = _.findIndex(state.listTable, {
        _id: updatedTable._id,
      });

      state.listTable.splice(index, 1, updatedTable);
    });
    builder.addCase(updateTableStatusApi.fulfilled, (state, action) => {
      const updated_table = action.payload.data;
      const updatedListTable = state.listTable.map(table => 
        table._id === updated_table._id ? updated_table : table
      );
      state.listTable = updatedListTable;
    });
    

    builder.addCase(removeTableApi.fulfilled, (state, action) => {
      const deletedTable = action.payload.data;
      state.listTable = state.listTable.filter(
        (table) => table._id !== deletedTable._id
      );
    });

    builder.addCase(findTableByOrganisationApi.fulfilled, (state, action) => {
      const data = action.payload;
      let maxNumero = 0;
      const groupedtableParZone = _.groupBy(action.payload, "zonetable");
      data.forEach((element: any) => {
        if (element.numero > maxNumero) {
          maxNumero = element.numero;
        }
      });
      state.listTable = action.payload;
      state.numeroTable = maxNumero;
      state.listtableparzone = groupedtableParZone;
    });
  },
});

export const ListTable = (state: RootState) => state.tables.listTable;
export const Listtableparzone = (state: RootState) =>
  state.tables.listtableparzone;

export const NumerTable = (state: RootState) => state.tables.numeroTable;

export default TableSlice.reducer;
