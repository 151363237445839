import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { SubmitHandler, useForm } from "react-hook-form";
import IntlTelInput from "react-intl-tel-input-18";
import "react-intl-tel-input-18/dist/main.css";
import "react-phone-number-input/style.css";
import Select from "react-select";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { SelectedOrganisations } from "../../_App/Redux/Slices/Auth/authSlice";
import { createAffectationApi } from "../../_App/Redux/Slices/affectationUserOrganisation/affectationUserOrganisationSlice";
import {
  ListUsersRoles,
  fetchUserRoles,
} from "../../_App/Redux/Slices/userRoles/userRoleSlice";
import { createUserApi } from "../../_App/Redux/Slices/users/userSlice";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { AddUserSchema } from "../../_App/Schemas/UserSchema";
import { UserRole } from "../../_App/Types/Entites/UserRole";
import AffectationUserOrganisationDataType from "../../_App/Types/Forms/AffectationUserOrganisationDataType";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import UserRoleForm from "../UserRole/UserRoleForm";
import { useTranslation } from "react-i18next";


const CustomModal = styled(Modal)`
  .modal-dialog {
    max-width: 800px;
    margin: 0 auto;
  }
`;

const AddUserForm = ({ ShowModal, setShowModal }: any) => {
  const dispatch = useAppDispatch();
  const formOptions = { resolver: yupResolver(AddUserSchema) };
  const { register, handleSubmit, formState, reset } = useForm<UserDataType>();
  const { errors } = formState;

  const organisations = useAppSelector(SelectedOrganisations);
  const [selectedOrganisations, setSelectedOrganisations] = useState<string[]>(
    []
  );
  const [mobile, setMobile] = useState<string>("");
  const userRoles = useAppSelector(ListUsersRoles);
  const [roles, setRoles] = useState<UserRole[]>([]);
  const [showUserRoleForm, setShowUserRoleForm] = useState(false);
  const {t} =useTranslation();

  useEffect(() => {
    dispatch(fetchUserRoles());
  }, [dispatch]);

  useEffect(() => {
    setRoles(userRoles);
  }, [userRoles]);
  const [selectedOrganisationsDates, setSelectedOrganisationsDates] = useState<
    Record<string, { date_debut: string; date_fin: string }>
  >({});

  const handleOrganisationDateChange = (
    organisationId: string,
    key: "date_debut" | "date_fin",
    value: string
  ) => {
    setSelectedOrganisationsDates((prevDates) => ({
      ...prevDates,
      [organisationId]: { ...(prevDates[organisationId] || {}), [key]: value },
    }));
  };

  const getOrganisationNameById = (organisationId: string) => {
    const selectedOrganisation = organisations?.find(
      (org) => org._id === organisationId
    );
    return selectedOrganisation ? selectedOrganisation.nom : "";
  };

  const onSubmit: SubmitHandler<UserDataType> = async (data: any) => {
    try {
      data.mobile = mobile;
      data.organisations = selectedOrganisations;
      data.langue = selectedLangue ? selectedLangue.value : null;
      data.image = data.image[0];
      const response = await dispatch(createUserApi(data));
      if (response.payload && response.payload.success) {
        ToastSuccess(response.payload.message);

        for (const organisationId of selectedOrganisations) {
          const dates = selectedOrganisationsDates[organisationId];
          if (dates) {
            const affectationData: AffectationUserOrganisationDataType = {
              user_id: response.payload.data._id,
              organisation_id: organisationId,
              date_debut: new Date(dates.date_debut),
              date_fin: new Date(dates.date_fin),
            };

            await dispatch(createAffectationApi(affectationData));
          }
        }
        reset();

        setShowModal(false);
      } else {
        ToastWarning(response.payload.message);
      }
    } catch (error) {
      ToastWarning(
        "Une erreur s'est produite lors de l'ajout de l'utilisateur."
      );
    }
  };
  const langueOptions = [
    { value: "fr", label: "Français" },
    { value: "en", label: "Anglais" },
    { value: "ar", label: "Arabe" },
  ];
  
  const [selectedLangue, setSelectedLangue] = useState<{ value: string; label: string } | null>(null);
  
  return (
    <CustomModal show={ShowModal} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>{t("Ajouter un nouveau utilisateur")}
</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit(onSubmit)} encType="multipart/form-data">
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formImage">
                <Form.Label>{t("Image")}</Form.Label>
                <Form.Control
                  type="file"
                  {...register("image")}
                  isInvalid={!!errors.image}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.image?.message}
                </Form.Control.Feedback>
              </Form.Group>
          
            </Col>
            <Col md={6}>
    <Form.Group controlId="formLangue" className="mb-3">
      <Form.Label>{t("Langue")}</Form.Label>
      <Select
        options={langueOptions}
        value={selectedLangue}
        onChange={(selectedOption) => setSelectedLangue(selectedOption)}
        isMulti={false}  
      />
      <Form.Control.Feedback type="invalid">
        {errors.langue?.message}
      </Form.Control.Feedback>
    </Form.Group>
  </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formNom">
                <Form.Label>{t("Nom")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Nom")}
                  {...register("nom", { required: "Nom obligatoire!!" })}
                  isInvalid={!!errors.nom}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.nom?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPrenom">
                <Form.Label>{t("Prénom")}</Form.Label>
                <Form.Control
                  type="text"
                  placeholder={t("Prénom")}
                  {...register("prenom", { required: "Prénom obligatoire!!" })}
                  isInvalid={!!errors.prenom}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.prenom?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group controlId="formEmail">
                <Form.Label>{t("Email")}</Form.Label>
                <Form.Control
                  type="email"
                  placeholder={t("Email")}
                  {...register("email", {
                    required: "Email obligatoire!!",
                    pattern: {
                      value: /^\S+@\S+$/i,
                      message: "Veuillez saisir un email valide",
                    },
                  })}
                  isInvalid={!!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.email?.message}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formPassword">
                <Form.Label>{t("Mot de passe")}</Form.Label>
                <Form.Control
                  type="password"
                  placeholder={t("Mot de passe")}
                  {...register("password", {
                    required: "Mot de passe obligatoire!!",
                  })}
                  isInvalid={!!errors.password}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.password?.message}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <Form.Label>{t("Téléphone")}</Form.Label>

              <Form.Group controlId="formMobile">
                <IntlTelInput
                  containerClassName="intl-tel-input"
                  inputClassName="form-control"
                  fieldName="mobile"
                  placeholder={t("Votre numéro mobile")}
                  value={mobile}
                  onPhoneNumberChange={(value, country, e, formattedValue) =>
                    setMobile(formattedValue || "")
                  }
                  style={{ width: "100%" }}
                  defaultCountry="tn"
                />
                {errors.mobile && (
                  <Form.Control.Feedback type="invalid">
                    {errors.mobile.message}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Label>{t("Votre numéro mobile")}</Form.Label>

              <Form.Group controlId="formRoleId">
                <div
                  style={{
                    position: "relative",
                    display: "inline-block",
                    width: "370px",
                  }}
                >
                  <Form.Control
                    as="select"
                    {...register("role_id")}
                    isInvalid={!!errors.role_id}
                  >
                    <option value="">{t("Sélectionner un rôle")}</option>
                    {roles.map((role) => (
                      <option key={role._id} value={role._id}>
                        {role.libelle}
                      </option>
                    ))}
                  </Form.Control>
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="tooltip-add-role">
                        {t("Ajouter un nouveau rôle utilisateur")}
                      </Tooltip>
                    }
                  >
                    <div
                      style={{
                        position: "absolute",
                        top: "50%",
                        right: "10px",
                        transform: "translateY(-50%)",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowUserRoleForm(true)}
                    >
                      <i
                        className="fas fa-plus"
                        style={{ color: "#426BD9", fontSize: "24px" }}
                      ></i>{" "}
                    </div>
                  </OverlayTrigger>
                </div>
                <Form.Control.Feedback type="invalid">
                  {errors.role_id?.message}
                </Form.Control.Feedback>
                <UserRoleForm
                  ShowModal={showUserRoleForm}
                  setShowModal={setShowUserRoleForm}
                  userRole={null}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Form.Group controlId="formGender" className="mb-3">
                <Form.Label> {t("Genre")}</Form.Label>
                <Form.Control
                  as="select"
                  {...register("gender")}
                  isInvalid={!!errors.gender}
                >
                  <option value="">{t("Sélectionner le genre")}</option>
                  <option value="homme">Homme</option>
                  <option value="femme">Femme</option>
                </Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.gender?.message}
                </Form.Control.Feedback>
              </Form.Group>

              {selectedOrganisations.map((organisationId, index) => (
                <Form.Group
                  key={organisationId}
                  controlId={`formPeriodeAffectation_${organisationId}`}
                >
                  <Form.Label
                    style={{
                      marginBottom:
                        index < selectedOrganisations.length - 1 ? "30px" : "0",
                    }}
                  >
                    {t("Période d'affectation pour")}{" "}
                    {getOrganisationNameById(organisationId)}
                  </Form.Label>
                </Form.Group>
              ))}
            </Col>

            <Col md={6}>
              <Form.Group controlId="formOrganisations" className="mb-3">
                <Form.Label>{t("Organisation")}</Form.Label>
                <Select
                  isMulti
                  options={organisations?.map((org) => ({
                    value: org._id,
                    label: org.nom,
                  }))}
                  value={selectedOrganisations.map((orgId) => ({
                    value: orgId,
                    label: getOrganisationNameById(orgId),
                  }))}
                  onChange={(selectedOptions: any) => {
                    const selectedOrganisations = selectedOptions.map(
                      (selectedOption: any) => selectedOption.value
                    );
                    setSelectedOrganisations(selectedOrganisations);
                  }}
                />
              </Form.Group>
              {selectedOrganisations.map((organisationId, index) => (
                <React.Fragment key={organisationId}>
                  <Row key={organisationId} className={index > 0 ? "mt-3" : ""}>
                    <Col md={6}>
                      <Form.Group controlId={`formDateDebut_${organisationId}`}>
                        <Form.Control
                          type="date"
                          onChange={(e) =>
                            handleOrganisationDateChange(
                              organisationId,
                              "date_debut",
                              e.target.value
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId={`formDateFin_${organisationId}`}>
                        <Form.Control
                          type="date"
                          onChange={(e) =>
                            handleOrganisationDateChange(
                              organisationId,
                              "date_fin",
                              e.target.value
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </React.Fragment>
              ))}
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-primary-app"
            type="reset"
            onClick={() => setShowModal(false)}
          >
           {t("Annuler")}
          </Button>
          <Button type="submit"className="btn btn-primary-app">
          {t("Enregistrer")}
          </Button>
        </Modal.Footer>
      </Form>
    </CustomModal>
  );
};

export default AddUserForm;
