import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import { Form } from "react-bootstrap";
import {
  resetRetrievedOrder,
  resetSelectedOrder,
  selectedOrder,
} from "../../../../_App/Redux/Slices/Orders/OrderSlice";
import { OrderType } from "../../../../_App/Types/Entites/Orders/OrderType";
import _ from "lodash";
import {
  PaidTicket,
  UnpaidTicket,
  findTicketsByOrderApi,
} from "../../../../_App/Redux/Slices/tickets/TicketSlice";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";

interface OrderProps {
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  hidePaymentButton: boolean | undefined;
  onArticlesToPayChange: (newArticlesToPay: newOrder | undefined) => void;
  miniOrder: (newOrder: OrderType | undefined) => void;
  retrievedOrder: OrderType | null;
}
interface PaidProduct {
  itemId: string;
  paid_quantity: number;
}
interface newOrder {
  orderId: string;
  paidProducts: PaidProduct[];
  newPrice: number;
}

interface ArticlesToPay {
  paidProducts: PaidProduct[];
}

const Order: React.FC<OrderProps> = ({
  setVisible,
  hidePaymentButton,
  onArticlesToPayChange,
  miniOrder,
  retrievedOrder,
}) => {
  const dispatch = useAppDispatch();
  const order = useAppSelector(selectedOrder);
  const parametrage = useAppSelector(parametrageCaisse);

  const openedOrder = retrievedOrder ? retrievedOrder : order;
  const paidTicket = useAppSelector(PaidTicket);
  const unpaidTicket = useAppSelector(UnpaidTicket);

  const [isPartialPayment, setIsPartialPayment] = useState(false);
  const [articlesToPay, setArticlesToPay] = useState<ArticlesToPay>(() => {
    const initialPaidProducts: PaidProduct[] = openedOrder!.items.map(
      (item) => ({
        itemId: item.article._id,
        paid_quantity: 0,
      })
    );
    console.log(initialPaidProducts);
    return {
      paidProducts: initialPaidProducts,
    };
  });

  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const boxClass = parametrage && parametrage.barcodeOnly ? " light-box" : "";
  const bgClass =
    parametrage && parametrage.barcodeOnly ? " light-background" : "";

  useEffect(() => {
    setIsPartialPayment(false);
    console.log(openedOrder);
    if (openedOrder) dispatch(findTicketsByOrderApi(openedOrder._id));
  }, [dispatch, openedOrder]);

  // Function to handle the switch change
  const handleSwitchChange = () => {
    setIsPartialPayment(!isPartialPayment);
    const initialPaidProducts: PaidProduct[] = openedOrder!.items.map(
      (item) => ({
        itemId: item.article._id,
        paid_quantity: 0,
      })
    );

    setArticlesToPay({
      paidProducts: initialPaidProducts,
    });
  };
  const handleSelectQuantityToPay = (itemId: string, quantity: number) => {
    let tempArray = _.cloneDeep(articlesToPay);
    tempArray.paidProducts.map((item: any) => {
      if (item.itemId === itemId) {
        return (item.paid_quantity = quantity);
      }
    });

    setArticlesToPay(tempArray);
  };

  function calculateTotalPrice(items: PaidProduct[]) {
    let total = 0;

    if (openedOrder && openedOrder.items) {
      items.forEach((item: PaidProduct) => {
        const foundItem = openedOrder.items.find(
          (element) => element.article._id === item.itemId
        );

        if (
          item &&
          item.itemId !== undefined &&
          foundItem &&
          foundItem.prix_unitaire !== undefined
        ) {
          total += item.paid_quantity * foundItem.prix_unitaire;
        }
      });
    }

    return total > 0 && isPartialPayment ? total : openedOrder?.total;
  }
  const articleQuantity = (item: any) => {
    return item.quantity - item.paid_quantity;
  };
  let paidMiniOrder = _.cloneDeep(openedOrder);
  const handleButtonClick = () => {
    if (isPartialPayment && paidMiniOrder.items) {
      for (const itemToPay of articlesToPay.paidProducts) {
        for (const mainItem of paidMiniOrder.items) {
          if (itemToPay.itemId === mainItem.article._id) {
            mainItem.quantity = itemToPay.paid_quantity;
            mainItem.prix_total =
              itemToPay.paid_quantity * mainItem.prix_unitaire;
          }
        }
      }
      paidMiniOrder.items = paidMiniOrder.items.filter(
        (item: any) => item.quantity !== 0
      );
      paidMiniOrder.total = 0;
      paidMiniOrder.items.map((item: any) => {
        return (paidMiniOrder.total += item.prix_total);
      });
      console.log(paidMiniOrder);

      const params = {
        orderId: openedOrder?._id || "",
        paidProducts: articlesToPay.paidProducts,
        newPrice: calculateTotalPrice(articlesToPay.paidProducts) || 0,
      };
      onArticlesToPayChange(params ? params : undefined);
      miniOrder(paidMiniOrder ? paidMiniOrder : undefined);
    } else {
      onArticlesToPayChange(undefined);
    }
    setVisible(true);
  };
  const { t } = useTranslation();

  return (
    <div>
      <div className="cart-header">
        <div className="widget-message" style={{ padding: "10px" }}>
          <div className="widget-title mb-10">
            <h5>{t("order") + openedOrder?.order_number}</h5>
            <button
              className="delete-button"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              onClick={() => {
                dispatch(resetSelectedOrder());
                dispatch(resetRetrievedOrder());
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      {openedOrder?.status === "Partiellement payée"
        ? unpaidTicket &&
          unpaidTicket?.items.map((item: any, index: number) => (
            <div className="widget-profile" key={item.article._id}>
              <div className="w-profile-view">
                <div className="col-6">
                  <div
                    className="w-profile-content"
                    style={{ display: "flex" }}
                  >
                    <img
                      src={
                        item.image && item.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="admin-profile"
                      className="img-fluid"
                    />
                    <div>
                      <h6 style={{ fontSize: "14px", fontWeight: "lighter" }}>
                        {item.article.titre.fr}
                      </h6>
                      {item.article.caracteristiques.length ? (
                        <p>
                          {item.article.caracteristiques.map(
                            (caracteristique: any) => caracteristique.value
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{
                    alignItems: "center",
                    justifyContent: "right",
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <div
                    className="w-profile-links"
                    style={{
                      padding: "0",
                      alignItems: "center",
                    }}
                  >
                    {!hidePaymentButton ? (
                      <>
                        <h6 className="mb-0" style={{ marginRight: "8px" }}>
                          {item.paid_quantity && !isPartialPayment
                            ? "Reste :"
                            : ""}
                        </h6>
                        <span className={"w-p-link" + btnClass}>
                          <p>{item.quantity - item.paid_quantity}</p>
                        </span>
                      </>
                    ) : isPartialPayment ? (
                      <>
                        <span className={"w-p-link" + btnClass}>
                          <p>{item.quantity - item.paid_quantity}</p>
                        </span>
                        <span className={"w-p-link" + btnClass}>
                          <p>
                            {articlesToPay.paidProducts.find(
                              (product) => product.itemId === item.article._id
                            )?.paid_quantity || 0}
                          </p>
                        </span>
                      </>
                    ) : (
                      <span className={"w-p-link" + btnClass}>
                        <p>{item.quantity - item.paid_quantity}</p>
                      </span>
                    )}

                    {isPartialPayment && !hidePaymentButton ? (
                      <span className="w-p-link">
                        <input
                          className="quantity-input"
                          type="text"
                          id="numericInput"
                          name="numericInput"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          value={
                            articlesToPay.paidProducts.find(
                              (product) => product.itemId === item.article._id
                            )?.paid_quantity || 0
                          }
                          min="0"
                          max={articleQuantity(item)}
                          onChange={(event) => {
                            const inputValue = event.target.value;
                            const parsedValue = parseInt(inputValue, 10);
                            const isValid =
                              !isNaN(parsedValue) &&
                              parsedValue >= 0 &&
                              parsedValue <= articleQuantity(item);

                            if (isValid) {
                              handleSelectQuantityToPay(
                                item.article._id,
                                parsedValue
                              );
                            } else {
                              handleSelectQuantityToPay(item.article._id, 0);
                            }
                          }}
                        />
                      </span>
                    ) : null}

                    <span
                      className="w-p-link"
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <p>{item.prix_unitaire}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        : openedOrder &&
          openedOrder?.items.map((item: any, index: number) =>
            articleQuantity(item) > 0 ? (
              <div className="widget-profile" key={item.article._id}>
                <div className={"w-profile-view" + boxClass}>
                  <div className="col-6">
                    <div
                      className="w-profile-content"
                      style={{ display: "flex" }}
                    >
                      <img
                        src={
                          item.image && item.image[0]
                            ? process.env.REACT_APP_API_PUBLIC_URL +
                              "/article/" +
                              item.image[0]
                            : "/assets/assets/img/no-image.jpg"
                        }
                        alt="admin-profile"
                        className="img-fluid"
                      />
                      <div>
                        <h6 style={{ fontSize: "14px", fontWeight: "lighter" }}>
                          {item.article.titre.fr}
                        </h6>
                        {item.article.caracteristiques.length ? (
                          <p>
                            {item.article.caracteristiques.map(
                              (caracteristique: any) => caracteristique.value
                            )}
                          </p>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div
                    className="col-6"
                    style={{
                      alignItems: "center",
                      justifyContent: "right",
                      display: "flex",
                      padding: 0,
                    }}
                  >
                    <div
                      className="w-profile-links"
                      style={{
                        padding: "0",
                        alignItems: "center",
                      }}
                    >
                      {!hidePaymentButton ? (
                        <>
                          <h6 className="mb-0" style={{ marginRight: "8px" }}>
                            {item.paid_quantity && !isPartialPayment
                              ? "Reste :"
                              : ""}
                          </h6>
                          <span className={"w-p-link" + btnClass}>
                            <p>{item.quantity - item.paid_quantity}</p>
                          </span>
                        </>
                      ) : isPartialPayment ? (
                        <>
                          <span className={"w-p-link" + btnClass}>
                            <p>{item.quantity - item.paid_quantity}</p>
                          </span>
                          <span className={"w-p-link" + btnClass}>
                            <p>
                              {articlesToPay.paidProducts.find(
                                (product) => product.itemId === item.article._id
                              )?.paid_quantity || 0}
                            </p>
                          </span>
                        </>
                      ) : (
                        <span className={"w-p-link" + btnClass}>
                          <p>{item.quantity - item.paid_quantity}</p>
                        </span>
                      )}

                      {isPartialPayment && !hidePaymentButton ? (
                        <span className={"w-p-link" + btnClass}>
                          <input
                            className="quantity-input"
                            type="text"
                            value={
                              articlesToPay.paidProducts.find(
                                (product) => product.itemId === item.article._id
                              )?.paid_quantity || 0
                            }
                            min="0"
                            max={articleQuantity(item)}
                            onChange={(event) => {
                              const inputValue = event.target.value;
                              const parsedValue = parseInt(inputValue, 10);
                              const isValid =
                                !isNaN(parsedValue) &&
                                parsedValue >= 0 &&
                                parsedValue <= articleQuantity(item);

                              if (isValid) {
                                handleSelectQuantityToPay(
                                  item.article._id,
                                  parsedValue
                                );
                              } else {
                                handleSelectQuantityToPay(item.article._id, 0);
                              }
                            }}
                          />
                        </span>
                      ) : null}

                      <span
                        className={"w-p-link" + btnClass}
                        style={{ width: "auto", justifyContent: "center" }}
                      >
                        <p>{item.prix_unitaire}</p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ) : null
          )}
      {/* <button onClick={() => console.log("paid : "  , JSON.stringify(paidTicket?.ticket_order.items))}>test</button> */}

      <div className="cart-footer">
        <div className={"widget-todo" + bgClass}>
          {isPartialPayment ? (
            <>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className={"align-self-center" + bgClass}>
                      Prix Total
                    </span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{openedOrder?.total.toFixed(3)}</p>
                </div>
              </div>
              <div className="todo-content">
                <div className="">
                  <h6>
                    <span className="align-self-center">
                      {t("Prix a payer")} :{" "}
                      {calculateTotalPrice(articlesToPay.paidProducts)}
                    </span>
                  </h6>
                </div>
              </div>
            </>
          ) : openedOrder?.status === "Partiellement payée" ? (
            <>
              <div className="todo-content">
                <div className="">
                  <h6>
                    <span className="align-self-center">
                      Prix total : {openedOrder?.total.toFixed(3)}
                    </span>
                    <span
                      className="align-self-center"
                      style={{ position: "absolute", right: "30px" }}
                    >
                      Montant payé :{" "}
                      {paidTicket?.ticket_order.ticket_order.total.toFixed(3)}
                    </span>
                  </h6>
                </div>
              </div>
              <div className="todo-content">
                <div className="todo-title">
                  <h6>
                    <span className="icon">
                      {/* <i className="fas fa-dollar-sign"></i> */}
                    </span>{" "}
                    <span className="align-self-center">Reste a payer</span>
                  </h6>
                </div>
                <div className="todo-text">
                  <p>{unpaidTicket?.total.toFixed(3)}</p>
                </div>
              </div>
            </>
          ) : (
            <div className="todo-content">
              <div className="todo-title">
                <h6>
                  <span
                    className={"align-self-center" + bgClass}
                    style={{
                      fontSize:
                        parametrage && parametrage.barcodeOnly
                          ? "40px"
                          : "unset",
                    }}
                  >
                    {t("Prix Total")}
                  </span>
                </h6>
              </div>
              <div className="todo-text">
                <p
                  style={{
                    fontSize:
                      parametrage && parametrage.barcodeOnly ? "40px" : "unset",
                  }}
                >
                  {openedOrder?.total.toFixed(3)}
                </p>
              </div>
            </div>
          )}
        </div>
        {/* {!hidePaymentButton && openedOrder?.status !== "Payée" ? (
          <div
            className="switch"
            style={{ justifyContent: "right", display: "flex" }}
          >
            <Form.Check
              type="switch"
              id="custom-switch"
              label={t("partial payment")}
              checked={isPartialPayment}
              onChange={handleSwitchChange}
            />
          </div>
        ) : null} */}

        <div
          className="widget-calendar-lists-scroll"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {!hidePaymentButton &&
          openedOrder?.status !== "Payée" &&
          parametrage &&
          !parametrage.barcodeOnly ? (
            <button
              className={"btn btn-success mb-2"}
              style={{ width: "100%", background: "#009688" }}
              onClick={(e) => handleButtonClick()}
            >
              {t("pay")}
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default Order;
