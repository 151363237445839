import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  ListCategories,
  ListCategoriesByCarte,
  findCategoriesApi,
  findCategoriesByCarteApi,
} from "../../_App/Redux/Slices/categories/categorieSlice";
import CategorieForm from "../../Components/Categories/CategorieForm";
import CategoriesList from "../../Components/Categories/CategoriesList";
import { useParams } from "react-router";
import { SelectedOrganisationId  ,societeId} from "../../_App/Redux/Slices/Auth/authSlice";
const CategoriesPage = () => {
  const { idcarte } = useParams();
  const [showModalCategorie, setShowModalCategorie] = useState(false);
  const [selectedCategorie, setSelectedCategorie] = useState(null); // State to hold the selected item
  const dispatch = useAppDispatch();
  const categories = useAppSelector(ListCategories);
  const categoriesOfCarte = useAppSelector(ListCategoriesByCarte);
  const currentOrganisation = useAppSelector(SelectedOrganisationId);
   const societe = useAppSelector(societeId);



  useEffect(() => {
    // Check if idcarte is defined before dispatching
    if (idcarte) {
      dispatch(findCategoriesByCarteApi(idcarte));
      console.log(idcarte);
    } else if (currentOrganisation){
      dispatch(findCategoriesApi(currentOrganisation));
    }
    console.log("idcarte:", idcarte);
  }, [dispatch, idcarte]);

  const openEditForm = (categorie: any) => {
    setSelectedCategorie(categorie);
    setShowModalCategorie(true);
  };
  return (
    <div>
      <div className="row">
        <button
          className="btn btn-primary btn-block mb-4 mr-2"
          onClick={() => setShowModalCategorie(true)}
        >
          Ajouter une catégorie
        </button>
      </div>
      <CategorieForm
        ShowModal={showModalCategorie}
        setShowModal={setShowModalCategorie}
        categorie={selectedCategorie}
        carteId={idcarte}
        currentOrganisation={currentOrganisation}
        societe={societe}

      />
      <CategoriesList
        categoriesListe={idcarte ? categoriesOfCarte : categories}
        onEditClick={openEditForm}
      />
    </div>
  );
};

export default CategoriesPage;
