import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../_App/Redux/hooks";
import {
  Panier,
  addToPanier,
  setQuantity,
} from "../../../_App/Redux/Slices/Caisse/PanierSlice";
import { ToastWarning } from "../../../Shared/Toasts/ToastWarning";
import {
  resetSelectedOrder,
  selectedOrder,
} from "../../../_App/Redux/Slices/Orders/OrderSlice";
import { Stocks } from "../../../_App/Redux/Slices/article/articleSlice";
import { SelectedOrganisationId } from "../../../_App/Redux/Slices/Auth/authSlice";
import { useDrag } from "react-dnd";

const CaisseArticlesItem = ({ Item, col }: any) => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(Panier);
  const stocks = useAppSelector(Stocks);

  const addToCart = async (article: any) => {
    console.log(article);
    dispatch(resetSelectedOrder());
    if (!cart.orderConfirmed) {
      dispatch(
        addToPanier({
          article: article,
          quantity: cart.calculatedQuantity,
          prix_unitaire: article.prix_vente_ttc,
          prix_total: article.prix_vente_ttc * cart.calculatedQuantity,
          paid_quantity: 0,
        })
      );
      dispatch(setQuantity(1));
    } else {
      ToastWarning("Commande confirmée !!");
    }
  };
  const [, drag] = useDrag({
    type: "caisseProduct",
    item: { data: Item }, // Make sure the structure matches what you expect in the drop target
  });
  return (
    <>
      <div
        className={`${col} col layout-spacing product-item`}
        onClick={() => {
          if (Item) {
            addToCart(Item);
          }
        }}
      >
        <div className="color-box">
          <div className="round-container" ref={drag}>
            {stocks && stocks[Item._id] ? (
              <span
                className="quantity-badge"
                style={{
                  background: stocks[Item._id][0].stock > 0 ? "green" : "red",
                }}
              >
                {" "}
                {stocks[Item._id][0].stock}{" "}
              </span>
            ) : null}

            <img
              src={
                Item.image && Item.image[0]
                  ? process.env.REACT_APP_API_PUBLIC_URL +
                    "/article/" +
                    Item.image[0]
                  : "/assets/assets/img/no-image.jpg"
              }
              alt="Your"
            />
          </div>

          <span className="product-detail-badge">
            <span className="text">
              {Item.titre.fr}
              {Item.caracteristiques.length
                ? Item.caracteristiques.map((item: any) => item.value + " ")
                : null}{" "}
            </span>{" "}
          </span>
        </div>
      </div>
    </>
  );
};

export default CaisseArticlesItem;
