// CategorieItem.js
import React from "react";
import { SousCategorie } from "../../../../_App/Types/Entites/SousCategorie";
interface SousCategorieItemProps {
  souscategorie: SousCategorie;
  onClick: () => void;
}

const SousCategorieItem: React.FC<SousCategorieItemProps> = ({
  souscategorie,
  onClick,
}) => {
  return (
    <div>
      <div className="card component-card_2" onClick={onClick}>
        {/* <img
          src={process.env.REACT_APP_API_BASE_URL + souscategorie.image[0]}
          className="card-img-top"
          alt={process.env.REACT_APP_API_BASE_URL + souscategorie.image[0]}
        /> */}
        <div className="card-body">
          <h5 className="card-title">{souscategorie.libelle.fr}</h5>
        </div>
      </div>
    </div>
  );
};

export default SousCategorieItem;
