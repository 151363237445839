import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import Select from "react-select";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import { ListCartes } from "../../_App/Redux/Slices/cartes/carteSlice";
import { ListCategoriesByCarte } from "../../_App/Redux/Slices/categories/categorieSlice";
import { CategorieSousCategories } from "../../_App/Redux/Slices/souscategories/souscategorieSlice";
import { findArticleByBarCode } from "../../_App/Redux/Slices/article/articleSlice";

export default function ConditionCommission({
  t,
  optionsorg,
  handleSelectChangeOrganisation,
  listorganisationselected,
  optionsUser,
  handleSelectChangeUser,
  listuserselected,
  register
}: any) {
  const dispatch = useAppDispatch();

  const [selectedCarte, setSelectedCarte] = useState<any>(null);
  const [selectedCategorie, setSelectedCategorie] = useState<any>(null);
  const [selectedSousCategorie, setSelectedSousCategorie] = useState<any>(null);
  const cartes = useAppSelector(ListCartes);
  const categories = useAppSelector(ListCategoriesByCarte);
  const sousCategories = useAppSelector(CategorieSousCategories);
  const [fournisseur, setFournisseur] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [article, setArticle] = useState(null);
  const [rand, setRand] = useState(true);

  const handleInputArticleChange = async (event: any) => {
    const barcode = event.target.value;
    setInputValue(barcode);
    const resultArticle = await dispatch(findArticleByBarCode(barcode));
    if (resultArticle.payload) {
      setArticle(resultArticle?.payload?._id);
      setFournisseur(resultArticle?.payload?.produit_ref?.fournisseurs);
      setInputValue(resultArticle?.payload?.titre?.fr); // Assuming the article name is in 'titre.fr'
    } else {
      // Handle the case where no article is found, if necessary
    }
  };
  const CartesOptions = cartes?.map((option) => ({
    value: option._id,
    label: option.libelle.fr,
  }));

  const handleChange = (option: any, type: string) => {
    if (type === "carte") {
      setSelectedCarte(option.value);
    } else if (type === "categorie") {
      setSelectedCategorie(option.value);
    } else {
      setSelectedSousCategorie(option.value);
    }
  };
  const categoriesdOptions =
    selectedCarte &&
    categories[selectedCarte]?.map((option) => ({
      value: option._id,
      label: option.libelle.fr,
    }));

  const sousCategoriesOptions =
    selectedCategorie &&
    sousCategories.map((option) => ({
      value: option._id,
      label: option.libelle.fr,
    }));

  const handleClearInput = () => {
    setInputValue("");
    setArticle(null);
  };
  return (
    <Tabs
      defaultActiveKey="profile"
      id="fill-tab-example"
      className="mb-3"
      fill
    >
      <Tab eventKey="client" title={t("Conditions organisation")}>
        <div className="row">
          <div className="col-12">
            <div>
              <label htmlFor="niveau ">
                <span style={{ color: "red" }}>*</span>
                {t("Organisation")}
              </label>
              <Select
                isMulti
                options={optionsorg}
                isSearchable={true}
                onChange={handleSelectChangeOrganisation}
                value={listorganisationselected.map((orgId: any) =>
                  optionsorg?.find((org: any) => org.value === orgId)
                )}
              
              />
            </div>
          </div>
        </div>
      </Tab>
      <Tab eventKey="user" title="Conditions user">
        <div className="row">
          <div className="col-12">
            <div className="form-group">
              <label htmlFor="montant">
                {" "}
                <span style={{ color: "red" }}>*</span>
                {t("Users")}
              </label>
              <Select
                isMulti
                options={optionsUser}
                isSearchable={true}
                onChange={handleSelectChangeUser}
                value={listuserselected.map((userid: any) =>
                  optionsUser?.find((user: any) => user.value === userid)
                )}
              />
            </div>
          </div>
        </div>
      </Tab>
      <Tab eventKey="achat" title="Condition achat">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="montant">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Montant minimum d'achat")}
              </label>
              <input type="number" className="form-control" id="montant"  {...register('montant_achat')}/>
            </div>
          </div>
         {/* <div className="col-6">
            <div className="form-group">
              <label htmlFor="cumulable">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Cumulable")}
              </label>
              <input
                className="m-2"
                //checked={cumulableachat}
                type="checkbox"
                id="cumulable"
              />
            </div>
          </div>*/}
        </div>
      </Tab>

      <Tab eventKey="produit" title="Conditions Produits">
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="carte">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Carte")}
              </label>
              <Select options={CartesOptions} isSearchable={true} />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="categorie">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Categorie")}
              </label>
              <Select
                options={categoriesdOptions}
                isSearchable={true}
                isDisabled={selectedCarte ? false : true}
              />{" "}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="sous">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Sous categorie")}
              </label>
              <Select
                value={selectedSousCategorie?.label}
                onChange={(e) => handleChange(e, "sous_categorie")}
                options={sousCategoriesOptions}
                isSearchable={true}
                isDisabled={selectedCategorie ? false : true}
              />{" "}
            </div>
          </div>

          <div className="col-6">
            <div className="form-group">
              <label htmlFor="Article">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Article")}
              </label>
              <div className="input-button" style={{ display: "flex" }}>
                <input
                  value={inputValue}
                  onChange={(e) => {
                    handleInputArticleChange(e);
                    setRand(!rand);
                  }}
                  type="text"
                  className="form-control"
                  id="Article"
                />
                {inputValue && (
                  <button
                    onClick={handleClearInput}
                    className="btn btn-secondary"
                  >
                    <i className="fas fa-times"></i>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="quantity">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Quantite / chiffre min")}
              </label>
              <input type="number" className="form-control" id="quantite" />
            </div>
          </div>
          <div className="col-6">
            <div className="form-group">
              <label htmlFor="quantity">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Quantite / chiffre max")}
              </label>
              <input type="number" className="form-control" id="quantite" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-4">
            <div className="form-group">
              <label>
                Quantité <span style={{ color: "red" }}></span>
              </label>
              <input
                className="m-2"
                type="radio"
                id="quantite1"
                name="quantite"
              />
              <label htmlFor="quantite1">
                Chiffre <span style={{ color: "red" }}></span>
              </label>
              <input
                className="m-2"
                type="radio"
                id="quantite2"
                name="quantite"
              />
            </div>
          </div>

          <div className="col-4">
            <div className="form-group">
              <label htmlFor="cumulable">
                {" "}
                <span style={{ color: "red" }}></span>
                {t("Cumulable")}
              </label>
              <input className="m-2" type="checkbox" id="cumulable" />
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
}
