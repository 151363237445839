import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../_App/Redux/hooks";
import {
  Panier,
  Reduction,
  resetReduction,
} from "../../_App/Redux/Slices/Caisse/PanierSlice";
import Swal from "sweetalert2";
import ReductionModal from "../../Components/Caisses/Reduction/ReductionModal";
import { getBonReductionByDepot } from "../../_App/Redux/Slices/reduction/reductionSlice";
import { DepotDefautOrganisation } from "../../_App/Redux/Slices/depot/depotSlice";

const TopTotalDisplay = () => {
  const dispatch = useAppDispatch();
  const cart = useAppSelector(Panier);
  const reduction = useAppSelector(Reduction);
  const [showModal, setShowModal] = useState(false);
  const currentDepot = useAppSelector(DepotDefautOrganisation);

  const handleResetReduction = async () => {
    Swal.fire({
      title: "Voulez vous vraiment annuler la reduction ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(resetReduction());
      }
    });
  };

  return (
    <div className="total">
      <h1
        onClick={() => console.log(currentDepot)}
        style={{ color: " #39FF14" }}
      >
        {" "}
        Total : {cart.totalNet?.toFixed(3)}{" "}
      </h1>
      {reduction !== 0 ? (
        <div
          className="badge btn-danger"
          style={{
            position: "absolute",
            right: "602px",
            bottom: "81%",
            width: "186px",
            height: "47px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "20px",
          }}
        >
          {" "}
          <i className="fas fa-tags mr-2"></i>
          {"- " + reduction.toFixed(3)}{" "}
          <i
            className="fas fa-times-circle ml-2"
            onClick={() => handleResetReduction()}
          ></i>
        </div>
      ) : null}
      <ReductionModal ShowModal={showModal} setShowModal={setShowModal} />
    </div>
  );
};

export default TopTotalDisplay;
