import UserRoleList from "../../Components/UserRole/UserRoleList";
import { selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../_App/Redux/hooks";
import React, { useEffect} from "react";
import { useNavigate } from 'react-router-dom';
import Navbar from "../../_Layouts/Navbar/Navbar";

const UserRolePage = () => {


  const autorisations = useAppSelector(selectedAutorisations);
const canViewRoles = autorisations?.CAN_VIEW_ROLES ;



const navigate = useNavigate();

useEffect(() => {
  if (!canViewRoles) {
    navigate("/unauthorized");
  }
}, [canViewRoles, navigate]);

  return (
    <>
      <Navbar  />
    <div>
      <UserRoleList />
    </div>
    </>
  );
};

export default UserRolePage;
