import React, { useEffect, useState } from "react";
import Numpad from "../NumPad/NumPad";

const GiftCardPaymentMethod = ({
  total,
  enteredAmountGiftCard,
  onAmountChange,
}: any) => {
  const [givenAmountGiftCard, setGivenAmountGiftCard] = useState<string | null>(
    null
  );
  const [isInputFocused, setIsInputFocused] = useState(false);

  // const containsOnlyNumbers = (str: string) => !/[^0-9.]/.test(str) && /^\d+(\.\d+)?$/.test(str);

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const amount: any = e.target.value;
    setGivenAmountGiftCard(isNaN(amount) ? null : amount);
    onAmountChange(isNaN(amount) ? null : parseFloat(amount));
  };

  useEffect(() => {
    setGivenAmountGiftCard(null);
  }, [total]);

  const handleButtonClick = (value: any) => {
    if (givenAmountGiftCard === null || givenAmountGiftCard === "0") {
      if (value === "." && givenAmountGiftCard === "0") {
        setGivenAmountGiftCard(givenAmountGiftCard + value.toString());
        onAmountChange(parseFloat(value));
      } else {
        setGivenAmountGiftCard(value.toString());
        onAmountChange(parseFloat(value));
      }
    } else {
      if (value === "." && givenAmountGiftCard.includes(".")) {
        // Do nothing if '.' is already present in the input
        return;
      }

      const newValue = givenAmountGiftCard.toString() + value.toString();
      setGivenAmountGiftCard(newValue);
      onAmountChange(parseFloat(newValue));
    }
  };

  const handleBackspaceClick = () => {
    if (givenAmountGiftCard !== null) {
      if (String(givenAmountGiftCard).length > 1) {
        let str = givenAmountGiftCard.toString();
        setGivenAmountGiftCard(str.slice(0, -1));
        onAmountChange(parseFloat(str.slice(0, -1)));
      } else if (String(givenAmountGiftCard).length === 1) {
        setGivenAmountGiftCard(null);
        onAmountChange(null);
      }
    }
  };

  const hideNumpad = () => {
    setIsInputFocused(false);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (
      e.relatedTarget &&
      (e.relatedTarget as HTMLElement).closest(".numpad-container")
    ) {
      return;
    }
    setIsInputFocused(false);
  };
  return (
    <div>
      <div className="navbar-item flex-row search-ul navbar-dropdown">
        <div className="nav-item align-self-center search-animated">
          <form
            className="form-inline search-full form-inline search"
            role="search"
          >
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control  ml-lg-auto"
                placeholder="Numéro GiftCard"
              />
            </div>
            <div className="search-bar" style={{ marginBottom: "7px" }}>
              <input
                type="text"
                className="form-control search-form-control  ml-lg-auto"
                placeholder="Montant"
                value={
                  enteredAmountGiftCard
                    ? enteredAmountGiftCard
                    : givenAmountGiftCard !== null
                    ? givenAmountGiftCard
                    : ""
                }
                onChange={handleAmountChange}
                onFocus={() => setIsInputFocused(true)}
                onBlur={handleBlur}
                readOnly
              />
            </div>
          </form>
        </div>
      </div>
      <div className={`numpad-container ${isInputFocused ? "show" : ""}`}>
        <Numpad
          onButtonClick={handleButtonClick}
          onBackspaceClick={handleBackspaceClick}
          onHideNumpad={hideNumpad}
        />
      </div>
    </div>
  );
};

export default GiftCardPaymentMethod;
