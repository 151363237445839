import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../_App/Redux/hooks";
import {
  resetRetrievedOrder,
  resetSelectedOrder,
  selectedOrder,
} from "../../../../_App/Redux/Slices/Orders/OrderSlice";
import TicketDeCaisse from "../../../../Components/Caisses/TicketDeCaisse/TicketDeCaisseModal";
import TicketCommande from "../../../../Components/Caisses/TicketDeCaisse/TicketDeCaissePaid";
import { useTranslation } from "react-i18next";
import { parametrageCaisse } from "../../../../_App/Redux/Slices/AppParametrages/caisseParametrages/caisseParametrageSlice";
import {
  resetReduction,
  setOperationType,
  setReduction,
} from "../../../../_App/Redux/Slices/Caisse/PanierSlice";
import Swal from "sweetalert2";
import { updateArticleStockApi } from "../../../../_App/Redux/Slices/article/articleSlice";
import { DepotDefautOrganisation } from "../../../../_App/Redux/Slices/depot/depotSlice";

const PaidOrder = ({ retrievedOrder }: any) => {
  const dispatch = useAppDispatch();
  const parametrage = useAppSelector(parametrageCaisse);
  const order = useAppSelector(selectedOrder);
  const openedOrder = retrievedOrder ? retrievedOrder : order;
  const [showModalTicket, setShowModalTicket] = useState(false);
  const currentDepot = useAppSelector(DepotDefautOrganisation);

  const { t } = useTranslation();
  const btnClass = parametrage && parametrage.barcodeOnly ? " big-btn" : "";
  const boxClass = parametrage && parametrage.barcodeOnly ? " light-box" : "";
  const bgClass =
    parametrage && parametrage.barcodeOnly ? " light-background" : "";
  const [refundedItems, setRefundedItems] = useState<any>([]);

  const handleSetTypeOperation = async (amount: any, refunded: any) => {
    Swal.fire({
      title: "Voulez vous vraiment retourner ces articles ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
    }).then(async (result) => {
      if (result.isConfirmed) {
        dispatch(setOperationType({ type: "Retour", refunded: refunded }));
        dispatch(setReduction(amount));
        refunded.map((item: any) => {
          dispatch(
            updateArticleStockApi({
              id: item.article._id,
              depotId: currentDepot._id,
              soldQuantity: -item.quantity,
            })
          );
        });
      }
    });
  };

  return (
    <div>
      <div className="cart-header">
        <div className="widget-message" style={{ padding: "10px" }}>
          <div className="widget-title mb-10">
            <h5>{t("Commande") + openedOrder?.order_number}</h5>
            <button
              className="delete-button"
              style={{
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
              }}
              onClick={() => {
                dispatch(resetSelectedOrder());
                dispatch(resetRetrievedOrder());
                dispatch(resetReduction());
              }}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      {parametrage && openedOrder ? (
        parametrage.barcodeOnly ? (
          <div className="widget-content searchable-container list">
            <div className="searchable-items list">
              <div className="items items-header-section item-content">
                <div className="">
                  <h4>Article</h4>
                </div>
                <div className="user-email">
                  <h4>Prix</h4>
                </div>
                <div className="user-location">
                  <h4> Quantité</h4>
                </div>
                <div className="user-location">
                  <h4> Total</h4>
                </div>
              </div>
              {openedOrder.items.map((item: any) => (
                <div className="items item-content">
                  <div className="user-profile">
                    <img
                      src={
                        item.article.image && item.article.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "article/" +
                            item.article.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="avatar"
                    />
                    <div className="user-meta-info">
                      <p className="user-name" data-name="Alan Green">
                        {item.article.titre.fr}
                      </p>
                      <p className="user-work" data-occupation="Web Developer">
                        {item.article.caracteristiques
                          .map(
                            (caracteristique: any) =>
                              caracteristique.value + " "
                          )
                          .join("")}
                      </p>
                    </div>
                  </div>
                  <div className="user-email">
                    <p className="usr-email-addr" data-email="alan@mail.com">
                      {item.prix_unitaire}
                    </p>
                  </div>
                  <div className="user-location">
                    <div
                      className="w-profile-links"
                      style={{
                        alignItems: "center",
                        justifyContent: "right",
                        display: "flex",
                        padding: 0,
                      }}
                    >
                      <span className={"w-p-link" + btnClass}>
                        <p>{item.quantity}</p>
                      </span>
                    </div>
                  </div>
                  <div className="user-email">
                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <p>
                        {item.prix_total.toFixed(3) +
                          parametrage.defaultCurrency}
                      </p>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          openedOrder?.items.map((item: any, index: number) => (
            <div className={"widget-profile"} key={index}>
              <div className={"w-profile-view" + boxClass}>
                <div className="col-6">
                  <div
                    className="w-profile-content"
                    style={{ display: "flex" }}
                  >
                    <img
                      src={
                        item.image && item.image[0]
                          ? process.env.REACT_APP_API_PUBLIC_URL +
                            "/article/" +
                            item.image[0]
                          : "/assets/assets/img/no-image.jpg"
                      }
                      alt="admin-profile"
                      className="img-fluid"
                    />
                    <div>
                      <h6
                        style={{
                          fontSize:
                            parametrage && parametrage.barcodeOnly
                              ? "30px"
                              : "14px",
                          fontWeight: "lighter",
                        }}
                      >
                        {item.article.titre.fr}
                      </h6>
                      {item.article.caracteristiques.length ? (
                        <p>
                          {item.article.caracteristiques.map(
                            (caracteristique: any) => caracteristique.value
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div
                  className="col-6"
                  style={{
                    alignItems: "center",
                    justifyContent: "right",
                    display: "flex",
                    padding: 0,
                  }}
                >
                  <div
                    className="w-profile-links"
                    style={{
                      padding: "0",
                      alignItems: "center",
                    }}
                  >
                    <span className={"w-p-link" + btnClass}>
                      <p>{item.quantity}</p>
                    </span>

                    <span
                      className={"w-p-link" + btnClass}
                      style={{ width: "auto", justifyContent: "center" }}
                    >
                      <p>{item.prix_total}</p>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          ))
        )
      ) : null}
      <div className="cart-footer">
        <div className={"widget-todo" + boxClass}>
          <div className="todo-content">
            <div className="todo-title">
              <h6>
                <span
                  className={"align-self-center" + boxClass}
                  style={{
                    fontSize:
                      parametrage && parametrage.barcodeOnly ? "40px" : "unset",
                  }}
                >
                  {t("Prix Total")}
                </span>
              </h6>
            </div>
            <div className="todo-text">
              <p
                style={{
                  fontSize:
                    parametrage && parametrage.barcodeOnly ? "40px" : "unset",
                }}
              >
                {openedOrder?.total.toFixed(3)}
              </p>
            </div>
          </div>
        </div>
        <div
          className="widget-calendar-lists-scroll"
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            className={"btn btn-success mb-2" + btnClass}
            style={{ width: "100%", background: "#009688" }}
            onClick={(e) => {
              openedOrder.items.map((item: any) => refundedItems.push(item));
              handleSetTypeOperation(openedOrder.total, refundedItems);
            }}
          >
            Echange / Retour
          </button>
          <button
            className={"btn btn-success mb-2" + btnClass}
            style={{ width: "100%", background: "#009688" }}
            onClick={() => setShowModalTicket(true)}
          >
            {t("print")} {t("ticket")}
          </button>
        </div>
      </div>
      <TicketCommande
        ShowModal={showModalTicket}
        setShowModal={setShowModalTicket}
        order_id={openedOrder?._id}
      />
    </div>
  );
};

export default PaidOrder;
