
import React, { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import styled from "styled-components";
import { ToastSuccess } from "../../Shared/Toasts/ToastSuccess";
import { ToastWarning } from "../../Shared/Toasts/ToastWarning";
import { initiateResetPasswordApi } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppDispatch } from "../../_App/Redux/hooks";
import { UserDataType } from "../../_App/Types/Forms/UserDataType";
import  { keyframes } from "styled-components";


const Container = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  background-color: #fff;
`;

const spinAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.i`
  color: #06958d;
  font-size: 24px;
  margin-bottom: 10px;
  display: inline-block;
  animation: ${spinAnimation} 1s linear infinite;
`;
const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
`;

const AnimatedIcon = styled.i`
  color: #06958d;
  font-size: 40px;
  margin-right: 5px;
  display: inline-block; // Assurez-vous que l'icône a un espace pour se déplacer
  animation: ${bounceAnimation} 1s infinite;
`;
const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  margin-bottom: 10px;
  font-size: 16px;
`;

const Input = styled.input`
  padding: 12px;
  margin-bottom: 15px;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
`;

const SubmitButton = styled.button`
  background-color: #007bff;
  color: #fff;
  padding: 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
  margin-bottom: 15px; // Ajout de la marge sous le bouton
`;


const Message = styled.p`
  margin-top: 10px; 
  margin-bottom: 0; 
  color: #555;
  font-size: 14px;
  text-align: center;
`;

const PasswordResetForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState("");
  const [isEmailSent, setIsEmailSent] = useState(false);

  const { register, handleSubmit } = useForm<UserDataType>();

  const onSubmit: SubmitHandler<UserDataType> = async (data) => {
    try {
      setIsSubmitting(true);
  
      const isEmail = /^\S+@\S+\.\S+$/.test(data.email);
     
      const response = await dispatch(initiateResetPasswordApi(data.email));
  
      if (response.payload.success) {
  
        if (isEmail) {
          ToastSuccess("Veuillez consulter votre Email pour trouver le lien de réinitialisation ");
          setMessage("Veuillez consulter votre Email pour trouver le lien de réinitialisation ");
          setIsEmailSent(true);
        } else {
          ToastSuccess(response.payload.message);
          setMessage(response.payload.message);
          setIsEmailSent(true);
        }
      } else {
        ToastWarning(response.payload.message);
        setMessage("");
      }
    } catch (error) {
      ToastWarning("Adresse Email invalide");
    } finally {
      setIsSubmitting(false);
    }
  };
  
 
  
 
  return (
    <Container>
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        {!isEmailSent && ( 
          <>
            <Input
              type="text"
              id="email"
              placeholder="Entrez votre numéro mobile ou votre email"
              {...register("email", { required: "Email requis" })}
              disabled={isSubmitting}
            />
            <SubmitButton type="submit" className="btn btn-primary-app">
              Envoyer le lien de réinitialisation
            </SubmitButton>
          </>
        )}
      {isSubmitting && <Spinner className="fas fa-spinner" />}
      {message && <Message> <div>   <AnimatedIcon className="far fa-envelope"></AnimatedIcon></div>{message}</Message>}
      </FormWrapper>
    </Container>
  );
};

export default PasswordResetForm;
