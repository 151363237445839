import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import UserList from "./UserList";
import UserItem from "./UserItem";
import AffectationList from "../AffectationUserOrg/AffectationList";
import UserRoleList from "../UserRole/UserRoleList";
import { useTranslation } from "react-i18next";
import { selectedAutorisations } from "../../_App/Redux/Slices/Auth/authSlice";
import { useAppSelector } from "../../_App/Redux/hooks";

import { useNavigate } from "react-router-dom";
import Navbar from "../../_Layouts/Navbar/Navbar";

const UserDisplayComponent = () => {
  const [displayList, setDisplayList] = useState(true);
  const [displayAffectations, setDisplayAffectations] = useState(false);
  const [displayRoles, setDisplayRoles] = useState(false);
  const { t } = useTranslation();
  const autorisations = useAppSelector(selectedAutorisations);
  const canViewUserOrgAff = autorisations?.CAN_VIEW_USER_ORG_AFF;
  const canViewUsers = autorisations?.CAN_VIEW_USERS;
  const canViewRoles = autorisations?.CAN_VIEW_ROLES;

  const navigate = useNavigate();

  useEffect(() => {
    if (!canViewUsers) {
      navigate("/unauthorized");
    }
  }, [canViewUsers, navigate]);

  return (
    <div style={{ marginTop: "0.5cm" }}>
      <div className="d-flex justify-content-end mb-3">
        <Button
          type="button"
          className="btn btn-primary-app"
          onClick={() => {
            setDisplayList(false);
            setDisplayAffectations(false);
            setDisplayRoles(false);
          }}
          style={{ marginRight: "5px" }}
        >
          <i className="far fa-address-card"></i>
        </Button>

        <Button
          type="button"
          className="btn btn-primary-app"
          onClick={() => {
            setDisplayList(true);
            setDisplayAffectations(false);
            setDisplayRoles(false);
          }}
          style={{ marginLeft: "5px" }}
        >
          <i className="fas fa-list"></i>
        </Button>
        {canViewUserOrgAff && (
          <Button
            type="button"
            className="btn btn-primary-app"
            onClick={() => {
              setDisplayAffectations(true);
              setDisplayList(false);
              setDisplayRoles(false);
            }}
            style={{ marginLeft: "5px" }}
          >
            {t("Affectations")}
          </Button>
        )}
        {canViewRoles && (
          <Button
            type="button"
            className="btn btn-primary-app"
            onClick={() => {
              setDisplayList(false);
              setDisplayAffectations(false);
              setDisplayRoles(true);
            }}
            style={{ marginLeft: "5px" }}
          >
            {t("Gestion des rôles")}
          </Button>
        )}
      </div>

      {displayList && <UserList />}
      {displayAffectations && <AffectationList />}
      {displayRoles && <UserRoleList />}
      {!displayList && !displayAffectations && !displayRoles && <UserItem />}
    </div>
  );
};

export default UserDisplayComponent;
