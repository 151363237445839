import React from "react";
import { useDrop } from "react-dnd";

const RaccourciVide = ({ Item, col, onDrop }: any) => {
  const [{ isOver }, drop] = useDrop({
    accept: "caisseProduct",
    drop: (item) => onDrop(item, Item._id), // Pass the raccourciId to the onDrop handler
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  return (
    <div
      className={`${col} col layout-spacing product-item`}
      onClick={() => {
        console.log(Item);
      }}
    >
      <div className="color-box">
        <div className="area">
          <div
            id="dropZone"
            ref={drop}
            style={{
              // Add your styles here
              border: isOver ? "2px solid #009688" : "2px dashed #009688",
              // backgroundColor: isOver ? " #009688" : "#c7e6e4",
              // color: isOver ? " #c7e6e4" : "#009688",
              padding: "10px",
              borderRadius: "20px",
              transition: "border 0.3s ease-in-out",
            }}
          >
            {" "}
            {Item && Item.data ? Item.data.ordre : Item.ordre}{" "}
          </div>
        </div>
        <div className="cl-info">
          <h5 className="cl-title" style={{ color: "transparent" }}>
            {Item && Item.data ? Item.data.ordre : Item.ordre}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default RaccourciVide;
